"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// index.ts
var piramid_constants_exports = {};
__export(piramid_constants_exports, {
  CHILDREN_WINDOW_DONE_WORD_TOKEN: () => CHILDREN_WINDOW_DONE_WORD_TOKEN,
  CLAIM_STATUS_WILDCARD_ORDER: () => CLAIM_STATUS_WILDCARD_ORDER,
  DEFAULT_COUNTRY_CODE: () => DEFAULT_COUNTRY_CODE,
  DEFAULT_CURRENCY: () => DEFAULT_CURRENCY,
  DEFAULT_ENDORSEMENT: () => DEFAULT_ENDORSEMENT,
  DEFAULT_LOCALE: () => DEFAULT_LOCALE,
  DEFAULT_TIMEZONE: () => DEFAULT_TIMEZONE,
  FALLBACK_LOCALE: () => FALLBACK_LOCALE,
  KACH_INSURER_SESSION_COOKIE_NAME: () => KACH_INSURER_SESSION_COOKIE_NAME,
  LEGAL_PERSON_AGE_THRESHOLD: () => LEGAL_PERSON_AGE_THRESHOLD,
  MAX_CLAIMED_AMOUNT: () => MAX_CLAIMED_AMOUNT,
  MAX_CLAIM_FILES_PER_UPLOAD: () => MAX_CLAIM_FILES_PER_UPLOAD,
  MAX_NEWS_REQUEST_FILES: () => MAX_NEWS_REQUEST_FILES,
  MAX_RFP_FILES: () => MAX_RFP_FILES,
  SYSTEM_EMAIL: () => SYSTEM_EMAIL,
  USER_TYPE_COOKIE_NAME: () => USER_TYPE_COOKIE_NAME,
  actions: () => actions,
  alertTables: () => alertTables,
  argentinaCities: () => argentinaCities,
  argentinaStateParties: () => argentinaStateParties,
  argentinaStates: () => argentinaStates,
  argentinaStatesRequiredParty: () => argentinaStatesRequiredParty,
  bodyParts: () => bodyParts,
  booleansString: () => booleansString,
  claimCheckAttributes: () => claimCheckAttributes,
  claimCombinations: () => claimCombinations,
  claimReactiveFormFlowSteps: () => claimReactiveFormFlowSteps,
  claimReportColumns: () => claimReportColumns,
  claimTypes: () => claimTypes,
  claimVehicleUses: () => claimVehicleUses,
  claimantVictimRelationships: () => claimantVictimRelationships,
  claimants: () => claimants,
  companyServiceBackends: () => companyServiceBackends,
  companyServices: () => companyServices,
  complaintCheckAttributes: () => complaintCheckAttributes,
  complaintReactiveFormFlowSteps: () => complaintReactiveFormFlowSteps,
  complaintWithLicensePlateVehicleTypes: () => complaintWithLicensePlateVehicleTypes,
  countryAndStates: () => countryAndStates,
  countryCodesAndName: () => countryCodesAndName,
  countryCuit: () => countryCuit,
  countryCurrencyList: () => countryCurrencyList,
  currencySymbolLabels: () => currencySymbolLabels,
  currencySymbolLocaleEquivalences: () => currencySymbolLocaleEquivalences,
  damageTypes: () => damageTypes,
  datasetEntities: () => datasetEntities,
  datasetEntityKeys: () => datasetEntityKeys,
  defaultElapsedDurationCriteria: () => defaultElapsedDurationCriteria,
  delegateClaimStrategies: () => delegateClaimStrategies,
  delegateComplaintStrategies: () => delegateComplaintStrategies,
  deriveClaimStrategies: () => deriveClaimStrategies,
  deriveComplaintStrategies: () => deriveComplaintStrategies,
  distinctList: () => distinctList,
  documentTypes: () => documentTypes,
  documentValidations: () => documentValidations,
  driverVehicleOwnership: () => driverVehicleOwnership,
  elapsedDurationCriteriaOptsByType: () => elapsedDurationCriteriaOptsByType,
  fieldAdjusterClaimStatus: () => fieldAdjusterClaimStatus,
  flattenDamageTypeToParentType: () => flattenDamageTypeToParentType,
  flattenDamageTypes: () => flattenDamageTypes,
  getAllColumnPaths: () => getAllColumnPaths,
  identityProviders: () => identityProviders,
  inhouseLawyerVictimRelationships: () => inhouseLawyerVictimRelationships,
  insurerClaimColumns: () => insurerClaimColumns,
  insurerClaimFilters: () => insurerClaimFilters,
  insurerComplaintColumns: () => insurerComplaintColumns,
  insurerComplaintFilters: () => insurerComplaintFilters,
  insurerSinisterColumns: () => insurerSinisterColumns,
  integrationTemplateParentObjectReferences: () => integrationTemplateParentObjectReferences,
  invalidChassisMessage: () => invalidChassisMessage,
  invalidEngineMessage: () => invalidEngineMessage,
  legalPersonClaimantVictimRelationships: () => legalPersonClaimantVictimRelationships,
  loadBalanceRuleFields: () => loadBalanceRuleFields,
  messageTemplateContextPaths: () => messageTemplateContextPaths,
  messageTemplateContextPathsByScope: () => messageTemplateContextPathsByScope,
  metricsCountGroupBy: () => metricsCountGroupBy,
  metricsFrequency: () => metricsFrequency,
  metricsHistogramBuckets: () => metricsHistogramBuckets,
  notificationEventsSupported: () => notificationEventsSupported,
  notificationScopes: () => notificationScopes,
  occurrenceCirculationDirection: () => occurrenceCirculationDirection,
  orderTypeSubTypes: () => orderTypeSubTypes,
  paraguayCities: () => paraguayCities,
  permissionLabelType: () => permissionLabelType,
  permissionsWithLabel: () => permissionsWithLabel,
  personDamageTypes: () => personDamageTypes,
  personParents: () => personParents,
  providerUserTypes: () => providerUserTypes,
  realPropertyOwnershipTypes: () => realPropertyOwnershipTypes,
  realPropertyTypes: () => realPropertyTypes,
  requiredFieldMessage: () => requiredFieldMessage,
  resources: () => resources,
  retrieveCaseInspectionTokenResource: () => retrieveCaseInspectionTokenResource,
  searchableEntities: () => searchableEntities,
  sessionTypes: () => sessionTypes,
  sexTypes: () => sexTypes,
  slaReportColumns: () => slaReportColumns,
  slaTypesWithTotalDuration: () => slaTypesWithTotalDuration,
  sortTypes: () => sortTypes,
  ssnStatementCsvOutputKeys: () => ssnStatementCsvOutputKeys,
  teamType: () => teamType,
  themes: () => themes,
  timezones: () => timezones,
  transformationApplyTo: () => transformationApplyTo,
  transformers: () => transformers,
  userTypes: () => userTypes,
  validationMessages: () => validationMessages,
  vehicleDocumentTypes: () => vehicleDocumentTypes,
  vehicleIdentificationKeys: () => vehicleIdentificationKeys,
  vehicleUses: () => vehicleUses,
  victimInjuriesRolesWithMaterialDamage: () => victimInjuriesRolesWithMaterialDamage,
  virtualMetrics: () => virtualMetrics,
  webhookEvents: () => webhookEvents,
  withLicensePlateVehicleTypes: () => withLicensePlateVehicleTypes,
  withoutLicensePlateVehicleTypes: () => withoutLicensePlateVehicleTypes
});
module.exports = __toCommonJS(piramid_constants_exports);

// src/user.ts
var userTypes = [
  "third",
  "system",
  "insurer",
  "policyholder",
  "backoffice",
  "field_adjuster",
  "vendor",
  "inhouse_lawyer"
];
var SYSTEM_EMAIL = "support@piramid.com.ar";

// src/alert.ts
var transformers = ["date_diff_workdays", "date_diff"];
var transformationApplyTo = ["comparison", "entity"];
var alertTables = [
  {
    label: "Casos",
    table: "case",
    type: "table",
    columns: [
      {
        label: "Estado",
        column: "status",
        type: "enum",
        enum: [
          {
            value: "not_assigned",
            label: "Sin asignaci\xF3n"
          },
          {
            value: "in_progress",
            label: "En progreso"
          },
          {
            value: "closed",
            label: "Cerrado"
          }
        ]
      },
      {
        label: "Solicitud de caso",
        column: "caseRequest",
        type: "table",
        columns: [
          {
            column: "denouncedAt",
            type: "date",
            label: "Fecha de denuncia"
          }
        ]
      }
    ]
  },
  {
    type: "table",
    label: "Intentos de env\xEDo de carta documento",
    table: "mailAttempt",
    columns: [
      {
        type: "date",
        column: "receivedAt",
        label: "Recibido el"
      },
      {
        type: "date",
        column: "sentAt",
        label: "Enviado el"
      },
      {
        type: "date",
        column: "expiredAt",
        label: "Expirado el"
      }
    ]
  },
  {
    type: "table",
    label: "Investigaciones",
    table: "investigation",
    columns: [
      {
        type: "enum",
        column: "status",
        label: "Estado",
        enum: [
          {
            label: "Pendiente",
            value: "pending"
          },
          {
            label: "Aceptado",
            value: "accepted"
          },
          {
            label: "Rechazado",
            value: "rejected"
          },
          {
            label: "Cerrado",
            value: "closed"
          }
        ]
      },
      {
        type: "date",
        column: "closedAt",
        label: "Cerrado el"
      },
      {
        type: "date",
        column: "acceptedAt",
        label: "Aceptado el"
      },
      {
        type: "date",
        column: "createdAt",
        label: "Creado el"
      }
    ]
  }
];
function getAllColumnPaths(entity) {
  const table = alertTables.find((ele) => ele.table === entity);
  if (!table) {
    return [];
  }
  const paths = [];
  for (const column of table.columns) {
    const isTable = column.type === "table";
    if (isTable) {
      for (const nestedColumn of column.columns) {
        paths.push(`${column.column}.${nestedColumn.column}`);
      }
    }
    if (!isTable) {
      paths.push(column.column);
    }
  }
  return paths;
}

// src/virtual-view.ts
var insurerClaimFilters = [
  "claim_damages",
  "responsible",
  "claimed_amount_from",
  "claimed_amount_to",
  "references",
  "status",
  "date_from",
  "date_to",
  "sub_status",
  "victim_relationship",
  "ramo",
  "team",
  "type",
  "coordinator",
  "states",
  "municipalities",
  "system_status",
  "stages"
];
var insurerComplaintFilters = [
  "responsible",
  "references",
  "status",
  "date_from",
  "date_to",
  "sub_status",
  "ramo",
  "team",
  "type",
  "stages",
  "system_status"
];
var insurerClaimColumns = [
  "victim",
  "damages",
  "claimed_at",
  "claimed_amount",
  "status",
  "reference",
  "sinister_number",
  "responsible"
];
var insurerComplaintColumns = [
  "type",
  "status",
  "damages",
  "description",
  "responsible",
  "policyholder",
  "created_at",
  "ramo",
  "reference",
  "stage",
  "system_status"
];
var insurerSinisterColumns = [
  "sinister_number",
  "created_at",
  "sinister_date",
  "policy_number",
  "license_plate"
];

// src/ssn.ts
var ssnStatementCsvOutputKeys = [
  "emp",
  "id",
  "hasInvestigation",
  "derivedAt",
  "sinisterId",
  "ramo",
  "investigationSummary",
  "result",
  "closeMotive",
  "allegedInvolvedType",
  "allegedInvolvedName",
  "allegedInvolvedDocumentType",
  "allegedInvolvedDocumentNumber",
  "allegedInvolvedEin",
  "allegedInvolvedAddress",
  "allegedInvolvedState",
  "allegedInvolvedCity",
  "allegedInvolvedZipCode",
  "professionalProfession",
  "professionalName",
  "professionalDocumentType",
  "professionalDocumentNumber",
  "professionalEin",
  "professionalAddress",
  "professionalState",
  "professionalCity",
  "professionalZipCode",
  "witnessName",
  "witnessDocumentType",
  "witnessDocumentNumber",
  "witnessEin",
  "witnessAddress",
  "witnessState",
  "witnessCity",
  "witnessZipCode",
  "lawyerType",
  "lawyerName",
  "lawyerDocumentType",
  "lawyerDocumentNumber",
  "lawyerEin",
  "lawyerState",
  "lawyerCity",
  "lawyerZipCode",
  "lawyerEnrollment",
  "liquidatorName",
  "liquidatorDocumentType",
  "liquidatorDocumentNumber",
  "liquidatorEin",
  "liquidatorSummary",
  "liquidatorEnrollment",
  "agreement",
  "surrender",
  "reluctance",
  "rejected",
  "prescription",
  "expiration",
  "demandRejected",
  "juice",
  "criminalComplaint",
  "complaint",
  "probation",
  "criminalConviction",
  "suspension",
  "others",
  "saving",
  "criminalComplaintPerform",
  "criminalComplaintAuthority",
  "criminalComplaintFollowUp",
  "criminalComplaintProfessionalAdvice",
  "criminalComplaintProfessionalAdviceName",
  "observations"
];

// src/message.ts
var requiredFieldMessage = "Campo requerido";
var invalidChassisMessage = "El n\xFAmero de chasis no es v\xE1lido";
var invalidEngineMessage = "El n\xFAmero de motor no es v\xE1lido";
var validationMessages = [{ name: "testingg" }];

// src/session.ts
var sessionTypes = [
  "system",
  "third",
  "policyholder",
  "insurer",
  "backoffice",
  "provider"
];
var providerUserTypes = [
  "field_adjuster",
  "vendor"
];

// src/timezone.ts
var timezones = ["America/Buenos_Aires"];

// src/document-type.ts
var documentTypes = ["dni", "passport"];

// src/sex.ts
var sexTypes = ["male", "female", "other"];

// src/company.ts
var companyServices = ["claim", "complaint"];
var companyServiceBackends = [
  "rivadavia",
  "tajy"
];

// src/person.ts
var personParents = ["father", "mother"];

// src/sla.ts
var virtualMetrics = [
  "time_to_first_response",
  "avg_response_time"
];
var elapsedDurationCriteriaOptsByType = {
  reference: ["working_days", "elapsed_days"],
  status: ["working_days", "elapsed_days"],
  stage: ["working_days", "elapsed_days"],
  chat: ["working_hours", "elapsed_hours"]
};
var slaTypesWithTotalDuration = [
  "stage",
  "reference"
];
var slaReportColumns = [
  "sla_type",
  "sla_name",
  "sla_elapsed_duration_criteria",
  "sla_version",
  "relation_type_responsible",
  "relation_type_coordinator",
  "relation_type_id",
  "started_at",
  "deadline",
  "completed_at",
  "status",
  "compliance",
  "sla_objective_reference_name",
  "progress",
  "sla_expected_time_to_resolution",
  "sla_current_time_to_resolution"
];
var defaultElapsedDurationCriteria = "elapsed_days";

// src/order.ts
var orderTypeSubTypes = {
  service: ["assessment", "repair", "investigation"],
  purchase: ["parts"]
};

// src/message-template.ts
var messageTemplateContextPaths = [
  "claim.owner.first_name",
  "claim.owner.last_name",
  "claim.owner.email",
  "claim.id",
  "claim.external_claim_id",
  "claim.sinister.policyholder_license_plate",
  "claim.sinister.selected_external_complaint.external_id",
  "file_request_placeholder_list",
  "claim.ref_id"
];
var messageTemplateContextPathsByScope = {
  offer: [],
  communication: messageTemplateContextPaths
};

// src/complaint.ts
var complaintCheckAttributes = [
  "license_plate",
  "policyholder",
  "chassis"
];
var delegateComplaintStrategies = ["users", "team"];
var deriveComplaintStrategies = ["user", "team"];
var DEFAULT_ENDORSEMENT = "0";

// src/window.ts
var CHILDREN_WINDOW_DONE_WORD_TOKEN = "done";

// src/claim.ts
var claimantVictimRelationships = [
  "brother",
  "mother",
  "father",
  "other_family_member",
  "tutor_curator",
  "attorney_in_fact",
  "attorney_representative",
  "other",
  "producer",
  "workshopper",
  "literate",
  "particular_victim"
];
var inhouseLawyerVictimRelationships = [
  "attorney_in_fact",
  "attorney_representative"
];
var legalPersonClaimantVictimRelationships = [
  "legal_representative",
  "attorney_in_fact",
  "attorney_representative",
  "other"
];
var damageTypes = ["material", "person"];
var personDamageTypes = [
  "injuries",
  "death",
  "reimbursement"
];
var claimCheckAttributes = [
  "license_plate",
  "policy_number",
  "zip_code"
];
var realPropertyTypes = [
  "commercial",
  "building",
  "government",
  "warehouse",
  "other",
  "housing"
];
var realPropertyOwnershipTypes = ["own", "rented", "other"];
var victimInjuriesRolesWithMaterialDamage = [
  "driver",
  "rider"
];
var claimants = ["legalPerson", "naturalPerson"];
var argentinaStatesRequiredParty = ["B", "S"];
var LEGAL_PERSON_AGE_THRESHOLD = 18;
var claimCombinations = [
  ["material"],
  ["person"],
  ["material", "person"],
  ["recovery"]
];
var flattenDamageTypes = [
  "death",
  "injury",
  "recovery",
  "material"
];
var flattenDamageTypeToParentType = {
  death: "person",
  injury: "person",
  material: "material",
  recovery: "recovery"
};
var delegateClaimStrategies = ["users", "team"];
var deriveClaimStrategies = ["user", "team"];
var claimReportColumns = [
  "id",
  "declared_damages",
  "occurrence_sinister_date",
  "occurrence_country",
  "occurrence_state",
  "occurrence_city",
  "occurrence_address",
  "occurrence_description",
  "occurrence_zip_code",
  "created_at",
  "desired_support_location",
  "claimed_amount",
  "created_by_email",
  "created_by_name",
  "victim_type",
  "victim_name",
  "victim_city",
  "victim_identification_type",
  "victim_identification_value",
  "ramo",
  "status",
  "policyholder_license_plate",
  "policyholder_policy_number",
  "reference",
  "responsible_name",
  "responsible_email",
  "complaint_external_id",
  "complaint_external_status",
  "complaint_agency",
  "complaint_occurrence_date",
  "complaint_registered_by",
  "complaint_handler_name",
  "complaint_handler_email",
  "complaint_registered_at",
  "complaint_location",
  "fraud_created_at",
  "fraud_reason",
  "fraud_close_motive",
  "fraud_external_status",
  "fraud_external_id",
  "fraud_sinister_type",
  "fraud_description",
  "fraud_result",
  "fraud_responsible_name",
  "fraud_responsible_email",
  "fraud_instance",
  "fraud_report_accepted_by_name",
  "fraud_report_accepted_by_email",
  "fraud_report_lab",
  "fraud_report_conclusion",
  "fraud_sinister_number"
];
var MAX_CLAIM_FILES_PER_UPLOAD = 5;
var claimTypes = [
  "material",
  "material_vehicle_with_license_plate",
  "material_vehicle_without_license_plate",
  "person_injury",
  "material_and_person_injury",
  "person_death",
  "material_real_property",
  "claimant_worker_compensation_insurer",
  "material_and_person_death"
];
var MAX_CLAIMED_AMOUNT = 999999999999999e-2;
var CLAIM_STATUS_WILDCARD_ORDER = -1;
var DEFAULT_TIMEZONE = "America/Argentina/Buenos_Aires";

// src/field-adjuster.ts
var fieldAdjusterClaimStatus = [
  "with_appraisal_report",
  "without_appraisal_report"
];

// src/validations.ts
var documentValidations = [
  {
    alias: "father_national_id_document_front",
    validations: [
      {
        entity: "national_identity_card",
        validation: "national_identity_card_fields"
      }
    ]
  },
  {
    alias: "mother_national_id_document_front",
    validations: [
      {
        entity: "national_identity_card",
        validation: "national_identity_card_fields"
      }
    ]
  },
  {
    alias: "certificate_medical_treatment",
    validations: [
      {
        entity: "medical_certificate",
        validation: "doctor_stamp_visible"
      },
      {
        entity: "medical_certificate",
        validation: "diagnosis_visible"
      },
      {
        entity: "medical_certificate",
        validation: "doctor_license_number_visible"
      }
    ]
  },
  {
    alias: "victim_national_identity_card_front",
    validations: [
      {
        validation: "birth_date",
        entity: "national_identity_card"
      }
    ]
  },
  {
    alias: "certificate_medical_treatment",
    validations: [
      {
        validation: "medical_certificate_sinister_date",
        entity: "medical_certificate"
      }
    ]
  },
  {
    alias: "certificate_medical_treatment",
    validations: [
      {
        validation: "medical_certificate_sinister_date",
        entity: "medical_certificate"
      }
    ]
  },
  {
    alias: "vehicle",
    validations: [
      {
        validation: "license_plate",
        entity: "vehicle"
      }
    ]
  },
  {
    alias: "vehicle_id_card_front",
    validations: [
      {
        validation: "make",
        entity: "vehicle_id_card_front"
      },
      {
        validation: "model",
        entity: "vehicle_id_card_front"
      },
      {
        validation: "license_plate",
        entity: "vehicle_id_card_front"
      }
    ]
  },
  {
    alias: "policy_coverage_certificate",
    validations: [
      {
        validation: "policy_period",
        entity: "coverage_certificate"
      }
    ]
  },
  {
    alias: "death_certificate",
    validations: [
      {
        validation: "death_certificate_fields",
        entity: "death_certificate"
      }
    ]
  },
  {
    alias: "vehicle_id_card_back",
    validations: [
      {
        validation: "address",
        entity: "vehicle_id_card_back"
      },
      {
        validation: "full_name",
        entity: "vehicle_id_card_back"
      },
      {
        validation: "document_number",
        entity: "vehicle_id_card_back"
      }
    ]
  }
];

// src/entity.ts
var searchableEntities = [
  "insurer",
  "worker_compensation_insurer",
  "health_insurance_fund"
];

// src/pagination.ts
var sortTypes = ["asc", "desc"];

// src/country.ts
var countryAndStates = [
  {
    code: "AFG",
    name: "Afghanistan",
    states: [
      { id: 3901, name: "Badakhshan", code: "BDS" },
      { id: 3871, name: "Badghis", code: "BDG" },
      { id: 3875, name: "Baghlan", code: "BGL" },
      { id: 3884, name: "Balkh", code: "BAL" },
      { id: 3872, name: "Bamyan", code: "BAM" },
      { id: 3892, name: "Daykundi", code: "DAY" },
      { id: 3899, name: "Farah", code: "FRA" },
      { id: 3889, name: "Faryab", code: "FYB" },
      { id: 3870, name: "Ghazni", code: "GHA" },
      { id: 3888, name: "Gh\u014Dr", code: "GHO" },
      { id: 3873, name: "Helmand", code: "HEL" },
      { id: 3887, name: "Herat", code: "HER" },
      { id: 3886, name: "Jowzjan", code: "JOW" },
      { id: 3902, name: "Kabul", code: "KAB" },
      { id: 3890, name: "Kandahar", code: "KAN" },
      { id: 3879, name: "Kapisa", code: "KAP" },
      { id: 3878, name: "Khost", code: "KHO" },
      { id: 3876, name: "Kunar", code: "KNR" },
      { id: 3900, name: "Kunduz Province", code: "KDZ" },
      { id: 3891, name: "Laghman", code: "LAG" },
      { id: 3897, name: "Logar", code: "LOG" },
      { id: 3882, name: "Nangarhar", code: "NAN" },
      { id: 3896, name: "Nimruz", code: "NIM" },
      { id: 3880, name: "Nuristan", code: "NUR" },
      { id: 3894, name: "Paktia", code: "PIA" },
      { id: 3877, name: "Paktika", code: "PKA" },
      { id: 3881, name: "Panjshir", code: "PAN" },
      { id: 3895, name: "Parwan", code: "PAR" },
      { id: 3883, name: "Samangan", code: "SAM" },
      { id: 3885, name: "Sar-e Pol", code: "SAR" },
      { id: 3893, name: "Takhar", code: "TAK" },
      { id: 3898, name: "Urozgan", code: "URU" },
      { id: 3874, name: "Zabul", code: "ZAB" }
    ]
  },
  { code: "ALA", name: "Aland Islands", states: [] },
  {
    code: "ALB",
    name: "Albania",
    states: [
      { id: 603, name: "Berat County", code: "01" },
      { id: 629, name: "Berat District", code: "BR" },
      { id: 607, name: "Bulqiz\xEB District", code: "BU" },
      { id: 618, name: "Delvin\xEB District", code: "DL" },
      { id: 608, name: "Devoll District", code: "DV" },
      { id: 610, name: "Dib\xEBr County", code: "09" },
      { id: 605, name: "Dib\xEBr District", code: "DI" },
      { id: 632, name: "Durr\xEBs County", code: "02" },
      { id: 639, name: "Durr\xEBs District", code: "DR" },
      { id: 598, name: "Elbasan County", code: "03" },
      { id: 631, name: "Fier County", code: "04" },
      { id: 627, name: "Fier District", code: "FR" },
      { id: 604, name: "Gjirokast\xEBr County", code: "05" },
      { id: 621, name: "Gjirokast\xEBr District", code: "GJ" },
      { id: 617, name: "Gramsh District", code: "GR" },
      { id: 600, name: "Has District", code: "HA" },
      { id: 594, name: "Kavaj\xEB District", code: "KA" },
      { id: 628, name: "Kolonj\xEB District", code: "ER" },
      { id: 630, name: "Kor\xE7\xEB County", code: "06" },
      { id: 597, name: "Kor\xE7\xEB District", code: "KO" },
      { id: 614, name: "Kruj\xEB District", code: "KR" },
      { id: 612, name: "Ku\xE7ov\xEB District", code: "KC" },
      { id: 601, name: "Kuk\xEBs County", code: "07" },
      { id: 623, name: "Kuk\xEBs District", code: "KU" },
      { id: 622, name: "Kurbin District", code: "KB" },
      { id: 609, name: "Lezh\xEB County", code: "08" },
      { id: 595, name: "Lezh\xEB District", code: "LE" },
      { id: 596, name: "Librazhd District", code: "LB" },
      { id: 599, name: "Lushnj\xEB District", code: "LU" },
      { id: 602, name: "Mal\xEBsi e Madhe District", code: "MM" },
      { id: 637, name: "Mallakast\xEBr District", code: "MK" },
      { id: 635, name: "Mat District", code: "MT" },
      { id: 638, name: "Mirdit\xEB District", code: "MR" },
      { id: 619, name: "Peqin District", code: "PQ" },
      { id: 625, name: "P\xEBrmet District", code: "PR" },
      { id: 606, name: "Pogradec District", code: "PG" },
      { id: 620, name: "Puk\xEB District", code: "PU" },
      { id: 624, name: "Sarand\xEB District", code: "SR" },
      { id: 611, name: "Shkod\xEBr County", code: "10" },
      { id: 626, name: "Shkod\xEBr District", code: "SH" },
      { id: 593, name: "Skrapar District", code: "SK" },
      { id: 616, name: "Tepelen\xEB District", code: "TE" },
      { id: 615, name: "Tirana County", code: "11" },
      { id: 633, name: "Tirana District", code: "TR" },
      { id: 636, name: "Tropoj\xEB District", code: "TP" },
      { id: 634, name: "Vlor\xEB County", code: "12" },
      { id: 613, name: "Vlor\xEB District", code: "VL" }
    ]
  },
  {
    code: "DZA",
    name: "Algeria",
    states: [
      { id: 1118, name: "Adrar", code: "01" },
      { id: 1119, name: "A\xEFn Defla", code: "44" },
      { id: 1122, name: "A\xEFn T\xE9mouchent", code: "46" },
      { id: 1144, name: "Algiers", code: "16" },
      { id: 1103, name: "Annaba", code: "23" },
      { id: 1142, name: "Batna", code: "05" },
      { id: 1108, name: "B\xE9char", code: "08" },
      { id: 1128, name: "B\xE9ja\xEFa", code: "06" },
      { id: 4909, name: "B\xE9ni Abb\xE8s", code: "53" },
      { id: 1114, name: "Biskra", code: "07" },
      { id: 1111, name: "Blida", code: "09" },
      { id: 4908, name: "Bordj Baji Mokhtar", code: "52" },
      { id: 1116, name: "Bordj Bou Arr\xE9ridj", code: "34" },
      { id: 1104, name: "Bou\xEFra", code: "10" },
      { id: 1125, name: "Boumerd\xE8s", code: "35" },
      { id: 1105, name: "Chlef", code: "02" },
      { id: 1121, name: "Constantine", code: "25" },
      { id: 4912, name: "Djanet", code: "56" },
      { id: 1098, name: "Djelfa", code: "17" },
      { id: 1129, name: "El Bayadh", code: "32" },
      { id: 4905, name: "El M'ghair", code: "49" },
      { id: 4906, name: "El Menia", code: "50" },
      { id: 1099, name: "El Oued", code: "39" },
      { id: 1100, name: "El Tarf", code: "36" },
      { id: 1127, name: "Gharda\xEFa", code: "47" },
      { id: 1137, name: "Guelma", code: "24" },
      { id: 1112, name: "Illizi", code: "33" },
      { id: 4914, name: "In Guezzam", code: "58" },
      { id: 4913, name: "In Salah", code: "57" },
      { id: 1113, name: "Jijel", code: "18" },
      { id: 1126, name: "Khenchela", code: "40" },
      { id: 1138, name: "Laghouat", code: "03" },
      { id: 1134, name: "M'Sila", code: "28" },
      { id: 1124, name: "Mascara", code: "29" },
      { id: 1109, name: "M\xE9d\xE9a", code: "26" },
      { id: 1132, name: "Mila", code: "43" },
      { id: 1140, name: "Mostaganem", code: "27" },
      { id: 1102, name: "Naama", code: "45" },
      { id: 1101, name: "Oran", code: "31" },
      { id: 1139, name: "Ouargla", code: "30" },
      { id: 4907, name: "Ouled Djellal", code: "51" },
      { id: 1136, name: "Oum El Bouaghi", code: "04" },
      { id: 1130, name: "Relizane", code: "48" },
      { id: 1123, name: "Sa\xEFda", code: "20" },
      { id: 1141, name: "S\xE9tif", code: "19" },
      { id: 4902, name: "Sidi Bel Abb\xE8s", code: "22" },
      { id: 1110, name: "Skikda", code: "21" },
      { id: 1143, name: "Souk Ahras", code: "41" },
      { id: 1135, name: "Tamanghasset", code: "11" },
      { id: 1117, name: "T\xE9bessa", code: "12" },
      { id: 1106, name: "Tiaret", code: "14" },
      { id: 4910, name: "Timimoun", code: "54" },
      { id: 1120, name: "Tindouf", code: "37" },
      { id: 1115, name: "Tipasa", code: "42" },
      { id: 1133, name: "Tissemsilt", code: "38" },
      { id: 1131, name: "Tizi Ouzou", code: "15" },
      { id: 1107, name: "Tlemcen", code: "13" },
      { id: 4911, name: "Touggourt", code: "55" }
    ]
  },
  { code: "ASM", name: "American Samoa", states: [] },
  {
    code: "AND",
    name: "Andorra",
    states: [
      { id: 488, name: "Andorra la Vella", code: "07" },
      { id: 489, name: "Canillo", code: "02" },
      { id: 487, name: "Encamp", code: "03" },
      { id: 492, name: "Escaldes-Engordany", code: "08" },
      { id: 493, name: "La Massana", code: "04" },
      { id: 491, name: "Ordino", code: "05" },
      { id: 490, name: "Sant Juli\xE0 de L\xF2ria", code: "06" }
    ]
  },
  {
    code: "AGO",
    name: "Angola",
    states: [
      { id: 221, name: "Bengo Province", code: "BGO" },
      { id: 218, name: "Benguela Province", code: "BGU" },
      { id: 212, name: "Bi\xE9 Province", code: "BIE" },
      { id: 228, name: "Cabinda Province", code: "CAB" },
      { id: 226, name: "Cuando Cubango Province", code: "CCU" },
      { id: 217, name: "Cuanza Norte Province", code: "CNO" },
      { id: 216, name: "Cuanza Sul", code: "CUS" },
      { id: 215, name: "Cunene Province", code: "CNN" },
      { id: 213, name: "Huambo Province", code: "HUA" },
      { id: 225, name: "Hu\xEDla Province", code: "HUI" },
      { id: 222, name: "Luanda Province", code: "LUA" },
      { id: 223, name: "Lunda Norte Province", code: "LNO" },
      { id: 220, name: "Lunda Sul Province", code: "LSU" },
      { id: 227, name: "Malanje Province", code: "MAL" },
      { id: 219, name: "Moxico Province", code: "MOX" },
      { id: 224, name: "U\xEDge Province", code: "UIG" },
      { id: 214, name: "Zaire Province", code: "ZAI" }
    ]
  },
  { code: "AIA", name: "Anguilla", states: [] },
  { code: "ATA", name: "Antarctica", states: [] },
  {
    code: "ATG",
    name: "Antigua And Barbuda",
    states: [
      { id: 3708, name: "Barbuda", code: "10" },
      { id: 3703, name: "Redonda", code: "11" },
      { id: 3709, name: "Saint George Parish", code: "03" },
      { id: 3706, name: "Saint John Parish", code: "04" },
      { id: 3707, name: "Saint Mary Parish", code: "05" },
      { id: 3705, name: "Saint Paul Parish", code: "06" },
      { id: 3704, name: "Saint Peter Parish", code: "07" },
      { id: 3710, name: "Saint Philip Parish", code: "08" }
    ]
  },
  {
    code: "ARG",
    name: "Argentina",
    states: [
      { id: 3656, name: "Buenos Aires", code: "B" },
      { id: 3647, name: "Catamarca", code: "K" },
      { id: 3640, name: "Chaco", code: "H" },
      { id: 3651, name: "Chubut", code: "U" },
      { id: 4880, name: "Ciudad Aut\xF3noma de Buenos Aires", code: "C" },
      { id: 3642, name: "C\xF3rdoba", code: "X" },
      { id: 3638, name: "Corrientes", code: "W" },
      { id: 3654, name: "Entre R\xEDos", code: "E" },
      { id: 3652, name: "Formosa", code: "P" },
      { id: 3645, name: "Jujuy", code: "Y" },
      { id: 3655, name: "La Pampa", code: "L" },
      { id: 3653, name: "La Rioja", code: "F" },
      { id: 3646, name: "Mendoza", code: "M" },
      { id: 3644, name: "Misiones", code: "N" },
      { id: 3648, name: "Neuqu\xE9n", code: "Q" },
      { id: 3639, name: "R\xEDo Negro", code: "R" },
      { id: 3643, name: "Salta", code: "A" },
      { id: 3634, name: "San Juan", code: "J" },
      { id: 3636, name: "San Luis", code: "D" },
      { id: 3649, name: "Santa Cruz", code: "Z" },
      { id: 3641, name: "Santa Fe", code: "S" },
      { id: 3635, name: "Santiago del Estero", code: "G" },
      { id: 3650, name: "Tierra del Fuego", code: "V" },
      { id: 3637, name: "Tucum\xE1n", code: "T" }
    ]
  },
  {
    code: "ARM",
    name: "Armenia",
    states: [
      { id: 2023, name: "Aragatsotn Region", code: "AG" },
      { id: 2024, name: "Ararat Province", code: "AR" },
      { id: 2026, name: "Armavir Region", code: "AV" },
      { id: 2028, name: "Gegharkunik Province", code: "GR" },
      { id: 2033, name: "Kotayk Region", code: "KT" },
      { id: 2029, name: "Lori Region", code: "LO" },
      { id: 2031, name: "Shirak Region", code: "SH" },
      { id: 2027, name: "Syunik Province", code: "SU" },
      { id: 2032, name: "Tavush Region", code: "TV" },
      { id: 2025, name: "Vayots Dzor Region", code: "VD" },
      { id: 2030, name: "Yerevan", code: "ER" }
    ]
  },
  { code: "ABW", name: "Aruba", states: [] },
  {
    code: "AUS",
    name: "Australia",
    states: [
      { id: 3907, name: "Australian Capital Territory", code: "ACT" },
      { id: 3909, name: "New South Wales", code: "NSW" },
      { id: 3910, name: "Northern Territory", code: "NT" },
      { id: 3905, name: "Queensland", code: "QLD" },
      { id: 3904, name: "South Australia", code: "SA" },
      { id: 3908, name: "Tasmania", code: "TAS" },
      { id: 3903, name: "Victoria", code: "VIC" },
      { id: 3906, name: "Western Australia", code: "WA" }
    ]
  },
  {
    code: "AUT",
    name: "Austria",
    states: [
      { id: 2062, name: "Burgenland", code: "1" },
      { id: 2057, name: "Carinthia", code: "2" },
      { id: 2065, name: "Lower Austria", code: "3" },
      { id: 2061, name: "Salzburg", code: "5" },
      { id: 2059, name: "Styria", code: "6" },
      { id: 2064, name: "Tyrol", code: "7" },
      { id: 2058, name: "Upper Austria", code: "4" },
      { id: 2060, name: "Vienna", code: "9" },
      { id: 2063, name: "Vorarlberg", code: "8" }
    ]
  },
  {
    code: "AZE",
    name: "Azerbaijan",
    states: [
      { id: 540, name: "Absheron District", code: "ABS" },
      { id: 559, name: "Agdam District", code: "AGM" },
      { id: 553, name: "Agdash District", code: "AGS" },
      { id: 577, name: "Aghjabadi District", code: "AGC" },
      { id: 543, name: "Agstafa District", code: "AGA" },
      { id: 547, name: "Agsu District", code: "AGU" },
      { id: 528, name: "Astara District", code: "AST" },
      { id: 575, name: "Babek District", code: "BAB" },
      { id: 552, name: "Baku", code: "BA" },
      { id: 560, name: "Balakan District", code: "BAL" },
      { id: 569, name: "Barda District", code: "BAR" },
      { id: 554, name: "Beylagan District", code: "BEY" },
      { id: 532, name: "Bilasuvar District", code: "BIL" },
      { id: 561, name: "Dashkasan District", code: "DAS" },
      { id: 527, name: "Fizuli District", code: "FUZ" },
      { id: 585, name: "Ganja", code: "GA" },
      { id: 589, name: "G\u0259d\u0259b\u0259y", code: "GAD" },
      { id: 573, name: "Gobustan District", code: "QOB" },
      { id: 551, name: "Goranboy District", code: "GOR" },
      { id: 531, name: "Goychay", code: "GOY" },
      { id: 574, name: "Goygol District", code: "GYG" },
      { id: 571, name: "Hajigabul District", code: "HAC" },
      { id: 544, name: "Imishli District", code: "IMI" },
      { id: 564, name: "Ismailli District", code: "ISM" },
      { id: 570, name: "Jabrayil District", code: "CAB" },
      { id: 578, name: "Jalilabad District", code: "CAL" },
      { id: 572, name: "Julfa District", code: "CUL" },
      { id: 525, name: "Kalbajar District", code: "KAL" },
      { id: 567, name: "Kangarli District", code: "KAN" },
      { id: 590, name: "Khachmaz District", code: "XAC" },
      { id: 537, name: "Khizi District", code: "XIZ" },
      { id: 524, name: "Khojali District", code: "XCI" },
      { id: 549, name: "Kurdamir District", code: "KUR" },
      { id: 541, name: "Lachin District", code: "LAC" },
      { id: 587, name: "Lankaran", code: "LAN" },
      { id: 558, name: "Lankaran District", code: "LA" },
      { id: 546, name: "Lerik District", code: "LER" },
      { id: 568, name: "Martuni", code: "XVD" },
      { id: 555, name: "Masally District", code: "MAS" },
      { id: 580, name: "Mingachevir", code: "MI" },
      { id: 562, name: "Nakhchivan Autonomous Republic", code: "NX" },
      { id: 530, name: "Neftchala District", code: "NEF" },
      { id: 556, name: "Oghuz District", code: "OGU" },
      { id: 534, name: "Ordubad District", code: "ORD" },
      { id: 542, name: "Qabala District", code: "QAB" },
      { id: 526, name: "Qakh District", code: "QAX" },
      { id: 521, name: "Qazakh District", code: "QAZ" },
      { id: 563, name: "Quba District", code: "QBA" },
      { id: 548, name: "Qubadli District", code: "QBI" },
      { id: 588, name: "Qusar District", code: "QUS" },
      { id: 557, name: "Saatly District", code: "SAT" },
      { id: 565, name: "Sabirabad District", code: "SAB" },
      { id: 522, name: "Sadarak District", code: "SAD" },
      { id: 545, name: "Salyan District", code: "SAL" },
      { id: 536, name: "Samukh District", code: "SMX" },
      { id: 591, name: "Shabran District", code: "SBN" },
      { id: 579, name: "Shahbuz District", code: "SAH" },
      { id: 518, name: "Shaki", code: "SA" },
      { id: 586, name: "Shaki District", code: "SAK" },
      { id: 529, name: "Shamakhi District", code: "SMI" },
      { id: 583, name: "Shamkir District", code: "SKR" },
      { id: 535, name: "Sharur District", code: "SAR" },
      { id: 520, name: "Shirvan", code: "SR" },
      { id: 592, name: "Shusha District", code: "SUS" },
      { id: 584, name: "Siazan District", code: "SIY" },
      { id: 582, name: "Sumqayit", code: "SM" },
      { id: 519, name: "Tartar District", code: "TAR" },
      { id: 533, name: "Tovuz District", code: "TOV" },
      { id: 539, name: "Ujar District", code: "UCA" },
      { id: 550, name: "Yardymli District", code: "YAR" },
      { id: 538, name: "Yevlakh", code: "YE" },
      { id: 523, name: "Yevlakh District", code: "YEV" },
      { id: 581, name: "Zangilan District", code: "ZAN" },
      { id: 566, name: "Zaqatala District", code: "ZAQ" },
      { id: 576, name: "Zardab District", code: "ZAR" }
    ]
  },
  {
    code: "BHR",
    name: "Bahrain",
    states: [
      { id: 1992, name: "Capital", code: "13" },
      { id: 1996, name: "Central", code: "16" },
      { id: 1995, name: "Muharraq", code: "15" },
      { id: 1994, name: "Northern", code: "17" },
      { id: 1993, name: "Southern", code: "14" }
    ]
  },
  {
    code: "BGD",
    name: "Bangladesh",
    states: [
      { id: 796, name: "Bagerhat District", code: "05" },
      { id: 802, name: "Bahadia", code: "33" },
      { id: 752, name: "Bandarban District", code: "01" },
      { id: 784, name: "Barguna District", code: "02" },
      { id: 818, name: "Barisal District", code: "06" },
      { id: 807, name: "Barisal Division", code: "A" },
      { id: 756, name: "Bhola District", code: "07" },
      { id: 797, name: "Bogra District", code: "03" },
      { id: 810, name: "Brahmanbaria District", code: "04" },
      { id: 768, name: "Chandpur District", code: "09" },
      { id: 761, name: "Chapai Nawabganj District", code: "45" },
      { id: 785, name: "Chittagong District", code: "10" },
      { id: 803, name: "Chittagong Division", code: "B" },
      { id: 788, name: "Chuadanga District", code: "12" },
      { id: 763, name: "Comilla District", code: "08" },
      { id: 751, name: "Cox's Bazar District", code: "11" },
      { id: 771, name: "Dhaka District", code: "13" },
      { id: 760, name: "Dhaka Division", code: "C" },
      { id: 783, name: "Dinajpur District", code: "14" },
      { id: 762, name: "Faridpur District", code: "15" },
      { id: 816, name: "Feni District", code: "16" },
      { id: 795, name: "Gaibandha District", code: "19" },
      { id: 798, name: "Gazipur District", code: "18" },
      { id: 792, name: "Gopalganj District", code: "17" },
      { id: 805, name: "Habiganj District", code: "20" },
      { id: 808, name: "Jamalpur District", code: "21" },
      { id: 757, name: "Jessore District", code: "22" },
      { id: 778, name: "Jhalokati District", code: "25" },
      { id: 789, name: "Jhenaidah District", code: "23" },
      { id: 806, name: "Joypurhat District", code: "24" },
      { id: 786, name: "Khagrachari District", code: "29" },
      { id: 811, name: "Khulna District", code: "27" },
      { id: 775, name: "Khulna Division", code: "D" },
      { id: 779, name: "Kishoreganj District", code: "26" },
      { id: 793, name: "Kurigram District", code: "28" },
      { id: 774, name: "Kushtia District", code: "30" },
      { id: 819, name: "Lakshmipur District", code: "31" },
      { id: 780, name: "Lalmonirhat District", code: "32" },
      { id: 817, name: "Madaripur District", code: "36" },
      { id: 776, name: "Meherpur District", code: "39" },
      { id: 794, name: "Moulvibazar District", code: "38" },
      { id: 790, name: "Munshiganj District", code: "35" },
      { id: 766, name: "Mymensingh District", code: "34" },
      { id: 758, name: "Mymensingh Division", code: "H" },
      { id: 814, name: "Naogaon District", code: "48" },
      { id: 769, name: "Narail District", code: "43" },
      { id: 770, name: "Narayanganj District", code: "40" },
      { id: 787, name: "Natore District", code: "44" },
      { id: 764, name: "Netrokona District", code: "41" },
      { id: 772, name: "Nilphamari District", code: "46" },
      { id: 815, name: "Noakhali District", code: "47" },
      { id: 754, name: "Pabna District", code: "49" },
      { id: 800, name: "Panchagarh District", code: "52" },
      { id: 777, name: "Patuakhali District", code: "51" },
      { id: 791, name: "Pirojpur District", code: "50" },
      { id: 773, name: "Rajbari District", code: "53" },
      { id: 813, name: "Rajshahi District", code: "54" },
      { id: 753, name: "Rajshahi Division", code: "E" },
      { id: 809, name: "Rangamati Hill District", code: "56" },
      { id: 759, name: "Rangpur District", code: "55" },
      { id: 750, name: "Rangpur Division", code: "F" },
      { id: 799, name: "Satkhira District", code: "58" },
      { id: 801, name: "Shariatpur District", code: "62" },
      { id: 755, name: "Sherpur District", code: "57" },
      { id: 781, name: "Sirajganj District", code: "59" },
      { id: 812, name: "Sunamganj District", code: "61" },
      { id: 767, name: "Sylhet District", code: "60" },
      { id: 765, name: "Sylhet Division", code: "G" },
      { id: 782, name: "Tangail District", code: "63" },
      { id: 804, name: "Thakurgaon District", code: "64" }
    ]
  },
  {
    code: "BRB",
    name: "Barbados",
    states: [
      { id: 1228, name: "Christ Church", code: "01" },
      { id: 1229, name: "Saint Andrew", code: "02" },
      { id: 1226, name: "Saint George", code: "03" },
      { id: 1224, name: "Saint James", code: "04" },
      { id: 1227, name: "Saint John", code: "05" },
      { id: 1223, name: "Saint Joseph", code: "06" },
      { id: 1221, name: "Saint Lucy", code: "07" },
      { id: 1230, name: "Saint Michael", code: "08" },
      { id: 1222, name: "Saint Peter", code: "09" },
      { id: 1220, name: "Saint Philip", code: "10" },
      { id: 1225, name: "Saint Thomas", code: "11" }
    ]
  },
  {
    code: "BLR",
    name: "Belarus",
    states: [
      { id: 2959, name: "Brest Region", code: "BR" },
      { id: 2955, name: "Gomel Region", code: "HO" },
      { id: 2956, name: "Grodno Region", code: "HR" },
      { id: 2958, name: "Minsk", code: "HM" },
      { id: 2957, name: "Minsk Region", code: "MI" },
      { id: 2954, name: "Mogilev Region", code: "MA" },
      { id: 2960, name: "Vitebsk Region", code: "VI" }
    ]
  },
  {
    code: "BEL",
    name: "Belgium",
    states: [
      { id: 1381, name: "Antwerp", code: "VAN" },
      { id: 1376, name: "Brussels-Capital Region", code: "BRU" },
      { id: 1377, name: "East Flanders", code: "VOV" },
      { id: 1373, name: "Flanders", code: "VLG" },
      { id: 1374, name: "Flemish Brabant", code: "VBR" },
      { id: 1375, name: "Hainaut", code: "WHT" },
      { id: 1384, name: "Li\xE8ge", code: "WLG" },
      { id: 1372, name: "Limburg", code: "VLI" },
      { id: 1379, name: "Luxembourg", code: "WLX" },
      { id: 1378, name: "Namur", code: "WNA" },
      { id: 1380, name: "Wallonia", code: "WAL" },
      { id: 1382, name: "Walloon Brabant", code: "WBR" },
      { id: 1383, name: "West Flanders", code: "VWV" }
    ]
  },
  {
    code: "BLZ",
    name: "Belize",
    states: [
      { id: 264, name: "Belize District", code: "BZ" },
      { id: 269, name: "Cayo District", code: "CY" },
      { id: 266, name: "Corozal District", code: "CZL" },
      { id: 268, name: "Orange Walk District", code: "OW" },
      { id: 265, name: "Stann Creek District", code: "SC" },
      { id: 267, name: "Toledo District", code: "TOL" }
    ]
  },
  {
    code: "BEN",
    name: "Benin",
    states: [
      { id: 3077, name: "Alibori Department", code: "AL" },
      { id: 3076, name: "Atakora Department", code: "AK" },
      { id: 3079, name: "Atlantique Department", code: "AQ" },
      { id: 3078, name: "Borgou Department", code: "BO" },
      { id: 3070, name: "Collines Department", code: "CO" },
      { id: 3072, name: "Donga Department", code: "DO" },
      { id: 3071, name: "Kouffo Department", code: "KO" },
      { id: 3081, name: "Littoral Department", code: "LI" },
      { id: 3075, name: "Mono Department", code: "MO" },
      { id: 3080, name: "Ou\xE9m\xE9 Department", code: "OU" },
      { id: 3074, name: "Plateau Department", code: "PL" },
      { id: 3073, name: "Zou Department", code: "ZO" }
    ]
  },
  {
    code: "BMU",
    name: "Bermuda",
    states: [
      { id: 4860, name: "Devonshire", code: "DEV" },
      { id: 4861, name: "Hamilton", code: "HA" },
      { id: 4863, name: "Paget", code: "PAG" },
      { id: 4864, name: "Pembroke", code: "PEM" },
      { id: 4866, name: "Saint George's", code: "SGE" },
      { id: 4867, name: "Sandys", code: "SAN" },
      { id: 4868, name: "Smith's", code: "SMI" },
      { id: 4869, name: "Southampton", code: "SOU" },
      { id: 4870, name: "Warwick", code: "WAR" }
    ]
  },
  {
    code: "BTN",
    name: "Bhutan",
    states: [
      { id: 240, name: "Bumthang District", code: "33" },
      { id: 239, name: "Chukha District", code: "12" },
      { id: 238, name: "Dagana District", code: "22" },
      { id: 229, name: "Gasa District", code: "GA" },
      { id: 232, name: "Haa District", code: "13" },
      { id: 234, name: "Lhuntse District", code: "44" },
      { id: 242, name: "Mongar District", code: "42" },
      { id: 237, name: "Paro District", code: "11" },
      { id: 244, name: "Pemagatshel District", code: "43" },
      { id: 235, name: "Punakha District", code: "23" },
      { id: 243, name: "Samdrup Jongkhar District", code: "45" },
      { id: 246, name: "Samtse District", code: "14" },
      { id: 247, name: "Sarpang District", code: "31" },
      { id: 241, name: "Thimphu District", code: "15" },
      { id: 236, name: "Trashigang District", code: "41" },
      { id: 245, name: "Trongsa District", code: "32" },
      { id: 230, name: "Tsirang District", code: "21" },
      { id: 231, name: "Wangdue Phodrang District", code: "24" },
      { id: 233, name: "Zhemgang District", code: "34" }
    ]
  },
  {
    code: "BOL",
    name: "Bolivia",
    states: [
      { id: 3375, name: "Beni Department", code: "B" },
      { id: 3382, name: "Chuquisaca Department", code: "H" },
      { id: 3381, name: "Cochabamba Department", code: "C" },
      { id: 3380, name: "La Paz Department", code: "L" },
      { id: 3376, name: "Oruro Department", code: "O" },
      { id: 3379, name: "Pando Department", code: "N" },
      { id: 3383, name: "Potos\xED Department", code: "P" },
      { id: 3377, name: "Santa Cruz Department", code: "S" },
      { id: 3378, name: "Tarija Department", code: "T" }
    ]
  },
  {
    code: "BES",
    name: "Bonaire, Sint Eustatius and Saba",
    states: [
      { id: 5086, name: "Bonaire", code: "BQ1" },
      { id: 5087, name: "Saba", code: "BQ2" },
      { id: 5088, name: "Sint Eustatius", code: "BQ3" }
    ]
  },
  {
    code: "BIH",
    name: "Bosnia and Herzegovina",
    states: [
      { id: 472, name: "Bosnian Podrinje Canton", code: "05" },
      { id: 460, name: "Br\u010Dko District", code: "BRC" },
      { id: 471, name: "Canton 10", code: "10" },
      { id: 462, name: "Central Bosnia Canton", code: "06" },
      {
        id: 467,
        name: "Federation of Bosnia and Herzegovina",
        code: "BIH"
      },
      { id: 463, name: "Herzegovina-Neretva Canton", code: "07" },
      { id: 464, name: "Posavina Canton", code: "02" },
      { id: 470, name: "Republika Srpska", code: "SRP" },
      { id: 466, name: "Sarajevo Canton", code: "09" },
      { id: 461, name: "Tuzla Canton", code: "03" },
      { id: 465, name: "Una-Sana Canton", code: "01" },
      { id: 469, name: "West Herzegovina Canton", code: "08" },
      { id: 468, name: "Zenica-Doboj Canton", code: "04" }
    ]
  },
  {
    code: "BWA",
    name: "Botswana",
    states: [
      { id: 3067, name: "Central District", code: "CE" },
      { id: 3061, name: "Ghanzi District", code: "GH" },
      { id: 3066, name: "Kgalagadi District", code: "KG" },
      { id: 3062, name: "Kgatleng District", code: "KL" },
      { id: 3069, name: "Kweneng District", code: "KW" },
      { id: 3060, name: "Ngamiland", code: "NG" },
      { id: 3068, name: "North-East District", code: "NE" },
      { id: 3065, name: "North-West District", code: "NW" },
      { id: 3064, name: "South-East District", code: "SE" },
      { id: 3063, name: "Southern District", code: "SO" }
    ]
  },
  { code: "BVT", name: "Bouvet Island", states: [] },
  {
    code: "BRA",
    name: "Brazil",
    states: [
      { id: 2012, name: "Acre", code: "AC" },
      { id: 2007, name: "Alagoas", code: "AL" },
      { id: 1999, name: "Amap\xE1", code: "AP" },
      { id: 2004, name: "Amazonas", code: "AM" },
      { id: 2002, name: "Bahia", code: "BA" },
      { id: 2016, name: "Cear\xE1", code: "CE" },
      { id: 2017, name: "Distrito Federal", code: "DF" },
      { id: 2018, name: "Esp\xEDrito Santo", code: "ES" },
      { id: 2e3, name: "Goi\xE1s", code: "GO" },
      { id: 2015, name: "Maranh\xE3o", code: "MA" },
      { id: 2011, name: "Mato Grosso", code: "MT" },
      { id: 2010, name: "Mato Grosso do Sul", code: "MS" },
      { id: 1998, name: "Minas Gerais", code: "MG" },
      { id: 2009, name: "Par\xE1", code: "PA" },
      { id: 2005, name: "Para\xEDba", code: "PB" },
      { id: 2022, name: "Paran\xE1", code: "PR" },
      { id: 2006, name: "Pernambuco", code: "PE" },
      { id: 2008, name: "Piau\xED", code: "PI" },
      { id: 1997, name: "Rio de Janeiro", code: "RJ" },
      { id: 2019, name: "Rio Grande do Norte", code: "RN" },
      { id: 2001, name: "Rio Grande do Sul", code: "RS" },
      { id: 2013, name: "Rond\xF4nia", code: "RO" },
      { id: 4858, name: "Roraima", code: "RR" },
      { id: 2014, name: "Santa Catarina", code: "SC" },
      { id: 2021, name: "S\xE3o Paulo", code: "SP" },
      { id: 2003, name: "Sergipe", code: "SE" },
      { id: 2020, name: "Tocantins", code: "TO" }
    ]
  },
  { code: "IOT", name: "British Indian Ocean Territory", states: [] },
  {
    code: "BRN",
    name: "Brunei",
    states: [
      { id: 1217, name: "Belait District", code: "BE" },
      { id: 1216, name: "Brunei-Muara District", code: "BM" },
      { id: 1218, name: "Temburong District", code: "TE" },
      { id: 1219, name: "Tutong District", code: "TU" }
    ]
  },
  {
    code: "BGR",
    name: "Bulgaria",
    states: [
      { id: 4699, name: "Blagoevgrad Province", code: "01" },
      { id: 4715, name: "Burgas Province", code: "02" },
      { id: 4718, name: "Dobrich Province", code: "08" },
      { id: 4693, name: "Gabrovo Province", code: "07" },
      { id: 4704, name: "Haskovo Province", code: "26" },
      { id: 4702, name: "Kardzhali Province", code: "09" },
      { id: 4703, name: "Kyustendil Province", code: "10" },
      { id: 4710, name: "Lovech Province", code: "11" },
      { id: 4696, name: "Montana Province", code: "12" },
      { id: 4712, name: "Pazardzhik Province", code: "13" },
      { id: 4695, name: "Pernik Province", code: "14" },
      { id: 4706, name: "Pleven Province", code: "15" },
      { id: 4701, name: "Plovdiv Province", code: "16" },
      { id: 4698, name: "Razgrad Province", code: "17" },
      { id: 4713, name: "Ruse Province", code: "18" },
      { id: 4882, name: "Shumen", code: "27" },
      { id: 4708, name: "Silistra Province", code: "19" },
      { id: 4700, name: "Sliven Province", code: "20" },
      { id: 4694, name: "Smolyan Province", code: "21" },
      { id: 4705, name: "Sofia City Province", code: "22" },
      { id: 4719, name: "Sofia Province", code: "23" },
      { id: 4707, name: "Stara Zagora Province", code: "24" },
      { id: 4714, name: "Targovishte Province", code: "25" },
      { id: 4717, name: "Varna Province", code: "03" },
      { id: 4709, name: "Veliko Tarnovo Province", code: "04" },
      { id: 4697, name: "Vidin Province", code: "05" },
      { id: 4711, name: "Vratsa Province", code: "06" },
      { id: 4716, name: "Yambol Province", code: "28" }
    ]
  },
  {
    code: "BFA",
    name: "Burkina Faso",
    states: [
      { id: 3160, name: "Bal\xE9 Province", code: "BAL" },
      { id: 3155, name: "Bam Province", code: "BAM" },
      { id: 3120, name: "Banwa Province", code: "BAN" },
      { id: 3152, name: "Baz\xE8ga Province", code: "BAZ" },
      { id: 3138, name: "Boucle du Mouhoun Region", code: "01" },
      { id: 3121, name: "Bougouriba Province", code: "BGR" },
      { id: 3131, name: "Boulgou", code: "BLG" },
      { id: 3153, name: "Cascades Region", code: "02" },
      { id: 3136, name: "Centre", code: "03" },
      { id: 3162, name: "Centre-Est Region", code: "04" },
      { id: 3127, name: "Centre-Nord Region", code: "05" },
      { id: 3115, name: "Centre-Ouest Region", code: "06" },
      { id: 3149, name: "Centre-Sud Region", code: "07" },
      { id: 3167, name: "Como\xE9 Province", code: "COM" },
      { id: 3158, name: "Est Region", code: "08" },
      { id: 3148, name: "Ganzourgou Province", code: "GAN" },
      { id: 3122, name: "Gnagna Province", code: "GNA" },
      { id: 3143, name: "Gourma Province", code: "GOU" },
      { id: 3165, name: "Hauts-Bassins Region", code: "09" },
      { id: 3129, name: "Houet Province", code: "HOU" },
      { id: 3135, name: "Ioba Province", code: "IOB" },
      { id: 3168, name: "Kadiogo Province", code: "KAD" },
      { id: 3112, name: "K\xE9n\xE9dougou Province", code: "KEN" },
      { id: 3132, name: "Komondjari Province", code: "KMD" },
      { id: 3157, name: "Kompienga Province", code: "KMP" },
      { id: 3146, name: "Kossi Province", code: "KOS" },
      { id: 3133, name: "Koulp\xE9logo Province", code: "KOP" },
      { id: 3161, name: "Kouritenga Province", code: "KOT" },
      { id: 3147, name: "Kourw\xE9ogo Province", code: "KOW" },
      { id: 3159, name: "L\xE9raba Province", code: "LER" },
      { id: 3151, name: "Loroum Province", code: "LOR" },
      { id: 3123, name: "Mouhoun", code: "MOU" },
      { id: 3116, name: "Nahouri Province", code: "NAO" },
      { id: 3113, name: "Namentenga Province", code: "NAM" },
      { id: 3142, name: "Nayala Province", code: "NAY" },
      { id: 3164, name: "Nord Region, Burkina Faso", code: "10" },
      { id: 3156, name: "Noumbiel Province", code: "NOU" },
      { id: 3141, name: "Oubritenga Province", code: "OUB" },
      { id: 3144, name: "Oudalan Province", code: "OUD" },
      { id: 3117, name: "Passor\xE9 Province", code: "PAS" },
      { id: 3125, name: "Plateau-Central Region", code: "11" },
      { id: 3163, name: "Poni Province", code: "PON" },
      { id: 3114, name: "Sahel Region", code: "12" },
      { id: 3154, name: "Sangui\xE9 Province", code: "SNG" },
      { id: 3126, name: "Sanmatenga Province", code: "SMT" },
      { id: 3139, name: "S\xE9no Province", code: "SEN" },
      { id: 3119, name: "Sissili Province", code: "SIS" },
      { id: 3166, name: "Soum Province", code: "SOM" },
      { id: 3137, name: "Sourou Province", code: "SOR" },
      { id: 3140, name: "Sud-Ouest Region", code: "13" },
      { id: 3128, name: "Tapoa Province", code: "TAP" },
      { id: 3134, name: "Tuy Province", code: "TUI" },
      { id: 3124, name: "Yagha Province", code: "YAG" },
      { id: 3150, name: "Yatenga Province", code: "YAT" },
      { id: 3145, name: "Ziro Province", code: "ZIR" },
      { id: 3130, name: "Zondoma Province", code: "ZON" },
      { id: 3118, name: "Zoundw\xE9ogo Province", code: "ZOU" }
    ]
  },
  {
    code: "BDI",
    name: "Burundi",
    states: [
      { id: 3196, name: "Bubanza Province", code: "BB" },
      { id: 3198, name: "Bujumbura Mairie Province", code: "BM" },
      { id: 3200, name: "Bujumbura Rural Province", code: "BL" },
      { id: 3202, name: "Bururi Province", code: "BR" },
      { id: 3201, name: "Cankuzo Province", code: "CA" },
      { id: 3190, name: "Cibitoke Province", code: "CI" },
      { id: 3197, name: "Gitega Province", code: "GI" },
      { id: 3194, name: "Karuzi Province", code: "KR" },
      { id: 3192, name: "Kayanza Province", code: "KY" },
      { id: 3195, name: "Kirundo Province", code: "KI" },
      { id: 3188, name: "Makamba Province", code: "MA" },
      { id: 3193, name: "Muramvya Province", code: "MU" },
      { id: 3186, name: "Muyinga Province", code: "MY" },
      { id: 3187, name: "Mwaro Province", code: "MW" },
      { id: 3199, name: "Ngozi Province", code: "NG" },
      { id: 3185, name: "Rumonge Province", code: "RM" },
      { id: 3189, name: "Rutana Province", code: "RT" },
      { id: 3191, name: "Ruyigi Province", code: "RY" }
    ]
  },
  {
    code: "KHM",
    name: "Cambodia",
    states: [
      { id: 3984, name: "Banteay Meanchey", code: "1" },
      { id: 3976, name: "Battambang", code: "2" },
      { id: 3991, name: "Kampong Cham", code: "3" },
      { id: 3979, name: "Kampong Chhnang", code: "4" },
      { id: 3988, name: "Kampong Speu", code: "5" },
      { id: 5070, name: "Kampong Thom", code: "6" },
      { id: 3981, name: "Kampot", code: "7" },
      { id: 3983, name: "Kandal", code: "8" },
      { id: 3978, name: "Kep", code: "23" },
      { id: 3982, name: "Koh Kong", code: "9" },
      { id: 3986, name: "Kratie", code: "10" },
      { id: 3985, name: "Mondulkiri", code: "11" },
      { id: 3987, name: "Oddar Meanchey", code: "22" },
      { id: 3980, name: "Pailin", code: "24" },
      { id: 3994, name: "Phnom Penh", code: "12" },
      { id: 3973, name: "Preah Vihear", code: "13" },
      { id: 3974, name: "Prey Veng", code: "14" },
      { id: 3977, name: "Pursat", code: "15" },
      { id: 3990, name: "Ratanakiri", code: "16" },
      { id: 3992, name: "Siem Reap", code: "17" },
      { id: 3989, name: "Sihanoukville", code: "18" },
      { id: 3993, name: "Stung Treng", code: "19" },
      { id: 3972, name: "Svay Rieng", code: "20" },
      { id: 3975, name: "Takeo", code: "21" }
    ]
  },
  {
    code: "CMR",
    name: "Cameroon",
    states: [
      { id: 2663, name: "Adamawa", code: "AD" },
      { id: 2660, name: "Centre", code: "CE" },
      { id: 2661, name: "East", code: "ES" },
      { id: 2656, name: "Far North", code: "EN" },
      { id: 2662, name: "Littoral", code: "LT" },
      { id: 2665, name: "North", code: "NO" },
      { id: 2657, name: "Northwest", code: "NW" },
      { id: 2659, name: "South", code: "SU" },
      { id: 2658, name: "Southwest", code: "SW" },
      { id: 2664, name: "West", code: "OU" }
    ]
  },
  {
    code: "CAN",
    name: "Canada",
    states: [
      { id: 872, name: "Alberta", code: "AB" },
      { id: 875, name: "British Columbia", code: "BC" },
      { id: 867, name: "Manitoba", code: "MB" },
      { id: 868, name: "New Brunswick", code: "NB" },
      { id: 877, name: "Newfoundland and Labrador", code: "NL" },
      { id: 878, name: "Northwest Territories", code: "NT" },
      { id: 874, name: "Nova Scotia", code: "NS" },
      { id: 876, name: "Nunavut", code: "NU" },
      { id: 866, name: "Ontario", code: "ON" },
      { id: 871, name: "Prince Edward Island", code: "PE" },
      { id: 873, name: "Quebec", code: "QC" },
      { id: 870, name: "Saskatchewan", code: "SK" },
      { id: 869, name: "Yukon", code: "YT" }
    ]
  },
  {
    code: "CPV",
    name: "Cape Verde",
    states: [
      { id: 2994, name: "Barlavento Islands", code: "B" },
      { id: 2999, name: "Boa Vista", code: "BV" },
      { id: 2996, name: "Brava", code: "BR" },
      { id: 2991, name: "Maio Municipality", code: "MA" },
      { id: 2987, name: "Mosteiros", code: "MO" },
      { id: 2997, name: "Paul", code: "PA" },
      { id: 2989, name: "Porto Novo", code: "PN" },
      { id: 2988, name: "Praia", code: "PR" },
      { id: 2982, name: "Ribeira Brava Municipality", code: "RB" },
      { id: 3002, name: "Ribeira Grande", code: "RG" },
      { id: 2984, name: "Ribeira Grande de Santiago", code: "RS" },
      { id: 2998, name: "Sal", code: "SL" },
      { id: 2985, name: "Santa Catarina", code: "CA" },
      { id: 2995, name: "Santa Catarina do Fogo", code: "CF" },
      { id: 3004, name: "Santa Cruz", code: "CR" },
      { id: 2986, name: "S\xE3o Domingos", code: "SD" },
      { id: 3e3, name: "S\xE3o Filipe", code: "SF" },
      { id: 2993, name: "S\xE3o Louren\xE7o dos \xD3rg\xE3os", code: "SO" },
      { id: 2990, name: "S\xE3o Miguel", code: "SM" },
      { id: 3001, name: "S\xE3o Vicente", code: "SV" },
      { id: 2992, name: "Sotavento Islands", code: "S" },
      { id: 2983, name: "Tarrafal", code: "TA" },
      { id: 3003, name: "Tarrafal de S\xE3o Nicolau", code: "TS" }
    ]
  },
  { code: "CYM", name: "Cayman Islands", states: [] },
  {
    code: "CAF",
    name: "Central African Republic",
    states: [
      { id: 1259, name: "Bamingui-Bangoran Prefecture", code: "BB" },
      { id: 1262, name: "Bangui", code: "BGF" },
      { id: 1264, name: "Basse-Kotto Prefecture", code: "BK" },
      { id: 1258, name: "Haut-Mbomou Prefecture", code: "HM" },
      { id: 1268, name: "Haute-Kotto Prefecture", code: "HK" },
      { id: 1263, name: "K\xE9mo Prefecture", code: "KG" },
      { id: 1256, name: "Lobaye Prefecture", code: "LB" },
      { id: 1257, name: "Mamb\xE9r\xE9-Kad\xE9\xEF", code: "HS" },
      { id: 1266, name: "Mbomou Prefecture", code: "MB" },
      { id: 1253, name: "Nana-Gr\xE9bizi Economic Prefecture", code: "KB" },
      { id: 1260, name: "Nana-Mamb\xE9r\xE9 Prefecture", code: "NM" },
      { id: 1255, name: "Ombella-M'Poko Prefecture", code: "MP" },
      { id: 1265, name: "Ouaka Prefecture", code: "UK" },
      { id: 1254, name: "Ouham Prefecture", code: "AC" },
      { id: 1267, name: "Ouham-Pend\xE9 Prefecture", code: "OP" },
      { id: 1252, name: "Sangha-Mba\xE9r\xE9", code: "SE" },
      { id: 1261, name: "Vakaga Prefecture", code: "VK" }
    ]
  },
  {
    code: "TCD",
    name: "Chad",
    states: [
      { id: 3583, name: "Bahr el Gazel", code: "BG" },
      { id: 3590, name: "Batha", code: "BA" },
      { id: 3574, name: "Borkou", code: "BO" },
      { id: 5114, name: "Chari-Baguirmi", code: "CB" },
      { id: 3575, name: "Ennedi-Est", code: "EE" },
      { id: 3584, name: "Ennedi-Ouest", code: "EO" },
      { id: 3576, name: "Gu\xE9ra", code: "GR" },
      { id: 3573, name: "Hadjer-Lamis", code: "HL" },
      { id: 3588, name: "Kanem", code: "KA" },
      { id: 3577, name: "Lac", code: "LC" },
      { id: 3585, name: "Logone Occidental", code: "LO" },
      { id: 3591, name: "Logone Oriental", code: "LR" },
      { id: 3589, name: "Mandoul", code: "MA" },
      { id: 3580, name: "Mayo-Kebbi Est", code: "ME" },
      { id: 3571, name: "Mayo-Kebbi Ouest", code: "MO" },
      { id: 3570, name: "Moyen-Chari", code: "MC" },
      { id: 3586, name: "N'Djamena", code: "ND" },
      { id: 3582, name: "Ouadda\xEF", code: "OD" },
      { id: 3592, name: "Salamat", code: "SA" },
      { id: 3572, name: "Sila", code: "SI" },
      { id: 3579, name: "Tandjil\xE9", code: "TA" },
      { id: 3587, name: "Tibesti", code: "TI" },
      { id: 3581, name: "Wadi Fira", code: "WF" }
    ]
  },
  {
    code: "CHL",
    name: "Chile",
    states: [
      {
        id: 2828,
        name: "Ais\xE9n del General Carlos Iba\xF1ez del Campo",
        code: "AI"
      },
      { id: 2832, name: "Antofagasta", code: "AN" },
      { id: 2829, name: "Arica y Parinacota", code: "AP" },
      { id: 2823, name: "Atacama", code: "AT" },
      { id: 2827, name: "Biob\xEDo", code: "BI" },
      { id: 2825, name: "Coquimbo", code: "CO" },
      { id: 2826, name: "La Araucan\xEDa", code: "AR" },
      {
        id: 2838,
        name: "Libertador General Bernardo O'Higgins",
        code: "LI"
      },
      { id: 2835, name: "Los Lagos", code: "LL" },
      { id: 2834, name: "Los R\xEDos", code: "LR" },
      {
        id: 2836,
        name: "Magallanes y de la Ant\xE1rtica Chilena",
        code: "MA"
      },
      { id: 2833, name: "Maule", code: "ML" },
      { id: 2831, name: "\xD1uble", code: "NB" },
      { id: 2824, name: "Regi\xF3n Metropolitana de Santiago", code: "RM" },
      { id: 2837, name: "Tarapac\xE1", code: "TA" },
      { id: 2830, name: "Valpara\xEDso", code: "VS" }
    ]
  },
  {
    code: "CHN",
    name: "China",
    states: [
      { id: 2251, name: "Anhui", code: "AH" },
      { id: 2257, name: "Beijing", code: "BJ" },
      { id: 2271, name: "Chongqing", code: "CQ" },
      { id: 2248, name: "Fujian", code: "FJ" },
      { id: 2275, name: "Gansu", code: "GS" },
      { id: 2279, name: "Guangdong", code: "GD" },
      { id: 2278, name: "Guangxi Zhuang", code: "GX" },
      { id: 2261, name: "Guizhou", code: "GZ" },
      { id: 2273, name: "Hainan", code: "HI" },
      { id: 2280, name: "Hebei", code: "HE" },
      { id: 2265, name: "Heilongjiang", code: "HL" },
      { id: 2259, name: "Henan", code: "HA" },
      { id: 2267, name: "Hong Kong SAR", code: "HK" },
      { id: 2274, name: "Hubei", code: "HB" },
      { id: 2258, name: "Hunan", code: "HN" },
      { id: 2269, name: "Inner Mongolia", code: "NM" },
      { id: 2250, name: "Jiangsu", code: "JS" },
      { id: 2256, name: "Jiangxi", code: "JX" },
      { id: 2253, name: "Jilin", code: "JL" },
      { id: 2268, name: "Liaoning", code: "LN" },
      { id: 2266, name: "Macau SAR", code: "MO" },
      { id: 2262, name: "Ningxia Huizu", code: "NX" },
      { id: 2270, name: "Qinghai", code: "QH" },
      { id: 2272, name: "Shaanxi", code: "SN" },
      { id: 2252, name: "Shandong", code: "SD" },
      { id: 2249, name: "Shanghai", code: "SH" },
      { id: 2254, name: "Shanxi", code: "SX" },
      { id: 2277, name: "Sichuan", code: "SC" },
      { id: 2255, name: "Taiwan", code: "TW" },
      { id: 2276, name: "Tianjin", code: "TJ" },
      { id: 2263, name: "Xinjiang", code: "XJ" },
      { id: 2264, name: "Xizang", code: "XZ" },
      { id: 2260, name: "Yunnan", code: "YN" },
      { id: 2247, name: "Zhejiang", code: "ZJ" }
    ]
  },
  { code: "CXR", name: "Christmas Island", states: [] },
  { code: "CCK", name: "Cocos (Keeling) Islands", states: [] },
  {
    code: "COL",
    name: "Colombia",
    states: [
      { id: 2895, name: "Amazonas", code: "AMA" },
      { id: 2890, name: "Antioquia", code: "ANT" },
      { id: 2881, name: "Arauca", code: "ARA" },
      {
        id: 2900,
        name: "Archipi\xE9lago de San Andr\xE9s, Providencia y Santa Catalina",
        code: "SAP"
      },
      { id: 2880, name: "Atl\xE1ntico", code: "ATL" },
      { id: 4921, name: "Bogot\xE1 D.C.", code: "DC" },
      { id: 2893, name: "Bol\xEDvar", code: "BOL" },
      { id: 2903, name: "Boyac\xE1", code: "BOY" },
      { id: 2887, name: "Caldas", code: "CAL" },
      { id: 2891, name: "Caquet\xE1", code: "CAQ" },
      { id: 2892, name: "Casanare", code: "CAS" },
      { id: 2884, name: "Cauca", code: "CAU" },
      { id: 2899, name: "Cesar", code: "CES" },
      { id: 2876, name: "Choc\xF3", code: "CHO" },
      { id: 2898, name: "C\xF3rdoba", code: "COR" },
      { id: 2875, name: "Cundinamarca", code: "CUN" },
      { id: 2882, name: "Guain\xEDa", code: "GUA" },
      { id: 2888, name: "Guaviare", code: "GUV" },
      { id: 4871, name: "Huila", code: "HUI" },
      { id: 2889, name: "La Guajira", code: "LAG" },
      { id: 2886, name: "Magdalena", code: "MAG" },
      { id: 2878, name: "Meta", code: "MET" },
      { id: 2897, name: "Nari\xF1o", code: "NAR" },
      { id: 2877, name: "Norte de Santander", code: "NSA" },
      { id: 2896, name: "Putumayo", code: "PUT" },
      { id: 2874, name: "Quind\xEDo", code: "QUI" },
      { id: 2879, name: "Risaralda", code: "RIS" },
      { id: 2901, name: "Santander", code: "SAN" },
      { id: 2902, name: "Sucre", code: "SUC" },
      { id: 2883, name: "Tolima", code: "TOL" },
      { id: 2904, name: "Valle del Cauca", code: "VAC" },
      { id: 2885, name: "Vaup\xE9s", code: "VAU" },
      { id: 2894, name: "Vichada", code: "VID" }
    ]
  },
  {
    code: "COM",
    name: "Comoros",
    states: [
      { id: 2821, name: "Anjouan", code: "A" },
      { id: 2822, name: "Grande Comore", code: "G" },
      { id: 2820, name: "Moh\xE9li", code: "M" }
    ]
  },
  {
    code: "COG",
    name: "Congo",
    states: [
      { id: 2866, name: "Bouenza Department", code: "11" },
      { id: 2870, name: "Brazzaville", code: "BZV" },
      { id: 2864, name: "Cuvette Department", code: "8" },
      { id: 2869, name: "Cuvette-Ouest Department", code: "15" },
      { id: 2867, name: "Kouilou Department", code: "5" },
      { id: 2868, name: "L\xE9koumou Department", code: "2" },
      { id: 2865, name: "Likouala Department", code: "7" },
      { id: 2872, name: "Niari Department", code: "9" },
      { id: 2862, name: "Plateaux Department", code: "14" },
      { id: 2863, name: "Pointe-Noire", code: "16" },
      { id: 2873, name: "Pool Department", code: "12" },
      { id: 2871, name: "Sangha Department", code: "13" }
    ]
  },
  { code: "COK", name: "Cook Islands", states: [] },
  {
    code: "CRI",
    name: "Costa Rica",
    states: [
      { id: 1215, name: "Alajuela Province", code: "A" },
      { id: 1209, name: "Guanacaste Province", code: "G" },
      { id: 1212, name: "Heredia Province", code: "H" },
      { id: 1213, name: "Lim\xF3n Province", code: "L" },
      { id: 1211, name: "Provincia de Cartago", code: "C" },
      { id: 1210, name: "Puntarenas Province", code: "P" },
      { id: 1214, name: "San Jos\xE9 Province", code: "SJ" }
    ]
  },
  {
    code: "CIV",
    name: "Cote D'Ivoire (Ivory Coast)",
    states: [
      { id: 2634, name: "Abidjan", code: "AB" },
      { id: 2626, name: "Agn\xE9by", code: "16" },
      { id: 2636, name: "Bafing Region", code: "17" },
      { id: 2643, name: "Bas-Sassandra District", code: "BS" },
      { id: 2635, name: "Bas-Sassandra Region", code: "09" },
      { id: 2654, name: "Como\xE9 District", code: "CM" },
      { id: 2644, name: "Dengu\xE9l\xE9 District", code: "DN" },
      { id: 2642, name: "Dengu\xE9l\xE9 Region", code: "10" },
      { id: 2645, name: "Dix-Huit Montagnes", code: "06" },
      { id: 2633, name: "Fromager", code: "18" },
      { id: 2651, name: "G\xF4h-Djiboua District", code: "GD" },
      { id: 2638, name: "Haut-Sassandra", code: "02" },
      { id: 2632, name: "Lacs District", code: "LC" },
      { id: 2640, name: "Lacs Region", code: "07" },
      { id: 2627, name: "Lagunes District", code: "LG" },
      { id: 2639, name: "Lagunes region", code: "01" },
      { id: 2631, name: "Marahou\xE9 Region", code: "12" },
      { id: 2629, name: "Montagnes District", code: "MG" },
      { id: 2646, name: "Moyen-Cavally", code: "19" },
      { id: 2630, name: "Moyen-Como\xE9", code: "05" },
      { id: 2655, name: "N'zi-Como\xE9", code: "11" },
      { id: 2648, name: "Sassandra-Marahou\xE9 District", code: "SM" },
      { id: 2625, name: "Savanes Region", code: "03" },
      { id: 2628, name: "Sud-Bandama", code: "15" },
      { id: 2652, name: "Sud-Como\xE9", code: "13" },
      { id: 2637, name: "Vall\xE9e du Bandama District", code: "VB" },
      { id: 2647, name: "Vall\xE9e du Bandama Region", code: "04" },
      { id: 2650, name: "Woroba District", code: "WR" },
      { id: 2649, name: "Worodougou", code: "14" },
      { id: 2653, name: "Yamoussoukro", code: "YM" },
      { id: 2641, name: "Zanzan Region", code: "ZZ" }
    ]
  },
  {
    code: "HRV",
    name: "Croatia",
    states: [
      { id: 734, name: "Bjelovar-Bilogora", code: "07" },
      { id: 737, name: "Brod-Posavina", code: "12" },
      { id: 728, name: "Dubrovnik-Neretva", code: "19" },
      { id: 743, name: "Istria", code: "18" },
      { id: 5069, name: "Karlovac", code: "04" },
      { id: 742, name: "Koprivnica-Kri\u017Eevci", code: "06" },
      { id: 729, name: "Krapina-Zagorje", code: "02" },
      { id: 731, name: "Lika-Senj", code: "09" },
      { id: 726, name: "Me\u0111imurje", code: "20" },
      { id: 740, name: "Osijek-Baranja", code: "14" },
      { id: 724, name: "Po\u017Eega-Slavonia", code: "11" },
      { id: 735, name: "Primorje-Gorski Kotar", code: "08" },
      { id: 730, name: "\u0160ibenik-Knin", code: "15" },
      { id: 733, name: "Sisak-Moslavina", code: "03" },
      { id: 725, name: "Split-Dalmatia", code: "17" },
      { id: 739, name: "Vara\u017Edin", code: "05" },
      { id: 732, name: "Virovitica-Podravina", code: "10" },
      { id: 741, name: "Vukovar-Syrmia", code: "16" },
      { id: 727, name: "Zadar", code: "13" },
      { id: 736, name: "Zagreb", code: "01" },
      { id: 738, name: "Zagreb", code: "21" }
    ]
  },
  {
    code: "CUB",
    name: "Cuba",
    states: [
      { id: 283, name: "Artemisa Province", code: "15" },
      { id: 286, name: "Camag\xFCey Province", code: "09" },
      { id: 282, name: "Ciego de \xC1vila Province", code: "08" },
      { id: 287, name: "Cienfuegos Province", code: "06" },
      { id: 275, name: "Granma Province", code: "12" },
      { id: 285, name: "Guant\xE1namo Province", code: "14" },
      { id: 272, name: "Havana Province", code: "03" },
      { id: 279, name: "Holgu\xEDn Province", code: "11" },
      { id: 278, name: "Isla de la Juventud", code: "99" },
      { id: 281, name: "Las Tunas Province", code: "10" },
      { id: 284, name: "Matanzas Province", code: "04" },
      { id: 276, name: "Mayabeque Province", code: "16" },
      { id: 277, name: "Pinar del R\xEDo Province", code: "01" },
      { id: 274, name: "Sancti Sp\xEDritus Province", code: "07" },
      { id: 273, name: "Santiago de Cuba Province", code: "13" },
      { id: 280, name: "Villa Clara Province", code: "05" }
    ]
  },
  { code: "CUW", name: "Cura\xE7ao", states: [] },
  {
    code: "CYP",
    name: "Cyprus",
    states: [
      { id: 749, name: "Famagusta District (Ma\u011Fusa)", code: "04" },
      { id: 744, name: "Kyrenia District (Keryneia)", code: "06" },
      { id: 747, name: "Larnaca District (Larnaka)", code: "03" },
      { id: 748, name: "Limassol District (Leymasun)", code: "02" },
      { id: 745, name: "Nicosia District (Lefko\u015Fa)", code: "01" },
      { id: 746, name: "Paphos District (Pafos)", code: "05" }
    ]
  },
  {
    code: "CZE",
    name: "Czech Republic",
    states: [
      { id: 4627, name: "Bene\u0161ov", code: "201" },
      { id: 4620, name: "Beroun", code: "202" },
      { id: 4615, name: "Blansko", code: "641" },
      { id: 4542, name: "B\u0159eclav", code: "644" },
      { id: 4568, name: "Brno-m\u011Bsto", code: "642" },
      { id: 4545, name: "Brno-venkov", code: "643" },
      { id: 4644, name: "Brunt\xE1l", code: "801" },
      { id: 4633, name: "\u010Cesk\xE1 L\xEDpa", code: "511" },
      { id: 4556, name: "\u010Cesk\xE9 Bud\u011Bjovice", code: "311" },
      { id: 4543, name: "\u010Cesk\xFD Krumlov", code: "312" },
      { id: 4573, name: "Cheb", code: "411" },
      { id: 4553, name: "Chomutov", code: "422" },
      { id: 4634, name: "Chrudim", code: "531" },
      { id: 4609, name: "D\u011B\u010D\xEDn", code: "421" },
      { id: 4641, name: "Doma\u017Elice", code: "321" },
      { id: 4559, name: "Fr\xFDdek-M\xEDstek", code: "802" },
      { id: 4611, name: "Havl\xED\u010Dk\u016Fv Brod", code: "631" },
      { id: 4561, name: "Hodon\xEDn", code: "645" },
      { id: 4580, name: "Hradec Kr\xE1lov\xE9", code: "521" },
      { id: 4612, name: "Jablonec nad Nisou", code: "512" },
      { id: 4625, name: "Jesen\xEDk", code: "711" },
      { id: 4640, name: "Ji\u010D\xEDn", code: "522" },
      { id: 4613, name: "Jihlava", code: "632" },
      { id: 4639, name: "Jiho\u010Desk\xFD kraj", code: "31" },
      { id: 4602, name: "Jihomoravsk\xFD kraj", code: "64" },
      { id: 4624, name: "Jind\u0159ich\u016Fv Hradec", code: "313" },
      { id: 4581, name: "Karlovarsk\xFD kraj", code: "41" },
      { id: 4604, name: "Karlovy Vary", code: "412" },
      { id: 4586, name: "Karvin\xE1", code: "803" },
      { id: 4631, name: "Kladno", code: "203" },
      { id: 4591, name: "Klatovy", code: "322" },
      { id: 4618, name: "Kol\xEDn", code: "204" },
      { id: 4575, name: "Kraj Vyso\u010Dina", code: "63" },
      { id: 4614, name: "Kr\xE1lov\xE9hradeck\xFD kraj", code: "52" },
      { id: 4593, name: "Krom\u011B\u0159\xED\u017E", code: "721" },
      { id: 4923, name: "Kutn\xE1 Hora", code: "205" },
      { id: 4590, name: "Liberec", code: "513" },
      { id: 4601, name: "Libereck\xFD kraj", code: "51" },
      { id: 4605, name: "Litom\u011B\u0159ice", code: "423" },
      { id: 4617, name: "Louny", code: "424" },
      { id: 4638, name: "M\u011Bln\xEDk", code: "206" },
      { id: 4643, name: "Mlad\xE1 Boleslav", code: "207" },
      { id: 4600, name: "Moravskoslezsk\xFD kraj", code: "80" },
      { id: 4629, name: "Most", code: "425" },
      { id: 4550, name: "N\xE1chod", code: "523" },
      { id: 4548, name: "Nov\xFD Ji\u010D\xEDn", code: "804" },
      { id: 4582, name: "Nymburk", code: "208" },
      { id: 4574, name: "Olomouc", code: "712" },
      { id: 4589, name: "Olomouck\xFD kraj", code: "71" },
      { id: 4623, name: "Opava", code: "805" },
      { id: 4584, name: "Ostrava-m\u011Bsto", code: "806" },
      { id: 4547, name: "Pardubice", code: "532" },
      { id: 4588, name: "Pardubick\xFD kraj", code: "53" },
      { id: 4645, name: "Pelh\u0159imov", code: "633" },
      { id: 4560, name: "P\xEDsek", code: "314" },
      { id: 4608, name: "Plze\u0148-jih", code: "324" },
      { id: 4544, name: "Plze\u0148-m\u011Bsto", code: "323" },
      { id: 4564, name: "Plze\u0148-sever", code: "325" },
      { id: 4607, name: "Plze\u0148sk\xFD kraj", code: "32" },
      { id: 4578, name: "Prachatice", code: "315" },
      { id: 4606, name: "Praha-v\xFDchod", code: "209" },
      { id: 4619, name: "Praha-z\xE1pad", code: "20A" },
      { id: 4598, name: "Praha, Hlavn\xED m\u011Bsto", code: "10" },
      { id: 4626, name: "P\u0159erov", code: "714" },
      { id: 4546, name: "P\u0159\xEDbram", code: "20B" },
      { id: 4551, name: "Prost\u011Bjov", code: "713" },
      { id: 4558, name: "Rakovn\xEDk", code: "20C" },
      { id: 4583, name: "Rokycany", code: "326" },
      { id: 4636, name: "Rychnov nad Kn\u011B\u017Enou", code: "524" },
      { id: 4596, name: "Semily", code: "514" },
      { id: 4595, name: "Sokolov", code: "413" },
      { id: 4628, name: "Strakonice", code: "316" },
      { id: 4554, name: "St\u0159edo\u010Desk\xFD kraj", code: "20" },
      { id: 4642, name: "\u0160umperk", code: "715" },
      { id: 4571, name: "Svitavy", code: "533" },
      { id: 4565, name: "T\xE1bor", code: "317" },
      { id: 4646, name: "Tachov", code: "327" },
      { id: 4621, name: "Teplice", code: "426" },
      { id: 4597, name: "T\u0159eb\xED\u010D", code: "634" },
      { id: 4579, name: "Trutnov", code: "525" },
      { id: 4592, name: "Uhersk\xE9 Hradi\u0161t\u011B", code: "722" },
      { id: 4576, name: "\xDAsteck\xFD kraj", code: "42" },
      { id: 4599, name: "\xDAst\xED nad Labem", code: "427" },
      { id: 4647, name: "\xDAst\xED nad Orlic\xED", code: "534" },
      { id: 4572, name: "Vset\xEDn", code: "723" },
      { id: 4622, name: "Vy\u0161kov", code: "646" },
      { id: 4648, name: "\u017D\u010F\xE1r nad S\xE1zavou", code: "635" },
      { id: 4563, name: "Zl\xEDn", code: "724" },
      { id: 4552, name: "Zl\xEDnsk\xFD kraj", code: "72" },
      { id: 4630, name: "Znojmo", code: "647" }
    ]
  },
  {
    code: "COD",
    name: "Democratic Republic of the Congo",
    states: [
      { id: 2753, name: "Bas-U\xE9l\xE9", code: "BU" },
      { id: 2744, name: "\xC9quateur", code: "EQ" },
      { id: 2750, name: "Haut-Katanga", code: "HK" },
      { id: 2758, name: "Haut-Lomami", code: "HL" },
      { id: 2734, name: "Haut-U\xE9l\xE9", code: "HU" },
      { id: 2751, name: "Ituri", code: "IT" },
      { id: 2757, name: "Kasa\xEF", code: "KS" },
      { id: 2742, name: "Kasa\xEF Central", code: "KC" },
      { id: 2735, name: "Kasa\xEF Oriental", code: "KE" },
      { id: 2741, name: "Kinshasa", code: "KN" },
      { id: 2746, name: "Kongo Central", code: "BC" },
      { id: 2740, name: "Kwango", code: "KG" },
      { id: 2759, name: "Kwilu", code: "KL" },
      { id: 2747, name: "Lomami", code: "LO" },
      { id: 4953, name: "Lualaba", code: "LU" },
      { id: 2755, name: "Mai-Ndombe", code: "MN" },
      { id: 2745, name: "Maniema", code: "MA" },
      { id: 2752, name: "Mongala", code: "MO" },
      { id: 2749, name: "Nord-Kivu", code: "NK" },
      { id: 2739, name: "Nord-Ubangi", code: "NU" },
      { id: 2743, name: "Sankuru", code: "SA" },
      { id: 2738, name: "Sud-Kivu", code: "SK" },
      { id: 2748, name: "Sud-Ubangi", code: "SU" },
      { id: 2733, name: "Tanganyika", code: "TA" },
      { id: 2756, name: "Tshopo", code: "TO" },
      { id: 2732, name: "Tshuapa", code: "TU" }
    ]
  },
  {
    code: "DNK",
    name: "Denmark",
    states: [
      { id: 1530, name: "Capital Region of Denmark", code: "84" },
      { id: 1531, name: "Central Denmark Region", code: "82" },
      { id: 1532, name: "North Denmark Region", code: "81" },
      { id: 1529, name: "Region of Southern Denmark", code: "83" },
      { id: 1528, name: "Region Zealand", code: "85" }
    ]
  },
  {
    code: "DJI",
    name: "Djibouti",
    states: [
      { id: 2933, name: "Ali Sabieh Region", code: "AS" },
      { id: 2932, name: "Arta Region", code: "AR" },
      { id: 2930, name: "Dikhil Region", code: "DI" },
      { id: 2929, name: "Djibouti", code: "DJ" },
      { id: 2928, name: "Obock Region", code: "OB" },
      { id: 2931, name: "Tadjourah Region", code: "TA" }
    ]
  },
  {
    code: "DMA",
    name: "Dominica",
    states: [
      { id: 4082, name: "Saint Andrew Parish", code: "02" },
      { id: 4078, name: "Saint David Parish", code: "03" },
      { id: 4079, name: "Saint George Parish", code: "04" },
      { id: 4076, name: "Saint John Parish", code: "05" },
      { id: 4085, name: "Saint Joseph Parish", code: "06" },
      { id: 4083, name: "Saint Luke Parish", code: "07" },
      { id: 4077, name: "Saint Mark Parish", code: "08" },
      { id: 4080, name: "Saint Patrick Parish", code: "09" },
      { id: 4084, name: "Saint Paul Parish", code: "10" },
      { id: 4081, name: "Saint Peter Parish", code: "11" }
    ]
  },
  {
    code: "DOM",
    name: "Dominican Republic",
    states: [
      { id: 4114, name: "Azua Province", code: "02" },
      { id: 4105, name: "Baoruco Province", code: "03" },
      { id: 4090, name: "Barahona Province", code: "04" },
      { id: 4107, name: "Dajab\xF3n Province", code: "05" },
      { id: 4095, name: "Distrito Nacional", code: "01" },
      { id: 4113, name: "Duarte Province", code: "06" },
      { id: 4086, name: "El Seibo Province", code: "08" },
      { id: 4102, name: "Espaillat Province", code: "09" },
      { id: 4106, name: "Hato Mayor Province", code: "30" },
      { id: 4089, name: "Hermanas Mirabal Province", code: "19" },
      { id: 4097, name: "Independencia", code: "10" },
      { id: 4109, name: "La Altagracia Province", code: "11" },
      { id: 4087, name: "La Romana Province", code: "12" },
      { id: 4116, name: "La Vega Province", code: "13" },
      { id: 4094, name: "Mar\xEDa Trinidad S\xE1nchez Province", code: "14" },
      { id: 4099, name: "Monse\xF1or Nouel Province", code: "28" },
      { id: 4115, name: "Monte Cristi Province", code: "15" },
      { id: 4111, name: "Monte Plata Province", code: "29" },
      { id: 4101, name: "Pedernales Province", code: "16" },
      { id: 4096, name: "Peravia Province", code: "17" },
      { id: 4092, name: "Puerto Plata Province", code: "18" },
      { id: 4103, name: "Saman\xE1 Province", code: "20" },
      { id: 4091, name: "San Crist\xF3bal Province", code: "21" },
      { id: 4112, name: "San Jos\xE9 de Ocoa Province", code: "31" },
      { id: 4098, name: "San Juan Province", code: "22" },
      { id: 4110, name: "San Pedro de Macor\xEDs", code: "23" },
      { id: 4088, name: "S\xE1nchez Ram\xEDrez Province", code: "24" },
      { id: 4108, name: "Santiago Province", code: "25" },
      { id: 4100, name: "Santiago Rodr\xEDguez Province", code: "26" },
      { id: 4093, name: "Santo Domingo Province", code: "32" },
      { id: 4104, name: "Valverde Province", code: "27" }
    ]
  },
  {
    code: "TLS",
    name: "East Timor",
    states: [
      { id: 4520, name: "Aileu municipality", code: "AL" },
      { id: 4518, name: "Ainaro Municipality", code: "AN" },
      { id: 4521, name: "Baucau Municipality", code: "BA" },
      { id: 4525, name: "Bobonaro Municipality", code: "BO" },
      { id: 4522, name: "Cova Lima Municipality", code: "CO" },
      { id: 4524, name: "Dili municipality", code: "DI" },
      { id: 4516, name: "Ermera District", code: "ER" },
      { id: 4523, name: "Laut\xE9m Municipality", code: "LA" },
      { id: 4515, name: "Liqui\xE7\xE1 Municipality", code: "LI" },
      { id: 4517, name: "Manatuto District", code: "MT" },
      { id: 4519, name: "Manufahi Municipality", code: "MF" },
      { id: 4514, name: "Viqueque Municipality", code: "VI" }
    ]
  },
  {
    code: "ECU",
    name: "Ecuador",
    states: [
      { id: 2923, name: "Azuay", code: "A" },
      { id: 2920, name: "Bol\xEDvar", code: "B" },
      { id: 2917, name: "Ca\xF1ar", code: "F" },
      { id: 2915, name: "Carchi", code: "C" },
      { id: 2925, name: "Chimborazo", code: "H" },
      { id: 2921, name: "Cotopaxi", code: "X" },
      { id: 2924, name: "El Oro", code: "O" },
      { id: 2922, name: "Esmeraldas", code: "E" },
      { id: 2905, name: "Gal\xE1pagos", code: "W" },
      { id: 2914, name: "Guayas", code: "G" },
      { id: 2911, name: "Imbabura", code: "I" },
      { id: 5068, name: "Loja", code: "L" },
      { id: 2910, name: "Los R\xEDos", code: "R" },
      { id: 2913, name: "Manab\xED", code: "M" },
      { id: 2918, name: "Morona-Santiago", code: "S" },
      { id: 2916, name: "Napo", code: "N" },
      { id: 2926, name: "Orellana", code: "D" },
      { id: 2907, name: "Pastaza", code: "Y" },
      { id: 2927, name: "Pichincha", code: "P" },
      { id: 2912, name: "Santa Elena", code: "SE" },
      { id: 2919, name: "Santo Domingo de los Ts\xE1chilas", code: "SD" },
      { id: 2906, name: "Sucumb\xEDos", code: "U" },
      { id: 2908, name: "Tungurahua", code: "T" },
      { id: 2909, name: "Zamora Chinchipe", code: "Z" }
    ]
  },
  {
    code: "EGY",
    name: "Egypt",
    states: [
      { id: 3235, name: "Alexandria", code: "ALX" },
      { id: 3225, name: "Aswan", code: "ASN" },
      { id: 3236, name: "Asyut", code: "AST" },
      { id: 3241, name: "Beheira", code: "BH" },
      { id: 3230, name: "Beni Suef", code: "BNS" },
      { id: 3223, name: "Cairo", code: "C" },
      { id: 3245, name: "Dakahlia", code: "DK" },
      { id: 3224, name: "Damietta", code: "DT" },
      { id: 3238, name: "Faiyum", code: "FYM" },
      { id: 3234, name: "Gharbia", code: "GH" },
      { id: 3239, name: "Giza", code: "GZ" },
      { id: 3244, name: "Ismailia", code: "IS" },
      { id: 3222, name: "Kafr el-Sheikh", code: "KFS" },
      { id: 3242, name: "Luxor", code: "LX" },
      { id: 3231, name: "Matrouh", code: "MT" },
      { id: 3243, name: "Minya", code: "MN" },
      { id: 3228, name: "Monufia", code: "MNF" },
      { id: 3246, name: "New Valley", code: "WAD" },
      { id: 3227, name: "North Sinai", code: "SIN" },
      { id: 3229, name: "Port Said", code: "PTS" },
      { id: 3232, name: "Qalyubia", code: "KB" },
      { id: 3247, name: "Qena", code: "KN" },
      { id: 3240, name: "Red Sea", code: "BA" },
      { id: 5067, name: "Sharqia", code: "SHR" },
      { id: 3226, name: "Sohag", code: "SHG" },
      { id: 3237, name: "South Sinai", code: "JS" },
      { id: 3233, name: "Suez", code: "SUZ" }
    ]
  },
  {
    code: "SLV",
    name: "El Salvador",
    states: [
      { id: 4139, name: "Ahuachap\xE1n Department", code: "AH" },
      { id: 4132, name: "Caba\xF1as Department", code: "CA" },
      { id: 4131, name: "Chalatenango Department", code: "CH" },
      { id: 4137, name: "Cuscatl\xE1n Department", code: "CU" },
      { id: 4134, name: "La Libertad Department", code: "LI" },
      { id: 4136, name: "La Paz Department", code: "PA" },
      { id: 4138, name: "La Uni\xF3n Department", code: "UN" },
      { id: 4130, name: "Moraz\xE1n Department", code: "MO" },
      { id: 4135, name: "San Miguel Department", code: "SM" },
      { id: 4133, name: "San Salvador Department", code: "SS" },
      { id: 4127, name: "San Vicente Department", code: "SV" },
      { id: 4128, name: "Santa Ana Department", code: "SA" },
      { id: 4140, name: "Sonsonate Department", code: "SO" },
      { id: 4129, name: "Usulut\xE1n Department", code: "US" }
    ]
  },
  {
    code: "GNQ",
    name: "Equatorial Guinea",
    states: [
      { id: 3444, name: "Annob\xF3n Province", code: "AN" },
      { id: 3446, name: "Bioko Norte Province", code: "BN" },
      { id: 3443, name: "Bioko Sur Province", code: "BS" },
      { id: 3445, name: "Centro Sur Province", code: "CS" },
      { id: 3442, name: "Insular Region", code: "I" },
      { id: 3439, name: "Ki\xE9-Ntem Province", code: "KN" },
      { id: 3441, name: "Litoral Province", code: "LI" },
      { id: 3438, name: "R\xEDo Muni", code: "C" },
      { id: 3440, name: "Wele-Nzas Province", code: "WN" }
    ]
  },
  {
    code: "ERI",
    name: "Eritrea",
    states: [
      { id: 3425, name: "Anseba Region", code: "AN" },
      { id: 3427, name: "Debub Region", code: "DU" },
      { id: 3428, name: "Gash-Barka Region", code: "GB" },
      { id: 3426, name: "Maekel Region", code: "MA" },
      { id: 3424, name: "Northern Red Sea Region", code: "SK" },
      { id: 3429, name: "Southern Red Sea Region", code: "DK" }
    ]
  },
  {
    code: "EST",
    name: "Estonia",
    states: [
      { id: 3567, name: "Harju County", code: "37" },
      { id: 3555, name: "Hiiu County", code: "39" },
      { id: 3569, name: "Ida-Viru County", code: "44" },
      { id: 3566, name: "J\xE4rva County", code: "51" },
      { id: 3565, name: "J\xF5geva County", code: "49" },
      { id: 3568, name: "L\xE4\xE4ne County", code: "57" },
      { id: 3564, name: "L\xE4\xE4ne-Viru County", code: "59" },
      { id: 3562, name: "P\xE4rnu County", code: "67" },
      { id: 3563, name: "P\xF5lva County", code: "65" },
      { id: 3559, name: "Rapla County", code: "70" },
      { id: 3561, name: "Saare County", code: "74" },
      { id: 3557, name: "Tartu County", code: "78" },
      { id: 3558, name: "Valga County", code: "82" },
      { id: 3556, name: "Viljandi County", code: "84" },
      { id: 3560, name: "V\xF5ru County", code: "86" }
    ]
  },
  {
    code: "ETH",
    name: "Ethiopia",
    states: [
      { id: 11, name: "Addis Ababa", code: "AA" },
      { id: 6, name: "Afar Region", code: "AF" },
      { id: 3, name: "Amhara Region", code: "AM" },
      { id: 9, name: "Benishangul-Gumuz Region", code: "BE" },
      { id: 8, name: "Dire Dawa", code: "DD" },
      { id: 10, name: "Gambela Region", code: "GA" },
      { id: 7, name: "Harari Region", code: "HA" },
      { id: 5, name: "Oromia Region", code: "OR" },
      { id: 2, name: "Somali Region", code: "SO" },
      {
        id: 1,
        name: "Southern Nations, Nationalities, and Peoples' Region",
        code: "SN"
      },
      { id: 4, name: "Tigray Region", code: "TI" }
    ]
  },
  { code: "FLK", name: "Falkland Islands", states: [] },
  { code: "FRO", name: "Faroe Islands", states: [] },
  {
    code: "FJI",
    name: "Fiji Islands",
    states: [
      { id: 1917, name: "Ba", code: "01" },
      { id: 1930, name: "Bua", code: "02" },
      { id: 1924, name: "Cakaudrove", code: "03" },
      { id: 1929, name: "Central Division", code: "C" },
      { id: 1932, name: "Eastern Division", code: "E" },
      { id: 1934, name: "Kadavu", code: "04" },
      { id: 1933, name: "Lau", code: "05" },
      { id: 1916, name: "Lomaiviti", code: "06" },
      { id: 1922, name: "Macuata", code: "07" },
      { id: 1919, name: "Nadroga-Navosa", code: "08" },
      { id: 1927, name: "Naitasiri", code: "09" },
      { id: 1928, name: "Namosi", code: "10" },
      { id: 1921, name: "Northern Division", code: "N" },
      { id: 1926, name: "Ra", code: "11" },
      { id: 1920, name: "Rewa", code: "12" },
      { id: 1931, name: "Rotuma", code: "R" },
      { id: 1925, name: "Serua", code: "13" },
      { id: 1918, name: "Tailevu", code: "14" },
      { id: 1923, name: "Western Division", code: "W" }
    ]
  },
  {
    code: "FIN",
    name: "Finland",
    states: [
      { id: 1509, name: "\xC5land Islands", code: "01" },
      { id: 1511, name: "Central Finland", code: "08" },
      { id: 1494, name: "Central Ostrobothnia", code: "07" },
      { id: 1507, name: "Finland Proper", code: "19" },
      { id: 1496, name: "Kainuu", code: "05" },
      { id: 1512, name: "Kymenlaakso", code: "09" },
      { id: 1500, name: "Lapland", code: "10" },
      { id: 1504, name: "North Karelia", code: "13" },
      { id: 1505, name: "Northern Ostrobothnia", code: "14" },
      { id: 1503, name: "Northern Savonia", code: "15" },
      { id: 1508, name: "Ostrobothnia", code: "12" },
      { id: 1502, name: "P\xE4ij\xE4nne Tavastia", code: "16" },
      { id: 1506, name: "Pirkanmaa", code: "11" },
      { id: 1501, name: "Satakunta", code: "17" },
      { id: 1497, name: "South Karelia", code: "02" },
      { id: 1498, name: "Southern Ostrobothnia", code: "03" },
      { id: 1495, name: "Southern Savonia", code: "04" },
      { id: 1493, name: "Tavastia Proper", code: "06" },
      { id: 1510, name: "Uusimaa", code: "18" }
    ]
  },
  {
    code: "FRA",
    name: "France",
    states: [
      { id: 4967, name: "Ain", code: "01" },
      { id: 4968, name: "Aisne", code: "02" },
      { id: 4969, name: "Allier", code: "03" },
      { id: 4970, name: "Alpes-de-Haute-Provence", code: "04" },
      { id: 4972, name: "Alpes-Maritimes", code: "06" },
      { id: 4811, name: "Alsace", code: "6AE" },
      { id: 4973, name: "Ard\xE8che", code: "07" },
      { id: 4974, name: "Ardennes", code: "08" },
      { id: 4975, name: "Ari\xE8ge", code: "09" },
      { id: 4976, name: "Aube", code: "10" },
      { id: 4977, name: "Aude", code: "11" },
      { id: 4798, name: "Auvergne-Rh\xF4ne-Alpes", code: "ARA" },
      { id: 4978, name: "Aveyron", code: "12" },
      { id: 5035, name: "Bas-Rhin", code: "67" },
      { id: 4979, name: "Bouches-du-Rh\xF4ne", code: "13" },
      { id: 4825, name: "Bourgogne-Franche-Comt\xE9", code: "BFC" },
      { id: 4807, name: "Bretagne", code: "BRE" },
      { id: 4981, name: "Calvados", code: "14" },
      { id: 4982, name: "Cantal", code: "15" },
      { id: 4818, name: "Centre-Val de Loire", code: "CVL" },
      { id: 4983, name: "Charente", code: "16" },
      { id: 4984, name: "Charente-Maritime", code: "17" },
      { id: 4985, name: "Cher", code: "18" },
      { id: 5064, name: "Clipperton", code: "CP" },
      { id: 4986, name: "Corr\xE8ze", code: "19" },
      { id: 4806, name: "Corse", code: "20R" },
      { id: 4996, name: "Corse-du-Sud", code: "2A" },
      { id: 4987, name: "C\xF4te-d'Or", code: "21" },
      { id: 4988, name: "C\xF4tes-d'Armor", code: "22" },
      { id: 4989, name: "Creuse", code: "23" },
      { id: 5047, name: "Deux-S\xE8vres", code: "79" },
      { id: 4990, name: "Dordogne", code: "24" },
      { id: 4991, name: "Doubs", code: "25" },
      { id: 4992, name: "Dr\xF4me", code: "26" },
      { id: 5059, name: "Essonne", code: "91" },
      { id: 4993, name: "Eure", code: "27" },
      { id: 4994, name: "Eure-et-Loir", code: "28" },
      { id: 4995, name: "Finist\xE8re", code: "29" },
      { id: 4822, name: "French Guiana", code: "973" },
      { id: 4824, name: "French Polynesia", code: "PF" },
      {
        id: 5065,
        name: "French Southern and Antarctic Lands",
        code: "TF"
      },
      { id: 4998, name: "Gard", code: "30" },
      { id: 5e3, name: "Gers", code: "32" },
      { id: 5001, name: "Gironde", code: "33" },
      { id: 4820, name: "Grand-Est", code: "GES" },
      { id: 4829, name: "Guadeloupe", code: "971" },
      { id: 5036, name: "Haut-Rhin", code: "68" },
      { id: 4997, name: "Haute-Corse", code: "2B" },
      { id: 4999, name: "Haute-Garonne", code: "31" },
      { id: 5011, name: "Haute-Loire", code: "43" },
      { id: 5020, name: "Haute-Marne", code: "52" },
      { id: 5039, name: "Haute-Sa\xF4ne", code: "70" },
      { id: 5043, name: "Haute-Savoie", code: "74" },
      { id: 5055, name: "Haute-Vienne", code: "87" },
      { id: 4971, name: "Hautes-Alpes", code: "05" },
      { id: 5033, name: "Hautes-Pyr\xE9n\xE9es", code: "65" },
      { id: 4828, name: "Hauts-de-France", code: "HDF" },
      { id: 5060, name: "Hauts-de-Seine", code: "92" },
      { id: 5002, name: "H\xE9rault", code: "34" },
      { id: 4796, name: "\xCEle-de-France", code: "IDF" },
      { id: 5003, name: "Ille-et-Vilaine", code: "35" },
      { id: 5004, name: "Indre", code: "36" },
      { id: 5005, name: "Indre-et-Loire", code: "37" },
      { id: 5006, name: "Is\xE8re", code: "38" },
      { id: 5007, name: "Jura", code: "39" },
      { id: 4823, name: "La R\xE9union", code: "974" },
      { id: 5008, name: "Landes", code: "40" },
      { id: 5009, name: "Loir-et-Cher", code: "41" },
      { id: 5010, name: "Loire", code: "42" },
      { id: 5012, name: "Loire-Atlantique", code: "44" },
      { id: 5013, name: "Loiret", code: "45" },
      { id: 5014, name: "Lot", code: "46" },
      { id: 5015, name: "Lot-et-Garonne", code: "47" },
      { id: 5016, name: "Loz\xE8re", code: "48" },
      { id: 5017, name: "Maine-et-Loire", code: "49" },
      { id: 5018, name: "Manche", code: "50" },
      { id: 5019, name: "Marne", code: "51" },
      { id: 4827, name: "Martinique", code: "972" },
      { id: 5021, name: "Mayenne", code: "53" },
      { id: 4797, name: "Mayotte", code: "976" },
      { id: 5038, name: "M\xE9tropole de Lyon", code: "69M" },
      { id: 5022, name: "Meurthe-et-Moselle", code: "54" },
      { id: 5023, name: "Meuse", code: "55" },
      { id: 5024, name: "Morbihan", code: "56" },
      { id: 5025, name: "Moselle", code: "57" },
      { id: 5026, name: "Ni\xE8vre", code: "58" },
      { id: 5027, name: "Nord", code: "59" },
      { id: 4804, name: "Normandie", code: "NOR" },
      { id: 4795, name: "Nouvelle-Aquitaine", code: "NAQ" },
      { id: 4799, name: "Occitanie", code: "OCC" },
      { id: 5028, name: "Oise", code: "60" },
      { id: 5029, name: "Orne", code: "61" },
      { id: 4816, name: "Paris", code: "75C" },
      { id: 5030, name: "Pas-de-Calais", code: "62" },
      { id: 4802, name: "Pays-de-la-Loire", code: "PDL" },
      { id: 4812, name: "Provence-Alpes-C\xF4te-d\u2019Azur", code: "PAC" },
      { id: 5031, name: "Puy-de-D\xF4me", code: "63" },
      { id: 5032, name: "Pyr\xE9n\xE9es-Atlantiques", code: "64" },
      { id: 5034, name: "Pyr\xE9n\xE9es-Orientales", code: "66" },
      { id: 5037, name: "Rh\xF4ne", code: "69" },
      { id: 4821, name: "Saint Pierre and Miquelon", code: "PM" },
      { id: 4794, name: "Saint-Barth\xE9lemy", code: "BL" },
      { id: 4809, name: "Saint-Martin", code: "MF" },
      { id: 5040, name: "Sa\xF4ne-et-Loire", code: "71" },
      { id: 5041, name: "Sarthe", code: "72" },
      { id: 5042, name: "Savoie", code: "73" },
      { id: 5045, name: "Seine-et-Marne", code: "77" },
      { id: 5044, name: "Seine-Maritime", code: "76" },
      { id: 5061, name: "Seine-Saint-Denis", code: "93" },
      { id: 5048, name: "Somme", code: "80" },
      { id: 5049, name: "Tarn", code: "81" },
      { id: 5050, name: "Tarn-et-Garonne", code: "82" },
      { id: 5058, name: "Territoire de Belfort", code: "90" },
      { id: 5063, name: "Val-d'Oise", code: "95" },
      { id: 5062, name: "Val-de-Marne", code: "94" },
      { id: 5051, name: "Var", code: "83" },
      { id: 5052, name: "Vaucluse", code: "84" },
      { id: 5053, name: "Vend\xE9e", code: "85" },
      { id: 5054, name: "Vienne", code: "86" },
      { id: 5056, name: "Vosges", code: "88" },
      { id: 4810, name: "Wallis and Futuna", code: "WF" },
      { id: 5057, name: "Yonne", code: "89" },
      { id: 5046, name: "Yvelines", code: "78" }
    ]
  },
  { code: "GUF", name: "French Guiana", states: [] },
  { code: "PYF", name: "French Polynesia", states: [] },
  { code: "ATF", name: "French Southern Territories", states: [] },
  {
    code: "GAB",
    name: "Gabon",
    states: [
      { id: 2727, name: "Estuaire Province", code: "1" },
      { id: 2726, name: "Haut-Ogoou\xE9 Province", code: "2" },
      { id: 2730, name: "Moyen-Ogoou\xE9 Province", code: "3" },
      { id: 2731, name: "Ngouni\xE9 Province", code: "4" },
      { id: 2725, name: "Nyanga Province", code: "5" },
      { id: 2724, name: "Ogoou\xE9-Ivindo Province", code: "6" },
      { id: 2729, name: "Ogoou\xE9-Lolo Province", code: "7" },
      { id: 2728, name: "Ogoou\xE9-Maritime Province", code: "8" },
      { id: 2723, name: "Woleu-Ntem Province", code: "9" }
    ]
  },
  {
    code: "GMB",
    name: "Gambia The",
    states: [
      { id: 2666, name: "Banjul", code: "B" },
      { id: 2669, name: "Central River Division", code: "M" },
      { id: 2670, name: "Lower River Division", code: "L" },
      { id: 2671, name: "North Bank Division", code: "N" },
      { id: 2668, name: "Upper River Division", code: "U" },
      { id: 2667, name: "West Coast Division", code: "W" }
    ]
  },
  {
    code: "GEO",
    name: "Georgia",
    states: [
      { id: 900, name: "Adjara", code: "AJ" },
      { id: 901, name: "Autonomous Republic of Abkhazia", code: "AB" },
      { id: 907, name: "Guria", code: "GU" },
      { id: 905, name: "Imereti", code: "IM" },
      { id: 910, name: "Kakheti", code: "KA" },
      { id: 897, name: "Khelvachauri Municipality", code: "29" },
      { id: 904, name: "Kvemo Kartli", code: "KK" },
      { id: 902, name: "Mtskheta-Mtianeti", code: "MM" },
      { id: 909, name: "Racha-Lechkhumi and Kvemo Svaneti", code: "RL" },
      { id: 908, name: "Samegrelo-Zemo Svaneti", code: "SZ" },
      { id: 906, name: "Samtskhe-Javakheti", code: "SJ" },
      { id: 898, name: "Senaki Municipality", code: "50" },
      { id: 903, name: "Shida Kartli", code: "SK" },
      { id: 899, name: "Tbilisi", code: "TB" }
    ]
  },
  {
    code: "DEU",
    name: "Germany",
    states: [
      { id: 3006, name: "Baden-W\xFCrttemberg", code: "BW" },
      { id: 3009, name: "Bavaria", code: "BY" },
      { id: 3010, name: "Berlin", code: "BE" },
      { id: 3013, name: "Brandenburg", code: "BB" },
      { id: 3014, name: "Bremen", code: "HB" },
      { id: 3016, name: "Hamburg", code: "HH" },
      { id: 3018, name: "Hesse", code: "HE" },
      { id: 3008, name: "Lower Saxony", code: "NI" },
      { id: 3007, name: "Mecklenburg-Vorpommern", code: "MV" },
      { id: 3017, name: "North Rhine-Westphalia", code: "NW" },
      { id: 3019, name: "Rhineland-Palatinate", code: "RP" },
      { id: 3020, name: "Saarland", code: "SL" },
      { id: 3021, name: "Saxony", code: "SN" },
      { id: 3011, name: "Saxony-Anhalt", code: "ST" },
      { id: 3005, name: "Schleswig-Holstein", code: "SH" },
      { id: 3015, name: "Thuringia", code: "TH" }
    ]
  },
  {
    code: "GHA",
    name: "Ghana",
    states: [
      { id: 53, name: "Ahafo", code: "AF" },
      { id: 48, name: "Ashanti", code: "AH" },
      { id: 4959, name: "Bono", code: "BO" },
      { id: 4958, name: "Bono East", code: "BE" },
      { id: 52, name: "Central", code: "CP" },
      { id: 50, name: "Eastern", code: "EP" },
      { id: 54, name: "Greater Accra", code: "AA" },
      { id: 4960, name: "North East", code: "NE" },
      { id: 51, name: "Northern", code: "NP" },
      { id: 4961, name: "Oti", code: "OT" },
      { id: 4962, name: "Savannah", code: "SV" },
      { id: 55, name: "Upper East", code: "UE" },
      { id: 57, name: "Upper West", code: "UW" },
      { id: 56, name: "Volta", code: "TV" },
      { id: 49, name: "Western", code: "WP" },
      { id: 4963, name: "Western North", code: "WN" }
    ]
  },
  { code: "GIB", name: "Gibraltar", states: [] },
  {
    code: "GRC",
    name: "Greece",
    states: [
      { id: 2116, name: "Achaea Regional Unit", code: "13" },
      { id: 2123, name: "Aetolia-Acarnania Regional Unit", code: "01" },
      { id: 2098, name: "Arcadia Prefecture", code: "12" },
      { id: 2105, name: "Argolis Regional Unit", code: "11" },
      { id: 2122, name: "Attica Region", code: "I" },
      { id: 2126, name: "Boeotia Regional Unit", code: "03" },
      { id: 2128, name: "Central Greece Region", code: "H" },
      { id: 2125, name: "Central Macedonia", code: "B" },
      { id: 2115, name: "Chania Regional Unit", code: "94" },
      { id: 2124, name: "Corfu Prefecture", code: "22" },
      { id: 2129, name: "Corinthia Regional Unit", code: "15" },
      { id: 2109, name: "Crete Region", code: "M" },
      { id: 2130, name: "Drama Regional Unit", code: "52" },
      { id: 2120, name: "East Attica Regional Unit", code: "A2" },
      { id: 2117, name: "East Macedonia and Thrace", code: "A" },
      { id: 2110, name: "Epirus Region", code: "D" },
      { id: 2101, name: "Euboea", code: "04" },
      { id: 2102, name: "Grevena Prefecture", code: "51" },
      { id: 2099, name: "Imathia Regional Unit", code: "53" },
      { id: 2113, name: "Ioannina Regional Unit", code: "33" },
      { id: 2131, name: "Ionian Islands Region", code: "F" },
      { id: 2095, name: "Karditsa Regional Unit", code: "41" },
      { id: 2100, name: "Kastoria Regional Unit", code: "56" },
      { id: 2127, name: "Kefalonia Prefecture", code: "23" },
      { id: 2111, name: "Kilkis Regional Unit", code: "57" },
      { id: 2112, name: "Kozani Prefecture", code: "58" },
      { id: 2106, name: "Laconia", code: "16" },
      { id: 2132, name: "Larissa Prefecture", code: "42" },
      { id: 2104, name: "Lefkada Regional Unit", code: "24" },
      { id: 2107, name: "Pella Regional Unit", code: "59" },
      { id: 2119, name: "Peloponnese Region", code: "J" },
      { id: 2114, name: "Phthiotis Prefecture", code: "06" },
      { id: 2103, name: "Preveza Prefecture", code: "34" },
      { id: 2121, name: "Serres Prefecture", code: "62" },
      { id: 2118, name: "South Aegean", code: "L" },
      { id: 2097, name: "Thessaloniki Regional Unit", code: "54" },
      { id: 2096, name: "West Greece Region", code: "G" },
      { id: 2108, name: "West Macedonia Region", code: "C" }
    ]
  },
  { code: "GRL", name: "Greenland", states: [] },
  {
    code: "GRD",
    name: "Grenada",
    states: [
      { id: 3867, name: "Carriacou and Petite Martinique", code: "10" },
      { id: 3865, name: "Saint Andrew Parish", code: "01" },
      { id: 3869, name: "Saint David Parish", code: "02" },
      { id: 3864, name: "Saint George Parish", code: "03" },
      { id: 3868, name: "Saint John Parish", code: "04" },
      { id: 3866, name: "Saint Mark Parish", code: "05" },
      { id: 3863, name: "Saint Patrick Parish", code: "06" }
    ]
  },
  { code: "GLP", name: "Guadeloupe", states: [] },
  { code: "GUM", name: "Guam", states: [] },
  {
    code: "GTM",
    name: "Guatemala",
    states: [
      { id: 3671, name: "Alta Verapaz Department", code: "AV" },
      { id: 3674, name: "Baja Verapaz Department", code: "BV" },
      { id: 3675, name: "Chimaltenango Department", code: "CM" },
      { id: 3666, name: "Chiquimula Department", code: "CQ" },
      { id: 3662, name: "El Progreso Department", code: "PR" },
      { id: 3677, name: "Escuintla Department", code: "ES" },
      { id: 3672, name: "Guatemala Department", code: "GU" },
      { id: 3670, name: "Huehuetenango Department", code: "HU" },
      { id: 3659, name: "Izabal Department", code: "IZ" },
      { id: 3658, name: "Jalapa Department", code: "JA" },
      { id: 3673, name: "Jutiapa Department", code: "JU" },
      { id: 3669, name: "Pet\xE9n Department", code: "PE" },
      { id: 3668, name: "Quetzaltenango Department", code: "QZ" },
      { id: 3657, name: "Quich\xE9 Department", code: "QC" },
      { id: 3664, name: "Retalhuleu Department", code: "RE" },
      { id: 3676, name: "Sacatep\xE9quez Department", code: "SA" },
      { id: 3667, name: "San Marcos Department", code: "SM" },
      { id: 3665, name: "Santa Rosa Department", code: "SR" },
      { id: 3661, name: "Solol\xE1 Department", code: "SO" },
      { id: 3660, name: "Suchitep\xE9quez Department", code: "SU" },
      { id: 3663, name: "Totonicap\xE1n Department", code: "TO" }
    ]
  },
  { code: "GGY", name: "Guernsey and Alderney", states: [] },
  {
    code: "GIN",
    name: "Guinea",
    states: [
      { id: 2672, name: "Beyla Prefecture", code: "BE" },
      { id: 2699, name: "Boffa Prefecture", code: "BF" },
      { id: 2709, name: "Bok\xE9 Prefecture", code: "BK" },
      { id: 2676, name: "Bok\xE9 Region", code: "B" },
      { id: 2686, name: "Conakry", code: "C" },
      { id: 2705, name: "Coyah Prefecture", code: "CO" },
      { id: 2679, name: "Dabola Prefecture", code: "DB" },
      { id: 2706, name: "Dalaba Prefecture", code: "DL" },
      { id: 2688, name: "Dinguiraye Prefecture", code: "DI" },
      { id: 2681, name: "Dubr\xE9ka Prefecture", code: "DU" },
      { id: 2682, name: "Faranah Prefecture", code: "FA" },
      { id: 2683, name: "For\xE9cariah Prefecture", code: "FO" },
      { id: 2675, name: "Fria Prefecture", code: "FR" },
      { id: 2685, name: "Gaoual Prefecture", code: "GA" },
      { id: 2711, name: "Gu\xE9ck\xE9dou Prefecture", code: "GU" },
      { id: 2704, name: "Kankan Prefecture", code: "KA" },
      { id: 2697, name: "Kankan Region", code: "K" },
      { id: 2710, name: "K\xE9rouan\xE9 Prefecture", code: "KE" },
      { id: 2693, name: "Kindia Prefecture", code: "KD" },
      { id: 2701, name: "Kindia Region", code: "D" },
      { id: 2691, name: "Kissidougou Prefecture", code: "KS" },
      { id: 2692, name: "Koubia Prefecture", code: "KB" },
      { id: 2703, name: "Koundara Prefecture", code: "KN" },
      { id: 2695, name: "Kouroussa Prefecture", code: "KO" },
      { id: 2680, name: "Lab\xE9 Prefecture", code: "LA" },
      { id: 2677, name: "Lab\xE9 Region", code: "L" },
      { id: 2690, name: "L\xE9louma Prefecture", code: "LE" },
      { id: 2708, name: "Lola Prefecture", code: "LO" },
      { id: 2702, name: "Macenta Prefecture", code: "MC" },
      { id: 2700, name: "Mali Prefecture", code: "ML" },
      { id: 2689, name: "Mamou Prefecture", code: "MM" },
      { id: 2698, name: "Mamou Region", code: "M" },
      { id: 2673, name: "Mandiana Prefecture", code: "MD" },
      { id: 2678, name: "Nz\xE9r\xE9kor\xE9 Prefecture", code: "NZ" },
      { id: 2684, name: "Nz\xE9r\xE9kor\xE9 Region", code: "N" },
      { id: 2694, name: "Pita Prefecture", code: "PI" },
      { id: 2707, name: "Siguiri Prefecture", code: "SI" },
      { id: 2687, name: "T\xE9lim\xE9l\xE9 Prefecture", code: "TE" },
      { id: 2696, name: "Tougu\xE9 Prefecture", code: "TO" },
      { id: 2674, name: "Yomou Prefecture", code: "YO" }
    ]
  },
  {
    code: "GNB",
    name: "Guinea-Bissau",
    states: [
      { id: 2720, name: "Bafat\xE1", code: "BA" },
      { id: 2714, name: "Biombo Region", code: "BM" },
      { id: 2722, name: "Bolama Region", code: "BL" },
      { id: 2713, name: "Cacheu Region", code: "CA" },
      { id: 2719, name: "Gab\xFA Region", code: "GA" },
      { id: 2721, name: "Leste Province", code: "L" },
      { id: 2717, name: "Norte Province", code: "N" },
      { id: 2718, name: "Oio Region", code: "OI" },
      { id: 2715, name: "Quinara Region", code: "QU" },
      { id: 2716, name: "Sul Province", code: "S" },
      { id: 2712, name: "Tombali Region", code: "TO" }
    ]
  },
  {
    code: "GUY",
    name: "Guyana",
    states: [
      { id: 2764, name: "Barima-Waini", code: "BA" },
      { id: 2760, name: "Cuyuni-Mazaruni", code: "CU" },
      { id: 2767, name: "Demerara-Mahaica", code: "DE" },
      { id: 2766, name: "East Berbice-Corentyne", code: "EB" },
      { id: 2768, name: "Essequibo Islands-West Demerara", code: "ES" },
      { id: 2762, name: "Mahaica-Berbice", code: "MA" },
      { id: 2765, name: "Pomeroon-Supenaam", code: "PM" },
      { id: 2761, name: "Potaro-Siparuni", code: "PT" },
      { id: 2763, name: "Upper Demerara-Berbice", code: "UD" },
      { id: 2769, name: "Upper Takutu-Upper Essequibo", code: "UT" }
    ]
  },
  {
    code: "HTI",
    name: "Haiti",
    states: [
      { id: 4123, name: "Artibonite", code: "AR" },
      { id: 4125, name: "Centre", code: "CE" },
      { id: 4119, name: "Grand'Anse", code: "GA" },
      { id: 4118, name: "Nippes", code: "NI" },
      { id: 4117, name: "Nord", code: "ND" },
      { id: 4121, name: "Nord-Est", code: "NE" },
      { id: 4126, name: "Nord-Ouest", code: "NO" },
      { id: 4120, name: "Ouest", code: "OU" },
      { id: 4122, name: "Sud", code: "SD" },
      { id: 4124, name: "Sud-Est", code: "SE" }
    ]
  },
  { code: "HMD", name: "Heard Island and McDonald Islands", states: [] },
  {
    code: "HND",
    name: "Honduras",
    states: [
      { id: 4047, name: "Atl\xE1ntida Department", code: "AT" },
      { id: 4045, name: "Bay Islands Department", code: "IB" },
      { id: 4041, name: "Choluteca Department", code: "CH" },
      { id: 4051, name: "Col\xF3n Department", code: "CL" },
      { id: 4042, name: "Comayagua Department", code: "CM" },
      { id: 4049, name: "Cop\xE1n Department", code: "CP" },
      { id: 4046, name: "Cort\xE9s Department", code: "CR" },
      { id: 4043, name: "El Para\xEDso Department", code: "EP" },
      { id: 4052, name: "Francisco Moraz\xE1n Department", code: "FM" },
      { id: 4048, name: "Gracias a Dios Department", code: "GD" },
      { id: 4044, name: "Intibuc\xE1 Department", code: "IN" },
      { id: 4058, name: "La Paz Department", code: "LP" },
      { id: 4054, name: "Lempira Department", code: "LE" },
      { id: 4056, name: "Ocotepeque Department", code: "OC" },
      { id: 4050, name: "Olancho Department", code: "OL" },
      { id: 4053, name: "Santa B\xE1rbara Department", code: "SB" },
      { id: 4055, name: "Valle Department", code: "VA" },
      { id: 4057, name: "Yoro Department", code: "YO" }
    ]
  },
  {
    code: "HKG",
    name: "Hong Kong S.A.R.",
    states: [
      { id: 4889, name: "Central and Western", code: "HCW" },
      { id: 4891, name: "Eastern", code: "HEA" },
      { id: 4888, name: "Islands", code: "NIS" },
      { id: 4895, name: "Kowloon City", code: "KKC" },
      { id: 4898, name: "Kwai Tsing", code: "NKT" },
      { id: 4897, name: "Kwun Tong", code: "KKT" },
      { id: 4900, name: "North", code: "NNO" },
      { id: 4887, name: "Sai Kung", code: "NSK" },
      { id: 4901, name: "Sha Tin", code: "NST" },
      { id: 4894, name: "Sham Shui Po", code: "KSS" },
      { id: 4892, name: "Southern", code: "HSO" },
      { id: 4885, name: "Tai Po", code: "NTP" },
      { id: 4884, name: "Tsuen Wan", code: "NTW" },
      { id: 4899, name: "Tuen Mun", code: "NTM" },
      { id: 4890, name: "Wan Chai", code: "HWC" },
      { id: 4896, name: "Wong Tai Sin", code: "KWT" },
      { id: 4893, name: "Yau Tsim Mong", code: "KYT" },
      { id: 4883, name: "Yuen Long", code: "NYL" }
    ]
  },
  {
    code: "HUN",
    name: "Hungary",
    states: [
      { id: 1048, name: "B\xE1cs-Kiskun", code: "BK" },
      { id: 1055, name: "Baranya", code: "BA" },
      { id: 1060, name: "B\xE9k\xE9s", code: "BE" },
      { id: 1036, name: "B\xE9k\xE9scsaba", code: "BC" },
      { id: 1058, name: "Borsod-Aba\xFAj-Zempl\xE9n", code: "BZ" },
      { id: 1064, name: "Budapest", code: "BU" },
      { id: 1031, name: "Csongr\xE1d County", code: "CS" },
      { id: 1032, name: "Debrecen", code: "DE" },
      { id: 1049, name: "Duna\xFAjv\xE1ros", code: "DU" },
      { id: 1037, name: "Eger", code: "EG" },
      { id: 1028, name: "\xC9rd", code: "ER" },
      { id: 1044, name: "Fej\xE9r County", code: "FE" },
      { id: 1041, name: "Gy\u0151r", code: "GY" },
      { id: 1042, name: "Gy\u0151r-Moson-Sopron County", code: "GS" },
      { id: 1063, name: "Hajd\xFA-Bihar County", code: "HB" },
      { id: 1040, name: "Heves County", code: "HE" },
      { id: 1027, name: "H\xF3dmez\u0151v\xE1s\xE1rhely", code: "HV" },
      { id: 1043, name: "J\xE1sz-Nagykun-Szolnok County", code: "JN" },
      { id: 1067, name: "Kaposv\xE1r", code: "KV" },
      { id: 1056, name: "Kecskem\xE9t", code: "KM" },
      { id: 5085, name: "Kom\xE1rom-Esztergom", code: "KE" },
      { id: 1065, name: "Miskolc", code: "MI" },
      { id: 1030, name: "Nagykanizsa", code: "NK" },
      { id: 1051, name: "N\xF3gr\xE1d County", code: "NO" },
      { id: 1034, name: "Ny\xEDregyh\xE1za", code: "NY" },
      { id: 1053, name: "P\xE9cs", code: "PS" },
      { id: 1059, name: "Pest County", code: "PE" },
      { id: 1068, name: "Salg\xF3tarj\xE1n", code: "ST" },
      { id: 1035, name: "Somogy County", code: "SO" },
      { id: 1057, name: "Sopron", code: "SN" },
      { id: 1045, name: "Szabolcs-Szatm\xE1r-Bereg County", code: "SZ" },
      { id: 1029, name: "Szeged", code: "SD" },
      { id: 1033, name: "Sz\xE9kesfeh\xE9rv\xE1r", code: "SF" },
      { id: 1061, name: "Szeksz\xE1rd", code: "SS" },
      { id: 1047, name: "Szolnok", code: "SK" },
      { id: 1052, name: "Szombathely", code: "SH" },
      { id: 1066, name: "Tatab\xE1nya", code: "TB" },
      { id: 1038, name: "Tolna County", code: "TO" },
      { id: 1039, name: "Vas County", code: "VA" },
      { id: 1062, name: "Veszpr\xE9m", code: "VM" },
      { id: 1054, name: "Veszpr\xE9m County", code: "VE" },
      { id: 1046, name: "Zala County", code: "ZA" },
      { id: 1050, name: "Zalaegerszeg", code: "ZE" }
    ]
  },
  {
    code: "ISL",
    name: "Iceland",
    states: [
      { id: 3431, name: "Capital Region", code: "1" },
      { id: 3433, name: "Eastern Region", code: "7" },
      { id: 3437, name: "Northeastern Region", code: "6" },
      { id: 3435, name: "Northwestern Region", code: "5" },
      { id: 3430, name: "Southern Peninsula Region", code: "2" },
      { id: 3434, name: "Southern Region", code: "8" },
      { id: 3436, name: "Western Region", code: "3" },
      { id: 3432, name: "Westfjords", code: "4" }
    ]
  },
  {
    code: "IND",
    name: "India",
    states: [
      { id: 4023, name: "Andaman and Nicobar Islands", code: "AN" },
      { id: 4017, name: "Andhra Pradesh", code: "AP" },
      { id: 4024, name: "Arunachal Pradesh", code: "AR" },
      { id: 4027, name: "Assam", code: "AS" },
      { id: 4037, name: "Bihar", code: "BR" },
      { id: 4031, name: "Chandigarh", code: "CH" },
      { id: 4040, name: "Chhattisgarh", code: "CT" },
      {
        id: 4033,
        name: "Dadra and Nagar Haveli and Daman and Diu",
        code: "DH"
      },
      { id: 4021, name: "Delhi", code: "DL" },
      { id: 4009, name: "Goa", code: "GA" },
      { id: 4030, name: "Gujarat", code: "GJ" },
      { id: 4007, name: "Haryana", code: "HR" },
      { id: 4020, name: "Himachal Pradesh", code: "HP" },
      { id: 4029, name: "Jammu and Kashmir", code: "JK" },
      { id: 4025, name: "Jharkhand", code: "JH" },
      { id: 4026, name: "Karnataka", code: "KA" },
      { id: 4028, name: "Kerala", code: "KL" },
      { id: 4852, name: "Ladakh", code: "LA" },
      { id: 4019, name: "Lakshadweep", code: "LD" },
      { id: 4039, name: "Madhya Pradesh", code: "MP" },
      { id: 4008, name: "Maharashtra", code: "MH" },
      { id: 4010, name: "Manipur", code: "MN" },
      { id: 4006, name: "Meghalaya", code: "ML" },
      { id: 4036, name: "Mizoram", code: "MZ" },
      { id: 4018, name: "Nagaland", code: "NL" },
      { id: 4013, name: "Odisha", code: "OR" },
      { id: 4011, name: "Puducherry", code: "PY" },
      { id: 4015, name: "Punjab", code: "PB" },
      { id: 4014, name: "Rajasthan", code: "RJ" },
      { id: 4034, name: "Sikkim", code: "SK" },
      { id: 4035, name: "Tamil Nadu", code: "TN" },
      { id: 4012, name: "Telangana", code: "TG" },
      { id: 4038, name: "Tripura", code: "TR" },
      { id: 4022, name: "Uttar Pradesh", code: "UP" },
      { id: 4016, name: "Uttarakhand", code: "UT" },
      { id: 4853, name: "West Bengal", code: "WB" }
    ]
  },
  {
    code: "IDN",
    name: "Indonesia",
    states: [
      { id: 1822, name: "Aceh", code: "AC" },
      { id: 1826, name: "Bali", code: "BA" },
      { id: 1810, name: "Banten", code: "BT" },
      { id: 1793, name: "Bengkulu", code: "BE" },
      { id: 1829, name: "DI Yogyakarta", code: "YO" },
      { id: 1805, name: "DKI Jakarta", code: "JK" },
      { id: 1812, name: "Gorontalo", code: "GO" },
      { id: 1815, name: "Jambi", code: "JA" },
      { id: 1825, name: "Jawa Barat", code: "JB" },
      { id: 1802, name: "Jawa Tengah", code: "JT" },
      { id: 1827, name: "Jawa Timur", code: "JI" },
      { id: 1806, name: "Kalimantan Barat", code: "KB" },
      { id: 1819, name: "Kalimantan Selatan", code: "KS" },
      { id: 1794, name: "Kalimantan Tengah", code: "KT" },
      { id: 1804, name: "Kalimantan Timur", code: "KI" },
      { id: 1824, name: "Kalimantan Utara", code: "KU" },
      { id: 1820, name: "Kepulauan Bangka Belitung", code: "BB" },
      { id: 1807, name: "Kepulauan Riau", code: "KR" },
      { id: 1811, name: "Lampung", code: "LA" },
      { id: 1800, name: "Maluku", code: "MA" },
      { id: 1801, name: "Maluku Utara", code: "MU" },
      { id: 1814, name: "Nusa Tenggara Barat", code: "NB" },
      { id: 1818, name: "Nusa Tenggara Timur", code: "NT" },
      { id: 1798, name: "Papua", code: "PA" },
      { id: 1799, name: "Papua Barat", code: "PB" },
      { id: 1809, name: "Riau", code: "RI" },
      { id: 1817, name: "Sulawesi Barat", code: "SR" },
      { id: 1795, name: "Sulawesi Selatan", code: "SN" },
      { id: 1813, name: "Sulawesi Tengah", code: "ST" },
      { id: 1796, name: "Sulawesi Tenggara", code: "SG" },
      { id: 1808, name: "Sulawesi Utara", code: "SA" },
      { id: 1828, name: "Sumatera Barat", code: "SB" },
      { id: 1816, name: "Sumatera Selatan", code: "SS" },
      { id: 1792, name: "Sumatera Utara", code: "SU" }
    ]
  },
  {
    code: "IRN",
    name: "Iran",
    states: [
      { id: 3929, name: "Alborz", code: "30" },
      { id: 3934, name: "Ardabil", code: "24" },
      { id: 3932, name: "Bushehr", code: "18" },
      { id: 3921, name: "Chaharmahal and Bakhtiari", code: "14" },
      { id: 3944, name: "East Azerbaijan", code: "03" },
      { id: 3939, name: "Fars", code: "07" },
      { id: 3920, name: "Gilan", code: "01" },
      { id: 3933, name: "Golestan", code: "27" },
      { id: 4920, name: "Hamadan", code: "13" },
      { id: 3937, name: "Hormozgan", code: "22" },
      { id: 3918, name: "Ilam", code: "16" },
      { id: 3923, name: "Isfahan", code: "10" },
      { id: 3943, name: "Kerman", code: "08" },
      { id: 3919, name: "Kermanshah", code: "05" },
      { id: 3917, name: "Khuzestan", code: "06" },
      { id: 3926, name: "Kohgiluyeh and Boyer-Ahmad", code: "17" },
      { id: 3935, name: "Kurdistan", code: "12" },
      { id: 3928, name: "Lorestan", code: "15" },
      { id: 3916, name: "Markazi", code: "00" },
      { id: 3938, name: "Mazandaran", code: "02" },
      { id: 3942, name: "North Khorasan", code: "28" },
      { id: 3941, name: "Qazvin", code: "26" },
      { id: 3922, name: "Qom", code: "25" },
      { id: 3927, name: "Razavi Khorasan", code: "09" },
      { id: 3940, name: "Semnan", code: "20" },
      { id: 3931, name: "Sistan and Baluchestan", code: "11" },
      { id: 3930, name: "South Khorasan", code: "29" },
      { id: 3945, name: "Tehran", code: "23" },
      { id: 3924, name: "West Azarbaijan", code: "04" },
      { id: 3936, name: "Yazd", code: "21" },
      { id: 3925, name: "Zanjan", code: "19" }
    ]
  },
  {
    code: "IRQ",
    name: "Iraq",
    states: [
      { id: 3964, name: "Al Anbar", code: "AN" },
      { id: 3958, name: "Al Muthanna", code: "MU" },
      { id: 3956, name: "Al-Q\u0101disiyyah", code: "QA" },
      { id: 3955, name: "Babylon", code: "BB" },
      { id: 3959, name: "Baghdad", code: "BG" },
      { id: 3960, name: "Basra", code: "BA" },
      { id: 3954, name: "Dhi Qar", code: "DQ" },
      { id: 3965, name: "Diyala", code: "DI" },
      { id: 3967, name: "Dohuk", code: "DA" },
      { id: 3968, name: "Erbil", code: "AR" },
      { id: 3957, name: "Karbala", code: "KA" },
      { id: 3971, name: "Kirkuk", code: "KI" },
      { id: 3966, name: "Maysan", code: "MA" },
      { id: 3962, name: "Najaf", code: "NA" },
      { id: 3963, name: "Nineveh", code: "NI" },
      { id: 3961, name: "Saladin", code: "SD" },
      { id: 3969, name: "Sulaymaniyah", code: "SU" },
      { id: 3970, name: "Wasit", code: "WA" }
    ]
  },
  {
    code: "IRL",
    name: "Ireland",
    states: [
      { id: 1095, name: "Carlow", code: "CW" },
      { id: 1088, name: "Cavan", code: "CN" },
      { id: 1091, name: "Clare", code: "CE" },
      { id: 1087, name: "Connacht", code: "C" },
      { id: 1074, name: "Cork", code: "CO" },
      { id: 1071, name: "Donegal", code: "DL" },
      { id: 1072, name: "Dublin", code: "D" },
      { id: 1079, name: "Galway", code: "G" },
      { id: 1077, name: "Kerry", code: "KY" },
      { id: 1082, name: "Kildare", code: "KE" },
      { id: 1090, name: "Kilkenny", code: "KK" },
      { id: 1096, name: "Laois", code: "LS" },
      { id: 1073, name: "Leinster", code: "L" },
      { id: 1094, name: "Limerick", code: "LK" },
      { id: 1076, name: "Longford", code: "LD" },
      { id: 1083, name: "Louth", code: "LH" },
      { id: 1084, name: "Mayo", code: "MO" },
      { id: 1092, name: "Meath", code: "MH" },
      { id: 1075, name: "Monaghan", code: "MN" },
      { id: 1080, name: "Munster", code: "M" },
      { id: 1078, name: "Offaly", code: "OY" },
      { id: 1081, name: "Roscommon", code: "RN" },
      { id: 1070, name: "Sligo", code: "SO" },
      { id: 1069, name: "Tipperary", code: "TA" },
      { id: 1086, name: "Ulster", code: "U" },
      { id: 1089, name: "Waterford", code: "WD" },
      { id: 1097, name: "Westmeath", code: "WH" },
      { id: 1093, name: "Wexford", code: "WX" },
      { id: 1085, name: "Wicklow", code: "WW" }
    ]
  },
  {
    code: "ISR",
    name: "Israel",
    states: [
      { id: 1367, name: "Central District", code: "M" },
      { id: 1369, name: "Haifa District", code: "HA" },
      { id: 1370, name: "Jerusalem District", code: "JM" },
      { id: 1366, name: "Northern District", code: "Z" },
      { id: 1368, name: "Southern District", code: "D" },
      { id: 1371, name: "Tel Aviv District", code: "TA" }
    ]
  },
  {
    code: "ITA",
    name: "Italy",
    states: [
      { id: 1679, name: "Abruzzo", code: "65" },
      { id: 1727, name: "Agrigento", code: "AG" },
      { id: 1783, name: "Alessandria", code: "AL" },
      { id: 1672, name: "Ancona", code: "AN" },
      { id: 1716, name: "Aosta Valley", code: "23" },
      { id: 1688, name: "Apulia", code: "75" },
      { id: 1681, name: "Ascoli Piceno", code: "AP" },
      { id: 1780, name: "Asti", code: "AT" },
      { id: 1692, name: "Avellino", code: "AV" },
      { id: 1686, name: "Barletta-Andria-Trani", code: "BT" },
      { id: 1706, name: "Basilicata", code: "77" },
      { id: 1689, name: "Belluno", code: "BL" },
      { id: 1701, name: "Benevento", code: "BN" },
      { id: 1704, name: "Bergamo", code: "BG" },
      { id: 1778, name: "Biella", code: "BI" },
      { id: 1717, name: "Brescia", code: "BS" },
      { id: 1714, name: "Brindisi", code: "BR" },
      { id: 1703, name: "Calabria", code: "78" },
      { id: 1718, name: "Caltanissetta", code: "CL" },
      { id: 1669, name: "Campania", code: "72" },
      { id: 1721, name: "Campobasso", code: "CB" },
      { id: 1731, name: "Caserta", code: "CE" },
      { id: 1728, name: "Catanzaro", code: "CZ" },
      { id: 1739, name: "Chieti", code: "CH" },
      { id: 1740, name: "Como", code: "CO" },
      { id: 1742, name: "Cosenza", code: "CS" },
      { id: 1751, name: "Cremona", code: "CR" },
      { id: 1754, name: "Crotone", code: "KR" },
      { id: 1775, name: "Cuneo", code: "CN" },
      { id: 1773, name: "Emilia-Romagna", code: "45" },
      { id: 1723, name: "Enna", code: "EN" },
      { id: 1744, name: "Fermo", code: "FM" },
      { id: 1746, name: "Ferrara", code: "FE" },
      { id: 1771, name: "Foggia", code: "FG" },
      { id: 1779, name: "Forl\xEC-Cesena", code: "FC" },
      { id: 1756, name: "Friuli\u2013Venezia Giulia", code: "36" },
      { id: 1776, name: "Frosinone", code: "FR" },
      { id: 1777, name: "Gorizia", code: "GO" },
      { id: 1787, name: "Grosseto", code: "GR" },
      { id: 1788, name: "Imperia", code: "IM" },
      { id: 1789, name: "Isernia", code: "IS" },
      { id: 1781, name: "L'Aquila", code: "AQ" },
      { id: 1791, name: "La Spezia", code: "SP" },
      { id: 1674, name: "Latina", code: "LT" },
      { id: 1678, name: "Lazio", code: "62" },
      { id: 1675, name: "Lecce", code: "LE" },
      { id: 1677, name: "Lecco", code: "LC" },
      { id: 1768, name: "Liguria", code: "42" },
      { id: 1745, name: "Livorno", code: "LI" },
      { id: 1747, name: "Lodi", code: "LO" },
      { id: 1705, name: "Lombardy", code: "25" },
      { id: 1749, name: "Lucca", code: "LU" },
      { id: 1750, name: "Macerata", code: "MC" },
      { id: 1758, name: "Mantua", code: "MN" },
      { id: 1670, name: "Marche", code: "57" },
      { id: 1759, name: "Massa and Carrara", code: "MS" },
      { id: 1760, name: "Matera", code: "MT" },
      { id: 1761, name: "Medio Campidano", code: "VS" },
      { id: 1757, name: "Modena", code: "MO" },
      { id: 1695, name: "Molise", code: "67" },
      { id: 1769, name: "Monza and Brianza", code: "MB" },
      { id: 1774, name: "Novara", code: "NO" },
      { id: 1790, name: "Nuoro", code: "NU" },
      { id: 1786, name: "Oristano", code: "OR" },
      { id: 1665, name: "Padua", code: "PD" },
      { id: 1668, name: "Palermo", code: "PA" },
      { id: 1666, name: "Parma", code: "PR" },
      { id: 1676, name: "Pavia", code: "PV" },
      { id: 1691, name: "Perugia", code: "PG" },
      { id: 1693, name: "Pesaro and Urbino", code: "PU" },
      { id: 1694, name: "Pescara", code: "PE" },
      { id: 1696, name: "Piacenza", code: "PC" },
      { id: 1702, name: "Piedmont", code: "21" },
      { id: 1685, name: "Pisa", code: "PI" },
      { id: 1687, name: "Pistoia", code: "PT" },
      { id: 1690, name: "Pordenone", code: "PN" },
      { id: 1697, name: "Potenza", code: "PZ" },
      { id: 1700, name: "Prato", code: "PO" },
      { id: 1729, name: "Ragusa", code: "RG" },
      { id: 1707, name: "Ravenna", code: "RA" },
      { id: 1708, name: "Reggio Emilia", code: "RE" },
      { id: 1712, name: "Rieti", code: "RI" },
      { id: 1713, name: "Rimini", code: "RN" },
      { id: 1719, name: "Rovigo", code: "RO" },
      { id: 1720, name: "Salerno", code: "SA" },
      { id: 1715, name: "Sardinia", code: "88" },
      { id: 1722, name: "Sassari", code: "SS" },
      { id: 1732, name: "Savona", code: "SV" },
      { id: 1709, name: "Sicily", code: "82" },
      { id: 1734, name: "Siena", code: "SI" },
      { id: 1667, name: "Siracusa", code: "SR" },
      { id: 1741, name: "Sondrio", code: "SO" },
      { id: 1730, name: "South Sardinia", code: "SU" },
      { id: 1743, name: "Taranto", code: "TA" },
      { id: 1752, name: "Teramo", code: "TE" },
      { id: 1755, name: "Terni", code: "TR" },
      { id: 1733, name: "Trapani", code: "TP" },
      { id: 1725, name: "Trentino-South Tyrol", code: "32" },
      { id: 1762, name: "Treviso", code: "TV" },
      { id: 1763, name: "Trieste", code: "TS" },
      { id: 1664, name: "Tuscany", code: "52" },
      { id: 1764, name: "Udine", code: "UD" },
      { id: 1683, name: "Umbria", code: "55" },
      { id: 1765, name: "Varese", code: "VA" },
      { id: 1753, name: "Veneto", code: "34" },
      { id: 1726, name: "Verbano-Cusio-Ossola", code: "VB" },
      { id: 1785, name: "Vercelli", code: "VC" },
      { id: 1736, name: "Verona", code: "VR" },
      { id: 1737, name: "Vibo Valentia", code: "VV" },
      { id: 1738, name: "Vicenza", code: "VI" },
      { id: 1735, name: "Viterbo", code: "VT" }
    ]
  },
  {
    code: "JAM",
    name: "Jamaica",
    states: [
      { id: 3753, name: "Clarendon Parish", code: "13" },
      { id: 3749, name: "Hanover Parish", code: "09" },
      { id: 3748, name: "Kingston Parish", code: "01" },
      { id: 3754, name: "Manchester Parish", code: "12" },
      { id: 3752, name: "Portland Parish", code: "04" },
      { id: 3751, name: "Saint Andrew", code: "02" },
      { id: 3744, name: "Saint Ann Parish", code: "06" },
      { id: 3746, name: "Saint Catherine Parish", code: "14" },
      { id: 3743, name: "Saint Elizabeth Parish", code: "11" },
      { id: 3745, name: "Saint James Parish", code: "08" },
      { id: 3747, name: "Saint Mary Parish", code: "05" },
      { id: 3750, name: "Saint Thomas Parish", code: "03" },
      { id: 3755, name: "Trelawny Parish", code: "07" },
      { id: 3742, name: "Westmoreland Parish", code: "10" }
    ]
  },
  {
    code: "JPN",
    name: "Japan",
    states: [
      { id: 827, name: "Aichi Prefecture", code: "23" },
      { id: 829, name: "Akita Prefecture", code: "05" },
      { id: 839, name: "Aomori Prefecture", code: "02" },
      { id: 821, name: "Chiba Prefecture", code: "12" },
      { id: 865, name: "Ehime Prefecture", code: "38" },
      { id: 848, name: "Fukui Prefecture", code: "18" },
      { id: 861, name: "Fukuoka Prefecture", code: "40" },
      { id: 847, name: "Fukushima Prefecture", code: "07" },
      { id: 858, name: "Gifu Prefecture", code: "21" },
      { id: 862, name: "Gunma Prefecture", code: "10" },
      { id: 828, name: "Hiroshima Prefecture", code: "34" },
      { id: 832, name: "Hokkaid\u014D Prefecture", code: "01" },
      { id: 831, name: "Hy\u014Dgo Prefecture", code: "28" },
      { id: 851, name: "Ibaraki Prefecture", code: "08" },
      { id: 830, name: "Ishikawa Prefecture", code: "17" },
      { id: 856, name: "Iwate Prefecture", code: "03" },
      { id: 864, name: "Kagawa Prefecture", code: "37" },
      { id: 840, name: "Kagoshima Prefecture", code: "46" },
      { id: 842, name: "Kanagawa Prefecture", code: "14" },
      { id: 4924, name: "K\u014Dchi Prefecture", code: "39" },
      { id: 846, name: "Kumamoto Prefecture", code: "43" },
      { id: 834, name: "Ky\u014Dto Prefecture", code: "26" },
      { id: 833, name: "Mie Prefecture", code: "24" },
      { id: 857, name: "Miyagi Prefecture", code: "04" },
      { id: 855, name: "Miyazaki Prefecture", code: "45" },
      { id: 843, name: "Nagano Prefecture", code: "20" },
      { id: 849, name: "Nagasaki Prefecture", code: "42" },
      { id: 824, name: "Nara Prefecture", code: "29" },
      { id: 841, name: "Niigata Prefecture", code: "15" },
      { id: 822, name: "\u014Cita Prefecture", code: "44" },
      { id: 820, name: "Okayama Prefecture", code: "33" },
      { id: 853, name: "Okinawa Prefecture", code: "47" },
      { id: 859, name: "\u014Csaka Prefecture", code: "27" },
      { id: 863, name: "Saga Prefecture", code: "41" },
      { id: 860, name: "Saitama Prefecture", code: "11" },
      { id: 845, name: "Shiga Prefecture", code: "25" },
      { id: 826, name: "Shimane Prefecture", code: "32" },
      { id: 825, name: "Shizuoka Prefecture", code: "22" },
      { id: 854, name: "Tochigi Prefecture", code: "09" },
      { id: 836, name: "Tokushima Prefecture", code: "36" },
      { id: 823, name: "Tokyo", code: "13" },
      { id: 850, name: "Tottori Prefecture", code: "31" },
      { id: 838, name: "Toyama Prefecture", code: "16" },
      { id: 844, name: "Wakayama Prefecture", code: "30" },
      { id: 837, name: "Yamagata Prefecture", code: "06" },
      { id: 835, name: "Yamaguchi Prefecture", code: "35" },
      { id: 852, name: "Yamanashi Prefecture", code: "19" }
    ]
  },
  { code: "JEY", name: "Jersey", states: [] },
  {
    code: "JOR",
    name: "Jordan",
    states: [
      { id: 963, name: "Ajloun", code: "AJ" },
      { id: 965, name: "Amman", code: "AM" },
      { id: 959, name: "Aqaba", code: "AQ" },
      { id: 961, name: "Balqa", code: "BA" },
      { id: 960, name: "Irbid", code: "IR" },
      { id: 966, name: "Jerash", code: "JA" },
      { id: 956, name: "Karak", code: "KA" },
      { id: 964, name: "Ma'an", code: "MN" },
      { id: 958, name: "Madaba", code: "MD" },
      { id: 962, name: "Mafraq", code: "MA" },
      { id: 957, name: "Tafilah", code: "AT" },
      { id: 967, name: "Zarqa", code: "AZ" }
    ]
  },
  {
    code: "KAZ",
    name: "Kazakhstan",
    states: [
      { id: 145, name: "Akmola Region", code: "AKM" },
      { id: 151, name: "Aktobe Region", code: "AKT" },
      { id: 152, name: "Almaty", code: "ALA" },
      { id: 143, name: "Almaty Region", code: "ALM" },
      { id: 153, name: "Atyrau Region", code: "ATY" },
      { id: 155, name: "Baikonur", code: "BAY" },
      { id: 154, name: "East Kazakhstan Region", code: "VOS" },
      { id: 147, name: "Jambyl Region", code: "ZHA" },
      { id: 150, name: "Karaganda Region", code: "KAR" },
      { id: 157, name: "Kostanay Region", code: "KUS" },
      { id: 142, name: "Kyzylorda Region", code: "KZY" },
      { id: 141, name: "Mangystau Region", code: "MAN" },
      { id: 144, name: "North Kazakhstan Region", code: "SEV" },
      { id: 156, name: "Nur-Sultan", code: "AST" },
      { id: 146, name: "Pavlodar Region", code: "PAV" },
      { id: 149, name: "Turkestan Region", code: "YUZ" },
      { id: 148, name: "West Kazakhstan Province", code: "ZAP" }
    ]
  },
  {
    code: "KEN",
    name: "Kenya",
    states: [
      { id: 181, name: "Baringo", code: "01" },
      { id: 210, name: "Bomet", code: "02" },
      { id: 168, name: "Bungoma", code: "03" },
      { id: 161, name: "Busia", code: "04" },
      { id: 201, name: "Elgeyo-Marakwet", code: "05" },
      { id: 163, name: "Embu", code: "06" },
      { id: 196, name: "Garissa", code: "07" },
      { id: 195, name: "Homa Bay", code: "08" },
      { id: 170, name: "Isiolo", code: "09" },
      { id: 197, name: "Kajiado", code: "10" },
      { id: 158, name: "Kakamega", code: "11" },
      { id: 193, name: "Kericho", code: "12" },
      { id: 199, name: "Kiambu", code: "13" },
      { id: 174, name: "Kilifi", code: "14" },
      { id: 167, name: "Kirinyaga", code: "15" },
      { id: 159, name: "Kisii", code: "16" },
      { id: 171, name: "Kisumu", code: "17" },
      { id: 211, name: "Kitui", code: "18" },
      { id: 173, name: "Kwale", code: "19" },
      { id: 164, name: "Laikipia", code: "20" },
      { id: 166, name: "Lamu", code: "21" },
      { id: 184, name: "Machakos", code: "22" },
      { id: 188, name: "Makueni", code: "23" },
      { id: 187, name: "Mandera", code: "24" },
      { id: 194, name: "Marsabit", code: "25" },
      { id: 198, name: "Meru", code: "26" },
      { id: 190, name: "Migori", code: "27" },
      { id: 200, name: "Mombasa", code: "28" },
      { id: 178, name: "Murang'a", code: "29" },
      { id: 191, name: "Nairobi City", code: "30" },
      { id: 203, name: "Nakuru", code: "31" },
      { id: 165, name: "Nandi", code: "32" },
      { id: 175, name: "Narok", code: "33" },
      { id: 209, name: "Nyamira", code: "34" },
      { id: 192, name: "Nyandarua", code: "35" },
      { id: 180, name: "Nyeri", code: "36" },
      { id: 207, name: "Samburu", code: "37" },
      { id: 186, name: "Siaya", code: "38" },
      { id: 176, name: "Taita\u2013Taveta", code: "39" },
      { id: 205, name: "Tana River", code: "40" },
      { id: 185, name: "Tharaka-Nithi", code: "41" },
      { id: 183, name: "Trans Nzoia", code: "42" },
      { id: 206, name: "Turkana", code: "43" },
      { id: 169, name: "Uasin Gishu", code: "44" },
      { id: 202, name: "Vihiga", code: "45" },
      { id: 182, name: "Wajir", code: "46" },
      { id: 208, name: "West Pokot", code: "47" }
    ]
  },
  {
    code: "KIR",
    name: "Kiribati",
    states: [
      { id: 1831, name: "Gilbert Islands", code: "G" },
      { id: 1832, name: "Line Islands", code: "L" },
      { id: 1830, name: "Phoenix Islands", code: "P" }
    ]
  },
  {
    code: "XKX",
    name: "Kosovo",
    states: [
      { id: 4876, name: "\u0110akovica District (Gjakove)", code: "XDG" },
      { id: 4877, name: "Gjilan District", code: "XGJ" },
      { id: 4878, name: "Kosovska Mitrovica District", code: "XKM" },
      { id: 3738, name: "Pe\u0107 District", code: "XPE" },
      { id: 4879, name: "Pristina (Pri\u015Ftine)", code: "XPI" },
      { id: 3723, name: "Prizren District", code: "XPR" },
      { id: 4874, name: "Uro\u0161evac District (Ferizaj)", code: "XUF" }
    ]
  },
  {
    code: "KWT",
    name: "Kuwait",
    states: [
      { id: 977, name: "Al Ahmadi", code: "AH" },
      { id: 975, name: "Al Farwaniyah", code: "FA" },
      { id: 972, name: "Al Jahra", code: "JA" },
      { id: 976, name: "Capital", code: "KU" },
      { id: 973, name: "Hawalli", code: "HA" },
      { id: 974, name: "Mubarak Al-Kabeer", code: "MU" }
    ]
  },
  {
    code: "KGZ",
    name: "Kyrgyzstan",
    states: [
      { id: 998, name: "Batken Region", code: "B" },
      { id: 1001, name: "Bishkek", code: "GB" },
      { id: 1004, name: "Chuy Region", code: "C" },
      { id: 1002, name: "Issyk-Kul Region", code: "Y" },
      { id: 1e3, name: "Jalal-Abad Region", code: "J" },
      { id: 999, name: "Naryn Region", code: "N" },
      { id: 1003, name: "Osh", code: "GO" },
      { id: 1005, name: "Osh Region", code: "O" },
      { id: 997, name: "Talas Region", code: "T" }
    ]
  },
  {
    code: "LAO",
    name: "Laos",
    states: [
      { id: 982, name: "Attapeu Province", code: "AT" },
      { id: 991, name: "Bokeo Province", code: "BK" },
      { id: 985, name: "Bolikhamsai Province", code: "BL" },
      { id: 996, name: "Champasak Province", code: "CH" },
      { id: 989, name: "Houaphanh Province", code: "HO" },
      { id: 986, name: "Khammouane Province", code: "KH" },
      { id: 992, name: "Luang Namtha Province", code: "LM" },
      { id: 978, name: "Luang Prabang Province", code: "LP" },
      { id: 988, name: "Oudomxay Province", code: "OU" },
      { id: 987, name: "Phongsaly Province", code: "PH" },
      { id: 993, name: "Sainyabuli Province", code: "XA" },
      { id: 981, name: "Salavan Province", code: "SL" },
      { id: 990, name: "Savannakhet Province", code: "SV" },
      { id: 984, name: "Sekong Province", code: "XE" },
      { id: 979, name: "Vientiane Prefecture", code: "VT" },
      { id: 980, name: "Vientiane Province", code: "VI" },
      { id: 994, name: "Xaisomboun", code: "XN" },
      { id: 983, name: "Xaisomboun Province", code: "XS" },
      { id: 995, name: "Xiangkhouang Province", code: "XI" }
    ]
  },
  {
    code: "LVA",
    name: "Latvia",
    states: [
      { id: 4445, name: "Aglona Municipality", code: "001" },
      { id: 4472, name: "Aizkraukle Municipality", code: "002" },
      { id: 4496, name: "Aizpute Municipality", code: "003" },
      { id: 4499, name: "Akn\u012Bste Municipality", code: "004" },
      { id: 4484, name: "Aloja Municipality", code: "005" },
      { id: 4485, name: "Alsunga Municipality", code: "006" },
      { id: 4487, name: "Al\u016Bksne Municipality", code: "007" },
      { id: 4497, name: "Amata Municipality", code: "008" },
      { id: 4457, name: "Ape Municipality", code: "009" },
      { id: 4481, name: "Auce Municipality", code: "010" },
      { id: 4427, name: "Bab\u012Bte Municipality", code: "012" },
      { id: 4482, name: "Baldone Municipality", code: "013" },
      { id: 4498, name: "Baltinava Municipality", code: "014" },
      { id: 4505, name: "Balvi Municipality", code: "015" },
      { id: 4465, name: "Bauska Municipality", code: "016" },
      { id: 4471, name: "Bever\u012Bna Municipality", code: "017" },
      { id: 4468, name: "Broc\u0113ni Municipality", code: "018" },
      { id: 4411, name: "Burtnieki Municipality", code: "019" },
      { id: 4454, name: "Carnikava Municipality", code: "020" },
      { id: 4469, name: "C\u0113sis Municipality", code: "022" },
      { id: 4414, name: "Cesvaine Municipality", code: "021" },
      { id: 4410, name: "Cibla Municipality", code: "023" },
      { id: 4504, name: "Dagda Municipality", code: "024" },
      { id: 4463, name: "Daugavpils", code: "DGV" },
      { id: 4492, name: "Daugavpils Municipality", code: "025" },
      { id: 4437, name: "Dobele Municipality", code: "026" },
      { id: 4428, name: "Dundaga Municipality", code: "027" },
      { id: 4458, name: "Durbe Municipality", code: "028" },
      { id: 4448, name: "Engure Municipality", code: "029" },
      { id: 4444, name: "\u0112rg\u013Ci Municipality", code: "030" },
      { id: 4510, name: "Garkalne Municipality", code: "031" },
      { id: 4470, name: "Grobi\u0146a Municipality", code: "032" },
      { id: 4400, name: "Gulbene Municipality", code: "033" },
      { id: 4441, name: "Iecava Municipality", code: "034" },
      { id: 4511, name: "Ik\u0161\u0137ile Municipality", code: "035" },
      { id: 4399, name: "Il\u016Bkste Municipality", code: "036" },
      { id: 4449, name: "In\u010Dukalns Municipality", code: "037" },
      { id: 4475, name: "Jaunjelgava Municipality", code: "038" },
      { id: 4407, name: "Jaunpiebalga Municipality", code: "039" },
      { id: 4489, name: "Jaunpils Municipality", code: "040" },
      { id: 4464, name: "J\u0113kabpils", code: "JKB" },
      { id: 4438, name: "J\u0113kabpils Municipality", code: "042" },
      { id: 4500, name: "Jelgava", code: "JEL" },
      { id: 4424, name: "Jelgava Municipality", code: "041" },
      { id: 4446, name: "J\u016Brmala", code: "JUR" },
      { id: 4420, name: "Kandava Municipality", code: "043" },
      { id: 4453, name: "K\u0101rsava Municipality", code: "044" },
      { id: 4412, name: "\u0136egums Municipality", code: "051" },
      { id: 4435, name: "\u0136ekava Municipality", code: "052" },
      { id: 4495, name: "Koc\u0113ni Municipality", code: "045" },
      { id: 4452, name: "Koknese Municipality", code: "046" },
      { id: 4474, name: "Kr\u0101slava Municipality", code: "047" },
      { id: 4422, name: "Krimulda Municipality", code: "048" },
      { id: 4413, name: "Krustpils Municipality", code: "049" },
      { id: 4490, name: "Kuld\u012Bga Municipality", code: "050" },
      { id: 4512, name: "Lielv\u0101rde Municipality", code: "053" },
      { id: 4460, name: "Liep\u0101ja", code: "LPX" },
      { id: 4488, name: "L\u012Bgatne Municipality", code: "055" },
      { id: 4418, name: "Limba\u017Ei Municipality", code: "054" },
      { id: 4401, name: "L\u012Bv\u0101ni Municipality", code: "056" },
      { id: 4419, name: "Lub\u0101na Municipality", code: "057" },
      { id: 4501, name: "Ludza Municipality", code: "058" },
      { id: 4433, name: "Madona Municipality", code: "059" },
      { id: 4461, name: "M\u0101lpils Municipality", code: "061" },
      { id: 4450, name: "M\u0101rupe Municipality", code: "062" },
      { id: 4513, name: "Mazsalaca Municipality", code: "060" },
      { id: 4451, name: "M\u0113rsrags Municipality", code: "063" },
      { id: 4398, name: "Nauk\u0161\u0113ni Municipality", code: "064" },
      { id: 4432, name: "Nereta Municipality", code: "065" },
      { id: 4436, name: "N\u012Bca Municipality", code: "066" },
      { id: 4416, name: "Ogre Municipality", code: "067" },
      { id: 4417, name: "Olaine Municipality", code: "068" },
      { id: 4442, name: "Ozolnieki Municipality", code: "069" },
      { id: 4507, name: "P\u0101rgauja Municipality", code: "070" },
      { id: 4467, name: "P\u0101vilosta Municipality", code: "071" },
      { id: 4405, name: "P\u013Cavi\u0146as Municipality", code: "072" },
      { id: 4483, name: "Prei\u013Ci Municipality", code: "073" },
      { id: 4429, name: "Priekule Municipality", code: "074" },
      { id: 4506, name: "Prieku\u013Ci Municipality", code: "075" },
      { id: 4479, name: "Rauna Municipality", code: "076" },
      { id: 4509, name: "R\u0113zekne", code: "REZ" },
      { id: 4455, name: "R\u0113zekne Municipality", code: "077" },
      { id: 4502, name: "Riebi\u0146i Municipality", code: "078" },
      { id: 4491, name: "Riga", code: "RIX" },
      { id: 4440, name: "Roja Municipality", code: "079" },
      { id: 4493, name: "Ropa\u017Ei Municipality", code: "080" },
      { id: 4503, name: "Rucava Municipality", code: "081" },
      { id: 4423, name: "Rug\u0101ji Municipality", code: "082" },
      { id: 4426, name: "R\u016Bjiena Municipality", code: "084" },
      { id: 4404, name: "Rund\u0101le Municipality", code: "083" },
      { id: 4434, name: "Sala Municipality", code: "085" },
      { id: 4396, name: "Salacgr\u012Bva Municipality", code: "086" },
      { id: 4402, name: "Salaspils Municipality", code: "087" },
      { id: 4439, name: "Saldus Municipality", code: "088" },
      { id: 4443, name: "Saulkrasti Municipality", code: "089" },
      { id: 4408, name: "S\u0113ja Municipality", code: "090" },
      { id: 4476, name: "Sigulda Municipality", code: "091" },
      { id: 4415, name: "Skr\u012Bveri Municipality", code: "092" },
      { id: 4447, name: "Skrunda Municipality", code: "093" },
      { id: 4462, name: "Smiltene Municipality", code: "094" },
      { id: 4478, name: "Stopi\u0146i Municipality", code: "095" },
      { id: 4494, name: "Stren\u010Di Municipality", code: "096" },
      { id: 4459, name: "Talsi Municipality", code: "097" },
      { id: 4480, name: "T\u0113rvete Municipality", code: "098" },
      { id: 4409, name: "Tukums Municipality", code: "099" },
      { id: 4508, name: "Vai\u0146ode Municipality", code: "100" },
      { id: 4425, name: "Valka Municipality", code: "101" },
      { id: 4473, name: "Valmiera", code: "VMR" },
      { id: 4431, name: "Varak\u013C\u0101ni Municipality", code: "102" },
      { id: 4406, name: "V\u0101rkava Municipality", code: "103" },
      { id: 4466, name: "Vecpiebalga Municipality", code: "104" },
      { id: 4397, name: "Vecumnieki Municipality", code: "105" },
      { id: 4421, name: "Ventspils", code: "VEN" },
      { id: 4403, name: "Ventspils Municipality", code: "106" },
      { id: 4456, name: "Vies\u012Bte Municipality", code: "107" },
      { id: 4477, name: "Vi\u013Caka Municipality", code: "108" },
      { id: 4486, name: "Vi\u013C\u0101ni Municipality", code: "109" },
      { id: 4430, name: "Zilupe Municipality", code: "110" }
    ]
  },
  {
    code: "LBN",
    name: "Lebanon",
    states: [
      { id: 2285, name: "Akkar", code: "AK" },
      { id: 2283, name: "Baalbek-Hermel", code: "BH" },
      { id: 2286, name: "Beirut", code: "BA" },
      { id: 2287, name: "Beqaa", code: "BI" },
      { id: 2282, name: "Mount Lebanon", code: "JL" },
      { id: 2288, name: "Nabatieh", code: "NA" },
      { id: 2284, name: "North", code: "AS" },
      { id: 2281, name: "South", code: "JA" }
    ]
  },
  {
    code: "LSO",
    name: "Lesotho",
    states: [
      { id: 3030, name: "Berea District", code: "D" },
      { id: 3029, name: "Butha-Buthe District", code: "B" },
      { id: 3026, name: "Leribe District", code: "C" },
      { id: 3022, name: "Mafeteng District", code: "E" },
      { id: 3028, name: "Maseru District", code: "A" },
      { id: 3023, name: "Mohale's Hoek District", code: "F" },
      { id: 3024, name: "Mokhotlong District", code: "J" },
      { id: 3025, name: "Qacha's Nek District", code: "H" },
      { id: 3027, name: "Quthing District", code: "G" },
      { id: 3031, name: "Thaba-Tseka District", code: "K" }
    ]
  },
  {
    code: "LBR",
    name: "Liberia",
    states: [
      { id: 3041, name: "Bomi County", code: "BM" },
      { id: 3034, name: "Bong County", code: "BG" },
      { id: 3044, name: "Gbarpolu County", code: "GP" },
      { id: 3040, name: "Grand Bassa County", code: "GB" },
      { id: 3036, name: "Grand Cape Mount County", code: "CM" },
      { id: 3039, name: "Grand Gedeh County", code: "GG" },
      { id: 3045, name: "Grand Kru County", code: "GK" },
      { id: 3037, name: "Lofa County", code: "LO" },
      { id: 3043, name: "Margibi County", code: "MG" },
      { id: 3042, name: "Maryland County", code: "MY" },
      { id: 3032, name: "Montserrado County", code: "MO" },
      { id: 3046, name: "Nimba", code: "NI" },
      { id: 3033, name: "River Cess County", code: "RI" },
      { id: 3038, name: "River Gee County", code: "RG" },
      { id: 3035, name: "Sinoe County", code: "SI" }
    ]
  },
  {
    code: "LBY",
    name: "Libya",
    states: [
      { id: 2964, name: "Al Wahat District", code: "WA" },
      { id: 2981, name: "Benghazi", code: "BA" },
      { id: 2966, name: "Derna District", code: "DR" },
      { id: 2969, name: "Ghat District", code: "GT" },
      { id: 2980, name: "Jabal al Akhdar", code: "JA" },
      { id: 2974, name: "Jabal al Gharbi District", code: "JG" },
      { id: 2979, name: "Jafara", code: "JI" },
      { id: 2970, name: "Jufra", code: "JU" },
      { id: 2972, name: "Kufra District", code: "KF" },
      { id: 2968, name: "Marj District", code: "MJ" },
      { id: 2978, name: "Misrata District", code: "MI" },
      { id: 2961, name: "Murqub", code: "MB" },
      { id: 2967, name: "Murzuq District", code: "MQ" },
      { id: 2976, name: "Nalut District", code: "NL" },
      { id: 2962, name: "Nuqat al Khams", code: "NQ" },
      { id: 2965, name: "Sabha District", code: "SB" },
      { id: 2977, name: "Sirte District", code: "SR" },
      { id: 2971, name: "Tripoli District", code: "TB" },
      { id: 2973, name: "Wadi al Hayaa District", code: "WD" },
      { id: 2975, name: "Wadi al Shatii District", code: "WS" },
      { id: 2963, name: "Zawiya District", code: "ZA" }
    ]
  },
  {
    code: "LIE",
    name: "Liechtenstein",
    states: [
      { id: 458, name: "Balzers", code: "01" },
      { id: 451, name: "Eschen", code: "02" },
      { id: 457, name: "Gamprin", code: "03" },
      { id: 455, name: "Mauren", code: "04" },
      { id: 454, name: "Planken", code: "05" },
      { id: 453, name: "Ruggell", code: "06" },
      { id: 450, name: "Schaan", code: "07" },
      { id: 449, name: "Schellenberg", code: "08" },
      { id: 459, name: "Triesen", code: "09" },
      { id: 456, name: "Triesenberg", code: "10" },
      { id: 452, name: "Vaduz", code: "11" }
    ]
  },
  {
    code: "LTU",
    name: "Lithuania",
    states: [
      { id: 1561, name: "Akmen\u0117 District Municipality", code: "01" },
      { id: 1605, name: "Alytus City Municipality", code: "02" },
      { id: 1574, name: "Alytus County", code: "AL" },
      { id: 1599, name: "Alytus District Municipality", code: "03" },
      { id: 1603, name: "Bir\u0161tonas Municipality", code: "05" },
      { id: 1566, name: "Bir\u017Eai District Municipality", code: "06" },
      { id: 1579, name: "Druskininkai municipality", code: "07" },
      { id: 1559, name: "Elektr\u0117nai municipality", code: "08" },
      { id: 1562, name: "Ignalina District Municipality", code: "09" },
      { id: 1567, name: "Jonava District Municipality", code: "10" },
      { id: 1581, name: "Joni\u0161kis District Municipality", code: "11" },
      { id: 1555, name: "Jurbarkas District Municipality", code: "12" },
      { id: 1583, name: "Kai\u0161iadorys District Municipality", code: "13" },
      { id: 1591, name: "Kalvarija municipality", code: "14" },
      { id: 1580, name: "Kaunas City Municipality", code: "15" },
      { id: 1556, name: "Kaunas County", code: "KU" },
      { id: 1565, name: "Kaunas District Municipality", code: "16" },
      { id: 1575, name: "Kazl\u0173 R\u016Bda municipality", code: "17" },
      { id: 1584, name: "K\u0117dainiai District Municipality", code: "18" },
      { id: 1618, name: "Kelm\u0117 District Municipality", code: "19" },
      { id: 1597, name: "Klaipeda City Municipality", code: "20" },
      { id: 1600, name: "Klaip\u0117da County", code: "KL" },
      { id: 1604, name: "Klaip\u0117da District Municipality", code: "21" },
      { id: 1571, name: "Kretinga District Municipality", code: "22" },
      { id: 1585, name: "Kupi\u0161kis District Municipality", code: "23" },
      { id: 1611, name: "Lazdijai District Municipality", code: "24" },
      { id: 1570, name: "Marijampol\u0117 County", code: "MR" },
      { id: 1610, name: "Marijampol\u0117 Municipality", code: "25" },
      { id: 1557, name: "Ma\u017Eeikiai District Municipality", code: "26" },
      { id: 1582, name: "Mol\u0117tai District Municipality", code: "27" },
      { id: 1563, name: "Neringa Municipality", code: "28" },
      { id: 1612, name: "Pag\u0117giai municipality", code: "29" },
      { id: 1595, name: "Pakruojis District Municipality", code: "30" },
      { id: 1588, name: "Palanga City Municipality", code: "31" },
      { id: 1589, name: "Panev\u0117\u017Eys City Municipality", code: "32" },
      { id: 1558, name: "Panev\u0117\u017Eys County", code: "PN" },
      { id: 1614, name: "Panev\u0117\u017Eys District Municipality", code: "33" },
      { id: 1616, name: "Pasvalys District Municipality", code: "34" },
      { id: 1553, name: "Plung\u0117 District Municipality", code: "35" },
      { id: 1578, name: "Prienai District Municipality", code: "36" },
      { id: 1568, name: "Radvili\u0161kis District Municipality", code: "37" },
      { id: 1587, name: "Raseiniai District Municipality", code: "38" },
      { id: 1590, name: "Rietavas municipality", code: "39" },
      { id: 1615, name: "Roki\u0161kis District Municipality", code: "40" },
      { id: 1576, name: "\u0160akiai District Municipality", code: "41" },
      { id: 1577, name: "\u0160al\u010Dininkai District Municipality", code: "42" },
      { id: 1609, name: "\u0160iauliai City Municipality", code: "43" },
      { id: 1586, name: "\u0160iauliai County", code: "SA" },
      { id: 1554, name: "\u0160iauliai District Municipality", code: "44" },
      { id: 1613, name: "\u0160ilal\u0117 District Municipality", code: "45" },
      { id: 1607, name: "\u0160ilut\u0117 District Municipality", code: "46" },
      { id: 1594, name: "\u0160irvintos District Municipality", code: "47" },
      { id: 1617, name: "Skuodas District Municipality", code: "48" },
      { id: 1560, name: "\u0160ven\u010Dionys District Municipality", code: "49" },
      { id: 1573, name: "Taurag\u0117 County", code: "TA" },
      { id: 1572, name: "Taurag\u0117 District Municipality", code: "50" },
      { id: 1569, name: "Tel\u0161iai County", code: "TE" },
      { id: 1608, name: "Tel\u0161iai District Municipality", code: "51" },
      { id: 1593, name: "Trakai District Municipality", code: "52" },
      { id: 1596, name: "Ukmerg\u0117 District Municipality", code: "53" },
      { id: 1621, name: "Utena County", code: "UT" },
      { id: 1598, name: "Utena District Municipality", code: "54" },
      { id: 1602, name: "Var\u0117na District Municipality", code: "55" },
      { id: 1620, name: "Vilkavi\u0161kis District Municipality", code: "56" },
      { id: 1606, name: "Vilnius City Municipality", code: "57" },
      { id: 1601, name: "Vilnius County", code: "VL" },
      { id: 1592, name: "Vilnius District Municipality", code: "58" },
      { id: 1564, name: "Visaginas Municipality", code: "59" },
      { id: 1619, name: "Zarasai District Municipality", code: "60" }
    ]
  },
  {
    code: "LUX",
    name: "Luxembourg",
    states: [
      { id: 1518, name: "Canton of Capellen", code: "CA" },
      { id: 1521, name: "Canton of Clervaux", code: "CL" },
      { id: 1513, name: "Canton of Diekirch", code: "DI" },
      { id: 1515, name: "Canton of Echternach", code: "EC" },
      { id: 1517, name: "Canton of Esch-sur-Alzette", code: "ES" },
      { id: 1525, name: "Canton of Grevenmacher", code: "GR" },
      { id: 1527, name: "Canton of Luxembourg", code: "LU" },
      { id: 1522, name: "Canton of Mersch", code: "ME" },
      { id: 1516, name: "Canton of Redange", code: "RD" },
      { id: 1519, name: "Canton of Remich", code: "RM" },
      { id: 1523, name: "Canton of Vianden", code: "VD" },
      { id: 1526, name: "Canton of Wiltz", code: "WI" },
      { id: 1524, name: "Diekirch District", code: "D" },
      { id: 1520, name: "Grevenmacher District", code: "G" },
      { id: 1514, name: "Luxembourg District", code: "L" }
    ]
  },
  { code: "MAC", name: "Macau S.A.R.", states: [] },
  {
    code: "MDG",
    name: "Madagascar",
    states: [
      { id: 2951, name: "Antananarivo Province", code: "T" },
      { id: 2950, name: "Antsiranana Province", code: "D" },
      { id: 2948, name: "Fianarantsoa Province", code: "F" },
      { id: 2953, name: "Mahajanga Province", code: "M" },
      { id: 2952, name: "Toamasina Province", code: "A" },
      { id: 2949, name: "Toliara Province", code: "U" }
    ]
  },
  {
    code: "MWI",
    name: "Malawi",
    states: [
      { id: 3096, name: "Balaka District", code: "BA" },
      { id: 3102, name: "Blantyre District", code: "BL" },
      { id: 3092, name: "Central Region", code: "C" },
      { id: 3107, name: "Chikwawa District", code: "CK" },
      { id: 3109, name: "Chiradzulu District", code: "CR" },
      { id: 3087, name: "Chitipa district", code: "CT" },
      { id: 3097, name: "Dedza District", code: "DE" },
      { id: 3090, name: "Dowa District", code: "DO" },
      { id: 3091, name: "Karonga District", code: "KR" },
      { id: 3094, name: "Kasungu District", code: "KS" },
      { id: 3093, name: "Likoma District", code: "LK" },
      { id: 3101, name: "Lilongwe District", code: "LI" },
      { id: 3082, name: "Machinga District", code: "MH" },
      { id: 3110, name: "Mangochi District", code: "MG" },
      { id: 3099, name: "Mchinji District", code: "MC" },
      { id: 3103, name: "Mulanje District", code: "MU" },
      { id: 3084, name: "Mwanza District", code: "MW" },
      { id: 3104, name: "Mzimba District", code: "MZ" },
      { id: 3095, name: "Nkhata Bay District", code: "NB" },
      { id: 3100, name: "Nkhotakota District", code: "NK" },
      { id: 3105, name: "Northern Region", code: "N" },
      { id: 3085, name: "Nsanje District", code: "NS" },
      { id: 3088, name: "Ntcheu District", code: "NU" },
      { id: 3111, name: "Ntchisi District", code: "NI" },
      { id: 3108, name: "Phalombe District", code: "PH" },
      { id: 3089, name: "Rumphi District", code: "RU" },
      { id: 3086, name: "Salima District", code: "SA" },
      { id: 3106, name: "Southern Region", code: "S" },
      { id: 3098, name: "Thyolo District", code: "TH" },
      { id: 3083, name: "Zomba District", code: "ZO" }
    ]
  },
  {
    code: "MYS",
    name: "Malaysia",
    states: [
      { id: 1950, name: "Johor", code: "01" },
      { id: 1947, name: "Kedah", code: "02" },
      { id: 1946, name: "Kelantan", code: "03" },
      { id: 1949, name: "Kuala Lumpur", code: "14" },
      { id: 1935, name: "Labuan", code: "15" },
      { id: 1941, name: "Malacca", code: "04" },
      { id: 1948, name: "Negeri Sembilan", code: "05" },
      { id: 1940, name: "Pahang", code: "06" },
      { id: 1939, name: "Penang", code: "07" },
      { id: 1943, name: "Perak", code: "08" },
      { id: 1938, name: "Perlis", code: "09" },
      { id: 1945, name: "Putrajaya", code: "16" },
      { id: 1936, name: "Sabah", code: "12" },
      { id: 1937, name: "Sarawak", code: "13" },
      { id: 1944, name: "Selangor", code: "10" },
      { id: 1942, name: "Terengganu", code: "11" }
    ]
  },
  {
    code: "MDV",
    name: "Maldives",
    states: [
      { id: 2594, name: "Addu Atoll", code: "01" },
      { id: 2587, name: "Alif Alif Atoll", code: "02" },
      { id: 2600, name: "Alif Dhaal Atoll", code: "00" },
      { id: 2604, name: "Central Province", code: "CE" },
      { id: 2590, name: "Dhaalu Atoll", code: "17" },
      { id: 2599, name: "Faafu Atoll", code: "14" },
      { id: 2598, name: "Gaafu Alif Atoll", code: "27" },
      { id: 2603, name: "Gaafu Dhaalu Atoll", code: "28" },
      { id: 2595, name: "Gnaviyani Atoll", code: "29" },
      { id: 2586, name: "Haa Alif Atoll", code: "07" },
      { id: 2597, name: "Haa Dhaalu Atoll", code: "23" },
      { id: 2596, name: "Kaafu Atoll", code: "26" },
      { id: 2601, name: "Laamu Atoll", code: "05" },
      { id: 2607, name: "Lhaviyani Atoll", code: "03" },
      { id: 2609, name: "Mal\xE9", code: "MLE" },
      { id: 2608, name: "Meemu Atoll", code: "12" },
      { id: 2592, name: "Noonu Atoll", code: "25" },
      { id: 2589, name: "North Central Province", code: "NC" },
      { id: 2588, name: "North Province", code: "NO" },
      { id: 2602, name: "Raa Atoll", code: "13" },
      { id: 2585, name: "Shaviyani Atoll", code: "24" },
      { id: 2606, name: "South Central Province", code: "SC" },
      { id: 2605, name: "South Province", code: "SU" },
      { id: 2591, name: "Thaa Atoll", code: "08" },
      { id: 2593, name: "Upper South Province", code: "US" },
      { id: 2584, name: "Vaavu Atoll", code: "04" }
    ]
  },
  {
    code: "MLI",
    name: "Mali",
    states: [
      { id: 253, name: "Bamako", code: "BKO" },
      { id: 258, name: "Gao Region", code: "7" },
      { id: 252, name: "Kayes Region", code: "1" },
      { id: 257, name: "Kidal Region", code: "8" },
      { id: 250, name: "Koulikoro Region", code: "2" },
      { id: 251, name: "M\xE9naka Region", code: "9" },
      { id: 255, name: "Mopti Region", code: "5" },
      { id: 249, name: "S\xE9gou Region", code: "4" },
      { id: 254, name: "Sikasso Region", code: "3" },
      { id: 256, name: "Taoud\xE9nit Region", code: "10" },
      { id: 248, name: "Tombouctou Region", code: "6" }
    ]
  },
  {
    code: "MLT",
    name: "Malta",
    states: [
      { id: 110, name: "Attard", code: "01" },
      { id: 108, name: "Balzan", code: "02" },
      { id: 107, name: "Birgu", code: "03" },
      { id: 97, name: "Birkirkara", code: "04" },
      { id: 88, name: "Bir\u017Cebbu\u0121a", code: "05" },
      { id: 138, name: "Cospicua", code: "06" },
      { id: 117, name: "Dingli", code: "07" },
      { id: 129, name: "Fgura", code: "08" },
      { id: 84, name: "Floriana", code: "09" },
      { id: 134, name: "Fontana", code: "10" },
      { id: 130, name: "G\u0127ajnsielem", code: "13" },
      { id: 92, name: "G\u0127arb", code: "14" },
      { id: 120, name: "G\u0127arg\u0127ur", code: "15" },
      { id: 106, name: "G\u0127asri", code: "16" },
      { id: 124, name: "G\u0127axaq", code: "17" },
      { id: 118, name: "Gudja", code: "11" },
      { id: 113, name: "G\u017Cira", code: "12" },
      { id: 105, name: "\u0126amrun", code: "18" },
      { id: 93, name: "Iklin", code: "19" },
      { id: 99, name: "Kalkara", code: "21" },
      { id: 91, name: "Ker\u010Bem", code: "22" },
      { id: 82, name: "Kirkop", code: "23" },
      { id: 126, name: "Lija", code: "24" },
      { id: 77, name: "Luqa", code: "25" },
      { id: 128, name: "Marsa", code: "26" },
      { id: 137, name: "Marsaskala", code: "27" },
      { id: 78, name: "Marsaxlokk", code: "28" },
      { id: 89, name: "Mdina", code: "29" },
      { id: 102, name: "Mellie\u0127a", code: "30" },
      { id: 109, name: "M\u0121arr", code: "31" },
      { id: 140, name: "Mosta", code: "32" },
      { id: 74, name: "Mqabba", code: "33" },
      { id: 96, name: "Msida", code: "34" },
      { id: 131, name: "Mtarfa", code: "35" },
      { id: 132, name: "Munxar", code: "36" },
      { id: 133, name: "Nadur", code: "37" },
      { id: 112, name: "Naxxar", code: "38" },
      { id: 115, name: "Paola", code: "39" },
      { id: 125, name: "Pembroke", code: "40" },
      { id: 127, name: "Piet\xE0", code: "41" },
      { id: 79, name: "Qala", code: "42" },
      { id: 119, name: "Qormi", code: "43" },
      { id: 111, name: "Qrendi", code: "44" },
      { id: 83, name: "Rabat", code: "46" },
      { id: 87, name: "Saint Lawrence", code: "50" },
      { id: 75, name: "San \u0120wann", code: "49" },
      { id: 116, name: "Sannat", code: "52" },
      { id: 94, name: "Santa Lu\u010Bija", code: "53" },
      { id: 90, name: "Santa Venera", code: "54" },
      { id: 136, name: "Senglea", code: "20" },
      { id: 98, name: "Si\u0121\u0121iewi", code: "55" },
      { id: 104, name: "Sliema", code: "56" },
      { id: 100, name: "St. Julian's", code: "48" },
      { id: 139, name: "St. Paul's Bay", code: "51" },
      { id: 86, name: "Swieqi", code: "57" },
      { id: 122, name: "Ta' Xbiex", code: "58" },
      { id: 103, name: "Tarxien", code: "59" },
      { id: 95, name: "Valletta", code: "60" },
      { id: 101, name: "Victoria", code: "45" },
      { id: 114, name: "Xag\u0127ra", code: "61" },
      { id: 121, name: "Xewkija", code: "62" },
      { id: 81, name: "Xg\u0127ajra", code: "63" },
      { id: 123, name: "\u017Babbar", code: "64" },
      { id: 85, name: "\u017Bebbu\u0121 Gozo", code: "65" },
      { id: 80, name: "\u017Bebbu\u0121 Malta", code: "66" },
      { id: 135, name: "\u017Bejtun", code: "67" },
      { id: 76, name: "\u017Burrieq", code: "68" }
    ]
  },
  { code: "IMN", name: "Man (Isle of)", states: [] },
  {
    code: "MHL",
    name: "Marshall Islands",
    states: [
      { id: 2574, name: "Ralik Chain", code: "L" },
      { id: 2573, name: "Ratak Chain", code: "T" }
    ]
  },
  { code: "MTQ", name: "Martinique", states: [] },
  {
    code: "MRT",
    name: "Mauritania",
    states: [
      { id: 3344, name: "Adrar", code: "07" },
      { id: 3349, name: "Assaba", code: "03" },
      { id: 3339, name: "Brakna", code: "05" },
      { id: 3346, name: "Dakhlet Nouadhibou", code: "08" },
      { id: 3341, name: "Gorgol", code: "04" },
      { id: 3350, name: "Guidimaka", code: "10" },
      { id: 3338, name: "Hodh Ech Chargui", code: "01" },
      { id: 3351, name: "Hodh El Gharbi", code: "02" },
      { id: 3342, name: "Inchiri", code: "12" },
      { id: 3343, name: "Nouakchott-Nord", code: "14" },
      { id: 3352, name: "Nouakchott-Ouest", code: "13" },
      { id: 3347, name: "Nouakchott-Sud", code: "15" },
      { id: 3345, name: "Tagant", code: "09" },
      { id: 3340, name: "Tiris Zemmour", code: "11" },
      { id: 3348, name: "Trarza", code: "06" }
    ]
  },
  {
    code: "MUS",
    name: "Mauritius",
    states: [
      { id: 3248, name: "Agalega Islands", code: "AG" },
      { id: 3259, name: "Black River", code: "BL" },
      { id: 3254, name: "Flacq", code: "FL" },
      { id: 3264, name: "Grand Port", code: "GP" },
      { id: 3253, name: "Moka", code: "MO" },
      { id: 3250, name: "Pamplemousses", code: "PA" },
      { id: 3263, name: "Plaines Wilhems", code: "PW" },
      { id: 3260, name: "Port Louis", code: "PL" },
      { id: 3261, name: "Rivi\xE8re du Rempart", code: "RR" },
      { id: 3249, name: "Rodrigues Island", code: "RO" },
      { id: 3251, name: "Saint Brandon Islands", code: "CC" },
      { id: 3257, name: "Savanne", code: "SA" }
    ]
  },
  { code: "MYT", name: "Mayotte", states: [] },
  {
    code: "MEX",
    name: "Mexico",
    states: [
      { id: 3456, name: "Aguascalientes", code: "AGU" },
      { id: 3457, name: "Baja California", code: "BCN" },
      { id: 3460, name: "Baja California Sur", code: "BCS" },
      { id: 3475, name: "Campeche", code: "CAM" },
      { id: 3451, name: "Chiapas", code: "CHP" },
      { id: 3447, name: "Chihuahua", code: "CHH" },
      { id: 3473, name: "Ciudad de M\xE9xico", code: "CDMX" },
      { id: 3471, name: "Coahuila de Zaragoza", code: "COA" },
      { id: 3472, name: "Colima", code: "COL" },
      { id: 3453, name: "Durango", code: "DUR" },
      { id: 3450, name: "Estado de M\xE9xico", code: "MEX" },
      { id: 3469, name: "Guanajuato", code: "GUA" },
      { id: 3459, name: "Guerrero", code: "GRO" },
      { id: 3470, name: "Hidalgo", code: "HID" },
      { id: 4857, name: "Jalisco", code: "JAL" },
      { id: 3474, name: "Michoac\xE1n de Ocampo", code: "MIC" },
      { id: 3465, name: "Morelos", code: "MOR" },
      { id: 3477, name: "Nayarit", code: "NAY" },
      { id: 3452, name: "Nuevo Le\xF3n", code: "NLE" },
      { id: 3448, name: "Oaxaca", code: "OAX" },
      { id: 3476, name: "Puebla", code: "PUE" },
      { id: 3455, name: "Quer\xE9taro", code: "QUE" },
      { id: 3467, name: "Quintana Roo", code: "ROO" },
      { id: 3461, name: "San Luis Potos\xED", code: "SLP" },
      { id: 3449, name: "Sinaloa", code: "SIN" },
      { id: 3468, name: "Sonora", code: "SON" },
      { id: 3454, name: "Tabasco", code: "TAB" },
      { id: 3463, name: "Tamaulipas", code: "TAM" },
      { id: 3458, name: "Tlaxcala", code: "TLA" },
      { id: 3464, name: "Veracruz de Ignacio de la Llave", code: "VER" },
      { id: 3466, name: "Yucat\xE1n", code: "YUC" },
      { id: 3462, name: "Zacatecas", code: "ZAC" }
    ]
  },
  {
    code: "FSM",
    name: "Micronesia",
    states: [
      { id: 2580, name: "Chuuk State", code: "TRK" },
      { id: 2583, name: "Kosrae State", code: "KSA" },
      { id: 2581, name: "Pohnpei State", code: "PNI" },
      { id: 2582, name: "Yap State", code: "YAP" }
    ]
  },
  {
    code: "MDA",
    name: "Moldova",
    states: [
      { id: 4368, name: "Anenii Noi District", code: "AN" },
      { id: 4393, name: "B\u0103l\u021Bi Municipality", code: "BA" },
      { id: 4379, name: "Basarabeasca District", code: "BS" },
      { id: 4362, name: "Bender Municipality", code: "BD" },
      { id: 4375, name: "Briceni District", code: "BR" },
      { id: 4391, name: "Cahul District", code: "CA" },
      { id: 4366, name: "C\u0103l\u0103ra\u0219i District", code: "CL" },
      { id: 4380, name: "Cantemir District", code: "CT" },
      { id: 4365, name: "C\u0103u\u0219eni District", code: "CS" },
      { id: 4373, name: "Chi\u0219in\u0103u Municipality", code: "CU" },
      { id: 4360, name: "Cimi\u0219lia District", code: "CM" },
      { id: 4390, name: "Criuleni District", code: "CR" },
      { id: 4384, name: "Dondu\u0219eni District", code: "DO" },
      { id: 4392, name: "Drochia District", code: "DR" },
      { id: 4383, name: "Dub\u0103sari District", code: "DU" },
      { id: 4387, name: "Edine\u021B District", code: "ED" },
      { id: 4381, name: "F\u0103le\u0219ti District", code: "FA" },
      { id: 4370, name: "Flore\u0219ti District", code: "FL" },
      { id: 4385, name: "Gagauzia", code: "GA" },
      { id: 4367, name: "Glodeni District", code: "GL" },
      { id: 4382, name: "H\xEEnce\u0219ti District", code: "HI" },
      { id: 4369, name: "Ialoveni District", code: "IA" },
      { id: 4363, name: "Nisporeni District", code: "NI" },
      { id: 4389, name: "Ocni\u021Ba District", code: "OC" },
      { id: 4361, name: "Orhei District", code: "OR" },
      { id: 4394, name: "Rezina District", code: "RE" },
      { id: 4376, name: "R\xEE\u0219cani District", code: "RI" },
      { id: 4364, name: "S\xEEngerei District", code: "SI" },
      { id: 4388, name: "\u0218old\u0103ne\u0219ti District", code: "SD" },
      { id: 4374, name: "Soroca District", code: "SO" },
      { id: 4378, name: "\u0218tefan Vod\u0103 District", code: "SV" },
      { id: 4377, name: "Str\u0103\u0219eni District", code: "ST" },
      { id: 4372, name: "Taraclia District", code: "TA" },
      { id: 4371, name: "Telene\u0219ti District", code: "TE" },
      {
        id: 4395,
        name: "Transnistria autonomous territorial unit",
        code: "SN"
      },
      { id: 4386, name: "Ungheni District", code: "UN" }
    ]
  },
  {
    code: "MCO",
    name: "Monaco",
    states: [
      { id: 4917, name: "La Colle", code: "CL" },
      { id: 4918, name: "La Condamine", code: "CO" },
      { id: 4919, name: "Moneghetti", code: "MG" }
    ]
  },
  {
    code: "MNG",
    name: "Mongolia",
    states: [
      { id: 1973, name: "Arkhangai Province", code: "073" },
      { id: 1969, name: "Bayan-\xD6lgii Province", code: "071" },
      { id: 1976, name: "Bayankhongor Province", code: "069" },
      { id: 1961, name: "Bulgan Province", code: "067" },
      { id: 1962, name: "Darkhan-Uul Province", code: "037" },
      { id: 1963, name: "Dornod Province", code: "061" },
      { id: 1981, name: "Dornogovi Province", code: "063" },
      { id: 1970, name: "Dundgovi Province", code: "059" },
      { id: 1972, name: "Govi-Altai Province", code: "065" },
      { id: 1978, name: "Govis\xFCmber Province", code: "064" },
      { id: 1974, name: "Khentii Province", code: "039" },
      { id: 1964, name: "Khovd Province", code: "043" },
      { id: 1975, name: "Kh\xF6vsg\xF6l Province", code: "041" },
      { id: 1967, name: "\xD6mn\xF6govi Province", code: "053" },
      { id: 1966, name: "Orkhon Province", code: "035" },
      { id: 1965, name: "\xD6v\xF6rkhangai Province", code: "055" },
      { id: 1980, name: "Selenge Province", code: "049" },
      { id: 1977, name: "S\xFCkhbaatar Province", code: "051" },
      { id: 1968, name: "T\xF6v Province", code: "047" },
      { id: 1971, name: "Uvs Province", code: "046" },
      { id: 1979, name: "Zavkhan Province", code: "057" }
    ]
  },
  {
    code: "MNE",
    name: "Montenegro",
    states: [
      { id: 23, name: "Andrijevica Municipality", code: "01" },
      { id: 13, name: "Bar Municipality", code: "02" },
      { id: 21, name: "Berane Municipality", code: "03" },
      { id: 25, name: "Bijelo Polje Municipality", code: "04" },
      { id: 30, name: "Budva Municipality", code: "05" },
      { id: 14, name: "Danilovgrad Municipality", code: "07" },
      { id: 24, name: "Gusinje Municipality", code: "22" },
      { id: 31, name: "Kola\u0161in Municipality", code: "09" },
      { id: 26, name: "Kotor Municipality", code: "10" },
      { id: 22, name: "Mojkovac Municipality", code: "11" },
      { id: 17, name: "Nik\u0161i\u0107 Municipality", code: "12" },
      { id: 28, name: "Old Royal Capital Cetinje", code: "06" },
      { id: 12, name: "Petnjica Municipality", code: "23" },
      { id: 19, name: "Plav Municipality", code: "13" },
      { id: 20, name: "Pljevlja Municipality", code: "14" },
      { id: 16, name: "Plu\u017Eine Municipality", code: "15" },
      { id: 27, name: "Podgorica Municipality", code: "16" },
      { id: 15, name: "Ro\u017Eaje Municipality", code: "17" },
      { id: 18, name: "\u0160avnik Municipality", code: "18" },
      { id: 29, name: "Tivat Municipality", code: "19" },
      { id: 33, name: "Ulcinj Municipality", code: "20" },
      { id: 32, name: "\u017Dabljak Municipality", code: "21" }
    ]
  },
  { code: "MSR", name: "Montserrat", states: [] },
  {
    code: "MAR",
    name: "Morocco",
    states: [
      { id: 4928, name: "Agadir-Ida-Ou-Tanane", code: "AGD" },
      { id: 3320, name: "Al Haouz", code: "HAO" },
      { id: 3267, name: "Al Hoce\xEFma", code: "HOC" },
      { id: 3266, name: "Aousserd (EH)", code: "AOU" },
      { id: 3297, name: "Assa-Zag (EH-partial)", code: "ASZ" },
      { id: 3321, name: "Azilal", code: "AZI" },
      { id: 3272, name: "B\xE9ni Mellal", code: "BEM" },
      { id: 3278, name: "B\xE9ni Mellal-Kh\xE9nifra", code: "05" },
      { id: 3304, name: "Benslimane", code: "BES" },
      { id: 3285, name: "Berkane", code: "BER" },
      { id: 4929, name: "Berrechid", code: "BRR" },
      { id: 3275, name: "Boujdour (EH)", code: "BOD" },
      { id: 3270, name: "Boulemane", code: "BOM" },
      { id: 4930, name: "Casablanca", code: "CAS" },
      { id: 3303, name: "Casablanca-Settat", code: "06" },
      { id: 3310, name: "Chefchaouen", code: "CHE" },
      { id: 3274, name: "Chichaoua", code: "CHI" },
      { id: 3302, name: "Chtouka-Ait Baha", code: "CHT" },
      { id: 3306, name: "Dakhla-Oued Ed-Dahab (EH)", code: "12" },
      { id: 3290, name: "Dr\xE2a-Tafilalet", code: "08" },
      { id: 4931, name: "Driouch", code: "DRI" },
      { id: 3291, name: "El Hajeb", code: "HAJ" },
      { id: 3280, name: "El Jadida", code: "JDI" },
      { id: 3309, name: "El Kel\xE2a des Sraghna", code: "KES" },
      { id: 3299, name: "Errachidia", code: "ERR" },
      { id: 3292, name: "Es-Semara (EH-partial)", code: "ESM" },
      { id: 3316, name: "Essaouira", code: "ESI" },
      { id: 3300, name: "Fahs-Anjra", code: "FAH" },
      { id: 4932, name: "F\xE8s", code: "FES" },
      { id: 3313, name: "F\xE8s-Mekn\xE8s", code: "03" },
      { id: 3301, name: "Figuig", code: "FIG" },
      { id: 4933, name: "Fquih Ben Salah", code: "FQH" },
      { id: 3265, name: "Guelmim", code: "GUE" },
      { id: 3305, name: "Guelmim-Oued Noun (EH-partial)", code: "10" },
      { id: 4934, name: "Guercif", code: "GUF" },
      { id: 3325, name: "Ifrane", code: "IFR" },
      { id: 3294, name: "Inezgane-Ait Melloul", code: "INE" },
      { id: 3307, name: "Jerada", code: "JRA" },
      { id: 3308, name: "K\xE9nitra", code: "KEN" },
      { id: 3276, name: "Kh\xE9misset", code: "KHE" },
      { id: 3317, name: "Kh\xE9nifra", code: "KHN" },
      { id: 3326, name: "Khouribga", code: "KHO" },
      { id: 3271, name: "L'Oriental", code: "02" },
      { id: 3293, name: "La\xE2youne (EH)", code: "LAA" },
      {
        id: 3298,
        name: "La\xE2youne-Sakia El Hamra (EH-partial)",
        code: "11"
      },
      { id: 3268, name: "Larache", code: "LAR" },
      { id: 4936, name: "M\u2019diq-Fnideq", code: "MDF" },
      { id: 4935, name: "Marrakech", code: "MAR" },
      { id: 3288, name: "Marrakesh-Safi", code: "07" },
      { id: 3284, name: "M\xE9diouna", code: "MED" },
      { id: 4937, name: "Mekn\xE8s", code: "MEK" },
      { id: 4938, name: "Midelt", code: "MID" },
      { id: 4939, name: "Mohammadia", code: "MOH" },
      { id: 3315, name: "Moulay Yacoub", code: "MOU" },
      { id: 3281, name: "Nador", code: "NAD" },
      { id: 3287, name: "Nouaceur", code: "NOU" },
      { id: 3269, name: "Ouarzazate", code: "OUA" },
      { id: 3319, name: "Oued Ed-Dahab (EH)", code: "OUD" },
      { id: 4941, name: "Ouezzane", code: "OUZ" },
      { id: 4940, name: "Oujda-Angad", code: "OUJ" },
      { id: 4942, name: "Rabat", code: "RAB" },
      { id: 4927, name: "Rabat-Sal\xE9-K\xE9nitra", code: "04" },
      { id: 4943, name: "Rehamna", code: "REH" },
      { id: 3311, name: "Safi", code: "SAF" },
      { id: 4944, name: "Sal\xE9", code: "SAL" },
      { id: 3289, name: "Sefrou", code: "SEF" },
      { id: 3282, name: "Settat", code: "SET" },
      { id: 4945, name: "Sidi Bennour", code: "SIB" },
      { id: 4946, name: "Sidi Ifni", code: "SIF" },
      { id: 3279, name: "Sidi Kacem", code: "SIK" },
      { id: 4952, name: "Sidi Slimane", code: "SIL" },
      { id: 4947, name: "Skhirate-T\xE9mara", code: "SKH" },
      { id: 3295, name: "Souss-Massa", code: "09" },
      { id: 3286, name: "Tan-Tan (EH-partial)", code: "TNT" },
      { id: 4950, name: "Tanger-Assilah", code: "TNG" },
      { id: 3324, name: "Tanger-T\xE9touan-Al Hoce\xEFma", code: "01" },
      { id: 3323, name: "Taounate", code: "TAO" },
      { id: 3322, name: "Taourirt", code: "TAI" },
      { id: 4948, name: "Tarfaya (EH-partial)", code: "TAF" },
      { id: 3314, name: "Taroudannt", code: "TAR" },
      { id: 3312, name: "Tata", code: "TAT" },
      { id: 3296, name: "Taza", code: "TAZ" },
      { id: 3318, name: "T\xE9touan", code: "TET" },
      { id: 4949, name: "Tinghir", code: "TIN" },
      { id: 3277, name: "Tiznit", code: "TIZ" },
      { id: 4951, name: "Youssoufia", code: "YUS" },
      { id: 3283, name: "Zagora", code: "ZAG" }
    ]
  },
  {
    code: "MOZ",
    name: "Mozambique",
    states: [
      { id: 3327, name: "Cabo Delgado Province", code: "P" },
      { id: 3329, name: "Gaza Province", code: "G" },
      { id: 3330, name: "Inhambane Province", code: "I" },
      { id: 3337, name: "Manica Province", code: "B" },
      { id: 3335, name: "Maputo", code: "MPM" },
      { id: 3332, name: "Maputo Province", code: "L" },
      { id: 3336, name: "Nampula Province", code: "N" },
      { id: 3333, name: "Niassa Province", code: "A" },
      { id: 3331, name: "Sofala Province", code: "S" },
      { id: 3334, name: "Tete Province", code: "T" },
      { id: 3328, name: "Zambezia Province", code: "Q" }
    ]
  },
  {
    code: "MMR",
    name: "Myanmar",
    states: [
      { id: 2142, name: "Ayeyarwady Region", code: "07" },
      { id: 2141, name: "Bago", code: "02" },
      { id: 2137, name: "Chin State", code: "14" },
      { id: 2143, name: "Kachin State", code: "11" },
      { id: 2144, name: "Kayah State", code: "12" },
      { id: 2133, name: "Kayin State", code: "13" },
      { id: 2136, name: "Magway Region", code: "03" },
      { id: 2134, name: "Mandalay Region", code: "04" },
      { id: 2147, name: "Mon State", code: "15" },
      { id: 2146, name: "Naypyidaw Union Territory", code: "18" },
      { id: 2138, name: "Rakhine State", code: "16" },
      { id: 2145, name: "Sagaing Region", code: "01" },
      { id: 2139, name: "Shan State", code: "17" },
      { id: 2140, name: "Tanintharyi Region", code: "05" },
      { id: 2135, name: "Yangon Region", code: "06" }
    ]
  },
  {
    code: "NAM",
    name: "Namibia",
    states: [
      { id: 43, name: "Erongo Region", code: "ER" },
      { id: 38, name: "Hardap Region", code: "HA" },
      { id: 45, name: "Karas Region", code: "KA" },
      { id: 36, name: "Kavango East Region", code: "KE" },
      { id: 35, name: "Kavango West Region", code: "KW" },
      { id: 44, name: "Khomas Region", code: "KH" },
      { id: 34, name: "Kunene Region", code: "KU" },
      { id: 40, name: "Ohangwena Region", code: "OW" },
      { id: 41, name: "Omaheke Region", code: "OH" },
      { id: 39, name: "Omusati Region", code: "OS" },
      { id: 37, name: "Oshana Region", code: "ON" },
      { id: 42, name: "Oshikoto Region", code: "OT" },
      { id: 46, name: "Otjozondjupa Region", code: "OD" },
      { id: 47, name: "Zambezi Region", code: "CA" }
    ]
  },
  {
    code: "NRU",
    name: "Nauru",
    states: [
      { id: 4656, name: "Aiwo District", code: "01" },
      { id: 4658, name: "Anabar District", code: "02" },
      { id: 4667, name: "Anetan District", code: "03" },
      { id: 4663, name: "Anibare District", code: "04" },
      { id: 4660, name: "Baiti District", code: "05" },
      { id: 4665, name: "Boe District", code: "06" },
      { id: 4662, name: "Buada District", code: "07" },
      { id: 4666, name: "Denigomodu District", code: "08" },
      { id: 4654, name: "Ewa District", code: "09" },
      { id: 4661, name: "Ijuw District", code: "10" },
      { id: 4657, name: "Meneng District", code: "11" },
      { id: 4659, name: "Nibok District", code: "12" },
      { id: 4655, name: "Uaboe District", code: "13" },
      { id: 4664, name: "Yaren District", code: "14" }
    ]
  },
  {
    code: "NPL",
    name: "Nepal",
    states: [
      { id: 2082, name: "Bagmati Zone", code: "BA" },
      { id: 2071, name: "Bheri Zone", code: "BH" },
      { id: 2073, name: "Central Region", code: "1" },
      { id: 2080, name: "Dhaulagiri Zone", code: "DH" },
      { id: 2069, name: "Eastern Development Region", code: "4" },
      { id: 2068, name: "Far-Western Development Region", code: "5" },
      { id: 2081, name: "Gandaki Zone", code: "GA" },
      { id: 2076, name: "Janakpur Zone", code: "JA" },
      { id: 2079, name: "Karnali Zone", code: "KA" },
      { id: 2072, name: "Kosi Zone", code: "KO" },
      { id: 2074, name: "Lumbini Zone", code: "LU" },
      { id: 2083, name: "Mahakali Zone", code: "MA" },
      { id: 2070, name: "Mechi Zone", code: "ME" },
      { id: 2066, name: "Mid-Western Region", code: "2" },
      { id: 2075, name: "Narayani Zone", code: "NA" },
      { id: 2077, name: "Rapti Zone", code: "RA" },
      { id: 2084, name: "Sagarmatha Zone", code: "SA" },
      { id: 2078, name: "Seti Zone", code: "SE" },
      { id: 2067, name: "Western Region", code: "3" }
    ]
  },
  {
    code: "NLD",
    name: "Netherlands",
    states: [
      { id: 2624, name: "Bonaire", code: "BQ1" },
      { id: 2613, name: "Drenthe", code: "DR" },
      { id: 2619, name: "Flevoland", code: "FL" },
      { id: 2622, name: "Friesland", code: "FR" },
      { id: 2611, name: "Gelderland", code: "GE" },
      { id: 2617, name: "Groningen", code: "GR" },
      { id: 2615, name: "Limburg", code: "LI" },
      { id: 2623, name: "North Brabant", code: "NB" },
      { id: 2612, name: "North Holland", code: "NH" },
      { id: 2618, name: "Overijssel", code: "OV" },
      { id: 2621, name: "Saba", code: "BQ2" },
      { id: 2616, name: "Sint Eustatius", code: "BQ3" },
      { id: 2614, name: "South Holland", code: "ZH" },
      { id: 2610, name: "Utrecht", code: "UT" },
      { id: 2620, name: "Zeeland", code: "ZE" }
    ]
  },
  { code: "NCL", name: "New Caledonia", states: [] },
  {
    code: "NZL",
    name: "New Zealand",
    states: [
      { id: 4072, name: "Auckland Region", code: "AUK" },
      { id: 4074, name: "Bay of Plenty Region", code: "BOP" },
      { id: 4066, name: "Canterbury Region", code: "CAN" },
      { id: 4067, name: "Chatham Islands", code: "CIT" },
      { id: 4068, name: "Gisborne District", code: "GIS" },
      { id: 4075, name: "Hawke's Bay Region", code: "HKB" },
      { id: 4060, name: "Manawatu-Wanganui Region", code: "MWT" },
      { id: 4063, name: "Marlborough Region", code: "MBH" },
      { id: 4070, name: "Nelson Region", code: "NSN" },
      { id: 4059, name: "Northland Region", code: "NTL" },
      { id: 4062, name: "Otago Region", code: "OTA" },
      { id: 4071, name: "Southland Region", code: "STL" },
      { id: 4069, name: "Taranaki Region", code: "TKI" },
      { id: 4073, name: "Tasman District", code: "TAS" },
      { id: 4061, name: "Waikato Region", code: "WKO" },
      { id: 4065, name: "Wellington Region", code: "WGN" },
      { id: 4064, name: "West Coast Region", code: "WTC" }
    ]
  },
  {
    code: "NIC",
    name: "Nicaragua",
    states: [
      { id: 946, name: "Boaco", code: "BO" },
      { id: 950, name: "Carazo", code: "CA" },
      { id: 954, name: "Chinandega", code: "CI" },
      { id: 940, name: "Chontales", code: "CO" },
      { id: 945, name: "Estel\xED", code: "ES" },
      { id: 943, name: "Granada", code: "GR" },
      { id: 955, name: "Jinotega", code: "JI" },
      { id: 944, name: "Le\xF3n", code: "LE" },
      { id: 948, name: "Madriz", code: "MD" },
      { id: 941, name: "Managua", code: "MN" },
      { id: 953, name: "Masaya", code: "MS" },
      { id: 947, name: "Matagalpa", code: "MT" },
      { id: 951, name: "North Caribbean Coast", code: "AN" },
      { id: 4964, name: "Nueva Segovia", code: "NS" },
      { id: 949, name: "R\xEDo San Juan", code: "SJ" },
      { id: 942, name: "Rivas", code: "RI" },
      { id: 952, name: "South Caribbean Coast", code: "AS" }
    ]
  },
  {
    code: "NER",
    name: "Niger",
    states: [
      { id: 71, name: "Agadez Region", code: "1" },
      { id: 72, name: "Diffa Region", code: "2" },
      { id: 68, name: "Dosso Region", code: "3" },
      { id: 70, name: "Maradi Region", code: "4" },
      { id: 73, name: "Tahoua Region", code: "5" },
      { id: 67, name: "Tillab\xE9ri Region", code: "6" },
      { id: 69, name: "Zinder Region", code: "7" }
    ]
  },
  {
    code: "NGA",
    name: "Nigeria",
    states: [
      { id: 303, name: "Abia", code: "AB" },
      { id: 293, name: "Abuja Federal Capital Territory", code: "FC" },
      { id: 320, name: "Adamawa", code: "AD" },
      { id: 304, name: "Akwa Ibom", code: "AK" },
      { id: 315, name: "Anambra", code: "AN" },
      { id: 312, name: "Bauchi", code: "BA" },
      { id: 305, name: "Bayelsa", code: "BY" },
      { id: 291, name: "Benue", code: "BE" },
      { id: 307, name: "Borno", code: "BO" },
      { id: 314, name: "Cross River", code: "CR" },
      { id: 316, name: "Delta", code: "DE" },
      { id: 311, name: "Ebonyi", code: "EB" },
      { id: 318, name: "Edo", code: "ED" },
      { id: 309, name: "Ekiti", code: "EK" },
      { id: 289, name: "Enugu", code: "EN" },
      { id: 310, name: "Gombe", code: "GO" },
      { id: 308, name: "Imo", code: "IM" },
      { id: 288, name: "Jigawa", code: "JI" },
      { id: 294, name: "Kaduna", code: "KD" },
      { id: 300, name: "Kano", code: "KN" },
      { id: 313, name: "Katsina", code: "KT" },
      { id: 290, name: "Kebbi", code: "KE" },
      { id: 298, name: "Kogi", code: "KO" },
      { id: 295, name: "Kwara", code: "KW" },
      { id: 306, name: "Lagos", code: "LA" },
      { id: 301, name: "Nasarawa", code: "NA" },
      { id: 317, name: "Niger", code: "NI" },
      { id: 323, name: "Ogun", code: "OG" },
      { id: 321, name: "Ondo", code: "ON" },
      { id: 322, name: "Osun", code: "OS" },
      { id: 296, name: "Oyo", code: "OY" },
      { id: 302, name: "Plateau", code: "PL" },
      { id: 4926, name: "Rivers", code: "RI" },
      { id: 292, name: "Sokoto", code: "SO" },
      { id: 319, name: "Taraba", code: "TA" },
      { id: 297, name: "Yobe", code: "YO" },
      { id: 299, name: "Zamfara", code: "ZA" }
    ]
  },
  { code: "NIU", name: "Niue", states: [] },
  { code: "NFK", name: "Norfolk Island", states: [] },
  {
    code: "PRK",
    name: "North Korea",
    states: [
      { id: 3998, name: "Chagang Province", code: "04" },
      { id: 3999, name: "Kangwon Province", code: "07" },
      { id: 3995, name: "North Hamgyong Province", code: "09" },
      { id: 4004, name: "North Hwanghae Province", code: "06" },
      { id: 4002, name: "North Pyongan Province", code: "03" },
      { id: 4005, name: "Pyongyang", code: "01" },
      { id: 4001, name: "Rason", code: "13" },
      { id: 3996, name: "Ryanggang Province", code: "10" },
      { id: 4e3, name: "South Hamgyong Province", code: "08" },
      { id: 4003, name: "South Hwanghae Province", code: "05" },
      { id: 3997, name: "South Pyongan Province", code: "02" }
    ]
  },
  {
    code: "MKD",
    name: "North Macedonia",
    states: [
      { id: 703, name: "Aerodrom Municipality", code: "01" },
      { id: 656, name: "Ara\u010Dinovo Municipality", code: "02" },
      { id: 716, name: "Berovo Municipality", code: "03" },
      { id: 679, name: "Bitola Municipality", code: "04" },
      { id: 649, name: "Bogdanci Municipality", code: "05" },
      { id: 721, name: "Bogovinje Municipality", code: "06" },
      { id: 652, name: "Bosilovo Municipality", code: "07" },
      { id: 660, name: "Brvenica Municipality", code: "08" },
      { id: 694, name: "Butel Municipality", code: "09" },
      { id: 704, name: "\u010Cair Municipality", code: "79" },
      { id: 676, name: "\u010Ca\u0161ka Municipality", code: "80" },
      { id: 702, name: "Centar Municipality", code: "77" },
      { id: 720, name: "Centar \u017Dupa Municipality", code: "78" },
      { id: 644, name: "\u010Ce\u0161inovo-Oble\u0161evo Municipality", code: "81" },
      { id: 715, name: "\u010Cu\u010Der-Sandevo Municipality", code: "82" },
      { id: 645, name: "Debarca Municipality", code: "22" },
      { id: 695, name: "Del\u010Devo Municipality", code: "23" },
      { id: 687, name: "Demir Hisar Municipality", code: "25" },
      { id: 655, name: "Demir Kapija Municipality", code: "24" },
      { id: 697, name: "Dojran Municipality", code: "26" },
      { id: 675, name: "Dolneni Municipality", code: "27" },
      { id: 657, name: "Drugovo Municipality", code: "28" },
      { id: 707, name: "Gazi Baba Municipality", code: "17" },
      { id: 648, name: "Gevgelija Municipality", code: "18" },
      { id: 722, name: "Gjor\u010De Petrov Municipality", code: "29" },
      { id: 693, name: "Gostivar Municipality", code: "19" },
      { id: 708, name: "Gradsko Municipality", code: "20" },
      { id: 684, name: "Greater Skopje", code: "85" },
      { id: 690, name: "Ilinden Municipality", code: "34" },
      { id: 678, name: "Jegunovce Municipality", code: "35" },
      { id: 674, name: "Karbinci", code: "37" },
      { id: 681, name: "Karpo\u0161 Municipality", code: "38" },
      { id: 713, name: "Kavadarci Municipality", code: "36" },
      { id: 688, name: "Ki\u010Devo Municipality", code: "40" },
      { id: 686, name: "Kisela Voda Municipality", code: "39" },
      { id: 723, name: "Ko\u010Dani Municipality", code: "42" },
      { id: 665, name: "Kon\u010De Municipality", code: "41" },
      { id: 641, name: "Kratovo Municipality", code: "43" },
      { id: 677, name: "Kriva Palanka Municipality", code: "44" },
      { id: 647, name: "Krivoga\u0161tani Municipality", code: "45" },
      { id: 714, name: "Kru\u0161evo Municipality", code: "46" },
      { id: 683, name: "Kumanovo Municipality", code: "47" },
      { id: 659, name: "Lipkovo Municipality", code: "48" },
      { id: 705, name: "Lozovo Municipality", code: "49" },
      { id: 701, name: "Makedonska Kamenica Municipality", code: "51" },
      { id: 692, name: "Makedonski Brod Municipality", code: "52" },
      { id: 669, name: "Mavrovo and Rostu\u0161a Municipality", code: "50" },
      { id: 653, name: "Mogila Municipality", code: "53" },
      { id: 664, name: "Negotino Municipality", code: "54" },
      { id: 696, name: "Novaci Municipality", code: "55" },
      { id: 718, name: "Novo Selo Municipality", code: "56" },
      { id: 699, name: "Ohrid Municipality", code: "58" },
      { id: 682, name: "Oslomej Municipality", code: "57" },
      { id: 685, name: "Peh\u010Devo Municipality", code: "60" },
      { id: 698, name: "Petrovec Municipality", code: "59" },
      { id: 670, name: "Plasnica Municipality", code: "61" },
      { id: 666, name: "Prilep Municipality", code: "62" },
      { id: 646, name: "Probi\u0161tip Municipality", code: "63" },
      { id: 709, name: "Radovi\u0161 Municipality", code: "64" },
      { id: 717, name: "Rankovce Municipality", code: "65" },
      { id: 712, name: "Resen Municipality", code: "66" },
      { id: 691, name: "Rosoman Municipality", code: "67" },
      { id: 667, name: "Saraj Municipality", code: "68" },
      { id: 719, name: "Sopi\u0161te Municipality", code: "70" },
      { id: 643, name: "Staro Nagori\u010Dane Municipality", code: "71" },
      { id: 661, name: "\u0160tip Municipality", code: "83" },
      { id: 700, name: "Struga Municipality", code: "72" },
      { id: 710, name: "Strumica Municipality", code: "73" },
      { id: 711, name: "Studeni\u010Dani Municipality", code: "74" },
      { id: 680, name: "\u0160uto Orizari Municipality", code: "84" },
      { id: 640, name: "Sveti Nikole Municipality", code: "69" },
      { id: 654, name: "Tearce Municipality", code: "75" },
      { id: 663, name: "Tetovo Municipality", code: "76" },
      { id: 671, name: "Valandovo Municipality", code: "10" },
      { id: 658, name: "Vasilevo Municipality", code: "11" },
      { id: 651, name: "Veles Municipality", code: "13" },
      { id: 662, name: "Vev\u010Dani Municipality", code: "12" },
      { id: 672, name: "Vinica Municipality", code: "14" },
      { id: 650, name: "Vrane\u0161tica Municipality", code: "15" },
      { id: 689, name: "Vrap\u010Di\u0161te Municipality", code: "16" },
      { id: 642, name: "Zajas Municipality", code: "31" },
      { id: 706, name: "Zelenikovo Municipality", code: "32" },
      { id: 668, name: "\u017Delino Municipality", code: "30" },
      { id: 673, name: "Zrnovci Municipality", code: "33" }
    ]
  },
  { code: "MNP", name: "Northern Mariana Islands", states: [] },
  {
    code: "NOR",
    name: "Norway",
    states: [
      { id: 1014, name: "Agder", code: "42" },
      { id: 1009, name: "Innlandet", code: "34" },
      { id: 1026, name: "Jan Mayen", code: "22" },
      { id: 1020, name: "M\xF8re og Romsdal", code: "15" },
      { id: 1025, name: "Nordland", code: "18" },
      { id: 1007, name: "Oslo", code: "03" },
      { id: 1021, name: "Rogaland", code: "11" },
      { id: 1013, name: "Svalbard", code: "21" },
      { id: 1015, name: "Troms og Finnmark", code: "54" },
      { id: 1006, name: "Tr\xF8ndelag", code: "50" },
      { id: 1024, name: "Vestfold og Telemark", code: "38" },
      { id: 1018, name: "Vestland", code: "46" },
      { id: 1011, name: "Viken", code: "30" }
    ]
  },
  {
    code: "OMN",
    name: "Oman",
    states: [
      { id: 3058, name: "Ad Dakhiliyah", code: "DA" },
      { id: 3047, name: "Ad Dhahirah", code: "ZA" },
      { id: 3048, name: "Al Batinah North", code: "BS" },
      { id: 3050, name: "Al Batinah Region", code: "BA" },
      { id: 3049, name: "Al Batinah South", code: "BJ" },
      { id: 3059, name: "Al Buraimi", code: "BU" },
      { id: 3056, name: "Al Wusta", code: "WU" },
      { id: 3053, name: "Ash Sharqiyah North", code: "SS" },
      { id: 3051, name: "Ash Sharqiyah Region", code: "SH" },
      { id: 3054, name: "Ash Sharqiyah South", code: "SJ" },
      { id: 3057, name: "Dhofar", code: "ZU" },
      { id: 3052, name: "Musandam", code: "MU" },
      { id: 3055, name: "Muscat", code: "MA" }
    ]
  },
  {
    code: "PAK",
    name: "Pakistan",
    states: [
      { id: 3172, name: "Azad Kashmir", code: "JK" },
      { id: 3174, name: "Balochistan", code: "BA" },
      {
        id: 3173,
        name: "Federally Administered Tribal Areas",
        code: "TA"
      },
      { id: 3170, name: "Gilgit-Baltistan", code: "GB" },
      { id: 3169, name: "Islamabad Capital Territory", code: "IS" },
      { id: 3171, name: "Khyber Pakhtunkhwa", code: "KP" },
      { id: 3176, name: "Punjab", code: "PB" },
      { id: 3175, name: "Sindh", code: "SD" }
    ]
  },
  {
    code: "PLW",
    name: "Palau",
    states: [
      { id: 4540, name: "Aimeliik", code: "002" },
      { id: 4528, name: "Airai", code: "004" },
      { id: 4538, name: "Angaur", code: "010" },
      { id: 4529, name: "Hatohobei", code: "050" },
      { id: 4539, name: "Kayangel", code: "100" },
      { id: 4532, name: "Koror", code: "150" },
      { id: 4530, name: "Melekeok", code: "212" },
      { id: 4537, name: "Ngaraard", code: "214" },
      { id: 4533, name: "Ngarchelong", code: "218" },
      { id: 4527, name: "Ngardmau", code: "222" },
      { id: 4531, name: "Ngatpang", code: "224" },
      { id: 4536, name: "Ngchesar", code: "226" },
      { id: 4541, name: "Ngeremlengui", code: "227" },
      { id: 4534, name: "Ngiwal", code: "228" },
      { id: 4526, name: "Peleliu", code: "350" },
      { id: 4535, name: "Sonsorol", code: "370" }
    ]
  },
  {
    code: "PSE",
    name: "Palestinian Territory Occupied",
    states: [
      { id: 5118, name: "Bethlehem", code: "BTH" },
      { id: 5119, name: "Deir El Balah", code: "DEB" },
      { id: 5120, name: "Gaza", code: "GZA" },
      { id: 5121, name: "Hebron", code: "HBN" },
      { id: 5122, name: "Jenin", code: "JEN" },
      { id: 5123, name: "Jericho and Al Aghwar", code: "JRH" },
      { id: 5124, name: "Jerusalem", code: "JEM" },
      { id: 5125, name: "Khan Yunis", code: "KYS" },
      { id: 5126, name: "Nablus", code: "NBS" },
      { id: 5127, name: "North Gaza", code: "NGZ" },
      { id: 5128, name: "Qalqilya", code: "QQA" },
      { id: 5129, name: "Rafah", code: "RFH" },
      { id: 5130, name: "Ramallah", code: "RBH" },
      { id: 5131, name: "Salfit", code: "SLT" },
      { id: 5132, name: "Tubas", code: "TBS" },
      { id: 5133, name: "Tulkarm", code: "TKM" }
    ]
  },
  {
    code: "PAN",
    name: "Panama",
    states: [
      { id: 1393, name: "Bocas del Toro Province", code: "1" },
      { id: 1397, name: "Chiriqu\xED Province", code: "4" },
      { id: 1387, name: "Cocl\xE9 Province", code: "2" },
      { id: 1386, name: "Col\xF3n Province", code: "3" },
      { id: 1385, name: "Dari\xE9n Province", code: "5" },
      { id: 1396, name: "Ember\xE1-Wounaan Comarca", code: "EM" },
      { id: 1388, name: "Guna Yala", code: "KY" },
      { id: 1389, name: "Herrera Province", code: "6" },
      { id: 1390, name: "Los Santos Province", code: "7" },
      { id: 1391, name: "Ng\xF6be-Bugl\xE9 Comarca", code: "NB" },
      { id: 1394, name: "Panam\xE1 Oeste Province", code: "10" },
      { id: 1395, name: "Panam\xE1 Province", code: "8" },
      { id: 1392, name: "Veraguas Province", code: "9" }
    ]
  },
  {
    code: "PNG",
    name: "Papua new Guinea",
    states: [
      { id: 4831, name: "Bougainville", code: "NSB" },
      { id: 4847, name: "Central Province", code: "CPM" },
      { id: 4846, name: "Chimbu Province", code: "CPK" },
      { id: 4834, name: "East New Britain", code: "EBR" },
      { id: 4845, name: "Eastern Highlands Province", code: "EHG" },
      { id: 4848, name: "Enga Province", code: "EPW" },
      { id: 4839, name: "Gulf", code: "GPK" },
      { id: 4833, name: "Hela", code: "HLA" },
      { id: 4832, name: "Jiwaka Province", code: "JWK" },
      { id: 4843, name: "Madang Province", code: "MPM" },
      { id: 4842, name: "Manus Province", code: "MRL" },
      { id: 4849, name: "Milne Bay Province", code: "MBA" },
      { id: 4835, name: "Morobe Province", code: "MPL" },
      { id: 4841, name: "New Ireland Province", code: "NIK" },
      { id: 4838, name: "Oro Province", code: "NPP" },
      { id: 4837, name: "Port Moresby", code: "NCD" },
      { id: 4836, name: "Sandaun Province", code: "SAN" },
      { id: 4844, name: "Southern Highlands Province", code: "SHM" },
      { id: 4830, name: "West New Britain Province", code: "WBK" },
      { id: 4840, name: "Western Highlands Province", code: "WHM" },
      { id: 4850, name: "Western Province", code: "WPD" }
    ]
  },
  {
    code: "PRY",
    name: "Paraguay",
    states: [
      { id: 2785, name: "Alto Paraguay Department", code: "16" },
      { id: 2784, name: "Alto Paran\xE1 Department", code: "10" },
      { id: 2782, name: "Amambay Department", code: "13" },
      { id: 5221, name: "Asuncion", code: "ASU" },
      { id: 2780, name: "Boquer\xF3n Department", code: "19" },
      { id: 2773, name: "Caaguaz\xFA", code: "5" },
      { id: 2775, name: "Caazap\xE1", code: "6" },
      { id: 2771, name: "Canindey\xFA", code: "14" },
      { id: 2777, name: "Central Department", code: "11" },
      { id: 2779, name: "Concepci\xF3n Department", code: "1" },
      { id: 2783, name: "Cordillera Department", code: "3" },
      { id: 2772, name: "Guair\xE1 Department", code: "4" },
      { id: 2778, name: "Itap\xFAa", code: "7" },
      { id: 2786, name: "Misiones Department", code: "8" },
      { id: 2781, name: "\xD1eembuc\xFA Department", code: "12" },
      { id: 2774, name: "Paraguar\xED Department", code: "9" },
      { id: 2770, name: "Presidente Hayes Department", code: "15" },
      { id: 2776, name: "San Pedro Department", code: "2" }
    ]
  },
  {
    code: "PER",
    name: "Peru",
    states: [
      { id: 3685, name: "Amazonas", code: "AMA" },
      { id: 3680, name: "\xC1ncash", code: "ANC" },
      { id: 3699, name: "Apur\xEDmac", code: "APU" },
      { id: 3681, name: "Arequipa", code: "ARE" },
      { id: 3692, name: "Ayacucho", code: "AYA" },
      { id: 3688, name: "Cajamarca", code: "CAJ" },
      { id: 3701, name: "Callao", code: "CAL" },
      { id: 3691, name: "Cusco", code: "CUS" },
      { id: 3679, name: "Huancavelica", code: "HUV" },
      { id: 3687, name: "Huanuco", code: "HUC" },
      { id: 3700, name: "Ica", code: "ICA" },
      { id: 3693, name: "Jun\xEDn", code: "JUN" },
      { id: 3683, name: "La Libertad", code: "LAL" },
      { id: 3702, name: "Lambayeque", code: "LAM" },
      { id: 3695, name: "Lima", code: "LIM" },
      { id: 4922, name: "Loreto", code: "LOR" },
      { id: 3678, name: "Madre de Dios", code: "MDD" },
      { id: 3698, name: "Moquegua", code: "MOQ" },
      { id: 3686, name: "Pasco", code: "PAS" },
      { id: 3697, name: "Piura", code: "PIU" },
      { id: 3682, name: "Puno", code: "PUN" },
      { id: 3694, name: "San Mart\xEDn", code: "SAM" },
      { id: 3696, name: "Tacna", code: "TAC" },
      { id: 3689, name: "Tumbes", code: "TUM" },
      { id: 3684, name: "Ucayali", code: "UCA" }
    ]
  },
  {
    code: "PHL",
    name: "Philippines",
    states: [
      { id: 1324, name: "Abra", code: "ABR" },
      { id: 1323, name: "Agusan del Norte", code: "AGN" },
      { id: 1326, name: "Agusan del Sur", code: "AGS" },
      { id: 1331, name: "Aklan", code: "AKL" },
      { id: 1337, name: "Albay", code: "ALB" },
      { id: 1336, name: "Antique", code: "ANT" },
      { id: 1334, name: "Apayao", code: "APA" },
      { id: 1341, name: "Aurora", code: "AUR" },
      {
        id: 1316,
        name: "Autonomous Region in Muslim Mindanao",
        code: "14"
      },
      { id: 1346, name: "Basilan", code: "BAS" },
      { id: 1344, name: "Bataan", code: "BAN" },
      { id: 1352, name: "Batanes", code: "BTN" },
      { id: 1359, name: "Batangas", code: "BTG" },
      { id: 1363, name: "Benguet", code: "BEN" },
      { id: 1304, name: "Bicol", code: "05" },
      { id: 1274, name: "Biliran", code: "BIL" },
      { id: 1272, name: "Bohol", code: "BOH" },
      { id: 1270, name: "Bukidnon", code: "BUK" },
      { id: 1278, name: "Bulacan", code: "BUL" },
      { id: 1279, name: "Cagayan", code: "CAG" },
      { id: 1342, name: "Cagayan Valley", code: "02" },
      { id: 1294, name: "Calabarzon", code: "40" },
      { id: 1283, name: "Camarines Norte", code: "CAN" },
      { id: 1287, name: "Camarines Sur", code: "CAS" },
      { id: 1285, name: "Camiguin", code: "CAM" },
      { id: 1292, name: "Capiz", code: "CAP" },
      { id: 1314, name: "Caraga", code: "13" },
      { id: 1301, name: "Catanduanes", code: "CAT" },
      { id: 1307, name: "Cavite", code: "CAV" },
      { id: 1306, name: "Cebu", code: "CEB" },
      { id: 1345, name: "Central Luzon", code: "03" },
      { id: 1308, name: "Central Visayas", code: "07" },
      { id: 1311, name: "Compostela Valley", code: "COM" },
      { id: 1335, name: "Cordillera Administrative", code: "15" },
      { id: 1320, name: "Cotabato", code: "NCO" },
      { id: 1340, name: "Davao", code: "11" },
      { id: 1319, name: "Davao del Norte", code: "DAV" },
      { id: 1318, name: "Davao del Sur", code: "DAS" },
      { id: 1309, name: "Davao Occidental", code: "DVO" },
      { id: 1289, name: "Davao Oriental", code: "DAO" },
      { id: 1291, name: "Dinagat Islands", code: "DIN" },
      { id: 1290, name: "Eastern Samar", code: "EAS" },
      { id: 1322, name: "Eastern Visayas", code: "08" },
      { id: 1303, name: "Guimaras", code: "GUI" },
      { id: 1300, name: "Ifugao", code: "IFU" },
      { id: 1355, name: "Ilocos", code: "01" },
      { id: 1298, name: "Ilocos Norte", code: "ILN" },
      { id: 1321, name: "Ilocos Sur", code: "ILS" },
      { id: 1315, name: "Iloilo", code: "ILI" },
      { id: 1313, name: "Isabela", code: "ISA" },
      { id: 1312, name: "Kalinga", code: "KAL" },
      { id: 1317, name: "La Union", code: "LUN" },
      { id: 1328, name: "Laguna", code: "LAG" },
      { id: 1327, name: "Lanao del Norte", code: "LAN" },
      { id: 1333, name: "Lanao del Sur", code: "LAS" },
      { id: 1332, name: "Leyte", code: "LEY" },
      { id: 1330, name: "Maguindanao", code: "MAG" },
      { id: 1329, name: "Marinduque", code: "MAD" },
      { id: 1338, name: "Masbate", code: "MAS" },
      { id: 1347, name: "Metro Manila", code: "NCR" },
      { id: 1299, name: "Mimaropa", code: "41" },
      { id: 1343, name: "Misamis Occidental", code: "MSC" },
      { id: 1348, name: "Misamis Oriental", code: "MSR" },
      { id: 1353, name: "Mountain Province", code: "MOU" },
      { id: 1351, name: "Negros Occidental", code: "NEC" },
      { id: 1350, name: "Negros Oriental", code: "NER" },
      { id: 1339, name: "Northern Mindanao", code: "10" },
      { id: 1349, name: "Northern Samar", code: "NSA" },
      { id: 1360, name: "Nueva Ecija", code: "NUE" },
      { id: 1358, name: "Nueva Vizcaya", code: "NUV" },
      { id: 1356, name: "Occidental Mindoro", code: "MDC" },
      { id: 1354, name: "Oriental Mindoro", code: "MDR" },
      { id: 1361, name: "Palawan", code: "PLW" },
      { id: 1365, name: "Pampanga", code: "PAM" },
      { id: 1364, name: "Pangasinan", code: "PAN" },
      { id: 1275, name: "Quezon", code: "QUE" },
      { id: 1273, name: "Quirino", code: "QUI" },
      { id: 1271, name: "Rizal", code: "RIZ" },
      { id: 1269, name: "Romblon", code: "ROM" },
      { id: 1277, name: "Sarangani", code: "SAR" },
      { id: 1276, name: "Siquijor", code: "SIG" },
      { id: 1310, name: "Soccsksargen", code: "12" },
      { id: 1281, name: "Sorsogon", code: "SOR" },
      { id: 1280, name: "South Cotabato", code: "SCO" },
      { id: 1284, name: "Southern Leyte", code: "SLE" },
      { id: 1282, name: "Sultan Kudarat", code: "SUK" },
      { id: 1288, name: "Sulu", code: "SLU" },
      { id: 1286, name: "Surigao del Norte", code: "SUN" },
      { id: 1296, name: "Surigao del Sur", code: "SUR" },
      { id: 1295, name: "Tarlac", code: "TAR" },
      { id: 1293, name: "Tawi-Tawi", code: "TAW" },
      { id: 5115, name: "Western Samar", code: "WSA" },
      { id: 1305, name: "Western Visayas", code: "06" },
      { id: 1297, name: "Zambales", code: "ZMB" },
      { id: 1302, name: "Zamboanga del Norte", code: "ZAN" },
      { id: 1357, name: "Zamboanga del Sur", code: "ZAS" },
      { id: 1325, name: "Zamboanga Peninsula", code: "09" },
      { id: 1362, name: "Zamboanga Sibugay", code: "ZSI" }
    ]
  },
  { code: "PCN", name: "Pitcairn Island", states: [] },
  {
    code: "POL",
    name: "Poland",
    states: [
      { id: 1634, name: "Greater Poland Voivodeship", code: "WP" },
      { id: 1625, name: "Kuyavian-Pomeranian Voivodeship", code: "KP" },
      { id: 1635, name: "Lesser Poland Voivodeship", code: "MA" },
      { id: 1629, name: "Lower Silesian Voivodeship", code: "DS" },
      { id: 1638, name: "Lublin Voivodeship", code: "LU" },
      { id: 1631, name: "Lubusz Voivodeship", code: "LB" },
      { id: 1636, name: "\u0141\xF3d\u017A Voivodeship", code: "LD" },
      { id: 1637, name: "Masovian Voivodeship", code: "MZ" },
      { id: 1622, name: "Opole Voivodeship", code: "OP" },
      { id: 1626, name: "Podkarpackie Voivodeship", code: "PK" },
      { id: 1632, name: "Podlaskie Voivodeship", code: "PD" },
      { id: 1624, name: "Pomeranian Voivodeship", code: "PM" },
      { id: 1623, name: "Silesian Voivodeship", code: "SL" },
      { id: 1630, name: "\u015Awi\u0119tokrzyskie Voivodeship", code: "SK" },
      { id: 1628, name: "Warmian-Masurian Voivodeship", code: "WN" },
      { id: 1633, name: "West Pomeranian Voivodeship", code: "ZP" }
    ]
  },
  {
    code: "PRT",
    name: "Portugal",
    states: [
      { id: 2233, name: "A\xE7ores", code: "20" },
      { id: 2235, name: "Aveiro", code: "01" },
      { id: 2230, name: "Beja", code: "02" },
      { id: 2244, name: "Braga", code: "03" },
      { id: 2229, name: "Bragan\xE7a", code: "04" },
      { id: 2241, name: "Castelo Branco", code: "05" },
      { id: 2246, name: "Coimbra", code: "06" },
      { id: 2236, name: "\xC9vora", code: "07" },
      { id: 2239, name: "Faro", code: "08" },
      { id: 4859, name: "Guarda", code: "09" },
      { id: 2240, name: "Leiria", code: "10" },
      { id: 2228, name: "Lisbon", code: "11" },
      { id: 2231, name: "Madeira", code: "30" },
      { id: 2232, name: "Portalegre", code: "12" },
      { id: 2243, name: "Porto", code: "13" },
      { id: 2238, name: "Santar\xE9m", code: "14" },
      { id: 2242, name: "Set\xFAbal", code: "15" },
      { id: 2245, name: "Viana do Castelo", code: "16" },
      { id: 2234, name: "Vila Real", code: "17" },
      { id: 2237, name: "Viseu", code: "18" }
    ]
  },
  {
    code: "PRI",
    name: "Puerto Rico",
    states: [
      { id: 5134, name: "Adjuntas", code: "001" },
      { id: 5135, name: "Aguada", code: "003" },
      { id: 5136, name: "Aguadilla", code: "005" },
      { id: 5137, name: "Aguas Buenas", code: "007" },
      { id: 5138, name: "Aibonito", code: "009" },
      { id: 5139, name: "A\xF1asco", code: "011" },
      { id: 5140, name: "Arecibo", code: "013" },
      { id: 5081, name: "Arecibo", code: "AR" },
      { id: 5141, name: "Arroyo", code: "015" },
      { id: 5142, name: "Barceloneta", code: "017" },
      { id: 5143, name: "Barranquitas", code: "019" },
      { id: 5076, name: "Bayamon", code: "BY" },
      { id: 5144, name: "Bayam\xF3n", code: "021" },
      { id: 5145, name: "Cabo Rojo", code: "023" },
      { id: 5079, name: "Caguas", code: "CG" },
      { id: 5146, name: "Caguas", code: "025" },
      { id: 5147, name: "Camuy", code: "027" },
      { id: 5148, name: "Can\xF3vanas", code: "029" },
      { id: 5077, name: "Carolina", code: "CL" },
      { id: 5149, name: "Carolina", code: "031" },
      { id: 5150, name: "Cata\xF1o", code: "033" },
      { id: 5151, name: "Cayey", code: "035" },
      { id: 5152, name: "Ceiba", code: "037" },
      { id: 5153, name: "Ciales", code: "039" },
      { id: 5154, name: "Cidra", code: "041" },
      { id: 5155, name: "Coamo", code: "043" },
      { id: 5156, name: "Comer\xEDo", code: "045" },
      { id: 5157, name: "Corozal", code: "047" },
      { id: 5158, name: "Culebra", code: "049" },
      { id: 5159, name: "Dorado", code: "051" },
      { id: 5160, name: "Fajardo", code: "053" },
      { id: 5161, name: "Florida", code: "054" },
      { id: 5162, name: "Gu\xE1nica", code: "055" },
      { id: 5163, name: "Guayama", code: "057" },
      { id: 5164, name: "Guayanilla", code: "059" },
      { id: 5080, name: "Guaynabo", code: "GN" },
      { id: 5165, name: "Guaynabo", code: "061" },
      { id: 5166, name: "Gurabo", code: "063" },
      { id: 5167, name: "Hatillo", code: "065" },
      { id: 5168, name: "Hormigueros", code: "067" },
      { id: 5169, name: "Humacao", code: "069" },
      { id: 5170, name: "Isabela", code: "071" },
      { id: 5171, name: "Jayuya", code: "073" },
      { id: 5172, name: "Juana D\xEDaz", code: "075" },
      { id: 5173, name: "Juncos", code: "077" },
      { id: 5174, name: "Lajas", code: "079" },
      { id: 5175, name: "Lares", code: "081" },
      { id: 5176, name: "Las Mar\xEDas", code: "083" },
      { id: 5177, name: "Las Piedras", code: "085" },
      { id: 5178, name: "Lo\xEDza", code: "087" },
      { id: 5179, name: "Luquillo", code: "089" },
      { id: 5180, name: "Manat\xED", code: "091" },
      { id: 5181, name: "Maricao", code: "093" },
      { id: 5182, name: "Maunabo", code: "095" },
      { id: 5083, name: "Mayag\xFCez", code: "MG" },
      { id: 5183, name: "Mayag\xFCez", code: "097" },
      { id: 5184, name: "Moca", code: "099" },
      { id: 5185, name: "Morovis", code: "101" },
      { id: 5186, name: "Naguabo", code: "103" },
      { id: 5187, name: "Naranjito", code: "105" },
      { id: 5188, name: "Orocovis", code: "107" },
      { id: 5189, name: "Patillas", code: "109" },
      { id: 5190, name: "Pe\xF1uelas", code: "111" },
      { id: 5078, name: "Ponce", code: "PO" },
      { id: 5191, name: "Ponce", code: "113" },
      { id: 5192, name: "Quebradillas", code: "115" },
      { id: 5193, name: "Rinc\xF3n", code: "117" },
      { id: 5194, name: "R\xEDo Grande", code: "119" },
      { id: 5195, name: "Sabana Grande", code: "121" },
      { id: 5196, name: "Salinas", code: "123" },
      { id: 5197, name: "San Germ\xE1n", code: "125" },
      { id: 5198, name: "San Juan", code: "127" },
      { id: 5075, name: "San Juan", code: "SJ" },
      { id: 5199, name: "San Lorenzo", code: "129" },
      { id: 5200, name: "San Sebasti\xE1n", code: "131" },
      { id: 5201, name: "Santa Isabel", code: "133" },
      { id: 5202, name: "Toa Alta", code: "135" },
      { id: 5082, name: "Toa Baja", code: "TB" },
      { id: 5203, name: "Toa Baja", code: "137" },
      { id: 5084, name: "Trujillo Alto", code: "TA" },
      { id: 5204, name: "Trujillo Alto", code: "139" },
      { id: 5205, name: "Utuado", code: "141" },
      { id: 5206, name: "Vega Alta", code: "143" },
      { id: 5207, name: "Vega Baja", code: "145" },
      { id: 5208, name: "Vieques", code: "147" },
      { id: 5209, name: "Villalba", code: "149" },
      { id: 5210, name: "Yabucoa", code: "151" },
      { id: 5211, name: "Yauco", code: "153" }
    ]
  },
  {
    code: "QAT",
    name: "Qatar",
    states: [
      { id: 3182, name: "Al Daayen", code: "ZA" },
      { id: 3183, name: "Al Khor", code: "KH" },
      { id: 3177, name: "Al Rayyan Municipality", code: "RA" },
      { id: 3179, name: "Al Wakrah", code: "WA" },
      { id: 3178, name: "Al-Shahaniya", code: "SH" },
      { id: 3181, name: "Doha", code: "DA" },
      { id: 3180, name: "Madinat ash Shamal", code: "MS" },
      { id: 3184, name: "Umm Salal Municipality", code: "US" }
    ]
  },
  { code: "REU", name: "Reunion", states: [] },
  {
    code: "ROU",
    name: "Romania",
    states: [
      { id: 4724, name: "Alba", code: "AB" },
      { id: 4739, name: "Arad County", code: "AR" },
      { id: 4722, name: "Arges", code: "AG" },
      { id: 4744, name: "Bac\u0103u County", code: "BC" },
      { id: 4723, name: "Bihor County", code: "BH" },
      { id: 4733, name: "Bistri\u021Ba-N\u0103s\u0103ud County", code: "BN" },
      { id: 4740, name: "Boto\u0219ani County", code: "BT" },
      { id: 4736, name: "Braila", code: "BR" },
      { id: 4759, name: "Bra\u0219ov County", code: "BV" },
      { id: 4730, name: "Bucharest", code: "B" },
      { id: 4756, name: "Buz\u0103u County", code: "BZ" },
      { id: 4732, name: "C\u0103l\u0103ra\u0219i County", code: "CL" },
      { id: 4753, name: "Cara\u0219-Severin County", code: "CS" },
      { id: 4734, name: "Cluj County", code: "CJ" },
      { id: 4737, name: "Constan\u021Ba County", code: "CT" },
      { id: 4754, name: "Covasna County", code: "CV" },
      { id: 4745, name: "D\xE2mbovi\u021Ba County", code: "DB" },
      { id: 4742, name: "Dolj County", code: "DJ" },
      { id: 4747, name: "Gala\u021Bi County", code: "GL" },
      { id: 4726, name: "Giurgiu County", code: "GR" },
      { id: 4750, name: "Gorj County", code: "GJ" },
      { id: 4749, name: "Harghita County", code: "HR" },
      { id: 4721, name: "Hunedoara County", code: "HD" },
      { id: 4743, name: "Ialomi\u021Ba County", code: "IL" },
      { id: 4735, name: "Ia\u0219i County", code: "IS" },
      { id: 4725, name: "Ilfov County", code: "IF" },
      { id: 4760, name: "Maramure\u0219 County", code: "MM" },
      { id: 4751, name: "Mehedin\u021Bi County", code: "MH" },
      { id: 4915, name: "Mure\u0219 County", code: "MS" },
      { id: 4731, name: "Neam\u021B County", code: "NT" },
      { id: 4738, name: "Olt County", code: "OT" },
      { id: 4729, name: "Prahova County", code: "PH" },
      { id: 4741, name: "S\u0103laj County", code: "SJ" },
      { id: 4746, name: "Satu Mare County", code: "SM" },
      { id: 4755, name: "Sibiu County", code: "SB" },
      { id: 4720, name: "Suceava County", code: "SV" },
      { id: 4728, name: "Teleorman County", code: "TR" },
      { id: 4748, name: "Timi\u0219 County", code: "TM" },
      { id: 4727, name: "Tulcea County", code: "TL" },
      { id: 4757, name: "V\xE2lcea County", code: "VL" },
      { id: 4752, name: "Vaslui County", code: "VS" },
      { id: 4758, name: "Vrancea County", code: "VN" }
    ]
  },
  {
    code: "RUS",
    name: "Russia",
    states: [
      { id: 1911, name: "Altai Krai", code: "ALT" },
      { id: 1876, name: "Altai Republic", code: "AL" },
      { id: 1858, name: "Amur Oblast", code: "AMU" },
      { id: 1849, name: "Arkhangelsk", code: "ARK" },
      { id: 1866, name: "Astrakhan Oblast", code: "AST" },
      { id: 1903, name: "Belgorod Oblast", code: "BEL" },
      { id: 1867, name: "Bryansk Oblast", code: "BRY" },
      { id: 1893, name: "Chechen Republic", code: "CE" },
      { id: 1845, name: "Chelyabinsk Oblast", code: "CHE" },
      { id: 1859, name: "Chukotka Autonomous Okrug", code: "CHU" },
      { id: 1914, name: "Chuvash Republic", code: "CU" },
      { id: 1880, name: "Irkutsk", code: "IRK" },
      { id: 1864, name: "Ivanovo Oblast", code: "IVA" },
      { id: 1835, name: "Jewish Autonomous Oblast", code: "YEV" },
      { id: 1892, name: "Kabardino-Balkar Republic", code: "KB" },
      { id: 1902, name: "Kaliningrad", code: "KGD" },
      { id: 1844, name: "Kaluga Oblast", code: "KLU" },
      { id: 1865, name: "Kamchatka Krai", code: "KAM" },
      { id: 1869, name: "Karachay-Cherkess Republic", code: "KC" },
      { id: 1897, name: "Kemerovo Oblast", code: "KEM" },
      { id: 1873, name: "Khabarovsk Krai", code: "KHA" },
      { id: 1838, name: "Khanty-Mansi Autonomous Okrug", code: "KHM" },
      { id: 1890, name: "Kirov Oblast", code: "KIR" },
      { id: 1899, name: "Komi Republic", code: "KO" },
      { id: 1910, name: "Kostroma Oblast", code: "KOS" },
      { id: 1891, name: "Krasnodar Krai", code: "KDA" },
      { id: 1840, name: "Krasnoyarsk Krai", code: "KYA" },
      { id: 1915, name: "Kurgan Oblast", code: "KGN" },
      { id: 1855, name: "Kursk Oblast", code: "KRS" },
      { id: 1896, name: "Leningrad Oblast", code: "LEN" },
      { id: 1889, name: "Lipetsk Oblast", code: "LIP" },
      { id: 1839, name: "Magadan Oblast", code: "MAG" },
      { id: 1870, name: "Mari El Republic", code: "ME" },
      { id: 1901, name: "Moscow", code: "MOW" },
      { id: 1882, name: "Moscow Oblast", code: "MOS" },
      { id: 1843, name: "Murmansk Oblast", code: "MUR" },
      { id: 1836, name: "Nenets Autonomous Okrug", code: "NEN" },
      { id: 1857, name: "Nizhny Novgorod Oblast", code: "NIZ" },
      { id: 1834, name: "Novgorod Oblast", code: "NGR" },
      { id: 1888, name: "Novosibirsk", code: "NVS" },
      { id: 1846, name: "Omsk Oblast", code: "OMS" },
      { id: 1886, name: "Orenburg Oblast", code: "ORE" },
      { id: 1908, name: "Oryol Oblast", code: "ORL" },
      { id: 1909, name: "Penza Oblast", code: "PNZ" },
      { id: 1871, name: "Perm Krai", code: "PER" },
      { id: 1833, name: "Primorsky Krai", code: "PRI" },
      { id: 1863, name: "Pskov Oblast", code: "PSK" },
      { id: 1852, name: "Republic of Adygea", code: "AD" },
      { id: 1854, name: "Republic of Bashkortostan", code: "BA" },
      { id: 1842, name: "Republic of Buryatia", code: "BU" },
      { id: 1850, name: "Republic of Dagestan", code: "DA" },
      { id: 1884, name: "Republic of Ingushetia", code: "IN" },
      { id: 1883, name: "Republic of Kalmykia", code: "KL" },
      { id: 1841, name: "Republic of Karelia", code: "KR" },
      { id: 1877, name: "Republic of Khakassia", code: "KK" },
      { id: 1898, name: "Republic of Mordovia", code: "MO" },
      { id: 1853, name: "Republic of North Ossetia-Alania", code: "SE" },
      { id: 1861, name: "Republic of Tatarstan", code: "TA" },
      { id: 1837, name: "Rostov Oblast", code: "ROS" },
      { id: 1905, name: "Ryazan Oblast", code: "RYA" },
      { id: 1879, name: "Saint Petersburg", code: "SPE" },
      { id: 1848, name: "Sakha Republic", code: "SA" },
      { id: 1875, name: "Sakhalin", code: "SAK" },
      { id: 1862, name: "Samara Oblast", code: "SAM" },
      { id: 1887, name: "Saratov Oblast", code: "SAR" },
      { id: 1912, name: "Sevastopol", code: "UA-40" },
      { id: 1885, name: "Smolensk Oblast", code: "SMO" },
      { id: 1868, name: "Stavropol Krai", code: "STA" },
      { id: 1894, name: "Sverdlovsk", code: "SVE" },
      { id: 1878, name: "Tambov Oblast", code: "TAM" },
      { id: 1872, name: "Tomsk Oblast", code: "TOM" },
      { id: 1895, name: "Tula Oblast", code: "TUL" },
      { id: 1900, name: "Tuva Republic", code: "TY" },
      { id: 1860, name: "Tver Oblast", code: "TVE" },
      { id: 1907, name: "Tyumen Oblast", code: "TYU" },
      { id: 1913, name: "Udmurt Republic", code: "UD" },
      { id: 1856, name: "Ulyanovsk Oblast", code: "ULY" },
      { id: 1881, name: "Vladimir Oblast", code: "VLA" },
      { id: 4916, name: "Volgograd Oblast", code: "VGG" },
      { id: 1874, name: "Vologda Oblast", code: "VLG" },
      { id: 1906, name: "Voronezh Oblast", code: "VOR" },
      { id: 1847, name: "Yamalo-Nenets Autonomous Okrug", code: "YAN" },
      { id: 1851, name: "Yaroslavl Oblast", code: "YAR" },
      { id: 1904, name: "Zabaykalsky Krai", code: "ZAB" }
    ]
  },
  {
    code: "RWA",
    name: "Rwanda",
    states: [
      { id: 261, name: "Eastern Province", code: "02" },
      { id: 262, name: "Kigali district", code: "01" },
      { id: 263, name: "Northern Province", code: "03" },
      { id: 259, name: "Southern Province", code: "05" },
      { id: 260, name: "Western Province", code: "04" }
    ]
  },
  { code: "SHN", name: "Saint Helena", states: [] },
  {
    code: "KNA",
    name: "Saint Kitts And Nevis",
    states: [
      { id: 3833, name: "Christ Church Nichola Town Parish", code: "01" },
      { id: 3832, name: "Nevis", code: "N" },
      { id: 3836, name: "Saint Anne Sandy Point Parish", code: "02" },
      { id: 3837, name: "Saint George Gingerland Parish", code: "04" },
      { id: 3835, name: "Saint James Windward Parish", code: "05" },
      { id: 3845, name: "Saint John Capisterre Parish", code: "06" },
      { id: 3840, name: "Saint John Figtree Parish", code: "07" },
      { id: 3841, name: "Saint Kitts", code: "K" },
      { id: 3844, name: "Saint Mary Cayon Parish", code: "08" },
      { id: 3834, name: "Saint Paul Capisterre Parish", code: "09" },
      { id: 3838, name: "Saint Paul Charlestown Parish", code: "10" },
      { id: 3831, name: "Saint Peter Basseterre Parish", code: "11" },
      { id: 3839, name: "Saint Thomas Lowland Parish", code: "12" },
      { id: 3842, name: "Saint Thomas Middle Island Parish", code: "13" },
      { id: 3843, name: "Trinity Palmetto Point Parish", code: "15" }
    ]
  },
  {
    code: "LCA",
    name: "Saint Lucia",
    states: [
      { id: 3757, name: "Anse la Raye Quarter", code: "01" },
      { id: 3761, name: "Canaries", code: "12" },
      { id: 3758, name: "Castries Quarter", code: "02" },
      { id: 3760, name: "Choiseul Quarter", code: "03" },
      { id: 3767, name: "Dauphin Quarter", code: "04" },
      { id: 3756, name: "Dennery Quarter", code: "05" },
      { id: 3766, name: "Gros Islet Quarter", code: "06" },
      { id: 3759, name: "Laborie Quarter", code: "07" },
      { id: 3762, name: "Micoud Quarter", code: "08" },
      { id: 3765, name: "Praslin Quarter", code: "09" },
      { id: 3764, name: "Soufri\xE8re Quarter", code: "10" },
      { id: 3763, name: "Vieux Fort Quarter", code: "11" }
    ]
  },
  { code: "SPM", name: "Saint Pierre and Miquelon", states: [] },
  {
    code: "VCT",
    name: "Saint Vincent And The Grenadines",
    states: [
      { id: 3389, name: "Charlotte Parish", code: "01" },
      { id: 3388, name: "Grenadines Parish", code: "06" },
      { id: 3386, name: "Saint Andrew Parish", code: "02" },
      { id: 3387, name: "Saint David Parish", code: "03" },
      { id: 3384, name: "Saint George Parish", code: "04" },
      { id: 3385, name: "Saint Patrick Parish", code: "05" }
    ]
  },
  { code: "BLM", name: "Saint-Barthelemy", states: [] },
  { code: "MAF", name: "Saint-Martin (French part)", states: [] },
  {
    code: "WSM",
    name: "Samoa",
    states: [
      { id: 4763, name: "A'ana", code: "AA" },
      { id: 4761, name: "Aiga-i-le-Tai", code: "AL" },
      { id: 4765, name: "Atua", code: "AT" },
      { id: 4764, name: "Fa'asaleleaga", code: "FA" },
      { id: 4769, name: "Gaga'emauga", code: "GE" },
      { id: 4771, name: "Gaga'ifomauga", code: "GI" },
      { id: 4767, name: "Palauli", code: "PA" },
      { id: 4762, name: "Satupa'itea", code: "SA" },
      { id: 4770, name: "Tuamasaga", code: "TU" },
      { id: 4768, name: "Va'a-o-Fonoti", code: "VF" },
      { id: 4766, name: "Vaisigano", code: "VS" }
    ]
  },
  {
    code: "SMR",
    name: "San Marino",
    states: [
      { id: 59, name: "Acquaviva", code: "01" },
      { id: 61, name: "Borgo Maggiore", code: "06" },
      { id: 60, name: "Chiesanuova", code: "02" },
      { id: 64, name: "Domagnano", code: "03" },
      { id: 62, name: "Faetano", code: "04" },
      { id: 66, name: "Fiorentino", code: "05" },
      { id: 63, name: "Montegiardino", code: "08" },
      { id: 58, name: "San Marino", code: "07" },
      { id: 65, name: "Serravalle", code: "09" }
    ]
  },
  {
    code: "STP",
    name: "Sao Tome and Principe",
    states: [
      { id: 270, name: "Pr\xEDncipe Province", code: "P" },
      { id: 271, name: "S\xE3o Tom\xE9 Province", code: "S" }
    ]
  },
  {
    code: "SAU",
    name: "Saudi Arabia",
    states: [
      { id: 2853, name: "'Asir", code: "14" },
      { id: 2859, name: "Al Bahah", code: "11" },
      { id: 2857, name: "Al Jawf", code: "12" },
      { id: 2851, name: "Al Madinah", code: "03" },
      { id: 2861, name: "Al-Qassim", code: "05" },
      { id: 2856, name: "Eastern Province", code: "04" },
      { id: 2855, name: "Ha'il", code: "06" },
      { id: 2858, name: "Jizan", code: "09" },
      { id: 2850, name: "Makkah", code: "02" },
      { id: 2860, name: "Najran", code: "10" },
      { id: 2854, name: "Northern Borders", code: "08" },
      { id: 2849, name: "Riyadh", code: "01" },
      { id: 2852, name: "Tabuk", code: "07" }
    ]
  },
  {
    code: "SEN",
    name: "Senegal",
    states: [
      { id: 473, name: "Dakar", code: "DK" },
      { id: 480, name: "Diourbel Region", code: "DB" },
      { id: 479, name: "Fatick", code: "FK" },
      { id: 475, name: "Kaffrine", code: "KA" },
      { id: 483, name: "Kaolack", code: "KL" },
      { id: 481, name: "K\xE9dougou", code: "KE" },
      { id: 474, name: "Kolda", code: "KD" },
      { id: 485, name: "Louga", code: "LG" },
      { id: 476, name: "Matam", code: "MT" },
      { id: 477, name: "Saint-Louis", code: "SL" },
      { id: 482, name: "S\xE9dhiou", code: "SE" },
      { id: 486, name: "Tambacounda Region", code: "TC" },
      { id: 484, name: "Thi\xE8s Region", code: "TH" },
      { id: 478, name: "Ziguinchor", code: "ZG" }
    ]
  },
  {
    code: "SRB",
    name: "Serbia",
    states: [
      { id: 3728, name: "Belgrade", code: "00" },
      { id: 3717, name: "Bor District", code: "14" },
      { id: 3732, name: "Brani\u010Devo District", code: "11" },
      { id: 3716, name: "Central Banat District", code: "02" },
      { id: 3715, name: "Jablanica District", code: "23" },
      { id: 3724, name: "Kolubara District", code: "09" },
      { id: 3719, name: "Ma\u010Dva District", code: "08" },
      { id: 3727, name: "Moravica District", code: "17" },
      { id: 3722, name: "Ni\u0161ava District", code: "20" },
      { id: 3714, name: "North Ba\u010Dka District", code: "01" },
      { id: 3736, name: "North Banat District", code: "03" },
      { id: 3721, name: "P\u010Dinja District", code: "24" },
      { id: 3712, name: "Pirot District", code: "22" },
      { id: 3741, name: "Podunavlje District", code: "10" },
      { id: 3737, name: "Pomoravlje District", code: "13" },
      { id: 3720, name: "Rasina District", code: "19" },
      { id: 3725, name: "Ra\u0161ka District", code: "18" },
      { id: 3711, name: "South Ba\u010Dka District", code: "06" },
      { id: 3713, name: "South Banat District", code: "04" },
      { id: 3740, name: "Srem District", code: "07" },
      { id: 3734, name: "\u0160umadija District", code: "12" },
      { id: 3718, name: "Toplica District", code: "21" },
      { id: 3733, name: "Vojvodina", code: "VO" },
      { id: 3726, name: "West Ba\u010Dka District", code: "05" },
      { id: 3731, name: "Zaje\u010Dar District", code: "15" },
      { id: 3729, name: "Zlatibor District", code: "16" }
    ]
  },
  {
    code: "SYC",
    name: "Seychelles",
    states: [
      { id: 513, name: "Anse Boileau", code: "02" },
      { id: 502, name: "Anse Royale", code: "05" },
      { id: 506, name: "Anse-aux-Pins", code: "01" },
      { id: 508, name: "Au Cap", code: "04" },
      { id: 497, name: "Baie Lazare", code: "06" },
      { id: 514, name: "Baie Sainte Anne", code: "07" },
      { id: 512, name: "Beau Vallon", code: "08" },
      { id: 515, name: "Bel Air", code: "09" },
      { id: 505, name: "Bel Ombre", code: "10" },
      { id: 517, name: "Cascade", code: "11" },
      { id: 503, name: "Glacis", code: "12" },
      { id: 500, name: "Grand'Anse Mah\xE9", code: "13" },
      { id: 504, name: "Grand'Anse Praslin", code: "14" },
      { id: 495, name: "La Digue", code: "15" },
      { id: 516, name: "La Rivi\xE8re Anglaise", code: "16" },
      { id: 499, name: "Les Mamelles", code: "24" },
      { id: 494, name: "Mont Buxton", code: "17" },
      { id: 498, name: "Mont Fleuri", code: "18" },
      { id: 511, name: "Plaisance", code: "19" },
      { id: 510, name: "Pointe La Rue", code: "20" },
      { id: 507, name: "Port Glaud", code: "21" },
      { id: 501, name: "Roche Caiman", code: "25" },
      { id: 496, name: "Saint Louis", code: "22" },
      { id: 509, name: "Takamaka", code: "23" }
    ]
  },
  {
    code: "SLE",
    name: "Sierra Leone",
    states: [
      { id: 914, name: "Eastern Province", code: "E" },
      { id: 911, name: "Northern Province", code: "N" },
      { id: 912, name: "Southern Province", code: "S" },
      { id: 913, name: "Western Area", code: "W" }
    ]
  },
  {
    code: "SGP",
    name: "Singapore",
    states: [
      { id: 4651, name: "Central Singapore", code: "01" },
      { id: 4649, name: "North East", code: "02" },
      { id: 4653, name: "North West", code: "03" },
      { id: 4650, name: "South East", code: "04" },
      { id: 4652, name: "South West", code: "05" }
    ]
  },
  { code: "SXM", name: "Sint Maarten (Dutch part)", states: [] },
  {
    code: "SVK",
    name: "Slovakia",
    states: [
      { id: 4352, name: "Bansk\xE1 Bystrica Region", code: "BC" },
      { id: 4356, name: "Bratislava Region", code: "BL" },
      { id: 4353, name: "Ko\u0161ice Region", code: "KI" },
      { id: 4357, name: "Nitra Region", code: "NI" },
      { id: 4354, name: "Pre\u0161ov Region", code: "PV" },
      { id: 4358, name: "Tren\u010D\xEDn Region", code: "TC" },
      { id: 4355, name: "Trnava Region", code: "TA" },
      { id: 4359, name: "\u017Dilina Region", code: "ZI" }
    ]
  },
  {
    code: "SVN",
    name: "Slovenia",
    states: [
      { id: 4183, name: "Ajdov\u0161\u010Dina Municipality", code: "001" },
      { id: 4326, name: "Ankaran Municipality", code: "213" },
      { id: 4301, name: "Beltinci Municipality", code: "002" },
      { id: 4166, name: "Benedikt Municipality", code: "148" },
      { id: 4179, name: "Bistrica ob Sotli Municipality", code: "149" },
      { id: 4202, name: "Bled Municipality", code: "003" },
      { id: 4278, name: "Bloke Municipality", code: "150" },
      { id: 4282, name: "Bohinj Municipality", code: "004" },
      { id: 4200, name: "Borovnica Municipality", code: "005" },
      { id: 4181, name: "Bovec Municipality", code: "006" },
      { id: 4141, name: "Braslov\u010De Municipality", code: "151" },
      { id: 4240, name: "Brda Municipality", code: "007" },
      { id: 4215, name: "Bre\u017Eice Municipality", code: "009" },
      { id: 4165, name: "Brezovica Municipality", code: "008" },
      { id: 4147, name: "Cankova Municipality", code: "152" },
      {
        id: 4310,
        name: "Cerklje na Gorenjskem Municipality",
        code: "012"
      },
      { id: 4162, name: "Cerknica Municipality", code: "013" },
      { id: 4178, name: "Cerkno Municipality", code: "014" },
      { id: 4176, name: "Cerkvenjak Municipality", code: "153" },
      { id: 4191, name: "City Municipality of Celje", code: "011" },
      { id: 4236, name: "City Municipality of Novo Mesto", code: "085" },
      { id: 4151, name: "\u010Cren\u0161ovci Municipality", code: "015" },
      { id: 4232, name: "\u010Crna na Koro\u0161kem Municipality", code: "016" },
      { id: 4291, name: "\u010Crnomelj Municipality", code: "017" },
      { id: 4304, name: "Destrnik Municipality", code: "018" },
      { id: 4167, name: "Diva\u010Da Municipality", code: "019" },
      { id: 4295, name: "Dobje Municipality", code: "154" },
      { id: 4216, name: "Dobrepolje Municipality", code: "020" },
      { id: 4252, name: "Dobrna Municipality", code: "155" },
      {
        id: 4308,
        name: "Dobrova\u2013Polhov Gradec Municipality",
        code: "021"
      },
      { id: 4189, name: "Dobrovnik Municipality", code: "156" },
      { id: 4173, name: "Dol pri Ljubljani Municipality", code: "022" },
      { id: 4281, name: "Dolenjske Toplice Municipality", code: "157" },
      { id: 4159, name: "Dom\u017Eale Municipality", code: "023" },
      { id: 4290, name: "Dornava Municipality", code: "024" },
      { id: 4345, name: "Dravograd Municipality", code: "025" },
      { id: 4213, name: "Duplek Municipality", code: "026" },
      { id: 4293, name: "Gorenja Vas\u2013Poljane Municipality", code: "027" },
      { id: 4210, name: "Gori\u0161nica Municipality", code: "028" },
      { id: 4284, name: "Gorje Municipality", code: "207" },
      { id: 4343, name: "Gornja Radgona Municipality", code: "029" },
      { id: 4339, name: "Gornji Grad Municipality", code: "030" },
      { id: 4271, name: "Gornji Petrovci Municipality", code: "031" },
      { id: 4217, name: "Grad Municipality", code: "158" },
      { id: 4336, name: "Grosuplje Municipality", code: "032" },
      { id: 4145, name: "Hajdina Municipality", code: "159" },
      { id: 4175, name: "Ho\u010De\u2013Slivnica Municipality", code: "160" },
      { id: 4327, name: "Hodo\u0161 Municipality", code: "161" },
      { id: 4193, name: "Horjul Municipality", code: "162" },
      { id: 4341, name: "Hrastnik Municipality", code: "034" },
      { id: 4321, name: "Hrpelje\u2013Kozina Municipality", code: "035" },
      { id: 4152, name: "Idrija Municipality", code: "036" },
      { id: 4286, name: "Ig Municipality", code: "037" },
      { id: 4305, name: "Ivan\u010Dna Gorica Municipality", code: "039" },
      { id: 4322, name: "Izola Municipality", code: "040" },
      { id: 4337, name: "Jesenice Municipality", code: "041" },
      { id: 4203, name: "Jezersko Municipality", code: "163" },
      { id: 4266, name: "Jur\u0161inci Municipality", code: "042" },
      { id: 4180, name: "Kamnik Municipality", code: "043" },
      { id: 4227, name: "Kanal ob So\u010Di Municipality", code: "044" },
      { id: 4150, name: "Kidri\u010Devo Municipality", code: "045" },
      { id: 4243, name: "Kobarid Municipality", code: "046" },
      { id: 4325, name: "Kobilje Municipality", code: "047" },
      { id: 4335, name: "Ko\u010Devje Municipality", code: "048" },
      { id: 4315, name: "Komen Municipality", code: "049" },
      { id: 4283, name: "Komenda Municipality", code: "164" },
      { id: 4319, name: "Koper City Municipality", code: "050" },
      {
        id: 4254,
        name: "Kostanjevica na Krki Municipality",
        code: "197"
      },
      { id: 4331, name: "Kostel Municipality", code: "165" },
      { id: 4186, name: "Kozje Municipality", code: "051" },
      { id: 4287, name: "Kranj City Municipality", code: "052" },
      { id: 4340, name: "Kranjska Gora Municipality", code: "053" },
      { id: 4238, name: "Kri\u017Eevci Municipality", code: "166" },
      { id: 4197, name: "Kungota", code: "055" },
      { id: 4211, name: "Kuzma Municipality", code: "056" },
      { id: 4338, name: "La\u0161ko Municipality", code: "057" },
      { id: 4142, name: "Lenart Municipality", code: "058" },
      { id: 4225, name: "Lendava Municipality", code: "059" },
      { id: 4347, name: "Litija Municipality", code: "060" },
      { id: 4270, name: "Ljubljana City Municipality", code: "061" },
      { id: 4294, name: "Ljubno Municipality", code: "062" },
      { id: 4351, name: "Ljutomer Municipality", code: "063" },
      { id: 4306, name: "Log\u2013Dragomer Municipality", code: "208" },
      { id: 4350, name: "Logatec Municipality", code: "064" },
      { id: 4174, name: "Lo\u0161ka Dolina Municipality", code: "065" },
      { id: 4158, name: "Lo\u0161ki Potok Municipality", code: "066" },
      { id: 4156, name: "Lovrenc na Pohorju Municipality", code: "167" },
      { id: 4219, name: "Lu\u010De Municipality", code: "067" },
      { id: 4302, name: "Lukovica Municipality", code: "068" },
      { id: 4157, name: "Maj\u0161perk Municipality", code: "069" },
      { id: 4224, name: "Makole Municipality", code: "198" },
      { id: 4242, name: "Maribor City Municipality", code: "070" },
      { id: 4244, name: "Markovci Municipality", code: "168" },
      { id: 4349, name: "Medvode Municipality", code: "071" },
      { id: 4348, name: "Menge\u0161 Municipality", code: "072" },
      { id: 4323, name: "Metlika Municipality", code: "073" },
      { id: 4265, name: "Me\u017Eica Municipality", code: "074" },
      {
        id: 4223,
        name: "Miklav\u017E na Dravskem Polju Municipality",
        code: "169"
      },
      { id: 4220, name: "Miren\u2013Kostanjevica Municipality", code: "075" },
      { id: 4298, name: "Mirna Municipality", code: "212" },
      { id: 4237, name: "Mirna Pe\u010D Municipality", code: "170" },
      { id: 4212, name: "Mislinja Municipality", code: "076" },
      { id: 4297, name: "Mokronog\u2013Trebelno Municipality", code: "199" },
      { id: 4168, name: "Morav\u010De Municipality", code: "077" },
      { id: 4218, name: "Moravske Toplice Municipality", code: "078" },
      { id: 4190, name: "Mozirje Municipality", code: "079" },
      { id: 4318, name: "Municipality of Apa\u010De", code: "195" },
      { id: 4309, name: "Municipality of Cirkulane", code: "196" },
      { id: 4344, name: "Municipality of Ilirska Bistrica", code: "038" },
      { id: 4314, name: "Municipality of Kr\u0161ko", code: "054" },
      { id: 4187, name: "Municipality of \u0160kofljica", code: "123" },
      { id: 4313, name: "Murska Sobota City Municipality", code: "080" },
      { id: 4208, name: "Muta Municipality", code: "081" },
      { id: 4177, name: "Naklo Municipality", code: "082" },
      { id: 4329, name: "Nazarje Municipality", code: "083" },
      { id: 4205, name: "Nova Gorica City Municipality", code: "084" },
      { id: 4320, name: "Odranci Municipality", code: "086" },
      { id: 4143, name: "Oplotnica", code: "171" },
      { id: 4221, name: "Ormo\u017E Municipality", code: "087" },
      { id: 4199, name: "Osilnica Municipality", code: "088" },
      { id: 4172, name: "Pesnica Municipality", code: "089" },
      { id: 4201, name: "Piran Municipality", code: "090" },
      { id: 4184, name: "Pivka Municipality", code: "091" },
      { id: 4146, name: "Pod\u010Detrtek Municipality", code: "092" },
      { id: 4161, name: "Podlehnik Municipality", code: "172" },
      { id: 4234, name: "Podvelka Municipality", code: "093" },
      { id: 4239, name: "Polj\u010Dane Municipality", code: "200" },
      { id: 4272, name: "Polzela Municipality", code: "173" },
      { id: 4330, name: "Postojna Municipality", code: "094" },
      { id: 4188, name: "Prebold Municipality", code: "174" },
      { id: 4303, name: "Preddvor Municipality", code: "095" },
      { id: 4274, name: "Prevalje Municipality", code: "175" },
      { id: 4228, name: "Ptuj City Municipality", code: "096" },
      { id: 4288, name: "Puconci Municipality", code: "097" },
      { id: 4204, name: "Ra\u010De\u2013Fram Municipality", code: "098" },
      { id: 4195, name: "Rade\u010De Municipality", code: "099" },
      { id: 4292, name: "Radenci Municipality", code: "100" },
      { id: 4275, name: "Radlje ob Dravi Municipality", code: "101" },
      { id: 4231, name: "Radovljica Municipality", code: "102" },
      { id: 4155, name: "Ravne na Koro\u0161kem Municipality", code: "103" },
      { id: 4206, name: "Razkri\u017Eje Municipality", code: "176" },
      { id: 4160, name: "Re\u010Dica ob Savinji Municipality", code: "209" },
      { id: 4253, name: "Ren\u010De\u2013Vogrsko Municipality", code: "201" },
      { id: 4235, name: "Ribnica Municipality", code: "104" },
      { id: 4207, name: "Ribnica na Pohorju Municipality", code: "177" },
      { id: 4233, name: "Roga\u0161ka Slatina Municipality", code: "106" },
      { id: 4264, name: "Roga\u0161ovci Municipality", code: "105" },
      { id: 4209, name: "Rogatec Municipality", code: "107" },
      { id: 4280, name: "Ru\u0161e Municipality", code: "108" },
      { id: 4222, name: "\u0160alovci Municipality", code: "033" },
      { id: 4230, name: "Selnica ob Dravi Municipality", code: "178" },
      { id: 4346, name: "Semi\u010D Municipality", code: "109" },
      { id: 4317, name: "\u0160empeter\u2013Vrtojba Municipality", code: "183" },
      { id: 4299, name: "\u0160en\u010Dur Municipality", code: "117" },
      { id: 4324, name: "\u0160entilj Municipality", code: "118" },
      { id: 4241, name: "\u0160entjernej Municipality", code: "119" },
      { id: 4171, name: "\u0160entjur Municipality", code: "120" },
      { id: 4311, name: "\u0160entrupert Municipality", code: "211" },
      { id: 4268, name: "Sevnica Municipality", code: "110" },
      { id: 4149, name: "Se\u017Eana Municipality", code: "111" },
      { id: 4170, name: "\u0160kocjan Municipality", code: "121" },
      { id: 4316, name: "\u0160kofja Loka Municipality", code: "122" },
      { id: 4169, name: "Slovenj Gradec City Municipality", code: "112" },
      { id: 4332, name: "Slovenska Bistrica Municipality", code: "113" },
      { id: 4198, name: "Slovenske Konjice Municipality", code: "114" },
      { id: 4285, name: "\u0160marje pri Jel\u0161ah Municipality", code: "124" },
      { id: 4289, name: "\u0160marje\u0161ke Toplice Municipality", code: "206" },
      { id: 4296, name: "\u0160martno ob Paki Municipality", code: "125" },
      { id: 4279, name: "\u0160martno pri Litiji Municipality", code: "194" },
      { id: 4277, name: "Sodra\u017Eica Municipality", code: "179" },
      { id: 4261, name: "Sol\u010Dava Municipality", code: "180" },
      { id: 4248, name: "\u0160o\u0161tanj Municipality", code: "126" },
      { id: 4263, name: "Sredi\u0161\u010De ob Dravi", code: "202" },
      { id: 4259, name: "Star\u0161e Municipality", code: "115" },
      { id: 4185, name: "\u0160tore Municipality", code: "127" },
      { id: 4333, name: "Stra\u017Ea Municipality", code: "203" },
      { id: 4164, name: "Sveta Ana Municipality", code: "181" },
      {
        id: 4260,
        name: "Sveta Trojica v Slovenskih Goricah Municipality",
        code: "204"
      },
      {
        id: 4229,
        name: "Sveti Andra\u017E v Slovenskih Goricah Municipality",
        code: "182"
      },
      {
        id: 4255,
        name: "Sveti Jurij ob \u0160\u010Davnici Municipality",
        code: "116"
      },
      {
        id: 4328,
        name: "Sveti Jurij v Slovenskih Goricah Municipality",
        code: "210"
      },
      { id: 4273, name: "Sveti Toma\u017E Municipality", code: "205" },
      { id: 4194, name: "Tabor Municipality", code: "184" },
      { id: 4312, name: "Ti\u0161ina Municipality", code: "010" },
      { id: 4247, name: "Tolmin Municipality", code: "128" },
      { id: 4246, name: "Trbovlje Municipality", code: "129" },
      { id: 4214, name: "Trebnje Municipality", code: "130" },
      { id: 4153, name: "Trnovska Vas Municipality", code: "185" },
      { id: 4250, name: "Tr\u017Ei\u010D Municipality", code: "131" },
      { id: 4334, name: "Trzin Municipality", code: "186" },
      { id: 4251, name: "Turni\u0161\u010De Municipality", code: "132" },
      { id: 4267, name: "Velika Polana Municipality", code: "187" },
      { id: 4144, name: "Velike La\u0161\u010De Municipality", code: "134" },
      { id: 4257, name: "Ver\u017Eej Municipality", code: "188" },
      { id: 4300, name: "Videm Municipality", code: "135" },
      { id: 4196, name: "Vipava Municipality", code: "136" },
      { id: 4148, name: "Vitanje Municipality", code: "137" },
      { id: 4154, name: "Vodice Municipality", code: "138" },
      { id: 4245, name: "Vojnik Municipality", code: "139" },
      { id: 4163, name: "Vransko Municipality", code: "189" },
      { id: 4262, name: "Vrhnika Municipality", code: "140" },
      { id: 4226, name: "Vuzenica Municipality", code: "141" },
      { id: 4269, name: "Zagorje ob Savi Municipality", code: "142" },
      { id: 4258, name: "\u017Dalec Municipality", code: "190" },
      { id: 4182, name: "Zavr\u010D Municipality", code: "143" },
      { id: 4256, name: "\u017Delezniki Municipality", code: "146" },
      { id: 4249, name: "\u017Detale Municipality", code: "191" },
      { id: 4192, name: "\u017Diri Municipality", code: "147" },
      { id: 4276, name: "\u017Dirovnica Municipality", code: "192" },
      { id: 4342, name: "Zre\u010De Municipality", code: "144" },
      { id: 4307, name: "\u017Du\u017Eemberk Municipality", code: "193" }
    ]
  },
  {
    code: "SLB",
    name: "Solomon Islands",
    states: [
      { id: 4784, name: "Central Province", code: "CE" },
      { id: 4781, name: "Choiseul Province", code: "CH" },
      { id: 4785, name: "Guadalcanal Province", code: "GU" },
      { id: 4778, name: "Honiara", code: "CT" },
      { id: 4780, name: "Isabel Province", code: "IS" },
      { id: 4782, name: "Makira-Ulawa Province", code: "MK" },
      { id: 4783, name: "Malaita Province", code: "ML" },
      { id: 4787, name: "Rennell and Bellona Province", code: "RB" },
      { id: 4779, name: "Temotu Province", code: "TE" },
      { id: 4786, name: "Western Province", code: "WE" }
    ]
  },
  {
    code: "SOM",
    name: "Somalia",
    states: [
      { id: 925, name: "Awdal Region", code: "AW" },
      { id: 917, name: "Bakool", code: "BK" },
      { id: 927, name: "Banaadir", code: "BN" },
      { id: 930, name: "Bari", code: "BR" },
      { id: 926, name: "Bay", code: "BY" },
      { id: 918, name: "Galguduud", code: "GA" },
      { id: 928, name: "Gedo", code: "GE" },
      { id: 915, name: "Hiran", code: "HI" },
      { id: 924, name: "Lower Juba", code: "JH" },
      { id: 921, name: "Lower Shebelle", code: "SH" },
      { id: 922, name: "Middle Juba", code: "JD" },
      { id: 923, name: "Middle Shebelle", code: "SD" },
      { id: 916, name: "Mudug", code: "MU" },
      { id: 920, name: "Nugal", code: "NU" },
      { id: 919, name: "Sanaag Region", code: "SA" },
      { id: 929, name: "Togdheer Region", code: "TO" }
    ]
  },
  {
    code: "ZAF",
    name: "South Africa",
    states: [
      { id: 938, name: "Eastern Cape", code: "EC" },
      { id: 932, name: "Free State", code: "FS" },
      { id: 936, name: "Gauteng", code: "GP" },
      { id: 935, name: "KwaZulu-Natal", code: "KZN" },
      { id: 933, name: "Limpopo", code: "LP" },
      { id: 937, name: "Mpumalanga", code: "MP" },
      { id: 934, name: "North West", code: "NW" },
      { id: 931, name: "Northern Cape", code: "NC" },
      { id: 939, name: "Western Cape", code: "WC" }
    ]
  },
  { code: "SGS", name: "South Georgia", states: [] },
  {
    code: "KOR",
    name: "South Korea",
    states: [
      { id: 3860, name: "Busan", code: "26" },
      { id: 3846, name: "Daegu", code: "27" },
      { id: 3850, name: "Daejeon", code: "30" },
      { id: 3862, name: "Gangwon Province", code: "42" },
      { id: 3858, name: "Gwangju", code: "29" },
      { id: 3847, name: "Gyeonggi Province", code: "41" },
      { id: 3848, name: "Incheon", code: "28" },
      { id: 3853, name: "Jeju", code: "49" },
      { id: 3854, name: "North Chungcheong Province", code: "43" },
      { id: 3855, name: "North Gyeongsang Province", code: "47" },
      { id: 3851, name: "North Jeolla Province", code: "45" },
      { id: 3861, name: "Sejong City", code: "50" },
      { id: 3849, name: "Seoul", code: "11" },
      { id: 3859, name: "South Chungcheong Province", code: "44" },
      { id: 3857, name: "South Gyeongsang Province", code: "48" },
      { id: 3856, name: "South Jeolla Province", code: "46" },
      { id: 3852, name: "Ulsan", code: "31" }
    ]
  },
  {
    code: "SSD",
    name: "South Sudan",
    states: [
      { id: 2092, name: "Central Equatoria", code: "EC" },
      { id: 2093, name: "Eastern Equatoria", code: "EE" },
      { id: 2094, name: "Jonglei State", code: "JG" },
      { id: 2090, name: "Lakes", code: "LK" },
      { id: 2088, name: "Northern Bahr el Ghazal", code: "BN" },
      { id: 2085, name: "Unity", code: "UY" },
      { id: 2086, name: "Upper Nile", code: "NU" },
      { id: 2087, name: "Warrap", code: "WR" },
      { id: 2091, name: "Western Bahr el Ghazal", code: "BW" },
      { id: 2089, name: "Western Equatoria", code: "EW" }
    ]
  },
  {
    code: "ESP",
    name: "Spain",
    states: [
      { id: 5089, name: "A Coru\xF1a", code: "C" },
      { id: 5109, name: "Albacete", code: "AB" },
      { id: 5108, name: "Alicante", code: "A" },
      { id: 5095, name: "Almeria", code: "AL" },
      { id: 5093, name: "Araba", code: "VI" },
      { id: 1160, name: "Asturias", code: "O" },
      { id: 1189, name: "\xC1vila", code: "AV" },
      { id: 5092, name: "Badajoz", code: "BA" },
      { id: 5102, name: "Barcelona", code: "B" },
      { id: 5094, name: "Bizkaia", code: "BI" },
      { id: 1146, name: "Burgos", code: "BU" },
      { id: 1190, name: "Caceres", code: "CC" },
      { id: 5096, name: "C\xE1diz", code: "CA" },
      { id: 1170, name: "Cantabria", code: "S" },
      { id: 5110, name: "Castell\xF3n", code: "CS" },
      { id: 5105, name: "Ciudad Real", code: "CR" },
      { id: 5097, name: "C\xF3rdoba", code: "CO" },
      { id: 5106, name: "Cuenca", code: "CU" },
      { id: 1191, name: "Gipuzkoa", code: "SS" },
      { id: 5103, name: "Girona", code: "GI" },
      { id: 5098, name: "Granada", code: "GR" },
      { id: 5107, name: "Guadalajara", code: "GU" },
      { id: 5099, name: "Huelva", code: "H" },
      { id: 1177, name: "Huesca", code: "HU" },
      { id: 1174, name: "Islas Baleares", code: "PM" },
      { id: 5100, name: "Ja\xE9n", code: "J" },
      { id: 1171, name: "La Rioja", code: "LO" },
      { id: 1185, name: "Las Palmas", code: "GC" },
      { id: 1200, name: "L\xE9on", code: "LE" },
      { id: 5104, name: "Lleida", code: "L" },
      { id: 5090, name: "Lugo", code: "LU" },
      { id: 1158, name: "Madrid", code: "M" },
      { id: 5101, name: "M\xE1laga", code: "MA" },
      { id: 1176, name: "Murcia", code: "MU" },
      { id: 1204, name: "Navarra", code: "NA" },
      { id: 5091, name: "Ourense", code: "OR" },
      { id: 1157, name: "Palencia", code: "P" },
      { id: 1167, name: "Pontevedra", code: "PO" },
      { id: 1147, name: "Salamanca", code: "SA" },
      { id: 5112, name: "Santa Cruz de Tenerife", code: "TF" },
      { id: 1192, name: "Segovia", code: "SG" },
      { id: 1193, name: "Sevilla", code: "SE" },
      { id: 1208, name: "Soria", code: "SO" },
      { id: 1203, name: "Tarragona", code: "T" },
      { id: 5111, name: "Teruel", code: "TE" },
      { id: 1205, name: "Toledo", code: "TO" },
      { id: 1175, name: "Valencia", code: "V" },
      { id: 1183, name: "Valladolid", code: "VA" },
      { id: 1161, name: "Zamora", code: "ZA" },
      { id: 5113, name: "Zaragoza", code: "Z" }
    ]
  },
  {
    code: "LKA",
    name: "Sri Lanka",
    states: [
      { id: 2799, name: "Ampara District", code: "52" },
      { id: 2816, name: "Anuradhapura District", code: "71" },
      { id: 2790, name: "Badulla District", code: "81" },
      { id: 2818, name: "Batticaloa District", code: "51" },
      { id: 2798, name: "Central Province", code: "2" },
      { id: 2815, name: "Colombo District", code: "11" },
      { id: 2808, name: "Eastern Province", code: "5" },
      { id: 2792, name: "Galle District", code: "31" },
      { id: 2804, name: "Gampaha District", code: "12" },
      { id: 2791, name: "Hambantota District", code: "33" },
      { id: 2787, name: "Jaffna District", code: "41" },
      { id: 2789, name: "Kalutara District", code: "13" },
      { id: 2788, name: "Kandy District", code: "21" },
      { id: 2797, name: "Kegalle District", code: "92" },
      { id: 2793, name: "Kilinochchi District", code: "42" },
      { id: 2805, name: "Mannar District", code: "43" },
      { id: 2810, name: "Matale District", code: "22" },
      { id: 2806, name: "Matara District", code: "32" },
      { id: 2819, name: "Monaragala District", code: "82" },
      { id: 2814, name: "Mullaitivu District", code: "45" },
      { id: 2800, name: "North Central Province", code: "7" },
      { id: 2817, name: "North Western Province", code: "6" },
      { id: 2813, name: "Northern Province", code: "4" },
      { id: 2794, name: "Nuwara Eliya District", code: "23" },
      { id: 2812, name: "Polonnaruwa District", code: "72" },
      { id: 2796, name: "Puttalam District", code: "62" },
      { id: 2807, name: "Ratnapura district", code: "91" },
      { id: 2803, name: "Sabaragamuwa Province", code: "9" },
      { id: 2801, name: "Southern Province", code: "3" },
      { id: 2795, name: "Trincomalee District", code: "53" },
      { id: 2811, name: "Uva Province", code: "8" },
      { id: 2809, name: "Vavuniya District", code: "44" },
      { id: 2802, name: "Western Province", code: "1" }
    ]
  },
  {
    code: "SDN",
    name: "Sudan",
    states: [
      { id: 885, name: "Al Jazirah", code: "GZ" },
      { id: 886, name: "Al Qadarif", code: "GD" },
      { id: 887, name: "Blue Nile", code: "NB" },
      { id: 896, name: "Central Darfur", code: "DC" },
      { id: 892, name: "East Darfur", code: "DE" },
      { id: 884, name: "Kassala", code: "KA" },
      { id: 881, name: "Khartoum", code: "KH" },
      { id: 890, name: "North Darfur", code: "DN" },
      { id: 893, name: "North Kordofan", code: "KN" },
      { id: 895, name: "Northern", code: "NO" },
      { id: 880, name: "Red Sea", code: "RS" },
      { id: 891, name: "River Nile", code: "NR" },
      { id: 882, name: "Sennar", code: "SI" },
      { id: 894, name: "South Darfur", code: "DS" },
      { id: 883, name: "South Kordofan", code: "KS" },
      { id: 888, name: "West Darfur", code: "DW" },
      { id: 889, name: "West Kordofan", code: "GK" },
      { id: 879, name: "White Nile", code: "NW" }
    ]
  },
  {
    code: "SUR",
    name: "Suriname",
    states: [
      { id: 2846, name: "Brokopondo District", code: "BR" },
      { id: 2839, name: "Commewijne District", code: "CM" },
      { id: 2842, name: "Coronie District", code: "CR" },
      { id: 2845, name: "Marowijne District", code: "MA" },
      { id: 2840, name: "Nickerie District", code: "NI" },
      { id: 2841, name: "Para District", code: "PR" },
      { id: 2843, name: "Paramaribo District", code: "PM" },
      { id: 2848, name: "Saramacca District", code: "SA" },
      { id: 2847, name: "Sipaliwini District", code: "SI" },
      { id: 2844, name: "Wanica District", code: "WA" }
    ]
  },
  { code: "SJM", name: "Svalbard And Jan Mayen Islands", states: [] },
  {
    code: "SWZ",
    name: "Swaziland",
    states: [
      { id: 969, name: "Hhohho District", code: "HH" },
      { id: 970, name: "Lubombo District", code: "LU" },
      { id: 968, name: "Manzini District", code: "MA" },
      { id: 971, name: "Shiselweni District", code: "SH" }
    ]
  },
  {
    code: "SWE",
    name: "Sweden",
    states: [
      { id: 1537, name: "Blekinge", code: "K" },
      { id: 1534, name: "Dalarna County", code: "W" },
      { id: 1533, name: "G\xE4vleborg County", code: "X" },
      { id: 1546, name: "Gotland County", code: "I" },
      { id: 1548, name: "Halland County", code: "N" },
      { id: 5117, name: "J\xE4mtland County", code: "0" },
      { id: 1550, name: "J\xF6nk\xF6ping County", code: "F" },
      { id: 1544, name: "Kalmar County", code: "H" },
      { id: 1542, name: "Kronoberg County", code: "G" },
      { id: 1538, name: "Norrbotten County", code: "BD" },
      { id: 1539, name: "\xD6rebro County", code: "T" },
      { id: 1536, name: "\xD6sterg\xF6tland County", code: "E" },
      { id: 1541, name: "Sk\xE5ne County", code: "M" },
      { id: 1540, name: "S\xF6dermanland County", code: "D" },
      { id: 1551, name: "Stockholm County", code: "AB" },
      { id: 1545, name: "Uppsala County", code: "C" },
      { id: 1535, name: "V\xE4rmland County", code: "S" },
      { id: 1543, name: "V\xE4sterbotten County", code: "AC" },
      { id: 1552, name: "V\xE4sternorrland County", code: "Y" },
      { id: 1549, name: "V\xE4stmanland County", code: "U" },
      { id: 1547, name: "V\xE4stra G\xF6taland County", code: "O" }
    ]
  },
  {
    code: "CHE",
    name: "Switzerland",
    states: [
      { id: 1639, name: "Aargau", code: "AG" },
      { id: 1655, name: "Appenzell Ausserrhoden", code: "AR" },
      { id: 1649, name: "Appenzell Innerrhoden", code: "AI" },
      { id: 1641, name: "Basel-Land", code: "BL" },
      { id: 4957, name: "Basel-Stadt", code: "BS" },
      { id: 1645, name: "Bern", code: "BE" },
      { id: 1640, name: "Fribourg", code: "FR" },
      { id: 1647, name: "Geneva", code: "GE" },
      { id: 1661, name: "Glarus", code: "GL" },
      { id: 1660, name: "Graub\xFCnden", code: "GR" },
      { id: 1658, name: "Jura", code: "JU" },
      { id: 1663, name: "Lucerne", code: "LU" },
      { id: 1659, name: "Neuch\xE2tel", code: "NE" },
      { id: 1652, name: "Nidwalden", code: "NW" },
      { id: 1650, name: "Obwalden", code: "OW" },
      { id: 1654, name: "Schaffhausen", code: "SH" },
      { id: 1653, name: "Schwyz", code: "SZ" },
      { id: 1662, name: "Solothurn", code: "SO" },
      { id: 1644, name: "St. Gallen", code: "SG" },
      { id: 1657, name: "Thurgau", code: "TG" },
      { id: 1643, name: "Ticino", code: "TI" },
      { id: 1642, name: "Uri", code: "UR" },
      { id: 1648, name: "Valais", code: "VS" },
      { id: 1651, name: "Vaud", code: "VD" },
      { id: 1646, name: "Zug", code: "ZG" },
      { id: 1656, name: "Z\xFCrich", code: "ZH" }
    ]
  },
  {
    code: "SYR",
    name: "Syria",
    states: [
      { id: 2941, name: "Al-Hasakah", code: "HA" },
      { id: 2944, name: "Al-Raqqah", code: "RA" },
      { id: 2946, name: "Aleppo", code: "HL" },
      { id: 2936, name: "As-Suwayda", code: "SU" },
      { id: 2939, name: "Damascus", code: "DI" },
      { id: 2945, name: "Daraa", code: "DR" },
      { id: 2937, name: "Deir ez-Zor", code: "DY" },
      { id: 2934, name: "Hama", code: "HM" },
      { id: 2942, name: "Homs", code: "HI" },
      { id: 2940, name: "Idlib", code: "ID" },
      { id: 2938, name: "Latakia", code: "LA" },
      { id: 2943, name: "Quneitra", code: "QU" },
      { id: 2935, name: "Rif Dimashq", code: "RD" },
      { id: 2947, name: "Tartus", code: "TA" }
    ]
  },
  {
    code: "TWN",
    name: "Taiwan",
    states: [
      { id: 3404, name: "Changhua", code: "CHA" },
      { id: 3408, name: "Chiayi", code: "CYI" },
      { id: 3418, name: "Chiayi", code: "CYQ" },
      { id: 3423, name: "Hsinchu", code: "HSQ" },
      { id: 3417, name: "Hsinchu", code: "HSZ" },
      { id: 3411, name: "Hualien", code: "HUA" },
      { id: 3412, name: "Kaohsiung", code: "KHH" },
      { id: 4965, name: "Keelung", code: "KEE" },
      { id: 3415, name: "Kinmen", code: "KIN" },
      { id: 3420, name: "Lienchiang", code: "LIE" },
      { id: 3413, name: "Miaoli", code: "MIA" },
      { id: 3407, name: "Nantou", code: "NAN" },
      { id: 4966, name: "New Taipei", code: "NWT" },
      { id: 3403, name: "Penghu", code: "PEN" },
      { id: 3405, name: "Pingtung", code: "PIF" },
      { id: 3406, name: "Taichung", code: "TXG" },
      { id: 3421, name: "Tainan", code: "TNN" },
      { id: 3422, name: "Taipei", code: "TPE" },
      { id: 3410, name: "Taitung", code: "TTT" },
      { id: 3419, name: "Taoyuan", code: "TAO" },
      { id: 3402, name: "Yilan", code: "ILA" },
      { id: 3416, name: "Yunlin", code: "YUN" }
    ]
  },
  {
    code: "TJK",
    name: "Tajikistan",
    states: [
      {
        id: 3397,
        name: "districts of Republican Subordination",
        code: "RA"
      },
      {
        id: 3399,
        name: "Gorno-Badakhshan Autonomous Province",
        code: "GB"
      },
      { id: 3398, name: "Khatlon Province", code: "KT" },
      { id: 3400, name: "Sughd Province", code: "SU" }
    ]
  },
  {
    code: "TZA",
    name: "Tanzania",
    states: [
      { id: 1491, name: "Arusha", code: "01" },
      { id: 1490, name: "Dar es Salaam", code: "02" },
      { id: 1466, name: "Dodoma", code: "03" },
      { id: 1481, name: "Geita", code: "27" },
      { id: 1489, name: "Iringa", code: "04" },
      { id: 1465, name: "Kagera", code: "05" },
      { id: 1482, name: "Katavi", code: "28" },
      { id: 1478, name: "Kigoma", code: "08" },
      { id: 1467, name: "Kilimanjaro", code: "09" },
      { id: 1483, name: "Lindi", code: "12" },
      { id: 1484, name: "Manyara", code: "26" },
      { id: 1468, name: "Mara", code: "13" },
      { id: 4955, name: "Mbeya", code: "14" },
      { id: 1470, name: "Morogoro", code: "16" },
      { id: 1476, name: "Mtwara", code: "17" },
      { id: 1479, name: "Mwanza", code: "18" },
      { id: 1480, name: "Njombe", code: "29" },
      { id: 1488, name: "Pemba North", code: "06" },
      { id: 1472, name: "Pemba South", code: "10" },
      { id: 1485, name: "Pwani", code: "19" },
      { id: 1477, name: "Rukwa", code: "20" },
      { id: 1486, name: "Ruvuma", code: "21" },
      { id: 1463, name: "Shinyanga", code: "22" },
      { id: 1464, name: "Simiyu", code: "30" },
      { id: 1474, name: "Singida", code: "23" },
      { id: 4956, name: "Songwe", code: "31" },
      { id: 1469, name: "Tabora", code: "24" },
      { id: 1487, name: "Tanga", code: "25" },
      { id: 1473, name: "Zanzibar North", code: "07" },
      { id: 1471, name: "Zanzibar South", code: "11" },
      { id: 1475, name: "Zanzibar West", code: "15" }
    ]
  },
  {
    code: "THA",
    name: "Thailand",
    states: [
      { id: 3523, name: "Amnat Charoen", code: "37" },
      { id: 3519, name: "Ang Thong", code: "15" },
      { id: 3554, name: "Bangkok", code: "10" },
      { id: 3533, name: "Bueng Kan", code: "38" },
      { id: 3534, name: "Buri Ram", code: "31" },
      { id: 3552, name: "Chachoengsao", code: "24" },
      { id: 3522, name: "Chai Nat", code: "18" },
      { id: 4954, name: "Chaiyaphum", code: "36" },
      { id: 3486, name: "Chanthaburi", code: "22" },
      { id: 3491, name: "Chiang Mai", code: "50" },
      { id: 3498, name: "Chiang Rai", code: "57" },
      { id: 3513, name: "Chon Buri", code: "20" },
      { id: 3526, name: "Chumphon", code: "86" },
      { id: 3550, name: "Kalasin", code: "46" },
      { id: 3516, name: "Kamphaeng Phet", code: "62" },
      { id: 3511, name: "Kanchanaburi", code: "71" },
      { id: 3485, name: "Khon Kaen", code: "40" },
      { id: 3478, name: "Krabi", code: "81" },
      { id: 3544, name: "Lampang", code: "52" },
      { id: 3483, name: "Lamphun", code: "51" },
      { id: 3509, name: "Loei", code: "42" },
      { id: 3543, name: "Lop Buri", code: "16" },
      { id: 3505, name: "Mae Hong Son", code: "58" },
      { id: 3517, name: "Maha Sarakham", code: "44" },
      { id: 3546, name: "Mukdahan", code: "49" },
      { id: 3535, name: "Nakhon Nayok", code: "26" },
      { id: 3503, name: "Nakhon Pathom", code: "73" },
      { id: 3548, name: "Nakhon Phanom", code: "48" },
      { id: 3497, name: "Nakhon Ratchasima", code: "30" },
      { id: 3492, name: "Nakhon Sawan", code: "60" },
      { id: 3520, name: "Nakhon Si Thammarat", code: "80" },
      { id: 3530, name: "Nan", code: "55" },
      { id: 3553, name: "Narathiwat", code: "96" },
      { id: 3480, name: "Nong Bua Lam Phu", code: "39" },
      { id: 3484, name: "Nong Khai", code: "43" },
      { id: 3495, name: "Nonthaburi", code: "12" },
      { id: 3500, name: "Pathum Thani", code: "13" },
      { id: 3540, name: "Pattani", code: "94" },
      { id: 3507, name: "Pattaya", code: "S" },
      { id: 3549, name: "Phangnga", code: "82" },
      { id: 3488, name: "Phatthalung", code: "93" },
      { id: 3538, name: "Phayao", code: "56" },
      { id: 3515, name: "Phetchabun", code: "67" },
      { id: 3532, name: "Phetchaburi", code: "76" },
      { id: 3514, name: "Phichit", code: "66" },
      { id: 3506, name: "Phitsanulok", code: "65" },
      { id: 3494, name: "Phra Nakhon Si Ayutthaya", code: "14" },
      { id: 3528, name: "Phrae", code: "54" },
      { id: 3536, name: "Phuket", code: "83" },
      { id: 3542, name: "Prachin Buri", code: "25" },
      { id: 3508, name: "Prachuap Khiri Khan", code: "77" },
      { id: 3479, name: "Ranong", code: "85" },
      { id: 3499, name: "Ratchaburi", code: "70" },
      { id: 3518, name: "Rayong", code: "21" },
      { id: 3510, name: "Roi Et", code: "45" },
      { id: 3529, name: "Sa Kaeo", code: "27" },
      { id: 3501, name: "Sakon Nakhon", code: "47" },
      { id: 3481, name: "Samut Prakan", code: "11" },
      { id: 3504, name: "Samut Sakhon", code: "74" },
      { id: 3502, name: "Samut Songkhram", code: "75" },
      { id: 3487, name: "Saraburi", code: "19" },
      { id: 3537, name: "Satun", code: "91" },
      { id: 3547, name: "Si Sa Ket", code: "33" },
      { id: 3490, name: "Sing Buri", code: "17" },
      { id: 3539, name: "Songkhla", code: "90" },
      { id: 3545, name: "Sukhothai", code: "64" },
      { id: 3524, name: "Suphan Buri", code: "72" },
      { id: 3482, name: "Surat Thani", code: "84" },
      { id: 3531, name: "Surin", code: "32" },
      { id: 3525, name: "Tak", code: "63" },
      { id: 3541, name: "Trang", code: "92" },
      { id: 3496, name: "Trat", code: "23" },
      { id: 3512, name: "Ubon Ratchathani", code: "34" },
      { id: 3527, name: "Udon Thani", code: "41" },
      { id: 3551, name: "Uthai Thani", code: "61" },
      { id: 3489, name: "Uttaradit", code: "53" },
      { id: 3493, name: "Yala", code: "95" },
      { id: 3521, name: "Yasothon", code: "35" }
    ]
  },
  {
    code: "BHS",
    name: "The Bahamas",
    states: [
      { id: 3601, name: "Acklins", code: "AK" },
      { id: 3628, name: "Acklins and Crooked Islands", code: "AC" },
      { id: 3593, name: "Berry Islands", code: "BY" },
      { id: 3629, name: "Bimini", code: "BI" },
      { id: 3605, name: "Black Point", code: "BP" },
      { id: 3611, name: "Cat Island", code: "CI" },
      { id: 3603, name: "Central Abaco", code: "CO" },
      { id: 3631, name: "Central Andros", code: "CS" },
      { id: 3596, name: "Central Eleuthera", code: "CE" },
      { id: 3621, name: "Crooked Island", code: "CK" },
      { id: 3614, name: "East Grand Bahama", code: "EG" },
      { id: 3612, name: "Exuma", code: "EX" },
      { id: 3626, name: "Freeport", code: "FP" },
      { id: 3619, name: "Fresh Creek", code: "FC" },
      { id: 3597, name: "Governor's Harbour", code: "GH" },
      { id: 3632, name: "Grand Cay", code: "GC" },
      { id: 3595, name: "Green Turtle Cay", code: "GT" },
      { id: 3613, name: "Harbour Island", code: "HI" },
      { id: 3598, name: "High Rock", code: "HR" },
      { id: 3624, name: "Hope Town", code: "HT" },
      { id: 3609, name: "Inagua", code: "IN" },
      { id: 3618, name: "Kemps Bay", code: "KB" },
      { id: 3610, name: "Long Island", code: "LI" },
      { id: 3625, name: "Mangrove Cay", code: "MC" },
      { id: 3604, name: "Marsh Harbour", code: "MH" },
      { id: 3633, name: "Mayaguana District", code: "MG" },
      { id: 4881, name: "New Providence", code: "NP" },
      { id: 3594, name: "Nichollstown and Berry Islands", code: "NB" },
      { id: 3616, name: "North Abaco", code: "NO" },
      { id: 3617, name: "North Andros", code: "NS" },
      { id: 3602, name: "North Eleuthera", code: "NE" },
      { id: 3615, name: "Ragged Island", code: "RI" },
      { id: 3623, name: "Rock Sound", code: "RS" },
      { id: 3600, name: "Rum Cay District", code: "RC" },
      { id: 3620, name: "San Salvador and Rum Cay", code: "SR" },
      { id: 3627, name: "San Salvador Island", code: "SS" },
      { id: 3606, name: "Sandy Point", code: "SP" },
      { id: 3608, name: "South Abaco", code: "SO" },
      { id: 3622, name: "South Andros", code: "SA" },
      { id: 3607, name: "South Eleuthera", code: "SE" },
      { id: 3630, name: "Spanish Wells", code: "SW" },
      { id: 3599, name: "West Grand Bahama", code: "WG" }
    ]
  },
  {
    code: "TGO",
    name: "Togo",
    states: [
      { id: 2575, name: "Centrale Region", code: "C" },
      { id: 2579, name: "Kara Region", code: "K" },
      { id: 2576, name: "Maritime", code: "M" },
      { id: 2577, name: "Plateaux Region", code: "P" },
      { id: 2578, name: "Savanes Region", code: "S" }
    ]
  },
  { code: "TKL", name: "Tokelau", states: [] },
  {
    code: "TON",
    name: "Tonga",
    states: [
      { id: 3913, name: "Ha\u02BBapai", code: "02" },
      { id: 3915, name: "\u02BBEua", code: "01" },
      { id: 3914, name: "Niuas", code: "03" },
      { id: 3912, name: "Tongatapu", code: "04" },
      { id: 3911, name: "Vava\u02BBu", code: "05" }
    ]
  },
  {
    code: "TTO",
    name: "Trinidad And Tobago",
    states: [
      { id: 3362, name: "Arima", code: "ARI" },
      { id: 3366, name: "Chaguanas", code: "CHA" },
      {
        id: 3354,
        name: "Couva-Tabaquite-Talparo Regional Corporation",
        code: "CTT"
      },
      {
        id: 3367,
        name: "Diego Martin Regional Corporation",
        code: "DMN"
      },
      { id: 3355, name: "Eastern Tobago", code: "ETO" },
      { id: 3365, name: "Penal-Debe Regional Corporation", code: "PED" },
      { id: 3360, name: "Point Fortin", code: "PTF" },
      { id: 3363, name: "Port of Spain", code: "POS" },
      {
        id: 3368,
        name: "Princes Town Regional Corporation",
        code: "PRT"
      },
      {
        id: 3356,
        name: "Rio Claro-Mayaro Regional Corporation",
        code: "MRC"
      },
      { id: 3359, name: "San Fernando", code: "SFO" },
      {
        id: 3357,
        name: "San Juan-Laventille Regional Corporation",
        code: "SJL"
      },
      {
        id: 3361,
        name: "Sangre Grande Regional Corporation",
        code: "SGE"
      },
      { id: 3364, name: "Siparia Regional Corporation", code: "SIP" },
      {
        id: 3358,
        name: "Tunapuna-Piarco Regional Corporation",
        code: "TUP"
      },
      { id: 3353, name: "Western Tobago", code: "WTO" }
    ]
  },
  {
    code: "TUN",
    name: "Tunisia",
    states: [
      { id: 2550, name: "Ariana", code: "12" },
      { id: 2572, name: "B\xE9ja", code: "31" },
      { id: 2566, name: "Ben Arous", code: "13" },
      { id: 2551, name: "Bizerte", code: "23" },
      { id: 2558, name: "Gab\xE8s", code: "81" },
      { id: 2556, name: "Gafsa", code: "71" },
      { id: 2552, name: "Jendouba", code: "32" },
      { id: 2564, name: "Kairouan", code: "41" },
      { id: 2570, name: "Kasserine", code: "42" },
      { id: 2562, name: "Kebili", code: "73" },
      { id: 2561, name: "Kef", code: "33" },
      { id: 2568, name: "Mahdia", code: "53" },
      { id: 2555, name: "Manouba", code: "14" },
      { id: 2560, name: "Medenine", code: "82" },
      { id: 2553, name: "Monastir", code: "52" },
      { id: 5116, name: "Nabeul", code: "21" },
      { id: 2557, name: "Sfax", code: "61" },
      { id: 2567, name: "Sidi Bouzid", code: "43" },
      { id: 2563, name: "Siliana", code: "34" },
      { id: 2571, name: "Sousse", code: "51" },
      { id: 2559, name: "Tataouine", code: "83" },
      { id: 2569, name: "Tozeur", code: "72" },
      { id: 2554, name: "Tunis", code: "11" },
      { id: 2565, name: "Zaghouan", code: "22" }
    ]
  },
  {
    code: "TUR",
    name: "Turkey",
    states: [
      { id: 2212, name: "Adana", code: "01" },
      { id: 2155, name: "Ad\u0131yaman", code: "02" },
      { id: 2179, name: "Afyonkarahisar", code: "03" },
      { id: 2193, name: "A\u011Fr\u0131", code: "04" },
      { id: 2210, name: "Aksaray", code: "68" },
      { id: 2161, name: "Amasya", code: "05" },
      { id: 2217, name: "Ankara", code: "06" },
      { id: 2169, name: "Antalya", code: "07" },
      { id: 2185, name: "Ardahan", code: "75" },
      { id: 2191, name: "Artvin", code: "08" },
      { id: 2187, name: "Ayd\u0131n", code: "09" },
      { id: 2175, name: "Bal\u0131kesir", code: "10" },
      { id: 2148, name: "Bart\u0131n", code: "74" },
      { id: 2194, name: "Batman", code: "72" },
      { id: 2177, name: "Bayburt", code: "69" },
      { id: 2221, name: "Bilecik", code: "11" },
      { id: 2153, name: "Bing\xF6l", code: "12" },
      { id: 2215, name: "Bitlis", code: "13" },
      { id: 2172, name: "Bolu", code: "14" },
      { id: 2209, name: "Burdur", code: "15" },
      { id: 2163, name: "Bursa", code: "16" },
      { id: 2216, name: "\xC7anakkale", code: "17" },
      { id: 2168, name: "\xC7ank\u0131r\u0131", code: "18" },
      { id: 2173, name: "\xC7orum", code: "19" },
      { id: 2157, name: "Denizli", code: "20" },
      { id: 2226, name: "Diyarbak\u0131r", code: "21" },
      { id: 2202, name: "D\xFCzce", code: "81" },
      { id: 2151, name: "Edirne", code: "22" },
      { id: 2159, name: "Elaz\u0131\u011F", code: "23" },
      { id: 2160, name: "Erzincan", code: "24" },
      { id: 2165, name: "Erzurum", code: "25" },
      { id: 2164, name: "Eski\u015Fehir", code: "26" },
      { id: 2203, name: "Gaziantep", code: "27" },
      { id: 2186, name: "Giresun", code: "28" },
      { id: 2204, name: "G\xFCm\xFC\u015Fhane", code: "29" },
      { id: 2190, name: "Hakk\xE2ri", code: "30" },
      { id: 2211, name: "Hatay", code: "31" },
      { id: 2166, name: "I\u011Fd\u0131r", code: "76" },
      { id: 2222, name: "Isparta", code: "32" },
      { id: 2170, name: "\u0130stanbul", code: "34" },
      { id: 2205, name: "\u0130zmir", code: "35" },
      { id: 2227, name: "Kahramanmara\u015F", code: "46" },
      { id: 2223, name: "Karab\xFCk", code: "78" },
      { id: 2184, name: "Karaman", code: "70" },
      { id: 2208, name: "Kars", code: "36" },
      { id: 2197, name: "Kastamonu", code: "37" },
      { id: 2200, name: "Kayseri", code: "38" },
      { id: 2154, name: "Kilis", code: "79" },
      { id: 2178, name: "K\u0131r\u0131kkale", code: "71" },
      { id: 2176, name: "K\u0131rklareli", code: "39" },
      { id: 2180, name: "K\u0131r\u015Fehir", code: "40" },
      { id: 2195, name: "Kocaeli", code: "41" },
      { id: 2171, name: "Konya", code: "42" },
      { id: 2149, name: "K\xFCtahya", code: "43" },
      { id: 2158, name: "Malatya", code: "44" },
      { id: 2198, name: "Manisa", code: "45" },
      { id: 2224, name: "Mardin", code: "47" },
      { id: 2156, name: "Mersin", code: "33" },
      { id: 2182, name: "Mu\u011Fla", code: "48" },
      { id: 2162, name: "Mu\u015F", code: "49" },
      { id: 2196, name: "Nev\u015Fehir", code: "50" },
      { id: 2189, name: "Ni\u011Fde", code: "51" },
      { id: 2174, name: "Ordu", code: "52" },
      { id: 2214, name: "Osmaniye", code: "80" },
      { id: 2219, name: "Rize", code: "53" },
      { id: 2150, name: "Sakarya", code: "54" },
      { id: 2220, name: "Samsun", code: "55" },
      { id: 2183, name: "\u015Eanl\u0131urfa", code: "63" },
      { id: 2207, name: "Siirt", code: "56" },
      { id: 4854, name: "Sinop", code: "57" },
      { id: 2181, name: "Sivas", code: "58" },
      { id: 2225, name: "\u015E\u0131rnak", code: "73" },
      { id: 2167, name: "Tekirda\u011F", code: "59" },
      { id: 2199, name: "Tokat", code: "60" },
      { id: 2206, name: "Trabzon", code: "61" },
      { id: 2192, name: "Tunceli", code: "62" },
      { id: 2201, name: "U\u015Fak", code: "64" },
      { id: 2152, name: "Van", code: "65" },
      { id: 2218, name: "Yalova", code: "77" },
      { id: 2188, name: "Yozgat", code: "66" },
      { id: 2213, name: "Zonguldak", code: "67" }
    ]
  },
  {
    code: "TKM",
    name: "Turkmenistan",
    states: [
      { id: 3374, name: "Ahal Region", code: "A" },
      { id: 3371, name: "Ashgabat", code: "S" },
      { id: 3372, name: "Balkan Region", code: "B" },
      { id: 3373, name: "Da\u015Foguz Region", code: "D" },
      { id: 3370, name: "Lebap Region", code: "L" },
      { id: 3369, name: "Mary Region", code: "M" }
    ]
  },
  { code: "TCA", name: "Turks And Caicos Islands", states: [] },
  {
    code: "TUV",
    name: "Tuvalu",
    states: [
      { id: 3951, name: "Funafuti", code: "FUN" },
      { id: 3947, name: "Nanumanga", code: "NMG" },
      { id: 3949, name: "Nanumea", code: "NMA" },
      { id: 3946, name: "Niutao Island Council", code: "NIT" },
      { id: 3948, name: "Nui", code: "NUI" },
      { id: 3952, name: "Nukufetau", code: "NKF" },
      { id: 3953, name: "Nukulaelae", code: "NKL" },
      { id: 3950, name: "Vaitupu", code: "VAI" }
    ]
  },
  {
    code: "UGA",
    name: "Uganda",
    states: [
      { id: 329, name: "Abim District", code: "314" },
      { id: 361, name: "Adjumani District", code: "301" },
      { id: 392, name: "Agago District", code: "322" },
      { id: 344, name: "Alebtong District", code: "323" },
      { id: 416, name: "Amolatar District", code: "315" },
      { id: 353, name: "Amudat District", code: "324" },
      { id: 352, name: "Amuria District", code: "216" },
      { id: 335, name: "Amuru District", code: "316" },
      { id: 328, name: "Apac District", code: "302" },
      { id: 447, name: "Arua District", code: "303" },
      { id: 441, name: "Budaka District", code: "217" },
      { id: 349, name: "Bududa District", code: "218" },
      { id: 387, name: "Bugiri District", code: "201" },
      { id: 391, name: "Buhweju District", code: "420" },
      { id: 377, name: "Buikwe District", code: "117" },
      { id: 343, name: "Bukedea District", code: "219" },
      { id: 375, name: "Bukomansimbi District", code: "118" },
      { id: 385, name: "Bukwo District", code: "220" },
      { id: 428, name: "Bulambuli District", code: "225" },
      { id: 389, name: "Buliisa District", code: "416" },
      { id: 419, name: "Bundibugyo District", code: "401" },
      { id: 381, name: "Bunyangabu District", code: "430" },
      { id: 386, name: "Bushenyi District", code: "402" },
      { id: 431, name: "Busia District", code: "202" },
      { id: 365, name: "Butaleja District", code: "221" },
      { id: 384, name: "Butambala District", code: "119" },
      { id: 388, name: "Butebo District", code: "233" },
      { id: 414, name: "Buvuma District", code: "120" },
      { id: 380, name: "Buyende District", code: "226" },
      { id: 396, name: "Central Region", code: "C" },
      { id: 341, name: "Dokolo District", code: "317" },
      { id: 372, name: "Eastern Region", code: "E" },
      { id: 366, name: "Gomba District", code: "121" },
      { id: 413, name: "Gulu District", code: "304" },
      { id: 339, name: "Ibanda District", code: "417" },
      { id: 340, name: "Iganga District", code: "203" },
      { id: 383, name: "Isingiro District", code: "418" },
      { id: 367, name: "Jinja District", code: "204" },
      { id: 434, name: "Kaabong District", code: "318" },
      { id: 426, name: "Kabale District", code: "404" },
      { id: 326, name: "Kabarole District", code: "405" },
      { id: 336, name: "Kaberamaido District", code: "213" },
      { id: 403, name: "Kagadi District", code: "427" },
      { id: 399, name: "Kakumiro District", code: "428" },
      { id: 405, name: "Kalangala District", code: "101" },
      { id: 398, name: "Kaliro District", code: "222" },
      { id: 394, name: "Kalungu District", code: "122" },
      { id: 382, name: "Kampala District", code: "102" },
      { id: 334, name: "Kamuli District", code: "205" },
      { id: 360, name: "Kamwenge District", code: "413" },
      { id: 373, name: "Kanungu District", code: "414" },
      { id: 432, name: "Kapchorwa District", code: "206" },
      { id: 440, name: "Kasese District", code: "406" },
      { id: 420, name: "Katakwi District", code: "207" },
      { id: 368, name: "Kayunga District", code: "112" },
      { id: 436, name: "Kibaale District", code: "407" },
      { id: 347, name: "Kiboga District", code: "103" },
      { id: 338, name: "Kibuku District", code: "227" },
      { id: 355, name: "Kiruhura District", code: "419" },
      { id: 346, name: "Kiryandongo District", code: "421" },
      { id: 409, name: "Kisoro District", code: "408" },
      { id: 348, name: "Kitgum District", code: "305" },
      { id: 345, name: "Koboko District", code: "319" },
      { id: 401, name: "Kole District", code: "325" },
      { id: 443, name: "Kotido District", code: "306" },
      { id: 425, name: "Kumi District", code: "208" },
      { id: 369, name: "Kween District", code: "228" },
      { id: 325, name: "Kyankwanzi District", code: "123" },
      { id: 437, name: "Kyegegwa District", code: "422" },
      { id: 402, name: "Kyenjojo District", code: "415" },
      { id: 448, name: "Kyotera District", code: "125" },
      { id: 411, name: "Lamwo District", code: "326" },
      { id: 342, name: "Lira District", code: "307" },
      { id: 445, name: "Luuka District", code: "229" },
      { id: 433, name: "Luwero District", code: "104" },
      { id: 417, name: "Lwengo District", code: "124" },
      { id: 376, name: "Lyantonde District", code: "114" },
      { id: 438, name: "Manafwa District", code: "223" },
      { id: 421, name: "Maracha District", code: "320" },
      { id: 356, name: "Masaka District", code: "105" },
      { id: 354, name: "Masindi District", code: "409" },
      { id: 418, name: "Mayuge District", code: "214" },
      { id: 350, name: "Mbale District", code: "209" },
      { id: 415, name: "Mbarara District", code: "410" },
      { id: 435, name: "Mitooma District", code: "423" },
      { id: 364, name: "Mityana District", code: "115" },
      { id: 395, name: "Moroto District", code: "308" },
      { id: 363, name: "Moyo District", code: "309" },
      { id: 327, name: "Mpigi District", code: "106" },
      { id: 371, name: "Mubende District", code: "107" },
      { id: 410, name: "Mukono District", code: "108" },
      { id: 393, name: "Nakapiripirit District", code: "311" },
      { id: 423, name: "Nakaseke District", code: "116" },
      { id: 406, name: "Nakasongola District", code: "109" },
      { id: 351, name: "Namayingo District", code: "230" },
      { id: 400, name: "Namisindwa District", code: "234" },
      { id: 337, name: "Namutumba District", code: "224" },
      { id: 430, name: "Napak District", code: "327" },
      { id: 446, name: "Nebbi District", code: "310" },
      { id: 424, name: "Ngora District", code: "231" },
      { id: 332, name: "Northern Region", code: "N" },
      { id: 422, name: "Ntoroko District", code: "424" },
      { id: 404, name: "Ntungamo District", code: "411" },
      { id: 378, name: "Nwoya District", code: "328" },
      { id: 374, name: "Omoro District", code: "331" },
      { id: 390, name: "Otuke District", code: "329" },
      { id: 397, name: "Oyam District", code: "321" },
      { id: 408, name: "Pader District", code: "312" },
      { id: 357, name: "Pakwach District", code: "332" },
      { id: 412, name: "Pallisa District", code: "210" },
      { id: 439, name: "Rakai District", code: "110" },
      { id: 358, name: "Rubanda District", code: "429" },
      { id: 442, name: "Rubirizi District", code: "425" },
      { id: 331, name: "Rukiga District", code: "431" },
      { id: 324, name: "Rukungiri District", code: "412" },
      { id: 427, name: "Sembabule District", code: "111" },
      { id: 333, name: "Serere District", code: "232" },
      { id: 407, name: "Sheema District", code: "426" },
      { id: 429, name: "Sironko District", code: "215" },
      { id: 444, name: "Soroti District", code: "211" },
      { id: 359, name: "Tororo District", code: "212" },
      { id: 362, name: "Wakiso District", code: "113" },
      { id: 370, name: "Western Region", code: "W" },
      { id: 330, name: "Yumbe District", code: "313" },
      { id: 379, name: "Zombo District", code: "330" }
    ]
  },
  {
    code: "UKR",
    name: "Ukraine",
    states: [
      { id: 4689, name: "Autonomous Republic of Crimea", code: "43" },
      { id: 4680, name: "Cherkaska oblast", code: "71" },
      { id: 4692, name: "Chernihivska oblast", code: "74" },
      { id: 4678, name: "Chernivetska oblast", code: "77" },
      { id: 4675, name: "Dnipropetrovska oblast", code: "12" },
      { id: 4691, name: "Donetska oblast", code: "14" },
      { id: 4682, name: "Ivano-Frankivska oblast", code: "26" },
      { id: 4686, name: "Kharkivska oblast", code: "63" },
      { id: 4684, name: "Khersonska oblast", code: "65" },
      { id: 4681, name: "Khmelnytska oblast", code: "68" },
      { id: 4677, name: "Kirovohradska oblast", code: "35" },
      { id: 4676, name: "Kyiv", code: "30" },
      { id: 4671, name: "Kyivska oblast", code: "32" },
      { id: 4673, name: "Luhanska oblast", code: "09" },
      { id: 4672, name: "Lvivska oblast", code: "46" },
      { id: 4679, name: "Mykolaivska oblast", code: "48" },
      { id: 4688, name: "Odeska oblast", code: "51" },
      { id: 5071, name: "Poltavska oblast", code: "53" },
      { id: 4683, name: "Rivnenska oblast", code: "56" },
      { id: 4685, name: "Sumska oblast", code: "59" },
      { id: 4674, name: "Ternopilska oblast", code: "61" },
      { id: 4669, name: "Vinnytska oblast", code: "05" },
      { id: 4690, name: "Volynska oblast", code: "07" },
      { id: 4670, name: "Zakarpatska Oblast", code: "21" },
      { id: 4687, name: "Zaporizka oblast", code: "23" },
      { id: 4668, name: "Zhytomyrska oblast", code: "18" }
    ]
  },
  {
    code: "ARE",
    name: "United Arab Emirates",
    states: [
      { id: 3396, name: "Abu Dhabi Emirate", code: "AZ" },
      { id: 3395, name: "Ajman Emirate", code: "AJ" },
      { id: 3391, name: "Dubai", code: "DU" },
      { id: 3393, name: "Fujairah", code: "FU" },
      { id: 3394, name: "Ras al-Khaimah", code: "RK" },
      { id: 3390, name: "Sharjah Emirate", code: "SH" },
      { id: 3392, name: "Umm al-Quwain", code: "UQ" }
    ]
  },
  {
    code: "GBR",
    name: "United Kingdom",
    states: [
      { id: 2463, name: "Aberdeen", code: "ABE" },
      { id: 2401, name: "Aberdeenshire", code: "ABD" },
      { id: 2387, name: "Angus", code: "ANS" },
      { id: 2533, name: "Antrim", code: "ANT" },
      { id: 2412, name: "Antrim and Newtownabbey", code: "ANN" },
      { id: 2498, name: "Ards", code: "ARD" },
      { id: 2523, name: "Ards and North Down", code: "AND" },
      { id: 2392, name: "Argyll and Bute", code: "AGB" },
      { id: 2331, name: "Armagh City and District Council", code: "ARM" },
      { id: 2324, name: "Armagh, Banbridge and Craigavon", code: "ABC" },
      { id: 2378, name: "Ascension Island", code: "SH-AC" },
      { id: 2363, name: "Ballymena Borough", code: "BLA" },
      { id: 2361, name: "Ballymoney", code: "BLY" },
      { id: 2315, name: "Banbridge", code: "BNB" },
      { id: 2499, name: "Barnsley", code: "BNS" },
      { id: 2339, name: "Bath and North East Somerset", code: "BAS" },
      { id: 2507, name: "Bedford", code: "BDF" },
      { id: 2311, name: "Belfast district", code: "BFS" },
      { id: 2425, name: "Birmingham", code: "BIR" },
      { id: 2329, name: "Blackburn with Darwen", code: "BBD" },
      { id: 2451, name: "Blackpool", code: "BPL" },
      { id: 2530, name: "Blaenau Gwent County Borough", code: "BGW" },
      { id: 2504, name: "Bolton", code: "BOL" },
      { id: 2342, name: "Bournemouth", code: "BMH" },
      { id: 2470, name: "Bracknell Forest", code: "BRC" },
      { id: 2529, name: "Bradford", code: "BRD" },
      { id: 2452, name: "Bridgend County Borough", code: "BGE" },
      { id: 2395, name: "Brighton and Hove", code: "BNH" },
      { id: 2405, name: "Buckinghamshire", code: "BKM" },
      { id: 2459, name: "Bury", code: "BUR" },
      { id: 2298, name: "Caerphilly County Borough", code: "CAY" },
      { id: 2517, name: "Calderdale", code: "CLD" },
      { id: 2423, name: "Cambridgeshire", code: "CAM" },
      { id: 2484, name: "Carmarthenshire", code: "CMN" },
      { id: 2439, name: "Carrickfergus Borough Council", code: "CKF" },
      { id: 2525, name: "Castlereagh", code: "CSR" },
      { id: 2316, name: "Causeway Coast and Glens", code: "CCG" },
      { id: 2303, name: "Central Bedfordshire", code: "CBF" },
      { id: 2509, name: "Ceredigion", code: "CGN" },
      { id: 2444, name: "Cheshire East", code: "CHE" },
      { id: 2442, name: "Cheshire West and Chester", code: "CHW" },
      { id: 2528, name: "City and County of Cardiff", code: "CRF" },
      { id: 2433, name: "City and County of Swansea", code: "SWA" },
      { id: 2413, name: "City of Bristol", code: "BST" },
      { id: 2485, name: "City of Derby", code: "DER" },
      { id: 2475, name: "City of Kingston upon Hull", code: "KHL" },
      { id: 2318, name: "City of Leicester", code: "LCE" },
      { id: 2424, name: "City of London", code: "LND" },
      { id: 2359, name: "City of Nottingham", code: "NGM" },
      { id: 2297, name: "City of Peterborough", code: "PTE" },
      { id: 2514, name: "City of Plymouth", code: "PLY" },
      { id: 2305, name: "City of Portsmouth", code: "POR" },
      { id: 2294, name: "City of Southampton", code: "STH" },
      { id: 2506, name: "City of Stoke-on-Trent", code: "STE" },
      { id: 2372, name: "City of Sunderland", code: "SND" },
      { id: 2357, name: "City of Westminster", code: "WSM" },
      { id: 2489, name: "City of Wolverhampton", code: "WLV" },
      { id: 2426, name: "City of York", code: "YOR" },
      { id: 2450, name: "Clackmannanshire", code: "CLK" },
      { id: 2461, name: "Coleraine Borough Council", code: "CLR" },
      { id: 2352, name: "Conwy County Borough", code: "CWY" },
      { id: 2445, name: "Cookstown District Council", code: "CKT" },
      { id: 2312, name: "Cornwall", code: "CON" },
      { id: 2406, name: "County Durham", code: "DUR" },
      { id: 2438, name: "Coventry", code: "COV" },
      { id: 2449, name: "Craigavon Borough Council", code: "CGV" },
      { id: 2334, name: "Cumbria", code: "CMA" },
      { id: 2389, name: "Darlington", code: "DAL" },
      { id: 2497, name: "Denbighshire", code: "DEN" },
      { id: 2403, name: "Derbyshire", code: "DBY" },
      { id: 2446, name: "Derry City and Strabane", code: "DRS" },
      { id: 2417, name: "Derry City Council", code: "DRY" },
      { id: 2491, name: "Devon", code: "DEV" },
      { id: 2364, name: "Doncaster", code: "DNC" },
      { id: 2345, name: "Dorset", code: "DOR" },
      { id: 2304, name: "Down District Council", code: "DOW" },
      { id: 2457, name: "Dudley", code: "DUD" },
      { id: 2415, name: "Dumfries and Galloway", code: "DGY" },
      { id: 2511, name: "Dundee", code: "DND" },
      {
        id: 2508,
        name: "Dungannon and South Tyrone Borough Council",
        code: "DGN"
      },
      { id: 2374, name: "East Ayrshire", code: "EAY" },
      { id: 2454, name: "East Dunbartonshire", code: "EDU" },
      { id: 2462, name: "East Lothian", code: "ELN" },
      { id: 2333, name: "East Renfrewshire", code: "ERW" },
      { id: 2370, name: "East Riding of Yorkshire", code: "ERY" },
      { id: 2414, name: "East Sussex", code: "ESX" },
      { id: 2428, name: "Edinburgh", code: "EDH" },
      { id: 2336, name: "England", code: "ENG" },
      { id: 2410, name: "Essex", code: "ESS" },
      { id: 2344, name: "Falkirk", code: "FAL" },
      { id: 2366, name: "Fermanagh and Omagh", code: "FMO" },
      { id: 2531, name: "Fermanagh District Council", code: "FER" },
      { id: 2479, name: "Fife", code: "FIF" },
      { id: 2437, name: "Flintshire", code: "FLN" },
      { id: 2431, name: "Gateshead", code: "GAT" },
      { id: 2404, name: "Glasgow", code: "GLG" },
      { id: 2373, name: "Gloucestershire", code: "GLS" },
      { id: 2379, name: "Gwynedd", code: "GWN" },
      { id: 2466, name: "Halton", code: "HAL" },
      { id: 2435, name: "Hampshire", code: "HAM" },
      { id: 2309, name: "Hartlepool", code: "HPL" },
      { id: 2500, name: "Herefordshire", code: "HEF" },
      { id: 2369, name: "Hertfordshire", code: "HRT" },
      { id: 2383, name: "Highland", code: "HLD" },
      { id: 2388, name: "Inverclyde", code: "IVC" },
      { id: 2289, name: "Isle of Wight", code: "IOW" },
      { id: 2343, name: "Isles of Scilly", code: "IOS" },
      { id: 2464, name: "Kent", code: "KEN" },
      { id: 2371, name: "Kirklees", code: "KIR" },
      { id: 2330, name: "Knowsley", code: "KWL" },
      { id: 2495, name: "Lancashire", code: "LAN" },
      { id: 2515, name: "Larne Borough Council", code: "LRN" },
      { id: 2503, name: "Leeds", code: "LDS" },
      { id: 2516, name: "Leicestershire", code: "LEC" },
      { id: 2382, name: "Limavady Borough Council", code: "LMV" },
      { id: 2355, name: "Lincolnshire", code: "LIN" },
      { id: 2460, name: "Lisburn and Castlereagh", code: "LBC" },
      { id: 2494, name: "Lisburn City Council", code: "LSB" },
      { id: 2340, name: "Liverpool", code: "LIV" },
      {
        id: 2356,
        name: "London Borough of Barking and Dagenham",
        code: "BDG"
      },
      { id: 2520, name: "London Borough of Barnet", code: "BNE" },
      { id: 2307, name: "London Borough of Bexley", code: "BEX" },
      { id: 2291, name: "London Borough of Brent", code: "BEN" },
      { id: 2490, name: "London Borough of Bromley", code: "BRY" },
      { id: 2349, name: "London Borough of Camden", code: "CMD" },
      { id: 2512, name: "London Borough of Croydon", code: "CRY" },
      { id: 2532, name: "London Borough of Ealing", code: "EAL" },
      { id: 2476, name: "London Borough of Enfield", code: "ENF" },
      { id: 2411, name: "London Borough of Hackney", code: "HCK" },
      {
        id: 2448,
        name: "London Borough of Hammersmith and Fulham",
        code: "HMF"
      },
      { id: 2306, name: "London Borough of Haringey", code: "HRY" },
      { id: 2385, name: "London Borough of Harrow", code: "HRW" },
      { id: 2347, name: "London Borough of Havering", code: "HAV" },
      { id: 2376, name: "London Borough of Hillingdon", code: "HIL" },
      { id: 2380, name: "London Borough of Hounslow", code: "HNS" },
      { id: 2319, name: "London Borough of Islington", code: "ISL" },
      { id: 2396, name: "London Borough of Lambeth", code: "LBH" },
      { id: 2358, name: "London Borough of Lewisham", code: "LEW" },
      { id: 2483, name: "London Borough of Merton", code: "MRT" },
      { id: 2418, name: "London Borough of Newham", code: "NWM" },
      { id: 2397, name: "London Borough of Redbridge", code: "RDB" },
      {
        id: 2501,
        name: "London Borough of Richmond upon Thames",
        code: "RIC"
      },
      { id: 2432, name: "London Borough of Southwark", code: "SWK" },
      { id: 2313, name: "London Borough of Sutton", code: "STN" },
      { id: 2390, name: "London Borough of Tower Hamlets", code: "TWH" },
      { id: 2326, name: "London Borough of Waltham Forest", code: "WFT" },
      { id: 2434, name: "London Borough of Wandsworth", code: "WND" },
      { id: 2322, name: "Magherafelt District Council", code: "MFT" },
      { id: 2398, name: "Manchester", code: "MAN" },
      { id: 2381, name: "Medway", code: "MDW" },
      { id: 2328, name: "Merthyr Tydfil County Borough", code: "MTY" },
      { id: 2320, name: "Metropolitan Borough of Wigan", code: "WGN" },
      { id: 2429, name: "Mid and East Antrim", code: "MEA" },
      { id: 2399, name: "Mid Ulster", code: "MUL" },
      { id: 2332, name: "Middlesbrough", code: "MDB" },
      { id: 2519, name: "Midlothian", code: "MLN" },
      { id: 2416, name: "Milton Keynes", code: "MIK" },
      { id: 2402, name: "Monmouthshire", code: "MON" },
      { id: 2360, name: "Moray", code: "MRY" },
      { id: 2348, name: "Moyle District Council", code: "MYL" },
      { id: 2351, name: "Neath Port Talbot County Borough", code: "NTL" },
      { id: 2458, name: "Newcastle upon Tyne", code: "NET" },
      { id: 2524, name: "Newport", code: "NWP" },
      {
        id: 2350,
        name: "Newry and Mourne District Council",
        code: "NYM"
      },
      { id: 2534, name: "Newry, Mourne and Down", code: "NMD" },
      { id: 2317, name: "Newtownabbey Borough Council", code: "NTA" },
      { id: 2473, name: "Norfolk", code: "NFK" },
      { id: 2535, name: "North Ayrshire", code: "NAY" },
      { id: 2513, name: "North Down Borough Council", code: "NDN" },
      { id: 2384, name: "North East Lincolnshire", code: "NEL" },
      { id: 2487, name: "North Lanarkshire", code: "NLK" },
      { id: 2453, name: "North Lincolnshire", code: "NLN" },
      { id: 2430, name: "North Somerset", code: "NSM" },
      { id: 2521, name: "North Tyneside", code: "NTY" },
      { id: 2522, name: "North Yorkshire", code: "NYK" },
      { id: 2480, name: "Northamptonshire", code: "NTH" },
      { id: 2337, name: "Northern Ireland", code: "NIR" },
      { id: 2365, name: "Northumberland", code: "NBL" },
      { id: 2456, name: "Nottinghamshire", code: "NTT" },
      { id: 2477, name: "Oldham", code: "OLD" },
      { id: 2314, name: "Omagh District Council", code: "OMH" },
      { id: 2474, name: "Orkney Islands", code: "ORK" },
      { id: 2353, name: "Outer Hebrides", code: "ELS" },
      { id: 2321, name: "Oxfordshire", code: "OXF" },
      { id: 2486, name: "Pembrokeshire", code: "PEM" },
      { id: 2325, name: "Perth and Kinross", code: "PKN" },
      { id: 2302, name: "Poole", code: "POL" },
      { id: 2441, name: "Powys", code: "POW" },
      { id: 2455, name: "Reading", code: "RDG" },
      { id: 2527, name: "Redcar and Cleveland", code: "RCC" },
      { id: 2443, name: "Renfrewshire", code: "RFW" },
      { id: 2301, name: "Rhondda Cynon Taf", code: "RCT" },
      { id: 2327, name: "Rochdale", code: "RCH" },
      { id: 2308, name: "Rotherham", code: "ROT" },
      { id: 2492, name: "Royal Borough of Greenwich", code: "GRE" },
      {
        id: 2368,
        name: "Royal Borough of Kensington and Chelsea",
        code: "KEC"
      },
      {
        id: 2481,
        name: "Royal Borough of Kingston upon Thames",
        code: "KTT"
      },
      { id: 2472, name: "Rutland", code: "RUT" },
      { id: 2502, name: "Saint Helena", code: "SH-HL" },
      { id: 2493, name: "Salford", code: "SLF" },
      { id: 2341, name: "Sandwell", code: "SAW" },
      { id: 2335, name: "Scotland", code: "SCT" },
      { id: 2346, name: "Scottish Borders", code: "SCB" },
      { id: 2518, name: "Sefton", code: "SFT" },
      { id: 2295, name: "Sheffield", code: "SHF" },
      { id: 2300, name: "Shetland Islands", code: "ZET" },
      { id: 2407, name: "Shropshire", code: "SHR" },
      { id: 2427, name: "Slough", code: "SLG" },
      { id: 2469, name: "Solihull", code: "SOL" },
      { id: 2386, name: "Somerset", code: "SOM" },
      { id: 2377, name: "South Ayrshire", code: "SAY" },
      { id: 2400, name: "South Gloucestershire", code: "SGC" },
      { id: 2362, name: "South Lanarkshire", code: "SLK" },
      { id: 2409, name: "South Tyneside", code: "STY" },
      { id: 2323, name: "Southend-on-Sea", code: "SOS" },
      { id: 2290, name: "St Helens", code: "SHN" },
      { id: 2447, name: "Staffordshire", code: "STS" },
      { id: 2488, name: "Stirling", code: "STG" },
      { id: 2394, name: "Stockport", code: "SKP" },
      { id: 2421, name: "Stockton-on-Tees", code: "STT" },
      { id: 2393, name: "Strabane District Council", code: "STB" },
      { id: 2467, name: "Suffolk", code: "SFK" },
      { id: 2526, name: "Surrey", code: "SRY" },
      { id: 2422, name: "Swindon", code: "SWD" },
      { id: 2367, name: "Tameside", code: "TAM" },
      { id: 2310, name: "Telford and Wrekin", code: "TFW" },
      { id: 2468, name: "Thurrock", code: "THR" },
      { id: 2478, name: "Torbay", code: "TOB" },
      { id: 2496, name: "Torfaen", code: "TOF" },
      { id: 2293, name: "Trafford", code: "TRF" },
      { id: 2375, name: "United Kingdom", code: "UKM" },
      { id: 2299, name: "Vale of Glamorgan", code: "VGL" },
      { id: 2465, name: "Wakefield", code: "WKF" },
      { id: 2338, name: "Wales", code: "WLS" },
      { id: 2292, name: "Walsall", code: "WLL" },
      { id: 2420, name: "Warrington", code: "WRT" },
      { id: 2505, name: "Warwickshire", code: "WAR" },
      { id: 2471, name: "West Berkshire", code: "WBK" },
      { id: 2440, name: "West Dunbartonshire", code: "WDU" },
      { id: 2354, name: "West Lothian", code: "WLN" },
      { id: 2296, name: "West Sussex", code: "WSX" },
      { id: 2391, name: "Wiltshire", code: "WIL" },
      { id: 2482, name: "Windsor and Maidenhead", code: "WNM" },
      { id: 2408, name: "Wirral", code: "WRL" },
      { id: 2419, name: "Wokingham", code: "WOK" },
      { id: 2510, name: "Worcestershire", code: "WOR" },
      { id: 2436, name: "Wrexham County Borough", code: "WRX" }
    ]
  },
  {
    code: "USA",
    name: "United States",
    states: [
      { id: 1456, name: "Alabama", code: "AL" },
      { id: 1400, name: "Alaska", code: "AK" },
      { id: 1424, name: "American Samoa", code: "AS" },
      { id: 1434, name: "Arizona", code: "AZ" },
      { id: 1444, name: "Arkansas", code: "AR" },
      { id: 1402, name: "Baker Island", code: "UM-81" },
      { id: 1416, name: "California", code: "CA" },
      { id: 1450, name: "Colorado", code: "CO" },
      { id: 1435, name: "Connecticut", code: "CT" },
      { id: 1399, name: "Delaware", code: "DE" },
      { id: 1437, name: "District of Columbia", code: "DC" },
      { id: 1436, name: "Florida", code: "FL" },
      { id: 1455, name: "Georgia", code: "GA" },
      { id: 1412, name: "Guam", code: "GU" },
      { id: 1411, name: "Hawaii", code: "HI" },
      { id: 1398, name: "Howland Island", code: "UM-84" },
      { id: 1460, name: "Idaho", code: "ID" },
      { id: 1425, name: "Illinois", code: "IL" },
      { id: 1440, name: "Indiana", code: "IN" },
      { id: 1459, name: "Iowa", code: "IA" },
      { id: 1410, name: "Jarvis Island", code: "UM-86" },
      { id: 1428, name: "Johnston Atoll", code: "UM-67" },
      { id: 1406, name: "Kansas", code: "KS" },
      { id: 1419, name: "Kentucky", code: "KY" },
      { id: 1403, name: "Kingman Reef", code: "UM-89" },
      { id: 1457, name: "Louisiana", code: "LA" },
      { id: 1453, name: "Maine", code: "ME" },
      { id: 1401, name: "Maryland", code: "MD" },
      { id: 1433, name: "Massachusetts", code: "MA" },
      { id: 1426, name: "Michigan", code: "MI" },
      { id: 1438, name: "Midway Atoll", code: "UM-71" },
      { id: 1420, name: "Minnesota", code: "MN" },
      { id: 1430, name: "Mississippi", code: "MS" },
      { id: 1451, name: "Missouri", code: "MO" },
      { id: 1446, name: "Montana", code: "MT" },
      { id: 1439, name: "Navassa Island", code: "UM-76" },
      { id: 1408, name: "Nebraska", code: "NE" },
      { id: 1458, name: "Nevada", code: "NV" },
      { id: 1404, name: "New Hampshire", code: "NH" },
      { id: 1417, name: "New Jersey", code: "NJ" },
      { id: 1423, name: "New Mexico", code: "NM" },
      { id: 1452, name: "New York", code: "NY" },
      { id: 1447, name: "North Carolina", code: "NC" },
      { id: 1418, name: "North Dakota", code: "ND" },
      { id: 1431, name: "Northern Mariana Islands", code: "MP" },
      { id: 4851, name: "Ohio", code: "OH" },
      { id: 1421, name: "Oklahoma", code: "OK" },
      { id: 1415, name: "Oregon", code: "OR" },
      { id: 1448, name: "Palmyra Atoll", code: "UM-95" },
      { id: 1422, name: "Pennsylvania", code: "PA" },
      { id: 1449, name: "Puerto Rico", code: "PR" },
      { id: 1461, name: "Rhode Island", code: "RI" },
      { id: 1443, name: "South Carolina", code: "SC" },
      { id: 1445, name: "South Dakota", code: "SD" },
      { id: 1454, name: "Tennessee", code: "TN" },
      { id: 1407, name: "Texas", code: "TX" },
      {
        id: 1432,
        name: "United States Minor Outlying Islands",
        code: "UM"
      },
      { id: 1413, name: "United States Virgin Islands", code: "VI" },
      { id: 1414, name: "Utah", code: "UT" },
      { id: 1409, name: "Vermont", code: "VT" },
      { id: 1427, name: "Virginia", code: "VA" },
      { id: 1405, name: "Wake Island", code: "UM-79" },
      { id: 1462, name: "Washington", code: "WA" },
      { id: 1429, name: "West Virginia", code: "WV" },
      { id: 1441, name: "Wisconsin", code: "WI" },
      { id: 1442, name: "Wyoming", code: "WY" }
    ]
  },
  {
    code: "UMI",
    name: "United States Minor Outlying Islands",
    states: [
      { id: 5212, name: "Baker Island", code: "81" },
      { id: 5213, name: "Howland Island", code: "84" },
      { id: 5214, name: "Jarvis Island", code: "86" },
      { id: 5215, name: "Johnston Atoll", code: "67" },
      { id: 5216, name: "Kingman Reef", code: "89" },
      { id: 5217, name: "Midway Islands", code: "71" },
      { id: 5218, name: "Navassa Island", code: "76" },
      { id: 5219, name: "Palmyra Atoll", code: "95" },
      { id: 5220, name: "Wake Island", code: "79" }
    ]
  },
  {
    code: "URY",
    name: "Uruguay",
    states: [
      { id: 3205, name: "Artigas Department", code: "AR" },
      { id: 3213, name: "Canelones Department", code: "CA" },
      { id: 3211, name: "Cerro Largo Department", code: "CL" },
      { id: 3208, name: "Colonia Department", code: "CO" },
      { id: 3209, name: "Durazno Department", code: "DU" },
      { id: 3203, name: "Flores Department", code: "FS" },
      { id: 3217, name: "Florida Department", code: "FD" },
      { id: 3215, name: "Lavalleja Department", code: "LA" },
      { id: 3206, name: "Maldonado Department", code: "MA" },
      { id: 3218, name: "Montevideo Department", code: "MO" },
      { id: 3212, name: "Paysand\xFA Department", code: "PA" },
      { id: 3210, name: "R\xEDo Negro Department", code: "RN" },
      { id: 3207, name: "Rivera Department", code: "RV" },
      { id: 3216, name: "Rocha Department", code: "RO" },
      { id: 3220, name: "Salto Department", code: "SA" },
      { id: 3204, name: "San Jos\xE9 Department", code: "SJ" },
      { id: 3219, name: "Soriano Department", code: "SO" },
      { id: 3221, name: "Tacuaremb\xF3 Department", code: "TA" },
      { id: 3214, name: "Treinta y Tres Department", code: "TT" }
    ]
  },
  {
    code: "UZB",
    name: "Uzbekistan",
    states: [
      { id: 2540, name: "Andijan Region", code: "AN" },
      { id: 2541, name: "Bukhara Region", code: "BU" },
      { id: 2538, name: "Fergana Region", code: "FA" },
      { id: 2545, name: "Jizzakh Region", code: "JI" },
      { id: 2548, name: "Karakalpakstan", code: "QR" },
      { id: 2537, name: "Namangan Region", code: "NG" },
      { id: 2542, name: "Navoiy Region", code: "NW" },
      { id: 2543, name: "Qashqadaryo Region", code: "QA" },
      { id: 2544, name: "Samarqand Region", code: "SA" },
      { id: 2547, name: "Sirdaryo Region", code: "SI" },
      { id: 2546, name: "Surxondaryo Region", code: "SU" },
      { id: 2536, name: "Tashkent", code: "TK" },
      { id: 2549, name: "Tashkent Region", code: "TO" },
      { id: 2539, name: "Xorazm Region", code: "XO" }
    ]
  },
  {
    code: "VUT",
    name: "Vanuatu",
    states: [
      { id: 4775, name: "Malampa", code: "MAP" },
      { id: 4773, name: "Penama", code: "PAM" },
      { id: 4776, name: "Sanma", code: "SAM" },
      { id: 4774, name: "Shefa", code: "SEE" },
      { id: 4777, name: "Tafea", code: "TAE" },
      { id: 4772, name: "Torba", code: "TOB" }
    ]
  },
  { code: "VAT", name: "Vatican City State (Holy See)", states: [] },
  {
    code: "VEN",
    name: "Venezuela",
    states: [
      { id: 2044, name: "Amazonas", code: "Z" },
      { id: 2050, name: "Anzo\xE1tegui", code: "B" },
      { id: 4856, name: "Apure", code: "C" },
      { id: 2047, name: "Aragua", code: "D" },
      { id: 2049, name: "Barinas", code: "E" },
      { id: 2039, name: "Bol\xEDvar", code: "F" },
      { id: 2040, name: "Carabobo", code: "G" },
      { id: 2034, name: "Cojedes", code: "H" },
      { id: 2051, name: "Delta Amacuro", code: "Y" },
      { id: 4855, name: "Distrito Capital", code: "A" },
      { id: 2035, name: "Falc\xF3n", code: "I" },
      { id: 2046, name: "Federal Dependencies of Venezuela", code: "W" },
      { id: 2045, name: "Gu\xE1rico", code: "J" },
      { id: 2055, name: "La Guaira", code: "X" },
      { id: 2038, name: "Lara", code: "K" },
      { id: 2053, name: "M\xE9rida", code: "L" },
      { id: 2037, name: "Miranda", code: "M" },
      { id: 2054, name: "Monagas", code: "N" },
      { id: 2052, name: "Nueva Esparta", code: "O" },
      { id: 2036, name: "Portuguesa", code: "P" },
      { id: 2056, name: "Sucre", code: "R" },
      { id: 2048, name: "T\xE1chira", code: "S" },
      { id: 2043, name: "Trujillo", code: "T" },
      { id: 2041, name: "Yaracuy", code: "U" },
      { id: 2042, name: "Zulia", code: "V" }
    ]
  },
  {
    code: "VNM",
    name: "Vietnam",
    states: [
      { id: 3794, name: "An Giang", code: "44" },
      { id: 3770, name: "B\xE0 R\u1ECBa-V\u0169ng T\xE0u", code: "43" },
      { id: 3815, name: "B\u1EAFc Giang", code: "54" },
      { id: 3822, name: "B\u1EAFc K\u1EA1n", code: "53" },
      { id: 3804, name: "B\u1EA1c Li\xEAu", code: "55" },
      { id: 3791, name: "B\u1EAFc Ninh", code: "56" },
      { id: 3796, name: "B\u1EBFn Tre", code: "50" },
      { id: 3785, name: "B\xECnh D\u01B0\u01A1ng", code: "57" },
      { id: 3830, name: "B\xECnh \u0110\u1ECBnh", code: "31" },
      { id: 3797, name: "B\xECnh Ph\u01B0\u1EDBc", code: "58" },
      { id: 3787, name: "B\xECnh Thu\u1EADn", code: "40" },
      { id: 3778, name: "C\xE0 Mau", code: "59" },
      { id: 4925, name: "C\u1EA7n Th\u01A1", code: "CT" },
      { id: 3782, name: "Cao B\u1EB1ng", code: "04" },
      { id: 3806, name: "\u0110\xE0 N\u1EB5ng", code: "DN" },
      { id: 3829, name: "\u0110\u1EAFk L\u1EAFk", code: "33" },
      { id: 3823, name: "\u0110\u1EAFk N\xF4ng", code: "72" },
      { id: 3773, name: "\u0110i\u1EC7n Bi\xEAn", code: "71" },
      { id: 3821, name: "\u0110\u1ED3ng Nai", code: "39" },
      { id: 3769, name: "\u0110\u1ED3ng Th\xE1p", code: "45" },
      { id: 3813, name: "Gia Lai", code: "30" },
      { id: 3779, name: "H\xE0 Giang", code: "03" },
      { id: 3802, name: "H\xE0 Nam", code: "63" },
      { id: 3810, name: "H\xE0 N\u1ED9i", code: "HN" },
      { id: 3816, name: "H\xE0 T\u0129nh", code: "23" },
      { id: 3827, name: "H\u1EA3i D\u01B0\u01A1ng", code: "61" },
      { id: 3783, name: "H\u1EA3i Ph\xF2ng", code: "HP" },
      { id: 3777, name: "H\u1EADu Giang", code: "73" },
      { id: 3811, name: "H\u1ED3 Ch\xED Minh", code: "SG" },
      { id: 3799, name: "H\xF2a B\xECnh", code: "14" },
      { id: 3768, name: "H\u01B0ng Y\xEAn", code: "66" },
      { id: 3793, name: "Kh\xE1nh H\xF2a", code: "34" },
      { id: 3800, name: "Ki\xEAn Giang", code: "47" },
      { id: 3772, name: "Kon Tum", code: "28" },
      { id: 3825, name: "Lai Ch\xE2u", code: "01" },
      { id: 3818, name: "L\xE2m \u0110\u1ED3ng", code: "35" },
      { id: 3792, name: "L\u1EA1ng S\u01A1n", code: "09" },
      { id: 3817, name: "L\xE0o Cai", code: "02" },
      { id: 3808, name: "Long An", code: "41" },
      { id: 3789, name: "Nam \u0110\u1ECBnh", code: "67" },
      { id: 3780, name: "Ngh\u1EC7 An", code: "22" },
      { id: 3786, name: "Ninh B\xECnh", code: "18" },
      { id: 3788, name: "Ninh Thu\u1EADn", code: "36" },
      { id: 3801, name: "Ph\xFA Th\u1ECD", code: "68" },
      { id: 3824, name: "Ph\xFA Y\xEAn", code: "32" },
      { id: 3809, name: "Qu\u1EA3ng B\xECnh", code: "24" },
      { id: 3776, name: "Qu\u1EA3ng Nam", code: "27" },
      { id: 3828, name: "Qu\u1EA3ng Ng\xE3i", code: "29" },
      { id: 3814, name: "Qu\u1EA3ng Ninh", code: "13" },
      { id: 3803, name: "Qu\u1EA3ng Tr\u1ECB", code: "25" },
      { id: 3819, name: "S\xF3c Tr\u0103ng", code: "52" },
      { id: 3812, name: "S\u01A1n La", code: "05" },
      { id: 3826, name: "T\xE2y Ninh", code: "37" },
      { id: 3775, name: "Th\xE1i B\xECnh", code: "20" },
      { id: 3807, name: "Th\xE1i Nguy\xEAn", code: "69" },
      { id: 3771, name: "Thanh H\xF3a", code: "21" },
      { id: 3798, name: "Th\u1EEBa Thi\xEAn-Hu\u1EBF", code: "26" },
      { id: 3781, name: "Ti\u1EC1n Giang", code: "46" },
      { id: 3805, name: "Tr\xE0 Vinh", code: "51" },
      { id: 3795, name: "Tuy\xEAn Quang", code: "07" },
      { id: 3790, name: "V\u0129nh Long", code: "49" },
      { id: 3774, name: "V\u0129nh Ph\xFAc", code: "70" },
      { id: 3784, name: "Y\xEAn B\xE1i", code: "06" }
    ]
  },
  { code: "VGB", name: "Virgin Islands (British)", states: [] },
  {
    code: "VIR",
    name: "Virgin Islands (US)",
    states: [
      { id: 5074, name: "Saint Croix", code: "SC" },
      { id: 5073, name: "Saint John", code: "SJ" },
      { id: 5072, name: "Saint Thomas", code: "ST" }
    ]
  },
  { code: "WLF", name: "Wallis And Futuna Islands", states: [] },
  { code: "ESH", name: "Western Sahara", states: [] },
  {
    code: "YEM",
    name: "Yemen",
    states: [
      { id: 1242, name: "'Adan", code: "AD" },
      { id: 1250, name: "'Amran", code: "AM" },
      { id: 1237, name: "Abyan", code: "AB" },
      { id: 1240, name: "Al Bayda'", code: "BA" },
      { id: 1241, name: "Al Hudaydah", code: "HU" },
      { id: 1243, name: "Al Jawf", code: "JA" },
      { id: 1251, name: "Al Mahrah", code: "MR" },
      { id: 1235, name: "Al Mahwit", code: "MW" },
      { id: 1232, name: "Amanat Al Asimah", code: "SA" },
      { id: 1246, name: "Dhamar", code: "DH" },
      { id: 1238, name: "Hadhramaut", code: "HD" },
      { id: 1244, name: "Hajjah", code: "HJ" },
      { id: 1233, name: "Ibb", code: "IB" },
      { id: 1245, name: "Lahij", code: "LA" },
      { id: 1234, name: "Ma'rib", code: "MA" },
      { id: 1248, name: "Raymah", code: "RA" },
      { id: 1249, name: "Saada", code: "SD" },
      { id: 1236, name: "Sana'a", code: "SN" },
      { id: 1247, name: "Shabwah", code: "SH" },
      { id: 1239, name: "Socotra", code: "SU" },
      { id: 1231, name: "Ta'izz", code: "TA" }
    ]
  },
  {
    code: "ZMB",
    name: "Zambia",
    states: [
      { id: 1986, name: "Central Province", code: "02" },
      { id: 1984, name: "Copperbelt Province", code: "08" },
      { id: 1991, name: "Eastern Province", code: "03" },
      { id: 1987, name: "Luapula Province", code: "04" },
      { id: 1988, name: "Lusaka Province", code: "09" },
      { id: 1989, name: "Muchinga Province", code: "10" },
      { id: 1982, name: "Northern Province", code: "05" },
      { id: 1985, name: "Northwestern Province", code: "06" },
      { id: 1990, name: "Southern Province", code: "07" },
      { id: 1983, name: "Western Province", code: "01" }
    ]
  },
  {
    code: "ZWE",
    name: "Zimbabwe",
    states: [
      { id: 1956, name: "Bulawayo Province", code: "BU" },
      { id: 1958, name: "Harare Province", code: "HA" },
      { id: 1959, name: "Manicaland", code: "MA" },
      { id: 1955, name: "Mashonaland Central Province", code: "MC" },
      { id: 1951, name: "Mashonaland East Province", code: "ME" },
      { id: 1953, name: "Mashonaland West Province", code: "MW" },
      { id: 1960, name: "Masvingo Province", code: "MV" },
      { id: 1954, name: "Matabeleland North Province", code: "MN" },
      { id: 1952, name: "Matabeleland South Province", code: "MS" },
      { id: 1957, name: "Midlands Province", code: "MI" }
    ]
  }
];
var DEFAULT_CURRENCY = "ARS";
var DEFAULT_LOCALE = "es-AR";
var DEFAULT_COUNTRY_CODE = "ARG";
var countryCodesAndName = () => countryAndStates.map((c) => {
  return {
    code: c.code,
    name: c.name
  };
});

// src/rfp.ts
var MAX_RFP_FILES = 5;

// src/states.ts
var argentinaStates = [
  {
    nombre_completo: "Provincia de Misiones",
    fuente: "IGN",
    iso_id: "AR-N",
    nombre: "Misiones",
    id: "54",
    categoria: "Provincia",
    iso_nombre: "Misiones",
    centroide: { lat: -26.8753965086829, lon: -54.6516966230371 }
  },
  {
    nombre_completo: "Provincia de San Luis",
    fuente: "IGN",
    iso_id: "AR-D",
    nombre: "San Luis",
    id: "74",
    categoria: "Provincia",
    iso_nombre: "San Luis",
    centroide: { lat: -33.7577257449137, lon: -66.0281298195836 }
  },
  {
    nombre_completo: "Provincia de San Juan",
    fuente: "IGN",
    iso_id: "AR-J",
    nombre: "San Juan",
    id: "70",
    categoria: "Provincia",
    iso_nombre: "San Juan",
    centroide: { lat: -30.8653679979618, lon: -68.8894908486844 }
  },
  {
    nombre_completo: "Provincia de Entre R\xEDos",
    fuente: "IGN",
    iso_id: "AR-E",
    nombre: "Entre R\xEDos",
    id: "30",
    categoria: "Provincia",
    iso_nombre: "Entre R\xEDos",
    centroide: { lat: -32.0588735436448, lon: -59.2014475514635 }
  },
  {
    nombre_completo: "Provincia de Santa Cruz",
    fuente: "IGN",
    iso_id: "AR-Z",
    nombre: "Santa Cruz",
    id: "78",
    categoria: "Provincia",
    iso_nombre: "Santa Cruz",
    centroide: { lat: -48.8154851827063, lon: -69.9557621671973 }
  },
  {
    nombre_completo: "Provincia de R\xEDo Negro",
    fuente: "IGN",
    iso_id: "AR-R",
    nombre: "R\xEDo Negro",
    id: "62",
    categoria: "Provincia",
    iso_nombre: "R\xEDo Negro",
    centroide: { lat: -40.4057957178801, lon: -67.229329893694 }
  },
  {
    nombre_completo: "Provincia del Chubut",
    fuente: "IGN",
    iso_id: "AR-U",
    nombre: "Chubut",
    id: "26",
    categoria: "Provincia",
    iso_nombre: "Chubut",
    centroide: { lat: -43.7886233529878, lon: -68.5267593943345 }
  },
  {
    nombre_completo: "Provincia de C\xF3rdoba",
    fuente: "IGN",
    iso_id: "AR-X",
    nombre: "C\xF3rdoba",
    id: "14",
    categoria: "Provincia",
    iso_nombre: "C\xF3rdoba",
    centroide: { lat: -32.142932663607, lon: -63.8017532741662 }
  },
  {
    nombre_completo: "Provincia de Mendoza",
    fuente: "IGN",
    iso_id: "AR-M",
    nombre: "Mendoza",
    id: "50",
    categoria: "Provincia",
    iso_nombre: "Mendoza",
    centroide: { lat: -34.6298873058957, lon: -68.5831228183798 }
  },
  {
    nombre_completo: "Provincia de La Rioja",
    fuente: "IGN",
    iso_id: "AR-F",
    nombre: "La Rioja",
    id: "46",
    categoria: "Provincia",
    iso_nombre: "La Rioja",
    centroide: { lat: -29.685776298315, lon: -67.1817359694432 }
  },
  {
    nombre_completo: "Provincia de Catamarca",
    fuente: "IGN",
    iso_id: "AR-K",
    nombre: "Catamarca",
    id: "10",
    categoria: "Provincia",
    iso_nombre: "Catamarca",
    centroide: { lat: -27.3358332810217, lon: -66.9476824299928 }
  },
  {
    nombre_completo: "Provincia de La Pampa",
    fuente: "IGN",
    iso_id: "AR-L",
    nombre: "La Pampa",
    id: "42",
    categoria: "Provincia",
    iso_nombre: "La Pampa",
    centroide: { lat: -37.1315537735949, lon: -65.4466546606951 }
  },
  {
    nombre_completo: "Provincia de Santiago del Estero",
    fuente: "IGN",
    iso_id: "AR-G",
    nombre: "Santiago del Estero",
    id: "86",
    categoria: "Provincia",
    iso_nombre: "Santiago del Estero",
    centroide: { lat: -27.7824116550944, lon: -63.2523866568588 }
  },
  {
    nombre_completo: "Provincia de Corrientes",
    fuente: "IGN",
    iso_id: "AR-W",
    nombre: "Corrientes",
    id: "18",
    categoria: "Provincia",
    iso_nombre: "Corrientes",
    centroide: { lat: -28.7743047046407, lon: -57.8012191977913 }
  },
  {
    nombre_completo: "Provincia de Santa Fe",
    fuente: "IGN",
    iso_id: "AR-S",
    nombre: "Santa Fe",
    id: "82",
    categoria: "Provincia",
    iso_nombre: "Santa Fe",
    centroide: { lat: -30.7069271588117, lon: -60.9498369430241 }
  },
  {
    nombre_completo: "Provincia de Tucum\xE1n",
    fuente: "IGN",
    iso_id: "AR-T",
    nombre: "Tucum\xE1n",
    id: "90",
    categoria: "Provincia",
    iso_nombre: "Tucum\xE1n",
    centroide: { lat: -26.9478001830786, lon: -65.3647579441481 }
  },
  {
    nombre_completo: "Provincia del Neuqu\xE9n",
    fuente: "IGN",
    iso_id: "AR-Q",
    nombre: "Neuqu\xE9n",
    id: "58",
    categoria: "Provincia",
    iso_nombre: "Neuqu\xE9n",
    centroide: { lat: -38.6417575824599, lon: -70.1185705180601 }
  },
  {
    nombre_completo: "Provincia de Salta",
    fuente: "IGN",
    iso_id: "AR-A",
    nombre: "Salta",
    id: "66",
    categoria: "Provincia",
    iso_nombre: "Salta",
    centroide: { lat: -24.2991344492002, lon: -64.8144629600627 }
  },
  {
    nombre_completo: "Provincia del Chaco",
    fuente: "IGN",
    iso_id: "AR-H",
    nombre: "Chaco",
    id: "22",
    categoria: "Provincia",
    iso_nombre: "Chaco",
    centroide: { lat: -26.3864309061226, lon: -60.7658307438603 }
  },
  {
    nombre_completo: "Provincia de Formosa",
    fuente: "IGN",
    iso_id: "AR-P",
    nombre: "Formosa",
    id: "34",
    categoria: "Provincia",
    iso_nombre: "Formosa",
    centroide: { lat: -24.894972594871, lon: -59.9324405800872 }
  },
  {
    nombre_completo: "Provincia de Jujuy",
    fuente: "IGN",
    iso_id: "AR-Y",
    nombre: "Jujuy",
    id: "38",
    categoria: "Provincia",
    iso_nombre: "Jujuy",
    centroide: { lat: -23.3200784211351, lon: -65.7642522180337 }
  },
  {
    nombre_completo: "Ciudad Aut\xF3noma de Buenos Aires",
    fuente: "IGN",
    iso_id: "AR-C",
    nombre: "Ciudad Aut\xF3noma de Buenos Aires",
    id: "02",
    categoria: "Ciudad Aut\xF3noma",
    iso_nombre: "Ciudad Aut\xF3noma de Buenos Aires",
    centroide: { lat: -34.6144934119689, lon: -58.4458563545429 }
  },
  {
    nombre_completo: "Provincia de Buenos Aires",
    fuente: "IGN",
    iso_id: "AR-B",
    nombre: "Buenos Aires",
    id: "06",
    categoria: "Provincia",
    iso_nombre: "Buenos Aires",
    centroide: { lat: -36.6769415180527, lon: -60.5588319815719 }
  },
  {
    nombre_completo: "Provincia de Tierra del Fuego, Ant\xE1rtida e Islas del Atl\xE1ntico Sur",
    fuente: "IGN",
    iso_id: "AR-V",
    nombre: "Tierra del Fuego, Ant\xE1rtida e Islas del Atl\xE1ntico Sur",
    id: "94",
    categoria: "Provincia",
    iso_nombre: "Tierra del Fuego",
    centroide: { lat: -82.52151781221, lon: -50.7427486049785 }
  }
];

// src/cities.ts
var argentinaCities = {
  B: [
    "ALBERTI",
    "CORONEL SEGUI",
    "MECHITA",
    "PLA",
    "VILLA GRISOLIA",
    "VILLA MARIA",
    "VILLA ORTIZ",
    "ADROGUE",
    "BURZACO",
    "CLAYPOLE",
    "DON ORIONE",
    "GLEW",
    "JOSE MARMOL",
    "LONGCHAMPS",
    "MALVINAS ARGENTINAS",
    "MINISTRO RIVADAVIA",
    "RAFAEL CALZADA",
    "SAN JOSE",
    "AREA CINTURON ECOLOGICO",
    "AVELLANEDA",
    "CRUCESITA",
    "DOCK SUD",
    "GERLI",
    "PI\xD1EYRO",
    "SARANDI",
    "VILLA DOMINICO",
    "WILDE",
    "AYACUCHO",
    "LA CONSTANCIA",
    "SOLANET",
    "UDAQUIOLA",
    "ARIEL",
    "AZUL",
    "CACHARI",
    "CHILLAR",
    "16 DE JULIO",
    "BAHIA BLANCA",
    "GR\xDCNBEIN",
    "INGENIERO WHITE",
    "VILLA BORDEAU",
    "VILLA ESPORA",
    "CABILDO",
    "GENERAL DANIEL CERRI",
    "BALCARCE",
    "LOS PINOS",
    "NAPALEOFU",
    "RAMOS OTERO",
    "SAN AGUSTIN",
    "VILLA LAGUNA LA BRAVA",
    "BARADERO",
    "IRINEO PORTELA",
    "SANTA COLOMA",
    "VILLA ALSINA",
    "ARRECIFES",
    "TODD",
    "VI\xA5A",
    "BARKER",
    "BENITO JUAREZ",
    "LOPEZ",
    "TEDIN URIBURU",
    "VILLA CACIQUE",
    "BERAZATEGUI",
    "BERAZATEGUI OESTE",
    "CARLOS TOMAS SOURIGUES",
    "EL PATO",
    "GUILLERMO ENRIQUE HUDSON",
    "JUAN MARIA GUTIERREZ",
    "PEREYRA",
    "PLATANOS",
    "RANELAGH",
    "VILLA ESPA\xD1A",
    "BARRIO BANCO PROVINCIA",
    "BARRIO EL CARMEN (ESTE)",
    "BARRIO UNIVERSITARIO",
    "BERISSO",
    "LOS TALAS",
    "VILLA ARG\xDCELLO",
    "VILLA DOLORES",
    "VILLA INDEPENDENCIA",
    "VILLA NUEVA",
    "VILLA PORTE\xD1A",
    "VILLA PROGRESO",
    "VILLA SAN CARLOS",
    "VILLA ZULA",
    "HALE",
    "JUAN F. IBARRA",
    "PAULA",
    "PIROVANO",
    "SAN CARLOS DE BOLIVAR",
    "URDAMPILLETA",
    "VILLA LYNCH PUEYRREDON",
    "ASAMBLEA",
    "BRAGADO",
    "COMODORO PY",
    "GENERAL O'BRIEN",
    "IRALA",
    "LA LIMPIA",
    "MAXIMO FERNANDEZ",
    "OLASCOAGA",
    "WARNES",
    "ALTAMIRANO",
    "BARRIO EL MIRADOR",
    "BARRIO LAS GOLONDRINAS",
    "BARRIO LOS BOSQUECITOS",
    "BARRIO PARQUE LAS ACACIAS",
    "CAMPOS DE ROCA",
    "CORONEL BRANDSEN",
    "CLUB DE CAMPO LAS MALVINAS",
    "GOMEZ",
    "JEPPENER",
    "OLIDEN",
    "POSADA DE LOS LAGOS",
    "SAMBOROMBON",
    "ALTO LOS CARDALES",
    "BARRIO LOS PIONEROS",
    "CAMPANA",
    "CHACRAS DEL RIO LUJAN",
    "LOMAS DEL RIO LUJAN",
    "ALEJANDRO PETION",
    "BARRIO EL TALADRO",
    "CA\xA5UELAS",
    "GOBERNADOR UDAONDO",
    "BARRIO BELGRANO",
    "MAXIMO PAZ",
    "SANTA ROSA",
    "URIBELARREA",
    "VICENTE CASARES",
    "CAPITAN SARMIENTO",
    "LA LUISA",
    "BELLOCQ",
    "CADRET",
    "CARLOS CASARES",
    "COLONIA MAURICIO",
    "HORTENSIA",
    "LA SOFIA",
    "MAURICIO HIRSCH",
    "MOCTEZUMA",
    "ORDOQUI",
    "SANTO TOMAS",
    "SMITH",
    "CARLOS TEJEDOR",
    "COLONIA SERE",
    "CURARU",
    "TIMOTE",
    "TRES ALGARROBOS",
    "CARMEN DE ARECO",
    "PUEBLO GOUIN",
    "TRES SARGENTOS",
    "CASTELLI",
    "CENTRO GUERRERO",
    "CERRO DE LA GLORIA (CANAL 15)",
    "COLON",
    "EL ARBOLITO",
    "PEARSON",
    "SARASA",
    "BAJO HONDO",
    "BALNEARIO PEHUEN CO",
    "PAGO CHICO",
    "PUNTA ALTA",
    "VILLA DEL MAR",
    "VILLA GENERAL ARIAS",
    "APARICIO",
    "BALNEARIO MARISOL",
    "CORONEL DORREGO",
    "EL PERDIDO",
    "FARO",
    "IRENE",
    "ORIENTE",
    "LA RUTA",
    "SAN ROMAN",
    "CORONEL PRINGLES",
    "EL DIVISORIO",
    "EL PENSAMIENTO",
    "INDIO RICO",
    "LARTIGAU",
    "CASCADAS",
    "CORONEL SUAREZ",
    "CURA MALAL",
    "D'ORBIGNY",
    "HUANGUELEN",
    "PASMAN",
    "SANTA MARIA",
    "SANTA TRINIDAD",
    "VILLA LA ARCADIA",
    "CASTILLA",
    "CHACABUCO",
    "LOS ANGELES",
    "O'HIGGINS",
    "RAWSON",
    "BARRIO LOMAS ALTAS",
    "CHASCOMUS",
    "BARRIO SAN CAYETANO",
    "LAGUNA VITEL",
    "MANUEL J. COBO",
    "VILLA PARQUE GIRADO",
    "BENITEZ",
    "CHIVILCOY",
    "EMILIO AYARZA",
    "GOROSTIAGA",
    "LA RICA",
    "MOQUEHUA",
    "RAMON BIAUS",
    "SAN SEBASTIAN",
    "ANDANT",
    "ARBOLEDAS",
    "DAIREAUX",
    "LA LARGA",
    "SALAZAR",
    "DOLORES",
    "SEVIGNE",
    "DIQUE N\xBA 1",
    "ENSENADA",
    "ISLA SANTIAGO (OESTE)",
    "PUNTA LARA",
    "VILLA CATELA",
    "BELEN DE ESCOBAR",
    "EL CAZADOR",
    "GARIN",
    "INGENIERO MASCHWITZ",
    "LOMA VERDE",
    "MATHEU",
    "MAQUINISTA F. SAVIO ESTE",
    "CANNING",
    "EL JAG\xDCEL",
    "LUIS GUILLON",
    "MONTE GRANDE",
    "9 DE ABRIL",
    "ARROYO DE LA CRUZ",
    "CAPILLA DEL SE\xA5OR",
    "DIEGO GAYNOR",
    "LOS CARDALES",
    "PARADA ORLANDO",
    "EL REMANSO",
    "PARADA ROBLES",
    "PAVON",
    "AEROPUERTO INTERNACIONAL EZEIZA",
    "CARLOS SPEGAZZINI",
    "JOSE MARIA EZEIZA",
    "LA UNION",
    "TRISTAN SUAREZ",
    "BOSQUES",
    "ESTANISLAO SEVERO ZEBALLOS",
    "FLORENCIO VARELA",
    "GOBERNADOR JULIO A. COSTA",
    "INGENIERO JUAN ALLAN",
    "VILLA BROWN",
    "VILLA SAN LUIS",
    "VILLA SANTA ROSA",
    "VILLA VATTEONE",
    "EL TROPEZON",
    "LA CAPILLA",
    "BLAQUIER",
    "FLORENTINO AMEGHINO",
    "PORVENIR",
    "CENTINELA DEL MAR",
    "COMANDANTE NICANOR OTAMENDI",
    "MAR DEL SUR",
    "MECHONGUE",
    "MIRAMAR",
    "GENERAL ALVEAR",
    "ARRIBE\xA5OS",
    "ASCENSION",
    "ESTACION ARENALES",
    "FERRE",
    "GENERAL ARENALES",
    "LA ANGELITA",
    "LA TRINIDAD",
    "GENERAL BELGRANO",
    "GORCHS",
    "GENERAL GUIDO",
    "LABARDEN",
    "BARRIO KENNEDY",
    "GENERAL JUAN MADARIAGA",
    "GENERAL LA MADRID",
    "LA COLINA",
    "LAS MARTINETAS",
    "LIBANO",
    "PONTAUT",
    "GENERAL HORNOS",
    "GENERAL LAS HERAS",
    "LA CHOZA",
    "PLOMER",
    "VILLARS",
    "GENERAL LAVALLE",
    "BARRIO RIO SALADO",
    "RANCHOS",
    "VILLANUEVA",
    "COLONIA SAN RICARDO",
    "GENERAL PINTO",
    "GERMANIA",
    "GUNTHER",
    "VILLA FRANCIA",
    "VILLA ROTH",
    "BARRIO EL BOQUERON",
    "BARRIO LA GLORIA",
    "BARRIO SANTA PAULA",
    "BATAN",
    "CHAPADMALAL",
    "EL MARQUESADO",
    "ESTACION CHAPADMALAL",
    "CAMET",
    "ESTACION CAMET",
    "MAR DEL PLATA",
    "PUNTA MOGOTES",
    "BARRIO EL CASAL",
    "SIERRA DE LOS PADRES",
    "BARRIO COLINAS VERDES",
    "BARRIO EL COYUNCO",
    "GENERAL RODRIGUEZ",
    "BARRIO MORABO",
    "BARRIO RUTA 24 KM. 10",
    "C.C. BOSQUE REAL",
    "GENERAL RODRGUEZ",
    "BARRIO PARQUE GENERAL SAN MARTIN",
    "BILLINGHURST",
    "CIUDAD DEL LIBERTADOR GENERAL SAN MARTIN",
    "CIUDAD JARDIN EL LIBERTADOR",
    "VILLA AYACUCHO",
    "VILLA BALLESTER",
    "VILLA BERNARDO MONTEAGUDO",
    "VILLA CHACABUCO",
    "VILLA CORONEL JOSE M. ZAPIOLA",
    "VILLA GENERAL ANTONIO J. DE SUCRE",
    "VILLA GENERAL EUGENIO NECOCHEA",
    "VILLA GENERAL JOSE TOMAS GUIDO",
    "VILLA GENERAL JUAN G. LAS HERAS",
    "VILLA GODOY CRUZ",
    "VILLA GRANADEROS DE SAN MARTIN",
    "VILLA GREGORIA MATORRAS",
    "VILLA JOSE LEON SUAREZ",
    "VILLA JUAN MARTIN DE PUEYRREDON",
    "VILLA LIBERTAD",
    "VILLA LYNCH",
    "VILLA MAIPU",
    "VILLA MARIA IRENE DE LOS REMEDIOS DE ESCALADA",
    "VILLA MARQUES ALEJANDRO MARIA DE AGUADO",
    "VILLA PARQUE PRESIDENTE FIGUEROA ALCORTA",
    "VILLA PARQUE SAN LORENZO",
    "VILLA SAN ANDRES",
    "VILLA YAPEYU",
    "BAIGORRITA",
    "LA DELFINA",
    "LOS TOLDOS",
    "SAN EMILIO",
    "ZAVALIA",
    "BANDERALO",
    "CA\xA5ADA SECA",
    "CORONEL CHARLONE",
    "EMILIO V. BUNGE",
    "GENERAL VILLEGAS",
    "MASSEY",
    "PICHINCHA",
    "PIEDRITAS",
    "SANTA ELEODORA",
    "SANTA REGINA",
    "VILLA SABOYA",
    "VILLA SAUZE",
    "ARROYO VENADO",
    "CASBAS",
    "GARRE",
    "GUAMINI",
    "LAGUNA ALSINA",
    "HENDERSON",
    "HERRERA VEGAS",
    "HURLINGHAM",
    "VILLA SANTOS TESEI",
    "WILLIAM C. MORRIS",
    "ITUZAINGO CENTRO",
    "ITUZAINGO SUR",
    "VILLA GOBERNADOR UDAONDO",
    "DEL VISO",
    "JOSE C. PAZ",
    "AGUSTIN ROCA",
    "AGUSTINA",
    "BALNEARIO LAGUNA DE GOMEZ",
    "FORTIN TIBURCIO",
    "JUNIN",
    "LA AGRARIA",
    "LAPLACETTE",
    "MORSE",
    "SAFORCADA",
    "LAS TONINAS",
    "AGUAS VERDES",
    "LUCILA DEL MAR",
    "MAR DE AJO",
    "MAR DE AJO NORTE",
    "SAN BERNARDO",
    "SAN CLEMENTE DEL TUYU",
    "MAR DEL TUYU",
    "SANTA TERESITA",
    "ALDO BONZI",
    "CIUDAD EVITA",
    "GONZALEZ CATAN",
    "GREGORIO DE LAFERRERE",
    "ISIDRO CASANOVA",
    "LA TABLADA",
    "LOMAS DEL MIRADOR",
    "RAFAEL CASTILLO",
    "RAMOS MEJIA",
    "SAN JUSTO",
    "TAPIALES",
    "20 DE JUNIO",
    "VILLA EDUARDO MADERO",
    "VILLA LUZURIAGA",
    "VIRREY DEL PINO",
    "LANUS ESTE",
    "LANUS OESTE",
    "MONTE CHINGOLO",
    "REMEDIOS ESCALADA DE SAN MARTIN",
    "VALENTIN ALSINA",
    "COUNTRY CLUB EL RODEO",
    "IGNACIO CORREAS",
    "ABASTO",
    "ANGEL ETCHEVERRY",
    "ARANA",
    "ARTURO SEGUI",
    "BARRIO EL CARMEN (OESTE)",
    "BARRIO GAMBIER",
    "BARRIO LAS MALVINAS",
    "BARRIO LAS QUINTAS",
    "CITY BELL",
    "EL RETIRO",
    "JOAQUIN GORINA",
    "JOSE HERNANDEZ",
    "JOSE MELCHOR ROMERO",
    "LA CUMBRE",
    "LA PLATA",
    "LISANDRO OLMOS",
    "LOS HORNOS",
    "MANUEL B. GONNET",
    "RINGUELET",
    "RUFINO DE ELIZALDE",
    "TOLOSA",
    "TRANSRADIO",
    "VILLA ELISA",
    "VILLA ELVIRA",
    "VILLA GARIBALDI",
    "VILLA MONTORO",
    "VILLA PARQUE SICARDI",
    "LOMAS DE COPELLO",
    "BARRIO RUTA SOL",
    "LAPRIDA",
    "PUEBLO NUEVO",
    "PUEBLO SAN JORGE",
    "CORONEL BOERR",
    "EL TRIGO",
    "LAS FLORES",
    "PARDO",
    "ALBERDI VIEJO",
    "EL DORADO",
    "FORTIN ACHA",
    "JUAN BAUTISTA ALBERDI",
    "LEANDRO N. ALEM",
    "VEDIA",
    "ARENAZA",
    "BAYAUCA",
    "BERMUDEZ",
    "CARLOS SALAS",
    "CORONEL MARTINEZ DE HOZ",
    "EL TRIUNFO",
    "LAS TOSCAS",
    "LINCOLN",
    "PASTEUR",
    "ROBERTS",
    "TRIUNVIRATO",
    "ARENAS VERDES",
    "LICENCIADO MATIENZO",
    "LOBERIA",
    "PIERES",
    "SAN MANUEL",
    "TAMANGUEYU",
    "ANTONIO CARBONI",
    "ELVIRA",
    "LAGUNA DE LOBOS",
    "LOBOS",
    "SALVADOR MARIA",
    "BANFIELD",
    "LLAVALLOL",
    "LOMAS DE ZAMORA",
    "TEMPERLEY",
    "TURDERA",
    "VILLA CENTENARIO",
    "VILLA FIORITO",
    "CARLOS KEEN",
    "CLUB DE CAMPO LOS PUENTES",
    "LUJAN",
    "BARRIO LAS CASUARINAS",
    "CORTINES",
    "LEZICA Y TORREZURI",
    "VILLA FLANDRIA NORTE (PUEBLO NUEVO)",
    "VILLA FLANDRIA SUR (EST. JAUREGUI)",
    "COUNTRY CLUB LAS PRADERAS",
    "OPEN DOOR",
    "OLIVERA",
    "TORRES",
    "ATALAYA",
    "GENERAL MANSILLA",
    "LOS NARANJOS",
    "MAGDALENA",
    "ROBERTO J. PAYRO",
    "VIEYTES",
    "LAS ARMAS",
    "MAIPU",
    "SANTO DOMINGO",
    "AREA DE PROMOCION EL TRIANGULO",
    "GRAND BOURG",
    "INGENIERO ADOLFO SOURDEAUX",
    "INGENIERO PABLO NOGUES",
    "LOS POLVORINES",
    "TORTUGUITAS",
    "VILLA DE MAYO",
    "CORONEL VIDAL",
    "GENERAL PIRAN",
    "LA ARMONIA",
    "MAR CHIQUITA",
    "LA BALIZA",
    "LA CALETA",
    "MAR DE COBO",
    "ATLANTIDA",
    "CAMET NORTE",
    "FRENTE MAR",
    "PLAYA DORADA",
    "SANTA CLARA DEL MAR",
    "SANTA ELENA",
    "VIVORATA",
    "BARRIO SANTA ROSA",
    "BARRIOS LISANDRO DE LA TORRE Y SANTA MARTA",
    "MARCOS PAZ",
    "GOLDNEY",
    "GOWLAND",
    "MERCEDES",
    "TOMAS JOFRE",
    "LIBERTAD",
    "MARIANO ACOSTA",
    "MERLO",
    "PONTEVEDRA",
    "SAN ANTONIO DE PADUA",
    "ABBOTT",
    "SAN MIGUEL DEL MONTE",
    "ZENON VIDELA DORNA",
    "BALNEARIO SAUCE GRANDE",
    "MONTE HERMOSO",
    "CUARTEL V",
    "FRANCISCO ALVAREZ",
    "LA REJA",
    "MORENO",
    "PASO DEL REY",
    "TRUJUI",
    "CASTELAR",
    "EL PALOMAR",
    "HAEDO",
    "MORON",
    "VILLA SARMIENTO",
    "JOSE JUAN ALMEYRA",
    "LAS MARIANAS",
    "NAVARRO",
    "VILLA MOLL",
    "CLARAZ",
    "ENERGIA",
    "JUAN N. FERNANDEZ",
    "NECOCHEA",
    "QUEQUEN",
    "COSTA BONITA",
    "NICANOR OLIVERA",
    "RAMON SANTAMARINA",
    "ALFREDO DEMARCHI",
    "CARLOS MARIA NAON",
    "12 DE OCTUBRE",
    "DUDIGNAC",
    "LA AURORA",
    "MARCELINO UGARTE",
    "MOREA",
    "NORUMBEGA",
    "9 DE JULIO",
    "PATRICIOS",
    "VILLA GENERAL FOURNIER",
    "BLANCAGRANDE",
    "COLONIA NIEVAS",
    "COLONIA SAN MIGUEL",
    "ESPIGAS",
    "HINOJO",
    "COLONIA HINOJO",
    "OLAVARRIA",
    "RECALDE",
    "SANTA LUISA",
    "SIERRA CHICA",
    "SIERRAS BAYAS",
    "VILLA ARRIETA",
    "VILLA ALFREDO FORTABAT",
    "VILLA LA SERRANIA",
    "BAHIA SAN BLAS",
    "CARDENAL CAGLIERO",
    "CARMEN DE PATAGONES",
    "JOSE B. CASAS",
    "JUAN A. PRADERE",
    "STROEDER",
    "VILLALONGA",
    "CAPITAN CASTRO",
    "CHICLANA",
    "FRANCISCO MADERO",
    "INOCENCIO SOSA",
    "JUAN JOSE PASO",
    "MAGDALA",
    "MONES CAZON",
    "NUEVA PLATA",
    "PEHUAJO",
    "BOCAYUVA",
    "DE BARY",
    "PELLEGRINI",
    "ACEVEDO",
    "FONTEZUELA",
    "GUERRICO",
    "JUAN A. DE LA PE\xA5A",
    "JUAN ANCHORENA",
    "LA VIOLETA",
    "MANUEL OCAMPO",
    "MARIANO BENITEZ",
    "MARIANO H. ALFONZO",
    "PERGAMINO",
    "PINZON",
    "RANCAGUA",
    "VILLA ANGELICA",
    "VILLA SAN JOSE",
    "CASALINS",
    "PILA",
    "FATIMA",
    "LA LONJA",
    "LOS CACHORROS",
    "MANZANARES",
    "MANZONE",
    "MAQUINISTA F. SAVIO (OESTE)",
    "PILAR",
    "PRESIDENTE DERQUI",
    "ROBERTO DE VICENZO",
    "SANTA TERESA",
    "VILLA ASTOLFI",
    "VILLA ROSA",
    "ZELAYA",
    "CARILO",
    "OSTENDE",
    "PINAMAR",
    "VALERIA DEL MAR",
    "BARRIO AMERICA UNIDA",
    "GUERNICA",
    "AZOPARDO",
    "BORDENAVE",
    "DARREGUEIRA",
    "17 DE AGOSTO",
    "ESTELA",
    "FELIPE SOLA",
    "LOPEZ LECUBE",
    "PUAN",
    "SAN GERMAN",
    "VILLA CASTELAR",
    "VILLA IRIS",
    "ALVAREZ JONTE",
    "PIPINAS",
    "PUNTA INDIO",
    "VERONICA",
    "BERNAL",
    "BERNAL OESTE",
    "DON BOSCO",
    "EZPELETA",
    "EZPELETA OESTE",
    "QUILMES",
    "QUILMES OESTE",
    "SAN FRANCISCO SOLANO",
    "VILLA LA FLORIDA",
    "EL PARAISO",
    "LAS BAHAMAS",
    "PEREZ MILLAN",
    "RAMALLO",
    "VILLA GENERAL SAVIO",
    "VILLA RAMALLO",
    "RAUCH",
    "AMERICA",
    "FORTIN OLAVARRIA",
    "GONZALEZ MORENO",
    "MIRA PAMPA",
    "ROOSEVELT",
    "SAN MAURICIO",
    "SANSINENA",
    "SUNDBLAD",
    "LA BEBA",
    "LAS CARABELAS",
    "LOS INDIOS",
    "RAFAEL OBLIGADO",
    "ROBERTO CANO",
    "BARRIO LAS MARGARITAS",
    "ROJAS",
    "VILLA PARQUE CECIR",
    "ESTACION SOL DE MAYO",
    "VILLA MANUEL POMAR",
    "CARLOS BEGUERIE",
    "ROQUE PEREZ",
    "ARROYO CORTO",
    "COLONIA SAN MARTIN",
    "DUFAUR",
    "ESPARTILLAR (E)",
    "GOYENA",
    "LAS ENCADENADAS",
    "PIGUE",
    "SAAVEDRA",
    "ALVAREZ DE TOLEDO",
    "CAZON",
    "DEL CARRIL",
    "POLVAREDAS",
    "SALADILLO",
    "ARROYO DULCE",
    "BERDIER",
    "GAHAN",
    "INES INDART",
    "LA INVENCIBLE",
    "SALTO",
    "QUENUMA",
    "SALLIQUELO",
    "AZCUENAGA",
    "CULULLU",
    "FRANKLIN",
    "SAN ANDRES DE GILES",
    "SOLIS",
    "VILLA ESPIL",
    "VILLA RUIZ",
    "DUGGAN",
    "SAN ANTONIO DE ARECO",
    "VILLA LIA",
    "BALNEARIO SAN CAYETANO",
    "OCHANDIO",
    "SAN CAYETANO",
    "SAN FERNANDO",
    "VICTORIA",
    "VIRREYES",
    "ACASSUSO",
    "BECCAR",
    "BOULOGNE SUR MER",
    "MARTINEZ",
    "SAN ISIDRO",
    "VILLA ADELINA",
    "BELLA VISTA",
    "CAMPO DE MAYO",
    "MU\xD1IZ",
    "SAN MIGUEL",
    "CONESA",
    "EREZCANO",
    "GENERAL ROJO",
    "LA EMILIA",
    "VILLA CAMPI",
    "VILLA CANTO",
    "VILLA RICCIO",
    "CAMPOS SALLES",
    "SAN NICOLAS DE LOS ARROYOS",
    "VILLA ESPERANZA",
    "GOBERNADOR CASTRO",
    "INGENIERO MONETA",
    "OBLIGADO",
    "PUEBLO DOYLE",
    "RIO TALA",
    "SAN PEDRO",
    "SANTA LUCIA",
    "ALEJANDRO KORN",
    "SAN VICENTE",
    "DOMSELAAR",
    "GENERAL RIVAS",
    "SUIPACHA",
    "DE LA CANAL",
    "GARDEY",
    "MARIA IGNACIA",
    "TANDIL",
    "CROTTO",
    "TAPALQUE",
    "VELLOSO",
    "BENAVIDEZ",
    "DIQUE LUJAN",
    "DON TORCUATO ESTE",
    "DON TORCUATO OESTE",
    "EL TALAR",
    "GENERAL PACHECO",
    "LOS TRONCOS DEL TALAR",
    "RICARDO ROJAS",
    "RINCON DE MILBERG",
    "TIGRE",
    "GENERAL CONESA",
    "CHASICO",
    "SALDUNGARAY",
    "SIERRA DE LA VENTANA",
    "TORNQUIST",
    "TRES PICOS",
    "VILLA SERRANA LA GRUTA",
    "VILLA VENTANA",
    "BERUTTI",
    "GIRODIAS",
    "LA CARRETA",
    "30 DE AGOSTO",
    "TRENQUE LAUQUEN",
    "TRONGE",
    "BALNEARIO ORENSE",
    "CLAROMECO",
    "DUNAMAR",
    "COPETONAS",
    "LIN CALEL",
    "MICAELA CASCALLARES",
    "ORENSE",
    "RETA",
    "SAN FRANCISCO DE BELLOCQ",
    "SAN MAYOL",
    "TRES ARROYOS",
    "VILLA RODRIGUEZ",
    "CASEROS",
    "CHURRUCA",
    "CIUDAD JARDIN LOMAS DEL PALOMAR",
    "CIUDADELA",
    "EL LIBERTADOR",
    "JOSE INGENIEROS",
    "LOMA HERMOSA",
    "MARTIN CORONADO",
    "11 DE SEPTIEMBRE",
    "PABLO PODESTA",
    "REMEDIOS DE ESCALADA",
    "SAENZ PE\xD1A",
    "SANTOS LUGARES",
    "VILLA BOSCH (EST. JUAN MARIA BOSCH)",
    "VILLA RAFFO",
    "INGENIERO THOMPSON",
    "TRES LOMAS",
    "AGUSTIN MOSCONI",
    "DEL VALLE",
    "ERNESTINA",
    "GOBERNADOR UGARTE",
    "LUCAS MONTEVERDE",
    "NORBERTO DE LA RIESTRA",
    "PEDERNALES",
    "SAN ENRIQUE",
    "VALDES",
    "25 DE MAYO",
    "CARAPACHAY",
    "FLORIDA",
    "FLORIDA OESTE",
    "LA LUCILA",
    "MUNRO",
    "OLIVOS",
    "VICENTE LOPEZ",
    "VILLA MARTELLI",
    "MAR AZUL",
    "MAR DE LAS PAMPAS",
    "VILLA GESELL",
    "ARGERICH",
    "COLONIA SAN ADOLFO",
    "COUNTRY LOS MEDANOS",
    "HILARIO ASCASUBI",
    "JUAN COUSTE",
    "MAYOR BURATOVICH",
    "MEDANOS",
    "PEDRO LURO",
    "TENIENTE ORIGONE",
    "COUNTRY CLUB EL CASCO",
    "ESCALADA",
    "LIMA",
    "ZARATE",
    "BARRIO SAAVEDRA",
    "CARHUE",
    "COLONIA SAN MIGUEL ARCANGEL",
    "DELFIN HUERGO",
    "ESPARTILLAR",
    "ESTEBAN AGUSTIN GASCON",
    "LA PALA",
    "MAZA",
    "RIVERA",
    "VILLA MARGARITA",
    "YUTUYACO",
    "ADOLFO GONZALES CHAVES",
    "DE LA GARMA",
    "JUAN E. BARRA",
    "VASQUEZ",
    "BARRIO RUTA 24 KILOMETRO 10"
  ],
  K: [
    "CHUCHUCARUANA",
    "COLPES",
    "EL BOLSON",
    "EL RODEO",
    "HUAYCAMA",
    "LA PUERTA",
    "LAS CHACRITAS",
    "LAS JUNTAS",
    "LOS CASTILLOS",
    "LOS TALAS",
    "LOS VARELA",
    "SINGUIL",
    "ANCASTI",
    "ANQUINCILA",
    "LA CANDELARIA",
    "LA MAJADA",
    "AMANAO",
    "ANDALGALA",
    "CHAQUIAGO",
    "CHOYA",
    "BUENA VISTA",
    "EL ALAMITO",
    "ACONQUIJA",
    "ALTO DE LAS JUNTAS",
    "EL LINDERO",
    "LA MESADA",
    "EL POTRERO",
    "LA AGUADA",
    "ANTOFAGASTA DE LA SIERRA",
    "ANTOFALLA",
    "EL PE\xD1ON",
    "LOS NACIMIENTOS",
    "BARRANCA LARGA",
    "BELEN",
    "CONDOR HUASI",
    "CORRAL QUEMADO",
    "EL DURAZNO",
    "FARALLON NEGRO",
    "HUALFIN",
    "JACIPUNCO",
    "LA PUNTILLA",
    "LONDRES",
    "PUERTA DE CORRAL QUEMADO",
    "PUERTA DE SAN JOSE",
    "VILLA VIL",
    "ADOLFO E. CARRANZA",
    "BALDE DE LA PUNTA",
    "CAPAYAN",
    "CHUMBICHA",
    "COLONIA DEL VALLE",
    "COLONIA NUEVA CONETA",
    "CONCEPCION",
    "CONETA",
    "EL BA\xD1ADO",
    "HUILLAPIMA",
    "LOS ANGELES",
    "LOS ANGELES NORTE",
    "LOS ANGELES SUR",
    "MIRAFLORES",
    "SAN MARTIN",
    "SAN PABLO",
    "SAN PEDRO",
    "EL PANTANILLO",
    "SAN FERNANDO DEL VALLE DE CATAMARCA",
    "EL ALTO",
    "GUAYAMBA",
    "INFANZON",
    "LOS CORRALES",
    "TAPSO",
    "VILISMAN",
    "COLLAGASTA",
    "POMANCILLO ESTE",
    "POMANCILLO OESTE",
    "EL HUECO",
    "LA CARRERA",
    "LA FALDA DE SAN ANTONIO",
    "LA TERCENA",
    "SAN ANTONIO",
    "SAN JOSE",
    "VILLA LAS PIRQUITAS",
    "CASA DE PIEDRA",
    "EL AYBAL",
    "EL DIVISADERO",
    "EL QUIMILO",
    "ESQUIU",
    "ICA\xD1O",
    "LA DORADA",
    "LA GUARDIA",
    "LAS ESQUINAS",
    "LAS PALMITAS",
    "QUIROS",
    "RAMBLONES",
    "RECREO",
    "AMADORES",
    "EL ROSARIO",
    "LA BAJADA",
    "LA HIGUERA",
    "LA MERCED",
    "LA VI\xD1A",
    "LAS LAJAS",
    "MONTE POTRERO",
    "PALO LABRADO",
    "VILLA DE BALCOZNA",
    "APOYACO",
    "COLANA",
    "EL PAJONAL",
    "JOYANGO",
    "MUTQUIN",
    "POMAN",
    "RINCON",
    "SAN MIGUEL",
    "SAUJIL",
    "SIJAN",
    "ANDALHUALA",
    "CASPICHANGO",
    "CHA\xD1AR PUNCO",
    "LAMPACITO",
    "MEDANITOS",
    "EL CAJON",
    "EL DESMONTE",
    "EL PUESTO",
    "FAMATANCA",
    "SAN JOSE BANDA",
    "FUERTE QUEMADO",
    "LA HOYADA",
    "LA LOMA",
    "EL CERRITO",
    "LAS MOJARRAS",
    "LORO HUASI",
    "PUNTA DE BALASTO",
    "PALO SECO",
    "SAN JOSE NORTE",
    "SAN JOSE VILLA",
    "SANTA MARIA",
    "YAPES",
    "ALIJILAN",
    "BA\xD1ADO DE OVANTA",
    "LAS CA\xD1AS",
    "LAVALLE",
    "LOS ALTOS",
    "MANANTIALES",
    "ANILLACO",
    "ANTINACO",
    "BANDA DE LUCERO",
    "CERRO NEGRO",
    "COPACABANA",
    "CORDOBITA",
    "COSTA DE REYES",
    "EL PUEBLITO",
    "EL SALADO",
    "FIAMBALA",
    "LA RAMADITA",
    "PAMPA BLANCA",
    "LOS BALVERDIS",
    "PALO BLANCO",
    "PUNTA DEL AGUA",
    "TATON",
    "TINOGASTA",
    "EL PORTEZUELO",
    "LAS TEJAS",
    "POLCOS",
    "POZO DEL MISTOL",
    "SAN ISIDRO",
    "SANTA ROSA",
    "SUMALAO",
    "VILLA DOLORES",
    "SANTA CRUZ"
  ],
  X: [
    "AMBOY",
    "ARROYO SAN ANTONIO",
    "CA\xD1ADA DEL SAUCE",
    "CAPILLA VIEJA",
    "EL CORCOVADO - EL TORREON",
    "EL DURAZNO",
    "EMBALSE",
    "LA CRUZ",
    "LA CUMBRECITA",
    "LAS BAJADAS",
    "LAS CALERAS",
    "LOS CONDORES",
    "LOS MOLINOS",
    "VILLA SAN MIGUEL",
    "LOS REARTES",
    "LUTTI",
    "PARQUE CALMAYO",
    "RIO DE LOS SAUCES",
    "SAN AGUSTIN",
    "SAN IGNACIO (LOTEO SAN JAVIER)",
    "SANTA ROSA DE CALAMUCHITA",
    "SANTA MONICA",
    "SAN IGNACIO (LOTEO VELEZ CRESPO)",
    "SEGUNDA USINA",
    "SOLAR DE LOS MOLINOS",
    "VILLA ALPINA",
    "VILLA AMANCAY",
    "VILLA BERNA",
    "VILLA CIUDAD PARQUE LOS REARTES (1a.SECC",
    "VILLA CIUDAD PARQUE LOS REARTES",
    "VILLA CIUDAD PARQUES LOS REARTES (1\xB0 SECCION)",
    "VILLA CIUDAD PARQUE LOS REARTES (3\xB0 SECCION)",
    "VILLA DEL DIQUE",
    "VILLA EL TALA",
    "VILLA GENERAL BELGRANO",
    "VILLA LA RIVERA",
    "VILLA QUILLINZO",
    "VILLA RUMIPAL",
    "VILLA YACANTO",
    "CORDOBA",
    "JARDIN ARENALES",
    "LA FLORESTA",
    "AGUA DE ORO",
    "ASCOCHINGA",
    "BARRIO NUEVO RIO CEBALLOS",
    "CANTERAS EL SAUCE",
    "CASA BAMBA",
    "COLONIA CAROYA",
    "COLONIA TIROLESA",
    "COLONIA VICENTE AGUERO",
    "VILLA CORAZON DE MARIA",
    "CORRAL QUEMADO",
    "COUNTRY CHACRAS DE LA VILLA - COUNTRY SA",
    "COUNTRY CHACRAS DE LA VILLA",
    "COUNTRY SAN ISIDRO",
    "EL MANZANO",
    "ESTACION COLONIA TIROLESA",
    "GENERAL PAZ",
    "JESUS MARIA",
    "LA CALERA",
    "DUMESNIL",
    "EL DIQUECITO",
    "LA GRANJA",
    "LA MORADA",
    "LA PUERTA",
    "LAS CORZUELAS",
    "LOS MOLLES",
    "MALVINAS ARGENTINAS",
    "MENDIOLAZA",
    "MI GRANJA",
    "PAJAS BLANCAS",
    "RIO CEBALLOS",
    "SALDAN",
    "SALSIPUEDES",
    "EL PUEBLITO",
    "SANTA ELENA",
    "TINOCO",
    "UNQUILLO",
    "VILLA ALLENDE",
    "VILLA CERRO AZUL",
    "VILLA EL FACHINAL - PARQUE NORTE - GUI\xD1A",
    "GUI\xD1AZU NORTE",
    "PARQUE NORTE",
    "1 DE AGOSTO",
    "ALMIRANTE BROWN",
    "CIUDAD DE LOS NI\xD1OS",
    "VILLA PASTORA",
    "VILLA LOS LLANOS",
    "JUAREZ CELMAN",
    "ALTO DE LOS QUEBRACHOS",
    "BA\xD1ADO DE SOTO",
    "CANTERAS QUILPO",
    "CRUZ DE CA\xD1A",
    "CRUZ DEL EJE",
    "EL BRETE",
    "EL RINCON",
    "GUANACO MUERTO",
    "LA BANDA",
    "LA BATEA",
    "LA HIGUERA",
    "LAS CA\xD1ADAS",
    "LAS PLAYAS",
    "LOS CHA\xD1ARITOS",
    "MEDIA NARANJA",
    "PASO VIEJO",
    "SAN MARCOS SIERRA",
    "SERREZUELA",
    "TUCLAME",
    "VILLA DE SOTO",
    "DEL CAMPILLO",
    "ESTACION LECUEDER",
    "HIPOLITO BOUCHARD",
    "HUINCA RENANCO",
    "ITALO",
    "MATTALDI",
    "NICOLAS BRUZZONE",
    "ONAGOITY",
    "PINCEN",
    "RANQUELES",
    "SANTA MAGDALENA",
    "VILLA HUIDOBRO",
    "VILLA SARMIENTO",
    "VILLA VALERIA",
    "ARROYO ALGODON",
    "ARROYO CABRAL",
    "AUSONIA",
    "CHAZON",
    "ETRURIA",
    "LA LAGUNA",
    "LA PALESTINA",
    "LA PLAYOSA",
    "LAS MOJARRAS",
    "LUCA",
    "PASCO",
    "SANABRIA",
    "SILVIO PELLICO",
    "TICINO",
    "TIO PUJIO",
    "VILLA ALBERTINA",
    "VILLA MARIA",
    "VILLA NUEVA",
    "VILLA OESTE",
    "AVELLANEDA",
    "CA\xD1ADA DE RIO PINTO",
    "CHU\xD1A",
    "COPACABANA",
    "DEAN FUNES",
    "ESQUINA DEL ALAMBRE",
    "LOS POZOS",
    "OLIVARES DE SAN NICOLAS",
    "QUILINO",
    "SAN PEDRO DE TOYOS",
    "VILLA GUTIERREZ",
    "VILLA QUILINO",
    "ALEJANDRO ROCA",
    "ASSUNTA",
    "BENGOLEA",
    "CARNERILLO",
    "CHARRAS",
    "EL RASTREADOR",
    "GENERAL CABRERA",
    "GENERAL DEHEZA",
    "HUANCHILLAS",
    "LA CARLOTA",
    "LOS CISNES",
    "OLAETA",
    "PACHECO DE MELO",
    "PASO DEL DURAZNO",
    "SANTA EUFEMIA",
    "UCACHA",
    "VILLA REDUCCION",
    "ALEJO LEDESMA",
    "ARIAS",
    "CAMILO ALDAO",
    "CAPITAN GENERAL BERNARDO O`HIGGINS",
    "CAVANAGH",
    "COLONIA BARGE",
    "COLONIA ITALIANA",
    "COLONIA VEINTICINCO",
    "CORRAL DE BUSTOS",
    "CRUZ ALTA",
    "GENERAL BALDISSERA",
    "GENERAL ROCA",
    "GUATIMOZIN",
    "INRIVILLE",
    "ISLA VERDE",
    "LEONES",
    "LOS SURGENTES",
    "MARCOS JUAREZ",
    "MONTE BUEY",
    "SAIRA",
    "SALADILLO",
    "VILLA ELISA",
    "CIENAGA DEL CORO",
    "EL CHACHO",
    "ESTANCIA DE GUADALUPE",
    "GUASAPAMPA",
    "LA PLAYA",
    "SAN CARLOS MINAS",
    "TALAINI",
    "TOSNO",
    "CHANCANI",
    "LAS PALMAS",
    "LOS TALARES",
    "SALSACATE",
    "SAN GERONIMO",
    "TALA CA\xD1ADA",
    "TANINGA",
    "VILLA DE POCHO",
    "GENERAL LEVALLE",
    "LA CESIRA",
    "LABOULAYE",
    "LEGUIZAMON",
    "MELO",
    "RIO BAMBA",
    "ROSALES",
    "SAN JOAQUIN",
    "SERRANO",
    "VILLA ROSSI",
    "BARRIO SANTA ISABEL",
    "BIALET MASSE",
    "SAN ROQUE DEL LAGO",
    "CABALANGO",
    "CAPILLA DEL MONTE",
    "CASA GRANDE",
    "CHARBONIER",
    "COSQUIN",
    "CUESTA BLANCA",
    "ESTANCIA VIEJA",
    "HUERTA GRANDE",
    "LA CUMBRE",
    "LA FALDA",
    "LAS JARILLAS",
    "LOS COCOS",
    "MALLIN",
    "MAYU SUMAJ",
    "QUEBRADA DE LUNA",
    "SAN ANTONIO DE ARREDONDO",
    "SAN ESTEBAN",
    "SAN ROQUE",
    "SANTA MARIA DE PUNILLA",
    "TALA HUASI",
    "TANTI",
    "VALLE HERMOSO",
    "VILLA CARLOS PAZ",
    "VILLA FLOR SERRANA",
    "VILLA GIARDINO",
    "VILLA LAGO AZUL",
    "VILLA PARQUE SIQUIMAN",
    "VILLA RIO ICHO CRUZ",
    "VILLA SAN JOSE",
    "VILLA SANTA CRUZ DEL LAGO",
    "ACHIRAS",
    "ADELIA MARIA",
    "ALCIRA",
    "ALPA CORRAL",
    "BERROTARAN",
    "BULNES",
    "CHAJAN",
    "CHUCUL",
    "CORONEL BAIGORRIA",
    "CORONEL MOLDES",
    "ELENA",
    "LA CAROLINA",
    "LA CAUTIVA",
    "LA GILDA",
    "LAS ACEQUIAS",
    "LAS ALBAHACAS",
    "LAS HIGUERAS",
    "LAS PE\xD1AS",
    "LAS VERTIENTES",
    "MALENA",
    "MONTE DE LOS GAUCHOS",
    "RIO CUARTO",
    "SAMPACHO",
    "SAN BASILIO",
    "SANTA CATALINA",
    "SUCO",
    "TOSQUITAS",
    "VICU\xD1A MACKENNA",
    "VILLA EL CHACAY",
    "VILLA SANTA EUGENIA",
    "WASHINGTON",
    "ATAHONA",
    "CA\xD1ADA DE MACHADO",
    "CAPILLA DE LOS REMEDIOS",
    "CHALACEA",
    "COLONIA LAS CUATRO ESQUINAS",
    "DIEGO DE ROJAS",
    "EL ALCALDE",
    "EL CRISPIN",
    "ESQUINA",
    "KILOMETRO 658",
    "LA PARA",
    "LA POSTA",
    "LA QUINTA",
    "LAS GRAMILLAS",
    "LAS SALADAS",
    "MAQUINISTA GALLINI",
    "MONTE DEL ROSARIO",
    "MONTECRISTO",
    "OBISPO TREJO",
    "PIQUILLIN",
    "PLAZA DE MERCEDES",
    "PUEBLO COMECHINGONES",
    "RIO PRIMERO",
    "SAGRADA FAMILIA",
    "SANTA ROSA DE RIO PRIMERO",
    "VILLA FONTANA",
    "CERRO COLORADO",
    "CHA\xD1AR VIEJO",
    "EUFRASIO LOZA",
    "GUTEMBERG",
    "LA RINCONADA",
    "LOS HOYOS",
    "PUESTO DE CASTRO",
    "RAYO CORTADO",
    "SAN PEDRO DE GUTEMBERG",
    "SEBASTIAN ELCANO",
    "VILLA CANDELARIA NORTE",
    "VILLA DE MARIA",
    "CALCHIN",
    "CALCHIN OESTE",
    "CAPILLA DEL CARMEN",
    "CARRILOBO",
    "COLAZO",
    "COLONIA VIDELA",
    "COSTASACATE",
    "IMPIRA",
    "LAGUNA LARGA",
    "LAS JUNTURAS",
    "LUQUE",
    "MANFREDI",
    "MATORRALES",
    "ONCATIVO",
    "PILAR",
    "POZO DEL MOLLE",
    "RINCON",
    "RIO SEGUNDO",
    "SANTIAGO TEMPLE",
    "VILLA DEL ROSARIO",
    "AMBUL",
    "ARROYO LOS PATOS",
    "EL HUAYCO",
    "LA CORTADERA",
    "LAS CALLES",
    "LAS OSCURAS",
    "LAS RABONAS",
    "LOS CALLEJONES",
    "MINA CLAVERO",
    "MUSSI",
    "NONO",
    "PANAHOLMA",
    "SAN HUBERTO",
    "SAN LORENZO",
    "SAN MARTIN",
    "SAN PEDRO",
    "SAN VICENTE",
    "SAUCE ARRIBA",
    "TASNA",
    "VILLA CURA BROCHERO",
    "CONLARA",
    "CRUZ CA\xD1A",
    "DOS ARROYOS",
    "EL PANTANILLO",
    "LA PAZ",
    "LA POBLACION",
    "LA RAMADA",
    "LA TRAVESIA",
    "LAS CHACRAS",
    "LAS TAPIAS",
    "LOMA BOLA",
    "LOS CERRILLOS",
    "LOS HORNILLOS",
    "LUYABA",
    "QUEBRACHO LADEADO",
    "QUEBRADA DE LOS POZOS",
    "SAN JAVIER Y YACANTO",
    "SAN JOSE",
    "VILLA DE LAS ROSAS",
    "ALTO RESBALOSO - EL BARRIAL",
    "EL VALLE",
    "VILLA DOLORES",
    "VILLA LA VI\xD1A",
    "ALICIA",
    "ALTOS DE CHIPION",
    "ARROYITO",
    "BALNEARIA",
    "BRINCKMANN",
    "COLONIA ANITA",
    "COLONIA 10 DE JULIO",
    "COLONIA LAS PICHANAS",
    "COLONIA MARINA",
    "COLONIA PROSPERIDAD",
    "COLONIA SAN BARTOLOME",
    "COLONIA SAN PEDRO",
    "COLONIA SANTA MARIA",
    "COLONIA VALTELINA",
    "COLONIA VIGNAUD",
    "DEVOTO",
    "EL ARA\xD1ADO",
    "EL FORTIN",
    "EL FUERTECITO",
    "EL TIO",
    "ESTACION LUXARDO",
    "COLONIA ITURRASPE",
    "FREYRE",
    "LA FRANCIA",
    "LA PAQUITA",
    "LA TORDILLA",
    "LAS VARAS",
    "LAS VARILLAS",
    "MARULL",
    "MIRAMAR",
    "MORTEROS",
    "PLAZA LUXARDO",
    "PLAZA SAN FRANCISCO",
    "PORTE\xD1A",
    "QUEBRACHO HERRADO",
    "SACANTA",
    "SAN FRANCISCO",
    "SATURNINO MARIA LASPIUR",
    "SEEBER",
    "TORO PUJIO",
    "TRANSITO",
    "VILLA CONCEPCION DEL TIO",
    "VILLA DEL TRANSITO",
    "VILLA SAN ESTEBAN",
    "ALTA GRACIA",
    "ANISACATE",
    "BARRIO GILBERT",
    "BARRIO GILBERT (1\xB0 DE MAYO)",
    "TEJAS TRES",
    "BOUWER",
    "CAMPOS DEL VIRREY",
    "CASEROS CENTRO",
    "CAUSANA",
    "COSTA AZUL",
    "DESPE\xD1ADEROS",
    "DIQUE CHICO",
    "EL POTRERILLO",
    "FALDA DEL CA\xD1ETE",
    "FALDA DEL CARMEN",
    "JOSE DE LA QUINTANA",
    "LA BOCA DEL RIO",
    "LA CARBONADA",
    "LA PAISANITA",
    "LA PERLA",
    "LA RANCHERITA",
    "LA SERRANITA",
    "LOS CEDROS - LAS QUINTAS",
    "BARRIO LAS QUINTAS",
    "LOS CEDROS",
    "LOZADA",
    "MALAGUE\xD1O",
    "MILENICA",
    "MONTE RALO",
    "POTRERO DE GARAY",
    "RAFAEL GARCIA",
    "SAN CLEMENTE",
    "SAN NICOLAS",
    "TIERRA ALTA",
    "SOCAVONES",
    "TOLEDO",
    "VALLE ALEGRE",
    "VALLE DE ANISACATE",
    "VILLA CIUDAD DE AMERICA (LOTEO DIEGO DE",
    "BARRIO VILLA DEL PARQUE",
    "VILLA CIUDAD DE AMERICA",
    "VILLA DEL PRADO",
    "LA DONOSA",
    "VILLA LA BOLSA",
    "VILLA LOS AROMOS",
    "VILLA PARQUE SANTA ANA",
    "MI VALLE",
    "VILLA SAN ISIDRO - JOSE DE LA QUINTANA",
    "VILLA SIERRAS DE ORO",
    "YOCSINA",
    "CAMINIAGA",
    "CHU\xD1A HUASI",
    "POZO NUEVO",
    "SAN FRANCISCO DEL CHA\xD1AR",
    "ALMAFUERTE",
    "COLONIA ALMADA",
    "CORRALITO",
    "DALMACIO VELEZ",
    "GENERAL FOTHERINGHAM",
    "HERNANDO",
    "JAMES CRAIK",
    "LAS ISLETILLAS",
    "LAS PERDICES",
    "LOS ZORROS",
    "OLIVA",
    "PAMPAYASTA NORTE",
    "PAMPAYASTA SUR",
    "PUNTA DEL AGUA",
    "RIO TERCERO",
    "TANCACHA",
    "VILLA ASCASUBI",
    "CANDELARIA SUR",
    "CA\xD1ADA DE LUQUE",
    "CAPILLA DE SITON",
    "LA PAMPA",
    "LOS MISTOLES",
    "SARMIENTO",
    "SIMBOLAR",
    "SINSACATE",
    "VILLA DEL TOTORAL",
    "CHURQUI CA\xD1ADA",
    "EL RODEO",
    "EL TUSCAL",
    "LAS ARRIAS",
    "LUCIO V. MANSILLA",
    "ROSARIO DEL SALADILLO",
    "SAN JOSE DE LA DORMIDA",
    "SAN JOSE DE LAS SALINAS",
    "SAN PEDRO NORTE",
    "VILLA TULUMBA",
    "ALDEA SANTA MARIA",
    "ALTO ALEGRE",
    "ANA ZUMARAN",
    "BALLESTEROS",
    "BALLESTEROS SUR",
    "BELL VILLE",
    "BENJAMIN GOULD",
    "CANALS",
    "CHILIBROSTE",
    "CINTRA",
    "COLONIA BISMARCK",
    "COLONIA BREMEN",
    "IDIAZABAL",
    "JUSTINIANO POSSE",
    "LABORDE",
    "MONTE LE\xD1A",
    "MONTE MAIZ",
    "MORRISON",
    "NOETINGER",
    "ORDO\xD1EZ",
    "PASCANAS",
    "PUEBLO ITALIANO",
    "RAMON J. CARCANO",
    "SAN ANTONIO DE LITIN",
    "SAN MARCOS",
    "SAN SEVERO",
    "VIAMONTE",
    "VILLA LOS PATOS",
    "WENCESLAO ESCALANTE"
  ],
  W: [
    "BELLA VISTA",
    "BERON DE ASTRADA",
    "YAHAPE",
    "CORRIENTES",
    "LAGUNA BRAVA",
    "RIACHUELO",
    "SAN CAYETANO",
    "CONCEPCION",
    "SANTA ROSA",
    "TABAY",
    "TATACUA",
    "CAZADORES CORRENTINOS",
    "CURUZU CUATIA",
    "PERUGORRIA",
    "EL SOMBRERO",
    "EMPEDRADO",
    "ESQUINA",
    "PUEBLO LIBERTADOR",
    "ALVEAR",
    "ESTACION TORRENT",
    "ITA IBATE",
    "LOMAS DE VALLEJOS",
    "NUESTRA SE\xD1ORA DEL ROSARIO DE CAA CATI",
    "PALMAR GRANDE",
    "COLONIA CAROLINA",
    "GOYA",
    "ITATI",
    "RAMADA PASO",
    "COLONIA LIEBIG'S",
    "ITUZAINGO",
    "SAN ANTONIO",
    "SAN CARLOS",
    "VILLA OLIVARI",
    "CRUZ DE LOS MILAGROS",
    "GOBERNADOR JUAN E. MARTINEZ",
    "LAVALLE",
    "SANTA LUCIA",
    "VILLA CORDOBA",
    "YATAYTI CALLE",
    "MBURUCUYA",
    "FELIPE YOFRE",
    "MARIANO I. LOZA",
    "MERCEDES",
    "COLONIA LIBERTAD",
    "ESTACION LIBERTAD",
    "JUAN PUJOL",
    "MOCORETA",
    "MONTE CASEROS",
    "PARADA ACU\xD1A",
    "PARADA LABOUGLE",
    "BONPLAND",
    "PARADA PUCHETA",
    "PASO DE LOS LIBRES",
    "TAPEBICUA",
    "SALADAS",
    "SAN LORENZO",
    "INGENIO PRIMER CORRENTINO",
    "PASO DE LA PATRIA",
    "SAN COSME",
    "SANTA ANA",
    "SAN LUIS DEL PALMAR",
    "COLONIA CARLOS PELLEGRINI",
    "GUAVIRAVI",
    "LA CRUZ",
    "YAPEYU",
    "LORETO",
    "SAN MIGUEL",
    "CHAVARRIA",
    "COLONIA PANDO",
    "9 DE JULIO",
    "PEDRO R. FERNANDEZ",
    "SAN ROQUE",
    "JOSE R. GOMEZ",
    "GARRUCHOS",
    "GOBERNADOR IGR.VALENTIN VIRASORO",
    "SANTO TOME",
    "SAUCE"
  ],
  H: [
    "CONCEPCION DEL BERMEJO",
    "LOS FRENTONES",
    "PAMPA DEL INFIERNO",
    "RIO MUERTO",
    "TACO POZO",
    "GENERAL VEDIA",
    "ISLA DEL CERRITO",
    "LA LEONESA",
    "LAS PALMAS",
    "PUERTO BERMEJO NUEVO",
    "PUERTO BERMEJO VIEJO",
    "PUERTO EVA PERON",
    "PRESIDENCIA ROQUE SAENZ PENA",
    "CHARATA",
    "GANCEDO",
    "GENERAL CAPDEVILA",
    "GENERAL PINEDO",
    "MESON DE FIERRO",
    "PAMPA LANDRIEL",
    "HERMOSO CAMPO",
    "ITIN",
    "CHOROTIS",
    "SANTA SYLVINA",
    "VENADOS GRANDES",
    "CORZUELA",
    "LA ESCONDIDA",
    "LA VERDE",
    "LAPACHITO",
    "MAKALLE",
    "EL ESPINILLO",
    "EL SAUZAL",
    "EL SAUZALITO",
    "FORTIN LAVALLE",
    "FUERTE ESPERANZA",
    "JUAN JOSE CASTELLI",
    "NUEVA POMPEYA",
    "VILLA RIO BERMEJITO",
    "WICHI",
    "ZAPARINQUI",
    "AVIA TERAI",
    "CAMPO LARGO",
    "FORTIN LAS CHU\xD1AS",
    "NAPENAY",
    "COLONIA POPULAR",
    "ESTACION GENERAL OBLIGADO",
    "LAGUNA BLANCA",
    "PUERTO TIROL",
    "CIERVO PETISO",
    "GENERAL JOSE DE SAN MARTIN",
    "LA EDUVIGIS",
    "LAGUNA LIMPIA",
    "PAMPA ALMIRON",
    "PAMPA DEL INDIO",
    "PRESIDENCIA ROCA",
    "SELVAS DEL RIO DE ORO",
    "TRES ISLETAS",
    "CORONEL DU GRATY",
    "ENRIQUE URIEN",
    "VILLA ANGELA",
    "LAS BRE\xD1AS",
    "LA CLOTILDE",
    "LA TIGRA",
    "SAN BERNARDO",
    "PRESIDENCIA DE LA PLAZA",
    "BARRIO DE LOS PESCADORES",
    "COLONIA BENITEZ",
    "MARGARITA BELEN",
    "QUITILIPI",
    "VILLA EL PALMAR",
    "BARRANQUERAS",
    "BASAIL",
    "COLONIA BARANDA",
    "FONTANA",
    "PUERTO VILELAS",
    "RESISTENCIA",
    "SAMUHU",
    "VILLA BERTHET",
    "CAPITAN SOLARI",
    "COLONIA ELISA",
    "COLONIAS UNIDAS",
    "INGENIERO BARBET",
    "LAS GARCITAS",
    "CHARADAI",
    "COTE LAI",
    "HAUMONIA",
    "HORQUILLA",
    "LA SABANA",
    "COLONIA ABORIGEN",
    "MACHAGAI",
    "NAPALPI",
    "MIRAFLORES"
  ],
  U: [
    "ARROYO VERDE",
    "PUERTO MADRYN",
    "PUERTO PIRAMIDE",
    "QUINTA EL MIRADOR",
    "RESERVA AREA PROTEGIDA EL DORADILLO",
    "BUENOS AIRES CHICO",
    "CHOLILA",
    "COSTA DE CHUBUT",
    "CUSHAMEN CENTRO",
    "EL HOYO",
    "EL MAITEN",
    "EPUYEN",
    "FOFO CAHUEL",
    "GUALJAINA",
    "LAGO EPUYEN",
    "LAGO PUELO",
    "LELEQUE",
    "ASTRA",
    "BAHIA BUSTAMANTE",
    "ACCESO NORTE",
    "BARRIO CALETA CORDOVA",
    "BARRIO CALETA OLIVARES",
    "BARRIO CASTELLI",
    "BARRIO CIUDADELA",
    "BARRIO GASODUCTO",
    "BARRIO G\xDCEMES",
    "BARRIO LAPRIDA",
    "BARRIO MANANTIAL ROSALES",
    "BARRIO MILITAR - AEROPUERTO MILITAR",
    "BARRIO PROSPERO PALAZZO",
    "BARRIO RESTINGA ALI",
    "BARRIO RODRIGUEZ PE\xD1A",
    "BARRIO SAAVEDRA",
    "BARRIO SARMIENTO",
    "BARRIO 25 DE MAYO",
    "BARRIO VILLA S.U.P.E.",
    "COMODORO RIVADAVIA",
    "KM. 5 - PRESIDENTE ORTIZ",
    "KM. 8 - DON BOSCO",
    "KM. 11 - CUARTELES",
    "KM. 3 - GENERAL MOSCONI",
    "DIADEMA ARGENTINA",
    "RADA TILLY",
    "CAMARONES",
    "GARAYALDE",
    "ALDEA ESCOLAR",
    "CORCOVADO",
    "ESQUEL",
    "LAGO ROSARIO",
    "LOS CIPRESES",
    "TREVELIN",
    "VILLA FUTALAUFQUEN",
    "DIQUE FLORENTINO AMEGHINO",
    "DOLAVON",
    "GAIMAN",
    "28 DE JULIO",
    "BLANCUNTRE",
    "EL ESCORIAL",
    "GASTRE",
    "LAGUNITA SALADA",
    "YALA LAUBAT",
    "ALDEA EPULEF",
    "CARRENLEUFU",
    "COLAN CONHUE",
    "PASO DEL SAPO",
    "TECKA",
    "EL MIRASOL",
    "LAS PLUMAS",
    "CERRO CONDOR",
    "LOS ALTARES",
    "PASO DE INDIOS",
    "PLAYA MAGAGNA",
    "PLAYA UNION",
    "RAWSON",
    "TRELEW",
    "ALDEA APELEG",
    "ALDEA BELEIRO",
    "ALTO RIO SENGUER",
    "DOCTOR RICARDO ROJAS",
    "FACUNDO",
    "LAGO BLANCO",
    "RIO MAYO",
    "BUEN PASTO",
    "SARMIENTO",
    "FRONTERA DE RIO PICO",
    "GOBERNADOR COSTA",
    "JOSE DE SAN MARTIN",
    "RIO PICO",
    "GAN GAN",
    "TELSEN"
  ],
  E: [
    "ARROYO BARU",
    "COLON",
    "COLONIA HUGUES",
    "HAMBIS",
    "HOCKER",
    "LA CLARITA",
    "PUEBLO CAZES",
    "PUEBLO LIEBIG'S",
    "EL BRILLANTE",
    "EL COLORADO",
    "SAN JOSE",
    "UBAJAY",
    "VILLA ELISA",
    "CALABACILLA",
    "CLODOMIRO LEDESMA",
    "COLONIA AYUI",
    "COLONIA GENERAL ROCA",
    "BENITO LEGEREN",
    "CONCORDIA",
    "LAS TEJAS",
    "VILLA ADELA",
    "VILLA ZORRAQUIN",
    "ESTACION YERUA",
    "ESTACION YUQUERI",
    "ESTANCIA GRANDE",
    "LA CRIOLLA",
    "LOS CHARRUAS",
    "NUEVA ESCOCIA",
    "PEDERNAL",
    "PUERTO YERUA",
    "ALDEA BRASILERA",
    "ALDEA GRAPSCHENTAL",
    "ALDEA PROTESTANTE",
    "ALDEA SALTO",
    "ALDEA SAN FRANCISCO",
    "ALDEA SPATZENKUTTER",
    "ALDEA VALLE MARIA",
    "COLONIA ENSAYO",
    "DIAMANTE",
    "STROBEL",
    "ESTACION CAMPS",
    "GENERAL ALVEAR",
    "GENERAL RACEDO",
    "GENERAL RAMIREZ",
    "LA JUANITA",
    "LA JAULA",
    "PARAJE LA VIRGEN",
    "PUERTO LAS CUEVAS",
    "ESTACION PUIGGARI",
    "VILLA LIBERTADOR SAN MARTIN",
    "CHAJARI",
    "COLONIA ALEMANA",
    "COLONIA PE\xD1A",
    "FEDERACION",
    "LOS CONQUISTADORES",
    "SAN JAIME DE LA FRONTERA",
    "SAN PEDRO",
    "SAN RAMON",
    "SANTA ANA",
    "VILLA DEL ROSARIO",
    "CONSCRIPTO BERNARDI",
    "EL CIMARRON",
    "FEDERAL",
    "NUEVA VIZCAYA",
    "SAUCE DE LUNA",
    "SAN JOSE DE FELICIANO",
    "SAN VICTOR",
    "ALDEA ASUNCION",
    "ESTACION LAZO",
    "GENERAL GALARZA",
    "GUALEGUAY",
    "PUERTO RUIZ",
    "ALDEA SAN ANTONIO",
    "ALDEA SAN JUAN",
    "ENRIQUE CARBO",
    "ESTACION ESCRI\xD1A",
    "FAUSTINO M. PARERA",
    "GENERAL ALMADA",
    "GILBERT",
    "GUALEGUAYCHU",
    "IRAZUSTA",
    "LARROQUE",
    "PASTOR BRITOS",
    "PUEBLO GENERAL BELGRANO",
    "URDINARRAIN",
    "CEIBAS",
    "IBICUY",
    "MEDANOS",
    "VILLA PARANACITO",
    "ALCARAZ",
    "BOVRIL",
    "COLONIA AVIGDOR",
    "EL SOLAR",
    "LA PAZ",
    "PIEDRAS BLANCAS",
    "SAN GUSTAVO",
    "SANTA ELENA",
    "SIR LEONARD",
    "ARANGUREN",
    "BETBEDER",
    "DON CRISTOBAL",
    "FEBRE",
    "HERNANDEZ",
    "LUCAS GONZALEZ",
    "NOGOYA",
    "XX DE SETIEMBRE",
    "ALDEA MARIA LUISA",
    "ALDEA SAN RAFAEL",
    "ALDEA SANTA MARIA",
    "ALDEA SANTA ROSA",
    "CERRITO",
    "PUEBLO MORENO",
    "COLONIA AVELLANEDA",
    "COLONIA CRESPO",
    "CRESPO",
    "EL PALENQUE",
    "EL PINGO",
    "EL RAMBLON",
    "HASENKAMP",
    "HERNANDARIAS",
    "LA PICADA",
    "LAS TUNAS",
    "MARIA GRANDE",
    "ORO VERDE",
    "PARANA",
    "PUEBLO BELLOCQ",
    "PUEBLO BRUGO",
    "PUEBLO GENERAL SAN MARTIN",
    "SAN BENITO",
    "SAUCE MONTRULL",
    "SAUCE PINTO",
    "SEGUI",
    "SOSA",
    "TABOSSI",
    "TEZANOS PINTO",
    "VIALE",
    "VILLA FONTANA",
    "VILLA GOBERNADOR LUIS F. ETCHEVEHERE",
    "VILLA URQUIZA",
    "GENERAL CAMPOS",
    "SAN SALVADOR",
    "ALTAMIRANO SUR",
    "DURAZNO",
    "ESTACION ARROYO CLE",
    "GOBERNADOR ECHAGUE",
    "GOBERNADOR MANSILLA",
    "GOBERNADOR SOLA",
    "GUARDAMONTE",
    "LAS GUACHAS",
    "MACIA",
    "ROSARIO DEL TALA",
    "BASAVILBASO",
    "CASEROS",
    "COLONIA ELIA",
    "CONCEPCION DEL URUGUAY",
    "HERRERA",
    "LAS MOSCAS",
    "LIBAROS",
    "1 DE MAYO",
    "PRONUNCIAMIENTO",
    "ROCAMORA",
    "SANTA ANITA",
    "VILLA MANTERO",
    "VILLA SAN JUSTO",
    "VILLA SAN MARCIAL",
    "ANTELO",
    "MOLINO DOLL",
    "VICTORIA",
    "ESTACION RAICES",
    "INGENIERO MIGUEL SAJAROFF",
    "JUBILEO",
    "PASO DE LA LAGUNA",
    "VILLA CLARA",
    "VILLA DOMINGUEZ",
    "VILLAGUAY"
  ],
  P: [
    "LA SOLEDAD",
    "GUADALCAZAR",
    "LA RINCONADA",
    "LAGUNA YEMA",
    "LAMADRID",
    "LOS CHIRIGUANOS",
    "POZO DE MAZA",
    "POZO DEL MORTERO",
    "VACA PERDIDA",
    "COLONIA PASTORIL",
    "FORMOSA",
    "GRAN GUARDIA",
    "MARIANO BOEDO",
    "MOJON DE FIERRO",
    "SAN HILARIO",
    "VILLA DEL CARMEN",
    "BANCO PAYAGUA",
    "GENERAL LUCIO V MANSILLA",
    "HERRADURA",
    "SAN FRANCISCO DE LAISHI",
    "TATANE",
    "VILLA ESCOLAR",
    "INGENIERO GUILLERMO N. JUAREZ",
    "BARTOLOME DE LAS CASAS",
    "COMUNIDAD ABORIGEN BARTOLOME DE LAS CASAS",
    "COLONIA SARMIENTO",
    "COMANDANTE FONTANA",
    "EL RECREO",
    "ESTANISLAO DEL CAMPO",
    "FORTIN CABO 1\xB0 LUGONES",
    "FORTIN SARGENTO 1\xB0 LEYES",
    "IBARRETA",
    "JUAN G. BAZAN",
    "LAS LOMITAS",
    "POSTA CAMBIO ZALAZAR",
    "POZO DEL TIGRE",
    "SAN MARTIN I",
    "SAN MARTIN II",
    "SUBTENIENTE PERIN",
    "VILLA GENERAL GUEMES",
    "VILLA GENERAL MANUEL BELGRANO",
    "BUENA VISTA",
    "EL ESPINILLO",
    "LAGUNA GALLO",
    "MISION TACAAGLE",
    "PORTON NEGRO",
    "TRES LAGUNAS",
    "CLORINDA",
    "LAGUNA BLANCA",
    "LAGUNA NAICK-NECK",
    "PALMA SOLA",
    "PUERTO PILCOMAYO",
    "RIACHO HE-HE",
    "RIACHO NEGRO",
    "SIETE PALMAS",
    "COLONIA CAMPO VILLAFA\xD1E",
    "EL COLORADO",
    "PALO SANTO",
    "PIRANE",
    "VILLA KILOMETRO 213",
    "EL POTRILLO",
    "GENERAL MOSCONI",
    "EL QUEBRACHO"
  ],
  Y: [
    "ABDON CASTRO TOLAY",
    "ABRA PAMPA",
    "ABRALAITE",
    "AGUA DE CASTILLA",
    "CASABINDO",
    "COCHINOCA",
    "LA REDONDA",
    "PUESTO DEL MARQUEZ",
    "QUEBRALE\xD1A",
    "QUERA",
    "RINCONADILLAS",
    "SAN FRANCISCO DE ALFARCITO",
    "SANTA ANA DE LA PUNA",
    "SANTUARIO DE TRES POZOS",
    "TAMBILLOS",
    "TUSAQUILLAS",
    "AGUAS CALIENTES",
    "FLEMING",
    "PILA PARDO",
    "BARRIO EL MILAGRO",
    "BARRIO LA UNION",
    "EL CARMEN",
    "LOS LAPACHOS",
    "SAN VICENTE",
    "MANANTIALES",
    "MONTERRICO",
    "PAMPA BLANCA",
    "PERICO",
    "PUESTO VIEJO",
    "SAN ISIDRO",
    "SAN JUANCITO",
    "GUERRERO",
    "LA ALMONA",
    "LEON",
    "LOZANO",
    "OCLOYAS",
    "SAN SALVADOR DE JUJUY",
    "TESORERO",
    "LOS NOGALES",
    "SAN PABLO DE REYES",
    "YALA",
    "APARZO",
    "CIANZO",
    "COCTACA",
    "EL AGUILAR",
    "HIPOLITO YRIGOYEN",
    "HUMAHUACA",
    "PALCA DE APARZO",
    "PALCA DE VARAS",
    "RODERO",
    "TRES CRUCES",
    "UQUIA",
    "BANANAL",
    "BERMEJITO",
    "CAIMANCITO",
    "CALILEGUA",
    "CHALICAN",
    "FRAILE PINTADO",
    "LIBERTAD",
    "LIBERTADOR GENERAL SAN MARTIN",
    "PUEBLO LEDESMA",
    "MAIZ NEGRO",
    "PAULINA",
    "YUTO",
    "CARAHUNCO",
    "CENTRO FORESTAL",
    "PALPALA",
    "RIO BLANCO",
    "CASA COLORADA",
    "COYAGUAIMA",
    "LAGUNILLAS DE FARALLON",
    "LIVIARA",
    "LOMA BLANCA",
    "NUEVO PIRQUITAS",
    "OROSMAYO",
    "RINCONADA",
    "EL CEIBAL",
    "LOS ALISOS",
    "LOTEO NAVEA",
    "NUESTRA SE?ORA DEL ROSARIO",
    "SAN ANTONIO",
    "ARRAYANAL",
    "ARROYO COLORADO",
    "DON EMILIO",
    "EL ACHERAL",
    "EL PUESTO",
    "EL QUEMADO",
    "LA ESPERANZA",
    "LA MANGA",
    "LA MENDIETA",
    "MIRAFLORES",
    "PALOS BLANCOS",
    "PARAPETI",
    "PIEDRITAS",
    "RODEITO",
    "ROSARIO DE RIO GRANDE",
    "SAN LUCAS",
    "SAN PEDRO",
    "SAUZAL",
    "EL FUERTE",
    "EL PIQUETE",
    "EL TALAR",
    "PALMA SOLA",
    "PUENTE LAVAYEN",
    "SANTA CLARA",
    "VINALITO",
    "CASIRA",
    "CIENEGA",
    "CIENEGUILLAS",
    "CUSI CUSI",
    "EL ANGOSTO",
    "LA CIENEGA",
    "MISARRUMI",
    "ORATORIO",
    "PAICONE",
    "SAN JUAN DE OROS",
    "SANTA CATALINA",
    "YOSCABA",
    "CATUA",
    "CORANZULI",
    "EL TORO",
    "HUANCAR",
    "JAMA",
    "MINA PROVIDENCIA",
    "OLACAPATO",
    "OLAROZ CHICO",
    "PASTOS CHICOS",
    "PUESTO SEY",
    "SAN JUAN DE QUILLAQUES",
    "SUSQUES",
    "COLONIA SAN JOSE",
    "YACORAITE",
    "HUACALERA",
    "JUELLA",
    "MAIMARA",
    "TILCARA",
    "BARCENA",
    "EL MORENO",
    "PUERTA DE COLORADOS",
    "PURMAMARCA",
    "TUMBAYA",
    "VOLCAN",
    "CASPALA",
    "PAMPICHUELA",
    "SAN FRANCISCO",
    "SANTA ANA",
    "VALLE COLORADO",
    "VALLE GRANDE",
    "BARRIOS",
    "CANGREJILLOS",
    "EL CONDOR",
    "LA INTERMEDIA",
    "LA QUIACA",
    "LLULLUCHAYOC",
    "PUMAHUASI",
    "SAN JOSE",
    "YAVI CHICO",
    "LA TOMA"
  ],
  L: [
    "DOBLAS",
    "MACACHIN",
    "MIGUEL RIGLOS",
    "ROLON",
    "TOMAS M. ANCHORENA",
    "ANZOATEGUI",
    "LA ADELA",
    "ANGUIL",
    "SANTA ROSA",
    "CATRILO",
    "LA GLORIA",
    "LONQUIMAY",
    "URIBURU",
    "CONHELO",
    "EDUARDO CASTEX",
    "MAURICIO MAYER",
    "MONTE NIEVAS",
    "RUCANELO",
    "WINIFREDA",
    "GOBERNADOR DUVAL",
    "PUELCHES",
    "SANTA ISABEL",
    "BERNARDO LARROUDE",
    "CEBALLOS",
    "CORONEL HILARIO LAGOS",
    "INTENDENTE ALVEAR",
    "SARAH",
    "VERTIZ",
    "ALGARROBO DEL AGUILA",
    "LA HUMADA",
    "ALPACHIRI",
    "GENERAL MANUEL J. CAMPOS",
    "GUATRACHE",
    "PERU",
    "SANTA TERESA",
    "ABRAMO",
    "BERNASCONI",
    "GENERAL SAN MARTIN",
    "HUCAL",
    "JACINTO ARAUZ",
    "CUCHILLO CO",
    "LA REFORMA",
    "LIMAY MAHUIDA",
    "CARRO QUEMADO",
    "LOVENTUE",
    "LUAN TORO",
    "TELEN",
    "VICTORICA",
    "AGUSTONI",
    "DORILA",
    "GENERAL PICO",
    "SPELUZZI",
    "TREBOLARES",
    "CASA DE PIEDRA",
    "PUELEN",
    "25 DE MAYO",
    "COLONIA BARON",
    "COLONIA SAN JOSE",
    "MIGUEL CANE",
    "QUEMU QUEMU",
    "RELMO",
    "VILLA MIRASOL",
    "CALEUFU",
    "INGENIERO FOSTER",
    "LA MARUJA",
    "PARERA",
    "PICHI HUINCA",
    "QUETREQUEN",
    "RANCUL",
    "ADOLFO VAN PRAET",
    "ALTA ITALIA",
    "DAMIAN MAISONAVE",
    "EMBAJADOR MARTINI",
    "FALUCHO",
    "INGENIERO LUIGGI",
    "OJEDA",
    "REALICO",
    "CACHIRULO",
    "NAICO",
    "TOAY",
    "ARATA",
    "METILEO",
    "TRENEL",
    "ATALIVA ROCA",
    "CHACHARRAMENDI",
    "COLONIA SANTA MARIA",
    "GENERAL ACHA",
    "QUEHUE",
    "UNANUE"
  ],
  F: [
    "AIMOGASTA",
    "MACHIGASTA",
    "SAN ANTONIO",
    "ARAUCO",
    "BA\xD1ADO DE LOS PANTANOS",
    "ESTACION MAZAN",
    "TERMAS DE SANTA TERESITA",
    "VILLA MAZAN",
    "LA RIOJA",
    "AMINGA",
    "ANILLACO",
    "ANJULLON",
    "CHUQUIS",
    "LOS MOLINOS",
    "PINCHAS",
    "SAN PEDRO",
    "SANTA VERA CRUZ",
    "AICU\xD1A",
    "GUANDACOL",
    "SANTA CLARA",
    "LOS PALACIOS",
    "PAGANCILLO",
    "BANDA FLORIDA",
    "VILLA UNION",
    "CHAMICAL",
    "POLCO",
    "ANGUINAN",
    "CHILECITO",
    "LA PUNTILLA",
    "LOS SARMIENTOS",
    "SAN MIGUEL",
    "COLONIA ANGUINAN",
    "COLONIA CATINZACO",
    "COLONIA MALLIGASTA",
    "COLONIA VICHIGASTA",
    "GUANCHIN",
    "MALLIGASTA",
    "MIRANDA",
    "NONOGASTA",
    "SAN NICOLAS",
    "SANTA FLORENTINA",
    "SA\xD1OGASTA",
    "TILIMUQUI",
    "VICHIGASTA",
    "ALTO CARRIZAL",
    "ANGULOS",
    "ANTINACO",
    "BAJO CARRIZAL",
    "CAMPANAS",
    "CHA\xD1ARMUYO",
    "FAMATINA",
    "LA CUADRA",
    "PITUIL",
    "PLAZA VIEJA",
    "SANTA CRUZ",
    "SANTO DOMINGO",
    "PUNTA DE LOS LLANOS",
    "TAMA",
    "CASTRO BARROS",
    "CHA\xD1AR",
    "LOMA BLANCA",
    "OLTA",
    "MALANZAN",
    "NACATE",
    "PORTEZUELO",
    "VILLA CASTELLI",
    "AMBIL",
    "COLONIA ORTIZ DE OCAMPO",
    "MILAGRO",
    "OLPAS",
    "SANTA RITA DE CATUNA",
    "ULAPES",
    "JAGUE",
    "VILLA SAN JOSE DE VINCHINA",
    "AMANA",
    "PATQUIA",
    "CHEPES",
    "DESIDERIO TELLO",
    "ALPASINCHE",
    "AMUSCHINA",
    "ANDOLUCAS",
    "CHAUPIHUASI",
    "CUIPAN",
    "LAS TALAS",
    "LOS ROBLES",
    "SALICAS",
    "SAN BLAS",
    "SHAQUI",
    "SURIYACO",
    "TUYUBIL",
    "VILLA SANAGASTA"
  ],
  M: [
    "1A. SECCION",
    "2A. SECCION",
    "3A. SECCION",
    "4A. SECCION",
    "5A. SECCION",
    "6A. SECCION",
    "7A. SECCION",
    "8A. SECCION",
    "9A. SECCION",
    "10A. SECCION",
    "11A. SECCION",
    "BOWEN",
    "CARMENSA",
    "GENERAL ALVEAR",
    "LOS COMPARTOS",
    "GODOY CRUZ",
    "GOBERNADOR BENEGAS",
    "LAS TORTUGAS",
    "PRESIDENTE SARMIENTO",
    "SAN FRANCISCO DEL MONTE",
    "VILLA HIPODROMO",
    "VILLA MARINI",
    "COLONIA SEGOVIA",
    "BERMEJO",
    "BUENA NUEVA",
    "CAPILLA DEL ROSARIO",
    "DORREGO",
    "EL SAUCE",
    "GENERAL BELGRANO",
    "JESUS NAZARENO",
    "LAS CA\xD1AS",
    "GUAYMALLEN",
    "PEDRO MOLINA",
    "RODEO DE LA CRUZ",
    "SAN JOSE",
    "VILLA NUEVA",
    "LA PRIMAVERA",
    "LOS CORRALITOS",
    "PUENTE DE HIERRO",
    "INGENIERO GIAGNONI",
    "JUNIN",
    "LA COLONIA",
    "LOS BARRIALES",
    "MEDRANO",
    "PHILLIPS",
    "RODRIGUEZ PE\xD1A",
    "DESAGUADERO",
    "LA PAZ",
    "VILLA ANTIGUA",
    "BLANCO ENCALADA",
    "JOCOLI",
    "LAS CUEVAS",
    "CAPDEVILA",
    "LAS HERAS",
    "EL ALGARROBAL",
    "EL BORBOLLON",
    "EL CHALLAO",
    "EL PLUMERILLO",
    "EL RESGUARDO",
    "EL ZAPALLAR",
    "LA CIENEGUITA",
    "PANQUEHUA",
    "SIERRAS DE ENCALADA",
    "LOS PENITENTES",
    "POLVAREDAS",
    "PUENTE DEL INCA",
    "PUNTA DE VACAS",
    "USPALLATA",
    "BARRIO ALTO DEL OLVIDO",
    "BARRIO JOCOLI II",
    "BARRIO LA PALMERA",
    "BARRIO LA PEGA",
    "BARRIO LAGUNAS DE BARTOLUZZI",
    "BARRIO LOS JARILLEROS",
    "BARRIO LOS OLIVOS",
    "BARRIO VIRGEN DEL ROSARIO",
    "COSTA DE ARAUJO",
    "EL PARAMILLO",
    "EL VERGEL",
    "BARRIO LA ESPERANZA",
    "INGENIERO GUSTAVO ANDRE",
    "JOCOLI VIEJO",
    "LAS VIOLETAS",
    "3 DE MAYO",
    "VILLA TULUMAYA",
    "AGRELO",
    "BARRIO PERDRIEL IV",
    "CACHEUTA",
    "COSTA FLORES",
    "EL CARRIZAL",
    "EL CARMELO",
    "EL SALTO",
    "LAS CARDITAS",
    "LOS MANANTIALES",
    "LAS COMPUERTAS",
    "LAS VEGAS",
    "PIEDRAS BLANCAS",
    "VALLE DEL SOL",
    "VILLA EL REFUGIO",
    "CARRODILLA",
    "CHACRAS DE CORIA",
    "LUJAN DE CUYO",
    "LA PUNTILLA",
    "MAYOR DRUMMOND",
    "VISTALBA",
    "BARRIO ADINA I Y II",
    "PERDRIEL",
    "POTRERILLOS",
    "UGARTECHE",
    "BARRANCAS",
    "BARRIO JESUS DE NAZARET",
    "CRUZ DE PIEDRA",
    "EL PEDREGAL",
    "FRAY LUIS BELTRAN",
    "COQUIMBITO",
    "GENERAL GUTIERREZ",
    "LUZURIAGA",
    "MAIPU",
    "RODEO DEL MEDIO",
    "RUSSELL",
    "SAN ROQUE",
    "VILLA TERESA",
    "AGUA ESCONDIDA",
    "LAS LE\xD1AS",
    "MALARGUE",
    "ANDRADE",
    "BARRIO COOPERATIVA LOS CAMPAMENTOS",
    "BARRIO RIVADAVIA",
    "EL MIRADOR",
    "LA CENTRAL",
    "LA ESPERANZA",
    "CUADRO ORTEGA",
    "LA FLORIDA",
    "LA LIBERTAD",
    "LOS ARBOLES",
    "LOS CAMPAMENTOS",
    "MUNDO NUEVO",
    "REDUCCION DE ABAJO",
    "RIVADAVIA",
    "SANTA MARIA DE ORO",
    "BARRIO CARRASCO",
    "BARRIO EL CEPILLO",
    "CHILECITO",
    "EUGENIO BUSTOS",
    "LA CONSULTA",
    "PAREDITAS",
    "SAN CARLOS",
    "ALTO VERDE",
    "BARRIO CHIVILCOY",
    "BARRIO EMANUEL",
    "BARRIO LA ESTACION",
    "BARRIO LOS CHARABONES",
    "BARRIO NTRA. SRA. DE FATIMA",
    "CHAPANAY",
    "CHIVILCOY",
    "EL ESPINO",
    "EL RAMBLON",
    "MONTECASEROS",
    "NUEVA CALIFORNIA",
    "PALMIRA",
    "CIUDAD DE SAN MARTIN",
    "TRES PORTE\xD1AS",
    "BARRIO CAMPOS EL TOLEDANO",
    "BARRIO EL NEVADO",
    "BARRIO EMPLEADOS DE COMERCIO",
    "BARRIO INTENDENCIA",
    "CAPITAN MONTOYA",
    "BARRIO ECHEVERRIA",
    "BARRIO LAS ROSAS",
    "BARRIO PRIMAVERA",
    "EL NIHUIL",
    "EL SOSNEADO",
    "EL TROPEZON",
    "GOUDGE",
    "JAIME PRATS",
    "LA LLAVE NUEVA",
    "LAS MALVINAS",
    "LOS REYUNOS",
    "MONTE COMAN",
    "POBRE DIABLO",
    "PUNTA DEL AGUA",
    "RAMA CAIDA",
    "REAL DEL PADRE",
    "SALTO DE LAS ROSAS",
    "CUADRO NACIONAL",
    "SAN RAFAEL",
    "25 DE MAYO",
    "VILLA ATUEL",
    "VILLA ATUEL NORTE",
    "BARRIO 12 DE OCTUBRE",
    "BARRIO MARIA AUXILIADORA",
    "BARRIO MOLINA CABRERA",
    "LA DORMIDA",
    "LAS CATITAS",
    "SANTA ROSA",
    "BARRIO SAN CAYETANO",
    "CAMPO LOS ANDES",
    "COLONIA LAS ROSAS",
    "EL MANZANO",
    "LOS SAUCES",
    "TUNUYAN",
    "VISTA FLORES",
    "BARRIO BELGRANO NORTE",
    "CORDON DEL PLATA",
    "EL PERAL",
    "EL ZAMPAL",
    "LA ARBOLEDA",
    "TUPUNGATO",
    "VILLA BASTIAS"
  ],
  N: [
    "APOSTOLES",
    "AZARA",
    "BARRIO RURAL",
    "ESTACION APOSTOLES",
    "PINDAPOY",
    "RINCON DE AZARA",
    "SAN JOSE",
    "TRES CAPONES",
    "ARISTOBULO DEL VALLE",
    "CAMPO GRANDE",
    "DOS DE MAYO NUCLEO I",
    "NUCLEO I",
    "NUCLEO II",
    "DOS DE MAYO NUCLEO III",
    "KILOMETRO 17 (RUTA 8)",
    "1 DE MAYO",
    "PUEBLO ILLIA",
    "SALTO ENCANTADO",
    "BARRIO DEL LAGO",
    "BONPLAND",
    "CANDELARIA",
    "CERRO CORA",
    "LORETO",
    "MARTIRES",
    "PROFUNDIDAD",
    "PUERTO SANTA ANA",
    "SANTA ANA",
    "BARRIO NUEVO GARUPA",
    "GARUPA",
    "NEMESIO PARMA",
    "POSADAS",
    "BARRA CONCEPCION",
    "CONCEPCION DE LA SIERRA",
    "LA CORITA",
    "SANTA MARIA",
    "COLONIA VICTORIA",
    "ELDORADO",
    "MARIA MAGDALENA",
    "NUEVA DELICIA",
    "9 DE JULIO",
    "9 DE JULIO KILOMETRO 20",
    "PUEBLO NUEVO",
    "PUERTO MADO",
    "PUERTO PINARES",
    "SANTIAGO DE LINIERS",
    "VALLE HERMOSO",
    "VILLA ROULET",
    "ALMIRANTE BROWN",
    "BERNARDO DE IRIGOYEN",
    "CABUREI",
    "DOS HERMANAS",
    "INTEGRACION",
    "PI\xD1ALITO NORTE",
    "PUERTO ANDRESITO",
    "PUERTO DESEADO",
    "SAN ANTONIO",
    "EL SOBERBIO",
    "FRACRAN",
    "SAN VICENTE",
    "ESPERANZA",
    "LIBERTAD",
    "PUERTO IGUAZU",
    "VILLA COOPERATIVA",
    "WANDA",
    "ALMAFUERTE",
    "ARROYO DEL MEDIO",
    "CAA - YARI",
    "CERRO AZUL",
    "DOS ARROYOS",
    "GOBERNADOR LOPEZ",
    "LEANDRO N. ALEM",
    "OLEGARIO V. ANDRADE",
    "VILLA LIBERTAD",
    "CAPIOVI",
    "CAPIOVICI\xD1O",
    "EL ALCAZAR",
    "GARUHAPE",
    "MBOPICUA",
    "PUERTO LEONI",
    "PUERTO RICO",
    "RUIZ DE MONTOYA",
    "SAN ALBERTO",
    "SAN GOTARDO",
    "SAN MIGUEL",
    "VILLA AKERMAN",
    "VILLA URRUTIA",
    "BARRIO CUATRO BOCAS",
    "BARRIO GUATAMBU",
    "BARIO ITA",
    "CARAGUATAY",
    "LAHARRAGUE",
    "MONTECARLO",
    "PIRAY KILOMETRO 18",
    "PUERTO PIRAY",
    "TARUMA",
    "VILLA PARODI",
    "ALBERDI",
    "BARRIO ESCUELA 461",
    "BARRIO ESCUELA 633",
    "CAMPO RAMON",
    "CAMPO VIERA",
    "EL SALTO",
    "GENERAL ALVEAR",
    "GUARANI",
    "LOS HELECHOS",
    "OBERA",
    "PANAMBI",
    "PANAMBI KILOMETRO 8",
    "PANAMBI KILOMETRO 15",
    "SAN MARTIN",
    "VILLA BONITA",
    "BARRIO TUNGOIL",
    "COLONIA POLANA",
    "CORPUS",
    "DOMINGO SAVIO",
    "GENERAL URQUIZA",
    "GOBERNADOR ROCA",
    "HELVECIA",
    "HIPOLITO YRIGOYEN",
    "JARDIN AMERICA",
    "OASIS",
    "ROCA CHICA",
    "SAN IGNACIO",
    "SANTO PIPO",
    "FLORENTINO AMEGHINO",
    "ITACARUARE",
    "MOJON GRANDE",
    "SAN JAVIER",
    "CRUCE CABALLERO",
    "PARAISO",
    "PI\xD1ALITO SUR",
    "SAN PEDRO",
    "TOBUNA",
    "ALBA POSSE",
    "COLONIA ALICIA",
    "ALICIA BAJA",
    "COLONIA AURORA",
    "SAN FRANCISCO DE ASIS",
    "SANTA RITA",
    "25 DE MAYO"
  ],
  Q: [
    "ALUMINE",
    "MOQUEHUE",
    "VILLA PEHUENIA",
    "AGUADA SAN ROQUE",
    "A\xD1ELO",
    "SAN PATRICIO DEL CHA\xD1AR",
    "LAS COLORADAS",
    "PIEDRA DEL AGUILA",
    "SANTO TOMAS",
    "ARROYITO",
    "BARRIO RUCA LUHE",
    "CENTENARIO",
    "CUTRAL CO",
    "EL CHOCON",
    "MARI MENUCO",
    "NEUQUEN",
    "11 DE OCTUBRE",
    "PLAZA HUINCUL",
    "PLOTTIER",
    "SENILLOSA",
    "VILLA EL CHOCON",
    "VISTA ALEGRE NORTE",
    "VISTA ALEGRE SUR",
    "CHOS MALAL",
    "TRICAO MALAL",
    "VILLA DEL CURI LEUVU",
    "JUNIN DE LOS ANDES",
    "SAN MARTIN DE LOS ANDES",
    "LAGO MELIQUINA",
    "CHORRIACA",
    "LONCOPUE",
    "VILLA LA ANGOSTURA",
    "VILLA TRAFUL",
    "ANDACOLLO",
    "HUINGANCO",
    "LAS OVEJAS",
    "LOS MICHES",
    "MANZANO AMARGO",
    "VARVARCO",
    "VILLA DEL NAHUEVE",
    "CAVIAHUE",
    "COPAHUE",
    "EL CHOLAR",
    "EL HUECU",
    "TAQUIMILAN",
    "BARRANCAS",
    "BUTA RANQUIL",
    "OCTAVIO PICO",
    "RINCON DE LOS SAUCES",
    "EL SAUCE",
    "PASO AGUERRE",
    "PICUN LEUFU",
    "BAJADA DEL AGRIO",
    "LA BUITRERA",
    "LAS LAJAS",
    "QUILI MALAL",
    "LOS CATUTOS",
    "COVUNCO CENTRO",
    "MARIANO MORENO",
    "RAMON M. CASTRO",
    "ZAPALA"
  ],
  R: [
    "BAHIA CREEK",
    "EL CONDOR",
    "EL JUNCAL",
    "GUARDIA MITRE",
    "LA LOBERIA",
    "LOTEO COSTA DE RIO",
    "POZO SALADO",
    "SAN JAVIER",
    "VIEDMA",
    "BARRIO UNION",
    "CHELFORO",
    "CHIMPAY",
    "CHOELE CHOEL",
    "CORONEL BELISLE",
    "DARWIN",
    "LAMARQUE",
    "LUIS BELTRAN",
    "POMONA",
    "VILLA ARELAUQUEN",
    "BARRIO EL PILAR",
    "COLONIA SUIZA",
    "EL BOLSON",
    "EL FOYEL",
    "MALLIN AHOGADO",
    "RIO VILLEGAS",
    "SAN CARLOS DE BARILOCHE",
    "VILLA CAMPANARIO",
    "VILLA LLAO LLAO",
    "VILLA CATEDRAL",
    "VILLA LOS COIHUES",
    "VILLA MASCARDI",
    "BARRIO COLONIA CONESA",
    "GENERAL CONESA",
    "BARRIO PLANTA COMPRESORA DE GAS",
    "AGUADA GUZMAN",
    "CERRO POLICIA",
    "EL CUY",
    "LAS PERLAS",
    "MENCUE",
    "NAUPA HUEN",
    "PASO CORDOVA",
    "VALLE AZUL",
    "ALLEN",
    "BAJO SAN CAYETANO",
    "BARDA DEL MEDIO",
    "BARRIO BLANCO",
    "BARRIO CALLE CIEGA N? 10",
    "BARRIO CALLE CIEGA N? 6",
    "BARRIO CANALE",
    "BARRIO CHACRA MONTE",
    "BARRIO COSTA ESTE",
    "BARRIO COSTA LINDA",
    "BARRIO COSTA OESTE",
    "BARRIO DESTACAMENTO",
    "BARRIO EL LABRADOR",
    "BARRIO EL MARUCHITO",
    "BARRIO EL PETROLEO",
    "BARRIO EMERGENTE",
    "COLONIA FATIMA",
    "BARRIO FRONTERA",
    "BARRIO GUERRICO",
    "BARRIO ISLA 10",
    "BARRIO LA BARDA",
    "BARRIO LA COSTA",
    "BARRIO LA DEFENSA",
    "BARRIO LA HERRADURA",
    "BARRIO LA RIBERA - BARRIO APYCAR",
    "BARRIO LAS ANGUSTIAS",
    "BARRIO LUISILLO",
    "BARRIO MAR DEL PLATA",
    "BARRIO MARIA ELVIRA",
    "BARRIO MO\xD1O AZUL",
    "BARRIO MOSCONI",
    "BARRIO NORTE",
    "BARRIO PINAR",
    "BARRIO PORVENIR",
    "BARRIO EL TREINTA",
    "BARRIO GORETTI",
    "BARRIO PUENTE 83",
    "BARRIO PUENTE DE MADERA",
    "BARRIO TRES LUCES",
    "TRES LUCES",
    "SANTA LUCIA",
    "BARRIO SANTA RITA",
    "CATRIEL",
    "CERVANTES",
    "CHICHINALES",
    "CINCO SALTOS",
    "BARRIO PRESIDENTE PERON",
    "CIPOLLETTI",
    "BARRIO LA LOR",
    "CONTRALMIRANTE CORDERO",
    "FERRI",
    "GENERAL ENRIQUE GODOY",
    "GENERAL FERNANDEZ ORO",
    "GENERAL ROCA",
    "BARRIO PINO AZUL",
    "INGENIERO LUIS A. HUERGO",
    "INGENIERO OTTO KRAUSE",
    "MAINQUE",
    "PENINSULA RUCA CO",
    "SARGENTO VIDAL",
    "VILLA ALBERDI",
    "VILLA DEL PARQUE",
    "VILLA MANZANO",
    "VILLA REGINA",
    "VILLA SAN ISIDRO",
    "COMICO",
    "CONA NIYEU",
    "MINISTRO RAMOS MEXIA",
    "PRAHUANIYEU",
    "SIERRA COLORADA",
    "TRENETA",
    "YAMINUE",
    "LAS BAYAS",
    "MAMUEL CHOIQUE",
    "\xD1ORQUINCO",
    "OJOS DE AGUA",
    "RIO CHICO",
    "BARRIO ESPERANZA",
    "COLONIA JULIA Y ECHARREN",
    "JUVENTUD UNIDA",
    "PICHI MAHUIDA",
    "RIO COLORADO",
    "SALTO ANDERSEN",
    "CA\xD1ADON CHILENO",
    "COMALLO",
    "DINA HUAPI",
    "LAGUNA BLANCA",
    "\xD1IRIHUAU",
    "PILCANIYEU",
    "PILQUINIYEU DEL LIMAY",
    "VILLA LLANQUIN",
    "ESTACION EMPALME",
    "LAS GRUTAS",
    "PLAYAS DORADAS",
    "PUERTO SAN ANTONIO ESTE",
    "PUNTA COLORADA",
    "SACO VIEJO",
    "SAN ANTONIO OESTE",
    "SIERRA GRANDE",
    "AGUADA CECILIO",
    "ARROYO LOS BERROS",
    "ARROYO VENTANA",
    "NAHUEL NIYEU",
    "SIERRA PAILEMAN",
    "VALCHETA",
    "AGUADA DE GUERRA",
    "CLEMENTE ONELLI",
    "COLAN CONUE",
    "EL CAIN",
    "INGENIERO JACOBACCI",
    "LOS MENUCOS",
    "MAQUINCHAO",
    "MINA SANTA TERESITA",
    "PILQUINIYEU"
  ],
  A: [
    "APOLINARIO SARAVIA",
    "CEIBALITO",
    "CENTRO 25 DE JUNIO",
    "CORONEL MOLLINEDO",
    "CORONEL OLLEROS",
    "EL QUEBRACHAL",
    "GAONA",
    "GENERAL PIZARRO",
    "JOAQUIN V. GONZALEZ",
    "LAS LAJITAS",
    "LUIS BURELA",
    "MACAPILLO",
    "NUESTRA SE\xD1ORA DE TALAVERA",
    "PIQUETE CABADO",
    "RIO DEL VALLE",
    "TOLLOCHE",
    "CACHI",
    "PAYOGASTA",
    "CAFAYATE",
    "TOLOMBOM",
    "ATOCHA",
    "BARRIO SAN RAFAEL",
    "LA CIENAGA",
    "LAS COSTAS",
    "SALTA",
    "CC EL TIPAL",
    "CC LA ALMUDENA",
    "VILLA SAN LORENZO",
    "CERRILLOS",
    "LA MERCED",
    "SAN AGUSTIN",
    "VILLA LOS ALAMOS",
    "BARRIO EL CONGRESO",
    "BARRIO LAS TUNAS",
    "BARRIO LOS OLMOS",
    "BARRIO LOS PINARES",
    "LA MAROMA",
    "BARRIO LA ROTONDA",
    "BARRIO SANTA TERESITA",
    "CHICOANA",
    "EL CARRIL",
    "CAMPO SANTO",
    "COBOS",
    "EL BORDO",
    "GENERAL GUEMES",
    "AGUARAY",
    "CAMPAMENTO VESPUCIO",
    "CAMPICHUELO",
    "CAMPO DURAN",
    "CAPIAZUTI",
    "CARBONCITO",
    "CORONEL CORNEJO",
    "DRAGONES",
    "EMBARCACION",
    "MISION TIERRAS FISCALES",
    "GENERAL BALLIVIAN",
    "GENERAL MOSCONI",
    "RECAREDO",
    "HICKMAN",
    "MISION CHAQUE\xD1A",
    "BARRIO EL MILAGRO",
    "BARRIO EL JARDIN DE SAN MARTIN",
    "MISION EL CRUCE",
    "MISION KILOMETRO 6",
    "PACARA",
    "PADRE LOZANO",
    "PIQUIRENDA",
    "PROFESOR SALVADOR MAZZA",
    "TARTAGAL",
    "TOBANTIRENDA",
    "TRANQUITAS",
    "YACUY",
    "GUACHIPAS",
    "IRUYA",
    "ISLA DE CA\xD1AS",
    "PUEBLO VIEJO",
    "LA CALDERA",
    "VAQUEROS",
    "EL JARDIN",
    "EL TALA",
    "LA CANDELARIA",
    "COBRES",
    "LA POMA",
    "AMPASCACHI",
    "CABRA CORRAL",
    "CORONEL MOLDES",
    "LA VI\xD1A",
    "TALAPAMPA",
    "OLACAPATO",
    "SAN ANTONIO DE LOS COBRES",
    "SANTA ROSA DE LOS PASTOS GRANDES",
    "TOLAR GRANDE",
    "EL GALPON",
    "EL TUNAL",
    "LUMBRERAS",
    "SAN JOSE DE METAN",
    "METAN VIEJO",
    "RIO PIEDRAS",
    "SAN JOSE DE ORQUERA",
    "LA PUERTA",
    "MOLINOS",
    "SECLANTAS",
    "AGUAS BLANCAS",
    "COLONIA SANTA ROSA",
    "LA MISION",
    "EL TABACAL",
    "HIPOLITO YRIGOYEN",
    "PICHANAL",
    "SAN RAMON DE LA NUEVA ORAN",
    "URUNDEL",
    "ALTO DE LA SIERRA",
    "CAPITAN JUAN PAGE",
    "CORONEL JUAN SOLA",
    "HITO 1",
    "LA UNION",
    "LOS BLANCOS",
    "PLUMA DE PATO",
    "RIVADAVIA",
    "SANTA MARIA",
    "SANTA ROSA",
    "SANTA VICTORIA ESTE",
    "ANTILLA",
    "COPO QUILE",
    "EL NARANJO",
    "EL POTRERO",
    "ROSARIO DE LA FRONTERA",
    "SAN FELIPE",
    "CAMPO QUIJANO",
    "LA MERCED DEL ENCON",
    "LA SILLETA",
    "ROSARIO DE LERMA",
    "ANGASTACO",
    "ANIMANA",
    "EL BARRIAL",
    "SAN CARLOS",
    "ACOYTE",
    "CAMPO LA CRUZ",
    "LOS TOLDOS",
    "NAZARENO",
    "POSCAYA",
    "SAN MARCOS",
    "SANTA VICTORIA"
  ],
  J: [
    "EL RINCON",
    "CAMPO AFUERA",
    "VILLA AMPACAMA",
    "VILLA GENERAL SAN MARTIN",
    "LAS TAPIAS",
    "VILLA EL SALVADOR",
    "VILLA SEFAIR (TALACASTO)",
    "BARREAL",
    "VILLA PITUIL",
    "CALINGASTA",
    "TAMBERIAS",
    "SAN JUAN",
    "BERMEJO",
    "BARRIO JUSTO P. CASTRO IV",
    "CAUCETE",
    "LAS TALAS",
    "LOS MEDANOS",
    "MARAYES",
    "PIE DE PALO",
    "VALLECITO",
    "VILLA INDEPENDENCIA",
    "CHIMBAS",
    "EL MOGOTE",
    "VILLA PAULA ALBARRACIN",
    "ANGUALASTO",
    "BELLA VISTA",
    "IGLESIA",
    "LAS FLORES",
    "PISMANTA",
    "RODEO",
    "TUDCUM",
    "EL MEDANO",
    "GRAN CHINA",
    "HUACO",
    "MOGNA",
    "NIQUIVIL",
    "EL FISCAL",
    "LA FALDA",
    "PAMPA VIEJA",
    "SAN ISIDRO",
    "SAN JOSE DE JACHAL",
    "VILLA MALVINAS ARGENTINAS",
    "VILLA MERCEDES",
    "COLONIA FIORITO",
    "LAS CHACRITAS",
    "9 DE JULIO",
    "BARRIO MUNICIPAL",
    "BARRIO RUTA 40",
    "CARPINTERIA",
    "LAS PIEDRITAS",
    "QUINTO CUARTEL",
    "LA RINCONADA",
    "VILLA ABERASTAIN",
    "VILLA BARBOZA",
    "VILLA NACUSI",
    "VILLA CENTENARIO",
    "EL MEDANITO",
    "RAWSON",
    "VILLA KRAUSE",
    "VILLA BOLA\xD1OS",
    "RIVADAVIA",
    "BARRIO SADOP",
    "DOS ACEQUIAS",
    "VILLA DOMINGUITO",
    "VILLA DON BOSCO",
    "VILLA SAN MARTIN",
    "ALTO DE SIERRA",
    "COLONIA GUTIERREZ",
    "SANTA LUCIA",
    "CA\xD1ADA HONDA",
    "CIENAGUITA",
    "COLONIA FISCAL",
    "DIVISADERO",
    "GUANACACHE",
    "LAS LAGUNAS",
    "LOS BERROS",
    "PEDERNAL",
    "PUNTA DEL MEDANO",
    "VILLA MEDIA AGUA",
    "VILLA IBA\xD1EZ",
    "ASTICA",
    "BALDE DEL ROSARIO",
    "CHUCUMA",
    "LOS BALDECITOS",
    "USNO",
    "VILLA SAN AGUSTIN",
    "EL ENCON",
    "TUPELI",
    "LA CHIMBERA",
    "VILLA BORJAS",
    "VILLA EL TANGO",
    "VILLA SANTA ROSA",
    "VILLA BASILIO NIEVAS",
    "VILLA TACU",
    "LA CA\xD1ADA"
  ],
  D: [
    "CANDELARIA",
    "LEANDRO N. ALEM",
    "LUJAN",
    "QUINES",
    "SAN FRANCISCO DEL MONTE DE ORO",
    "LA CALERA",
    "NOGOLI",
    "VILLA DE LA QUEBRADA",
    "VILLA GENERAL ROCA",
    "CAROLINA",
    "EL TRAPICHE",
    "ESTANCIA GRANDE",
    "FRAGA",
    "LA BAJADA",
    "LA FLORIDA",
    "LA TOMA",
    "RIOCITO",
    "RIO GRANDE",
    "SALADILLO",
    "CONCARAN",
    "CORTADERAS",
    "NASCHEL",
    "PAPAGAYOS",
    "RENCA",
    "SAN PABLO",
    "TILISARAO",
    "VILLA DEL CARMEN",
    "VILLA LARCA",
    "JUAN JORBA",
    "JUAN LLERENA",
    "JUSTO DARACT",
    "LA PUNILLA",
    "LAVAISSE",
    "NACION RANQUEL",
    "SAN JOSE DEL MORRO",
    "VILLA MERCEDES",
    "LA RIBERA",
    "VILLA REYNOLDS",
    "COUNTRY CLUB LOS CALDENES",
    "5TA BRIGADA",
    "VILLA SALLES",
    "ANCHORENA",
    "ARIZONA",
    "BAGUAL",
    "BATAVIA",
    "BUENA ESPERANZA",
    "FORTIN EL PATRIA",
    "FORTUNA",
    "LA MAROMA",
    "LOS OVEROS",
    "MARTIN DE LOYOLA",
    "NAHUEL MAPA",
    "NAVIA",
    "NUEVA GALIA",
    "UNION",
    "CARPINTERIA",
    "CERRO DE ORO",
    "LAFINUR",
    "LOS CAJONES",
    "LOS MOLLES",
    "MERLO",
    "SANTA ROSA DEL CONLARA",
    "TALITA",
    "ALTO PELADO",
    "ALTO PENCOSO",
    "BALDE",
    "BEAZLEY",
    "CAZADOR",
    "CHOSMES",
    "DESAGUADERO",
    "EL VOLCAN",
    "JARILLA",
    "CERRO COLORADO",
    "CRUZ DE PIEDRA",
    "EL CHORRILLO",
    "LAS CHACRAS",
    "SAN ROQUE",
    "CIUDAD DE LA PUNTA",
    "MOSMOTA",
    "POTRERO DE LOS FUNES",
    "SALINAS DEL BEBEDERO",
    "SAN JERONIMO",
    "SAN LUIS",
    "ZANJITAS",
    "LA VERTIENTE",
    "LAS AGUADAS",
    "LAS LAGUNAS",
    "PASO GRANDE",
    "POTRERILLO",
    "SAN MARTIN",
    "VILLA DE PRAGA"
  ],
  Z: [
    "COMANDANTE LUIS PIEDRABUENA",
    "PUERTO SANTA CRUZ",
    "CALETA OLIVIA",
    "CA\xD1ADON SECO",
    "FITZ ROY",
    "JARAMILLO",
    "KOLUEL KAIKE",
    "LAS HERAS",
    "PICO TRUNCADO",
    "PUERTO DESEADO",
    "TELLIER",
    "EL TURBIO",
    "JULIA DUFOUR",
    "MINA 3",
    "RIO GALLEGOS",
    "ROSPENTEK",
    "28 DE NOVIEMBRE",
    "YACIMIENTOS RIO TURBIO",
    "EL CALAFATE",
    "EL CHALTEN",
    "TRES LAGOS",
    "LOS ANTIGUOS",
    "PERITO MORENO",
    "PUERTO SAN JULIAN",
    "BAJO CARACOLES",
    "GOBERNADOR GREGORES",
    "HIPOLITO YRIGOYEN"
  ],
  S: [
    "ARMSTRONG",
    "BOUQUET",
    "LAS PAREJAS",
    "LAS ROSAS",
    "MONTES DE OCA",
    "TORTUGAS",
    "AREQUITO",
    "ARTEAGA",
    "BERAVEBU",
    "BIGAND",
    "CASILDA",
    "CHABAS",
    "CHA\xD1AR LADEADO",
    "GODEKEN",
    "LOS MOLINOS",
    "LOS NOGALES",
    "LOS QUIRQUINCHOS",
    "SAN JOSE DE LA ESQUINA",
    "SANFORD",
    "VILLADA",
    "ALDAO",
    "ANGELICA",
    "ATALIVA",
    "AURELIA",
    "BARRIOS ACAPULCO Y VERACRUZ",
    "BAUER Y SIGEL",
    "BELLA ITALIA",
    "CASTELLANOS",
    "COLONIA BICHA",
    "COLONIA CELLO",
    "COLONIA MARGARITA",
    "COLONIA RAQUEL",
    "CORONEL FRAGA",
    "EGUSQUIZA",
    "ESMERALDA",
    "ESTACION CLUCELLAS",
    "ESTACION SAGUIER",
    "EUSEBIA Y CAROLINA",
    "EUSTOLIA",
    "FRONTERA",
    "GARIBALDI",
    "HUMBERTO PRIMO",
    "JOSEFINA",
    "LEHMANN",
    "MARIA JUANA",
    "NUEVA LEHMANN",
    "PLAZA CLUCELLAS",
    "PLAZA SAGUIER",
    "ESTACION PRESIDENTE ROCA",
    "PRESIDENTE ROCA",
    "PUEBLO MARINI",
    "RAFAELA",
    "RAMONA",
    "SAN ANTONIO",
    "SAN VICENTE",
    "SANTA CLARA DE SAGUIER",
    "SUNCHALES",
    "SUSANA",
    "TACURAL",
    "VILA",
    "VILLA JOSEFINA",
    "VILLA SAN JOSE",
    "VIRGINIA",
    "ZENON PEREYRA",
    "ALCORTA",
    "BARRIO ARROYO DEL MEDIO",
    "BARRIO MITRE",
    "BOMBAL",
    "CA\xD1ADA RICA",
    "CEPEDA",
    "EMPALME VILLA CONSTITUCION",
    "FIRMAT",
    "GENERAL GELLY",
    "GODOY",
    "JUAN B. MOLINA",
    "JUNCAL",
    "LA VANGUARDIA",
    "MAXIMO PAZ",
    "PAVON",
    "PAVON ARRIBA",
    "PEYRANO",
    "RUEDA",
    "SANTA TERESA",
    "SARGENTO CABRAL",
    "STEPHENSON",
    "THEOBALD",
    "VILLA CONSTITUCION",
    "CAYASTA",
    "HELVECIA",
    "LOS ZAPALLOS",
    "SALADERO MARIANO CABAL",
    "SANTA ROSA DE CALCHINES",
    "AARON CASTELLANOS",
    "AMENABAR",
    "CAFFERATA",
    "CA\xD1ADA DEL UCLE",
    "CARMEN",
    "CARRERAS",
    "CHAPUY",
    "CHOVET",
    "CHRISTOPHERSEN",
    "DIEGO DE ALVEAR",
    "ELORTONDO",
    "HUGHES",
    "LA CHISPA",
    "LABORDEBOY",
    "LAZZARINO",
    "MAGGIOLO",
    "MARIA TERESA",
    "MELINCUE",
    "MIGUEL TORRES",
    "MURPHY",
    "RUFINO",
    "SAN EDUARDO",
    "SAN FRANCISCO DE SANTA FE",
    "SAN GREGORIO",
    "SANCTI SPIRITU",
    "SANTA ISABEL",
    "TEODELINA",
    "VENADO TUERTO",
    "VILLA CA\xD1AS",
    "WHEELWRIGHT",
    "ARROYO CEIBAL",
    "AVELLANEDA",
    "BERNA",
    "EL ARAZA",
    "EL RABON",
    "FLORENCIA",
    "GUADALUPE NORTE",
    "INGENIERO CHANOURDIE",
    "LA ISLETA",
    "LA SARITA",
    "LANTERI",
    "LAS GARZAS",
    "LAS TOSCAS",
    "LOS LAURELES",
    "MALABRIGO",
    "PARAJE SAN MANUEL",
    "PUERTO RECONQUISTA",
    "RECONQUISTA",
    "SAN ANTONIO DE OBLIGADO",
    "TACUARENDI",
    "VILLA ANA",
    "VILLA GUILLERMINA",
    "VILLA OCAMPO",
    "BARRIO CICARELLI",
    "BUSTINZA",
    "CA\xD1ADA DE GOMEZ",
    "CARRIZALES",
    "CLASSON",
    "COLONIA MEDICI",
    "CORREA",
    "LARGUIA",
    "LUCIO V. LOPEZ",
    "OLIVEROS",
    "PUEBLO ANDINO",
    "SALTO GRANDE",
    "SERODINO",
    "TOTORAS",
    "VILLA ELOISA",
    "VILLA LA RIVERA - OLIVEROS",
    "VILLA LA RIVERA - PUEBLO ANDINO",
    "ANGEL GALLARDO",
    "ARROYO AGUIAR",
    "ARROYO LEYES",
    "RINCON NORTE",
    "CABAL",
    "CAMPO ANDINO",
    "CANDIOTI",
    "EMILIA",
    "LAGUNA PAIVA",
    "LLAMBI CAMPBELL",
    "MONTE VERA",
    "NELSON",
    "PARAJE CHACO CHICO",
    "PARAJE LA COSTA",
    "RECREO",
    "RINCON POTRERO",
    "SAN JOSE DEL RINCON",
    "SANTA FE",
    "SANTO TOME",
    "SAUCE VIEJO",
    "VILLA ADELINA",
    "VILLA LAURA",
    "CAVOUR",
    "CULULU",
    "ELISA",
    "EMPALME SAN CARLOS",
    "ESPERANZA",
    "FELICIA",
    "FRANCK",
    "GRUTLY",
    "HIPATIA",
    "HUMBOLDT",
    "JACINTO L. ARAUZ",
    "LA PELADA",
    "LAS TUNAS",
    "MARIA LUISA",
    "MATILDE",
    "NUEVO TORINO",
    "PILAR",
    "PLAZA MATILDE",
    "PROGRESO",
    "PROVIDENCIA",
    "SA PEREYRA",
    "SAN AGUSTIN",
    "SAN CARLOS CENTRO",
    "SAN CARLOS NORTE",
    "SAN CARLOS SUD",
    "SAN JERONIMO DEL SAUCE",
    "SAN JERONIMO NORTE",
    "SAN MARIANO",
    "SANTA CLARA DE BUENA VISTA",
    "SANTO DOMINGO",
    "SARMIENTO",
    "ESTEBAN RAMS",
    "GATO COLORADO",
    "GREGORIA PEREZ DE DENIS",
    "LOGRO\xD1O",
    "MONTEFIORE",
    "POZO BORRADO",
    "SAN BERNARDO",
    "SANTA MARGARITA",
    "TOSTADO",
    "VILLA MINETTI",
    "ACEBAL",
    "ALBARELLOS",
    "ALVAREZ",
    "ALVEAR",
    "ARBILLA",
    "ARMINDA",
    "ARROYO SECO",
    "CARMEN DEL SAUCE",
    "CORONEL BOGADO",
    "CORONEL RODOLFO S. DOMINGUEZ",
    "CUATRO ESQUINAS",
    "EL CARAMELO",
    "FIGHIERA",
    "FUNES",
    "GENERAL LAGOS",
    "GRANADERO BAIGORRIA",
    "IBARLUCEA",
    "KILOMETRO 101",
    "LOS MUCHACHOS - LA ALBORADA",
    "MONTE FLORES",
    "PEREZ",
    "PI\xD1ERO",
    "PUEBLO ESTHER",
    "PUEBLO MU\xD1OZ",
    "PUEBLO URANGA",
    "PUERTO ARROYO SECO",
    "ROSARIO",
    "SOLDINI",
    "VILLA AMELIA",
    "VILLA DEL PLATA",
    "VILLA GOBERNADOR GALVEZ",
    "ZAVALLA",
    "AGUARA GRANDE",
    "AMBROSETTI",
    "ARRUFO",
    "BALNEARIO LA VERDE",
    "CAPIVARA",
    "CERES",
    "COLONIA ANA",
    "COLONIA BOSSI",
    "COLONIA ROSA",
    "CONSTANZA",
    "CURUPAYTI",
    "HERSILIA",
    "HUANQUEROS",
    "LA CABRAL",
    "LA LUCILA",
    "LA RUBIA",
    "LAS AVISPAS",
    "LAS PALMERAS",
    "MOISES VILLE",
    "MONIGOTES",
    "\xD1ANDUCITA",
    "PALACIOS",
    "SAN CRISTOBAL",
    "SAN GUILLERMO",
    "SANTURCE",
    "SOLEDAD",
    "SUARDI",
    "VILLA SARALEGUI",
    "VILLA TRINIDAD",
    "ALEJANDRA",
    "CACIQUE ARIACAIQUIN",
    "COLONIA DURAN",
    "LA BRAVA",
    "ROMANG",
    "SAN JAVIER",
    "AROCENA",
    "BALNEARIO MONJE",
    "BARRANCAS",
    "BARRIO CAIMA",
    "BARRIO EL PACAA - BARRIO COMIPINI",
    "BERNARDO DE IRIGOYEN",
    "CASALEGNO",
    "CENTENO",
    "CORONDA",
    "DESVIO ARIJON",
    "DIAZ",
    "GABOTO",
    "GALVEZ",
    "GESSLER",
    "IRIGOYEN",
    "LARRECHEA",
    "LOMA ALTA",
    "LOPEZ",
    "MACIEL",
    "MONJE",
    "PUERTO ARAGON",
    "SAN EUGENIO",
    "SAN FABIAN",
    "SAN GENARO",
    "SAN GENARO NORTE",
    "ANGELONI",
    "CAYASTACITO",
    "COLONIA DOLORES",
    "ESTHER",
    "GOBERNADOR CRESPO",
    "LA CRIOLLA",
    "LA PENCA Y CARAGUATA",
    "MARCELINO ESCALADA",
    "NARE",
    "PEDRO GOMEZ CELLO",
    "RAMAYON",
    "SAN JUSTO",
    "SAN MARTIN NORTE",
    "SILVA",
    "VERA Y PINTADO",
    "VIDELA",
    "CAPITAN BERMUDEZ",
    "CARCARA\xD1A",
    "CORONEL ARNOLD",
    "FRAY LUIS BELTRAN",
    "FUENTES",
    "LUIS PALACIOS",
    "PUERTO GENERAL SAN MARTIN",
    "PUJATO",
    "RICARDONE",
    "ROLDAN",
    "SAN JERONIMO SUD",
    "SAN LORENZO",
    "TIMBUES",
    "VILLA ELVIRA",
    "VILLA MUGUETA",
    "CA\xD1ADA ROSQUIN",
    "CARLOS PELLEGRINI",
    "CASAS",
    "CASTELAR",
    "COLONIA BELGRANO",
    "CRISPI",
    "EL TREBOL",
    "LANDETA",
    "LAS BANDURRIAS",
    "LAS PETACAS",
    "LOS CARDOS",
    "MARIA SUSANA",
    "PIAMONTE",
    "SAN JORGE",
    "SAN MARTIN DE LAS ESCOBAS",
    "SASTRE",
    "TRAILL",
    "WILDERMUTH",
    "CALCHAQUI",
    "CA\xD1ADA OMBU",
    "COLMENA",
    "FORTIN OLMOS",
    "GARABATO",
    "GOLONDRINA",
    "INTIYACO",
    "KILOMETRO 115",
    "LA GALLARETA",
    "LOS AMORES",
    "MARGARITA",
    "PARAJE 29",
    "POZO DE LOS INDIOS",
    "PUEBLO SANTA LUCIA",
    "TARTAGAL",
    "TOBA",
    "VERA"
  ],
  G: [
    "ARGENTINA",
    "CASARES",
    "MALBRAN",
    "VILLA GENERAL MITRE",
    "CAMPO GALLO",
    "CORONEL MANUEL L. RICO",
    "DONADEU",
    "SACHAYOJ",
    "SANTOS LUGARES",
    "ESTACION ATAMISQUI",
    "MEDELLIN",
    "VILLA ATAMISQUI",
    "COLONIA DORA",
    "HERRERA",
    "ICA\xD1O",
    "LUGONES",
    "REAL SAYANA",
    "VILLA MAILIN",
    "ABRA GRANDE",
    "ANTAJE",
    "ARDILES",
    "CA\xD1ADA ESCOBAR",
    "CHAUPI POZO",
    "CLODOMIRA",
    "HUYAMAMPA",
    "LA AURORA",
    "LA BANDA",
    "LA DARSENA",
    "LOS QUIROGA",
    "LOS SORIA",
    "SIMBOLAR",
    "TRAMO 16",
    "TRAMO 20",
    "BANDERA",
    "CUATRO BOCAS",
    "FORTIN INCA",
    "GUARDIA ESCOLTA",
    "EL DEAN",
    "EL MOJON",
    "EL ZANJON",
    "LOS CARDOZOS",
    "MACO",
    "MAQUITO",
    "MORALES",
    "PUESTO DE SAN ANTONIO",
    "SAN PEDRO",
    "SANTA MARIA",
    "SANTIAGO DEL ESTERO",
    "VUELTA DE LA BARRANCA",
    "YANDA",
    "EL CABURE",
    "LA FIRMEZA",
    "LOS PIRPINTOS",
    "LOS TIGRES",
    "MONTE QUEMADO",
    "NUEVA ESPERANZA",
    "PAMPA DE LOS GUANACOS",
    "SAN JOSE DEL BOQUERON",
    "URUTAU",
    "ANCAJAN",
    "CHOYA",
    "ESTACION LA PUNTA",
    "FRIAS",
    "LAPRIDA",
    "TAPSO",
    "VILLA LA PUNTA",
    "BANDERA BAJADA",
    "CASPI CORRAL",
    "COLONIA SAN JUAN",
    "EL CRUCERO",
    "KILOMETRO 30",
    "LA CA\xD1ADA",
    "LA INVERNADA",
    "MINERVA",
    "VACA HUA\xD1UNA",
    "VILLA FIGUEROA",
    "A\xD1ATUYA",
    "AVERIAS",
    "ESTACION TACA\xD1ITAS",
    "LA NENA",
    "LOS JURIES",
    "TOMAS YOUNG",
    "LAVALLE",
    "EL ARENAL",
    "EL BOBADAL",
    "EL CHARCO",
    "EL RINCON",
    "GRAMILLA",
    "LILO VIEJO",
    "ISCA YACU",
    "ISCA YACU SEMAUL",
    "POZO HONDO",
    "EL COLORADO",
    "EL CUADRADO",
    "MATARA",
    "SUNCHO CORRAL",
    "VILELAS",
    "YUCHAN",
    "VILLA SAN MARTIN",
    "VILLA UNION",
    "AEROLITO",
    "ALHUAMPA",
    "HASSE",
    "HERNAN MEJIA MIRAVAL",
    "LAS TINAJAS",
    "LIBERTAD",
    "PATAY",
    "PUEBLO PABLO TORELO",
    "QUIMILI",
    "ROVERSI",
    "TINTINA",
    "WEISBURD",
    "EL 49",
    "SOL DE JULIO",
    "VILLA OJO DE AGUA",
    "LAS DELICIAS",
    "POZO BETBEDER",
    "RAPELLI",
    "SANTO DOMINGO",
    "RAMIREZ DE VELAZCO",
    "SUMAMPA",
    "SUMAMPA VIEJO",
    "CHA\xD1AR POZO DE ABAJO",
    "CHAUCHILLAS",
    "COLONIA TINCO",
    "LA NUEVA DONOSA",
    "LOS MIRANDA",
    "LOS NU\xD1EZ",
    "MANSUPA",
    "POZUELOS",
    "RODEO DE VALDEZ",
    "SAUZAL",
    "TERMAS DE RIO HONDO",
    "VILLA GIMENEZ",
    "VILLA RIO HONDO",
    "VILLA TURISTICA DEL EMBALSE",
    "VINARA",
    "COLONIA ALPINA",
    "PALO NEGRO",
    "SELVA",
    "BELTRAN",
    "COLONIA EL SIMBOLAR",
    "FERNANDEZ",
    "INGENIERO FORRES",
    "VILMER",
    "CHILCA JULIANA",
    "LOS TELARES",
    "VILLA SALAVINA",
    "BREA POZO",
    "ESTACION ROBLES",
    "ESTACION TABOADA",
    "VILLA NUEVA",
    "GARZA",
    "ARRAGA",
    "NUEVA FRANCIA",
    "SIMBOL",
    "SUMAMAO",
    "VILLA SILIPICA"
  ],
  C: [
    "CIUDAD DE BUENOS AIRES",
    "CONSTITUCION",
    "MONSERRAT",
    "PUERTO MADERO",
    "RETIRO",
    "SAN NICOLAS",
    "SAN TELMO",
    "RECOLETA",
    "BALVANERA",
    "SAN CRISTOBAL",
    "BARRACAS",
    "BOCA",
    "NUEVA POMPEYA",
    "PARQUE PATRICIOS",
    "ALMAGRO",
    "BOEDO",
    "CABALLITO",
    "FLORES",
    "PARQUE CHACABUCO",
    "VILLA LUGANO",
    "VILLA RIACHUELO",
    "VILLA SOLDATI",
    "LINIERS",
    "MATADEROS",
    "PARQUE AVELLANEDA",
    "FLORESTA",
    "MONTE CASTRO",
    "VELEZ SARSFIELD",
    "VERSALLES",
    "VILLA LURO",
    "VILLA REAL",
    "VILLA DEL PARQUE",
    "VILLA DEVOTO",
    "VILLA GENERAL MITRE",
    "VILLA SANTA RITA",
    "COGHLAN",
    "SAAVEDRA",
    "VILLA PUEYRREDON",
    "VILLA URQUIZA",
    "BELGRANO",
    "COLEGIALES",
    "NU\xD1EZ",
    "PALERMO",
    "AGRONOMIA",
    "CHACARITA",
    "PARQUE CHAS",
    "PATERNAL",
    "VILLA CRESPO",
    "VILLA ORTUZAR"
  ],
  T: [
    "BARRIO SAN JORGE",
    "EL CHA\xD1AR",
    "EL NARANJO",
    "GARMENDIA",
    "LA RAMADA",
    "MACOMITAS",
    "PIEDRABUENA",
    "7 DE ABRIL",
    "VILLA BENJAMIN ARAOZ",
    "VILLA BURRUYACU",
    "VILLA PADRE MONTI",
    "ALDERETES",
    "EL CORTE",
    "LOS GUTIERREZ",
    "BANDA DEL RIO SALI",
    "BARRIO AEROPUERTO",
    "LASTENIA",
    "COLOMBRES",
    "COLONIA MAYO - BARRIO LA MILAGROSA",
    "EL PARAISO",
    "EX INGENIO ESPERANZA",
    "EX INGENIO LUJAN",
    "EL BRACHO",
    "INGENIO LA FLORIDA",
    "LA FLORIDA",
    "LAS CEJAS",
    "EX INGENIO LOS RALOS",
    "VILLA RECASTE",
    "VILLA TERCERA",
    "PACARA",
    "RANCHILLOS",
    "SAN ANDRES",
    "ALPACHIRI",
    "ALTO VERDE",
    "ARCADIA",
    "SAN ROQUE",
    "CONCEPCION",
    "ILTICO",
    "LA TRINIDAD",
    "MEDINA",
    "BARRIO CASA ROSADA",
    "CAMPO DE HERRERA",
    "EX INGENIO NUEVA BAVIERA",
    "FAMAILLA",
    "INGENIO FRONTERITA",
    "GRANEROS",
    "LAMADRID",
    "TACO RALO",
    "JUAN BAUTISTA ALBERDI",
    "VILLA BELGRANO",
    "LA COCHA",
    "SAN JOSE DE LA COCHA",
    "BELLA VISTA",
    "ESTACION ARAOZ",
    "LOS PUESTOS",
    "MANUEL GARCIA FERNANDEZ",
    "PALA PALA",
    "RIO COLORADO",
    "SANTA ROSA DE LEALES",
    "VILLA FIAD - INGENIO LEALES",
    "VILLA DE LEALES",
    "BARRIO SAN FELIPE",
    "BARRIO ARAUJO",
    "EL MANANTIAL",
    "INGENIO SAN PABLO",
    "LA REDUCCION",
    "LULES",
    "ACHERAL",
    "CAPITAN CACERES",
    "MONTEROS",
    "PUEBLO INDEPENDENCIA",
    "RIO SECO",
    "SANTA LUCIA",
    "SARGENTO MOYA",
    "SOLDADO MALDONADO",
    "TENIENTE BERDINA",
    "VILLA QUINTEROS",
    "AGUILARES",
    "INGENIO SANTA BARBARA",
    "LOS SARMIENTOS",
    "RIO CHICO",
    "SANTA ANA",
    "VILLA CLODOMIRO HILERET",
    "SAN MIGUEL DE TUCUMAN",
    "ATAHONA",
    "MONTEAGUDO",
    "NUEVA TRINIDAD",
    "SANTA CRUZ",
    "SIMOCA",
    "VILLA CHICLIGASTA",
    "AMAICHA DEL VALLE",
    "COLALAO DEL VALLE",
    "EL MOLLAR",
    "TAFI DEL VALLE",
    "BARRIO EL CRUCE",
    "BARRIO LOMAS DE TAFI",
    "BARRIO MUTUAL SAN MARTIN",
    "BARRIO PARADA 14",
    "BARRIO U.T.A. II",
    "DIAG. NORTE - LUZ Y FUERZA - LOS POCITOS",
    "EL CADILLAL",
    "TAFI VIEJO",
    "VILLA LAS FLORES",
    "VILLA MARIANO MORENO - EL COLMENAR",
    "CHOROMORO",
    "SAN PEDRO DE COLALAO",
    "VILLA DE TRANCAS",
    "BARRIO SAN JOSE III",
    "COUNTRY JOCKEY CLUB",
    "VILLA CARMELA",
    "EX INGENIO SAN JOSE",
    "YERBA BUENA - MARCOS PAZ"
  ],
  V: ["RIO GRANDE", "TOLHUIN", "LAGUNA ESCONDIDA", "USHUAIA"]
};
var paraguayCities = {
  "16": ["test"],
  "10": ["test"],
  "13": ["test"],
  ASU: ["test"],
  "19": ["test"],
  "5": ["test"],
  "6": ["test"],
  "14": ["test"],
  "11": ["test"],
  "1": ["test"],
  "3": ["test"],
  "4": ["test"],
  "7": ["test"],
  "8": ["test"],
  "12": ["test"],
  "9": ["test"],
  "15": ["test"],
  "2": ["test"]
};

// src/file.ts
var MAX_NEWS_REQUEST_FILES = 10;

// src/idp.ts
var identityProviders = ["google", "facebook", "microsoft"];

// src/dataset.ts
var datasetEntities = ["car", "moto"];
var datasetEntityKeys = ["make", "model"];

// src/metrics.ts
var metricsCountGroupBy = [
  "claim_damage_type",
  "responsible_assigned",
  "ramo",
  "zone"
];
var metricsHistogramBuckets = [
  "50000",
  "100000",
  "250000",
  "5000000",
  "1000000"
];
var metricsFrequency = ["daily", "monthly"];
var booleansString = ["true", "false"];

// src/load-balance-rule.ts
var import_i11n = require("i11n");

// src/stateParties.ts
var argentinaStateParties = {
  B: [
    "Jos\xE9 C. Paz",
    "Adolfo Gonzales Chaves",
    "General Juan Madariaga",
    "Tandil",
    "Coronel Pringles",
    "Tigre",
    "Tornquist",
    "Campana",
    "Maip\xFA",
    "Adolfo Alsina",
    "Pu\xE1n",
    "Villarino",
    "General Villegas",
    "San Pedro",
    "Chivilcoy",
    "General Alvear",
    "Castelli",
    "Almirante Brown",
    "La Plata",
    "Brandsen",
    "25 de Mayo",
    "Pehuaj\xF3",
    "General Belgrano",
    "Monte",
    "Roque P\xE9rez",
    "Pilar",
    "Luj\xE1n",
    "Azul",
    "Merlo",
    "Chacabuco",
    "Carmen de Areco",
    "General Rodr\xEDguez",
    "Exaltaci\xF3n de la Cruz",
    "San Miguel",
    "San Andr\xE9s de Giles",
    "Jun\xEDn",
    "Marcos Paz",
    "Mercedes",
    "Hurlingham",
    "Mor\xF3n",
    "Ciudad Libertador San Mart\xEDn",
    "Ituzaing\xF3",
    "Suipacha",
    "Tres de Febrero",
    "Alberti",
    "Lan\xFAs",
    "General Las Heras",
    "Lomas de Zamora",
    "Navarro",
    "Ca\xF1uelas",
    "Lobos",
    "Quilmes",
    "Pila",
    "General La Madrid",
    "Bol\xEDvar",
    "Balcarce",
    "Hip\xF3lito Yrigoyen",
    "Dolores",
    "Tres Lomas",
    "Ayacucho",
    "General Pinto",
    "Guamin\xED",
    "Avellaneda",
    "Col\xF3n",
    "San Cayetano",
    "Tres Arroyos",
    "Saavedra",
    "Monte Hermoso",
    "Lober\xEDa",
    "Berisso",
    "Tordillo",
    "Ramallo",
    "San Nicol\xE1s",
    "General Guido",
    "Coronel Su\xE1rez",
    "Coronel Dorrego",
    "Necochea",
    "Bah\xEDa Blanca",
    "General Alvarado",
    "General Pueyrred\xF3n",
    "Mar Chiquita",
    "Villa Gesell",
    "Pinamar",
    "General Lavalle",
    "La Costa",
    "Magdalena",
    "Vicente L\xF3pez",
    "Saladillo",
    "Las Flores",
    "Rojas",
    "Escobar",
    "Pergamino",
    "Rauch",
    "Salliquel\xF3",
    "Berazategui",
    "Presidente Per\xF3n",
    "Coronel de Marina Leonardo Rosales",
    "Leandro N. Alem",
    "General Arenales",
    "Rivadavia",
    "General Paz",
    "San Antonio de Areco",
    "Moreno",
    "La Matanza",
    "Lezama",
    "Punta Indio",
    "Pellegrini",
    "Carlos Tejedor",
    "Arrecifes",
    "Capit\xE1n Sarmiento",
    "Salto",
    "9 de Julio",
    "Trenque Lauquen",
    "Carlos Casares",
    "Olavarr\xEDa",
    "Esteban Echeverr\xEDa",
    "General Viamonte",
    "Malvinas Argentinas",
    "Lincoln",
    "Tapalqu\xE9",
    "Bragado",
    "Jos\xE9 M. Ezeiza",
    "Florencio Varela",
    "San Vicente",
    "Daireaux",
    "Ensenada",
    "Laprida",
    "San Isidro",
    "Benito Ju\xE1rez",
    "San Fernando",
    "Z\xE1rate",
    "Patagones",
    "Chascom\xFAs",
    "Baradero",
    "Florentino Ameghino"
  ],
  S: [
    "Villa Constituci\xF3n",
    "La Capital",
    "Vera",
    "9 de Julio",
    "Garay",
    "Las Colonias",
    "Caseros",
    "Rosario",
    "San Lorenzo",
    "Iriondo",
    "Belgrano",
    "San Jer\xF3nimo",
    "San Mart\xEDn",
    "Castellanos",
    "San Justo",
    "San Crist\xF3bal",
    "San Javier",
    "General Obligado",
    "General L\xF3pez"
  ]
};

// src/load-balance-rule.ts
var distinctList = (list, distinctKey) => {
  return [...new Set(list.map((l) => l[distinctKey]))].map((l) => ({
    label: l,
    value: l
  }));
};
var loadBalanceRuleFields = [
  {
    label: "Ramo",
    path: "ramo",
    load: "lazy",
    options: []
  },
  {
    label: "Ocurrencia Ciudad",
    load: "lazy",
    path: "occurrence.city",
    options: []
  },
  {
    load: "eager",
    label: "Provincia",
    path: "desired_support_location_state",
    options: (countryAndStates.find((c) => c.code === DEFAULT_COUNTRY_CODE)?.states || []).map((opt) => ({
      label: opt.name,
      value: opt.code
    }))
  },
  {
    load: "eager",
    label: "Partido",
    path: "desired_support_location_state_municipality",
    options: distinctList(
      Object.values(argentinaStateParties).flat().map((opt) => ({
        label: opt,
        value: opt
      })),
      "value"
    )
  },
  {
    load: "eager",
    label: "Tipo de reclamo",
    path: "claim_type",
    options: claimTypes.map((type) => ({
      label: (0, import_i11n.translate)(`claim.type.${type}`),
      value: type
    }))
  }
];

// src/damage.ts
var bodyParts = ["upper", "lower", "left", "right"];

// src/vehicle.ts
var withLicensePlateVehicleTypes = [
  "car",
  "moto",
  "special_transport_service",
  "public_transport_service",
  "trailer_motor_home",
  "pickup_class_a_and_b_jeep",
  "truck_chassis",
  "hitched_semi_trailer",
  "tractors_and_rural_machines",
  "other"
];
var complaintWithLicensePlateVehicleTypes = ["car", "moto"];
var withoutLicensePlateVehicleTypes = [
  "bike",
  "machinery",
  "skateboard",
  "other"
];
var claimVehicleUses = [
  "private",
  "taxi",
  "remis",
  "ambulance",
  "police",
  "school",
  "special_service",
  "goods_transport",
  "local",
  "route_service",
  "electronic_platform",
  "delivery_service",
  "agency",
  "school_transport",
  "special_school_pickup"
];
var vehicleUses = [
  "private",
  "taxi",
  "remis",
  "rental",
  "ambulance",
  "police",
  "school",
  "specialService",
  "goods",
  "persons"
];
var driverVehicleOwnership = ["owner", "third"];
var vehicleDocumentTypes = ["title", "registration", "other"];
var vehicleIdentificationKeys = ["license_plate", "chassis"];

// src/notification.ts
var notificationEventsSupported = [
  "contract_invoice_uploaded",
  "contract_report_created",
  "contract_updates_published",
  "order_invoice_uploaded",
  "order_report_created",
  "order_updates_published",
  "rfp_bid_submitted",
  "appraisal_invoice_uploaded",
  "appraisal_updates_published",
  "appraisal_report_created",
  "claim_created",
  "fraud_news_answered",
  "fraud_investigation_report_accepted",
  "file_request_uploaded",
  "complaint_file_request_uploaded",
  "pending_file_request_upload",
  "pending_sinister_complaint",
  "claim_responsible_assigned",
  "sinister_claim_chat_message_send",
  "fraud_updated",
  "fraud_closed",
  "offer_accepted",
  "offer_rejected",
  "offer_expired",
  "claim_documentation_requested",
  "criminal_case_request",
  "claim_not_response",
  "claim_offer_flow",
  "claim_documentation_completed",
  "claim_rejected",
  "claim_additional_management",
  "appraisal_report_created",
  "pending_offer_agreement_response",
  "pending_offer_response",
  "pending_offer_agreement_response_internal",
  "pending_offer_response_internal",
  "offer_agreement_rejected",
  "offer_agreement_expired",
  "offer_agreement_completed",
  "offer_agreement_cancelled",
  "alert_sla_objective_progress",
  "alert_sla_total_progress"
];
var notificationScopes = ["all", "unread", "read"];

// src/case-inspection-query.ts
var retrieveCaseInspectionTokenResource = [
  "case",
  "case_request",
  "investigation"
];

// src/team.ts
var teamType = ["case", "complaint", "claim", "lab"];

// src/resources.ts
var resources = [
  "case",
  "case_enums",
  "invitation",
  "company",
  "case_request",
  "case_request_processor",
  "user",
  "investigation",
  "team",
  "case_load_balance_rule",
  "contact",
  "price",
  "price_group",
  "contact_price",
  "role",
  "alert",
  "stats",
  "integration",
  "virtual_view",
  "workflow",
  "case_draft",
  "statement",
  "company_link",
  "trash_transaction",
  "investigation_user",
  "autoinspector-integration",
  "claim",
  "complaint"
];

// src/actions.ts
var actions = [
  "create",
  "update",
  "read",
  "read_all",
  "read_own",
  "delete",
  "take",
  "define",
  "assign",
  "create_own",
  "create_all",
  "update_own",
  "update_all",
  "remove_own",
  "remove_all"
];

// src/themes.ts
var themes = [
  {
    name: "zinc",
    label: "Zinc",
    activeColor: {
      light: "240 5.9% 10%",
      dark: "240 5.2% 33.9%"
    },
    cssVars: {
      light: {
        background: "0 0% 100%",
        foreground: "240 10% 3.9%",
        card: "0 0% 100%",
        "card-foreground": "240 10% 3.9%",
        popover: "0 0% 100%",
        "popover-foreground": "240 10% 3.9%",
        primary: "240 5.9% 10%",
        "primary-foreground": "0 0% 98%",
        secondary: "240 4.8% 95.9%",
        "secondary-foreground": "240 5.9% 10%",
        muted: "240 4.8% 95.9%",
        "muted-foreground": "240 3.8% 46.1%",
        accent: "240 4.8% 95.9%",
        "accent-foreground": "240 5.9% 10%",
        destructive: "0 84.2% 60.2%",
        "destructive-foreground": "0 0% 98%",
        border: "240 5.9% 90%",
        input: "240 5.9% 90%",
        ring: "240 5.9% 10%",
        radius: "0.5rem"
      },
      dark: {
        background: "240 10% 3.9%",
        foreground: "0 0% 98%",
        card: "240 10% 3.9%",
        "card-foreground": "0 0% 98%",
        popover: "240 10% 3.9%",
        "popover-foreground": "0 0% 98%",
        primary: "0 0% 98%",
        "primary-foreground": "240 5.9% 10%",
        secondary: "240 3.7% 15.9%",
        "secondary-foreground": "0 0% 98%",
        muted: "240 3.7% 15.9%",
        "muted-foreground": "240 5% 64.9%",
        accent: "240 3.7% 15.9%",
        "accent-foreground": "0 0% 98%",
        destructive: "0 62.8% 30.6%",
        "destructive-foreground": "0 0% 98%",
        border: "240 3.7% 15.9%",
        input: "240 3.7% 15.9%",
        ring: "240 4.9% 83.9%"
      }
    }
  },
  {
    name: "slate",
    label: "Slate",
    activeColor: {
      light: "215.4 16.3% 46.9%",
      dark: "215.3 19.3% 34.5%"
    },
    cssVars: {
      light: {
        background: "0 0% 100%",
        foreground: "222.2 84% 4.9%",
        card: "0 0% 100%",
        "card-foreground": "222.2 84% 4.9%",
        popover: "0 0% 100%",
        "popover-foreground": "222.2 84% 4.9%",
        primary: "222.2 47.4% 11.2%",
        "primary-foreground": "210 40% 98%",
        secondary: "210 40% 96.1%",
        "secondary-foreground": "222.2 47.4% 11.2%",
        muted: "210 40% 96.1%",
        "muted-foreground": "215.4 16.3% 46.9%",
        accent: "210 40% 96.1%",
        "accent-foreground": "222.2 47.4% 11.2%",
        destructive: "0 84.2% 60.2%",
        "destructive-foreground": "210 40% 98%",
        border: "214.3 31.8% 91.4%",
        input: "214.3 31.8% 91.4%",
        ring: "222.2 84% 4.9%",
        radius: "0.5rem"
      },
      dark: {
        background: "222.2 84% 4.9%",
        foreground: "210 40% 98%",
        card: "222.2 84% 4.9%",
        "card-foreground": "210 40% 98%",
        popover: "222.2 84% 4.9%",
        "popover-foreground": "210 40% 98%",
        primary: "210 40% 98%",
        "primary-foreground": "222.2 47.4% 11.2%",
        secondary: "217.2 32.6% 17.5%",
        "secondary-foreground": "210 40% 98%",
        muted: "217.2 32.6% 17.5%",
        "muted-foreground": "215 20.2% 65.1%",
        accent: "217.2 32.6% 17.5%",
        "accent-foreground": "210 40% 98%",
        destructive: "0 62.8% 30.6%",
        "destructive-foreground": "210 40% 98%",
        border: "217.2 32.6% 17.5%",
        input: "217.2 32.6% 17.5%",
        ring: "212.7 26.8% 83.9"
      }
    }
  },
  {
    name: "stone",
    label: "Stone",
    activeColor: {
      light: "25 5.3% 44.7%",
      dark: "33.3 5.5% 32.4%"
    },
    cssVars: {
      light: {
        background: "0 0% 100%",
        foreground: "20 14.3% 4.1%",
        card: "0 0% 100%",
        "card-foreground": "20 14.3% 4.1%",
        popover: "0 0% 100%",
        "popover-foreground": "20 14.3% 4.1%",
        primary: "24 9.8% 10%",
        "primary-foreground": "60 9.1% 97.8%",
        secondary: "60 4.8% 95.9%",
        "secondary-foreground": "24 9.8% 10%",
        muted: "60 4.8% 95.9%",
        "muted-foreground": "25 5.3% 44.7%",
        accent: "60 4.8% 95.9%",
        "accent-foreground": "24 9.8% 10%",
        destructive: "0 84.2% 60.2%",
        "destructive-foreground": "60 9.1% 97.8%",
        border: "20 5.9% 90%",
        input: "20 5.9% 90%",
        ring: "20 14.3% 4.1%",
        radius: "0.95rem"
      },
      dark: {
        background: "20 14.3% 4.1%",
        foreground: "60 9.1% 97.8%",
        card: "20 14.3% 4.1%",
        "card-foreground": "60 9.1% 97.8%",
        popover: "20 14.3% 4.1%",
        "popover-foreground": "60 9.1% 97.8%",
        primary: "60 9.1% 97.8%",
        "primary-foreground": "24 9.8% 10%",
        secondary: "12 6.5% 15.1%",
        "secondary-foreground": "60 9.1% 97.8%",
        muted: "12 6.5% 15.1%",
        "muted-foreground": "24 5.4% 63.9%",
        accent: "12 6.5% 15.1%",
        "accent-foreground": "60 9.1% 97.8%",
        destructive: "0 62.8% 30.6%",
        "destructive-foreground": "60 9.1% 97.8%",
        border: "12 6.5% 15.1%",
        input: "12 6.5% 15.1%",
        ring: "24 5.7% 82.9%"
      }
    }
  },
  {
    name: "gray",
    label: "Gray",
    activeColor: {
      light: "220 8.9% 46.1%",
      dark: "215 13.8% 34.1%"
    },
    cssVars: {
      light: {
        background: "0 0% 100%",
        foreground: "224 71.4% 4.1%",
        card: "0 0% 100%",
        "card-foreground": "224 71.4% 4.1%",
        popover: "0 0% 100%",
        "popover-foreground": "224 71.4% 4.1%",
        primary: "220.9 39.3% 11%",
        "primary-foreground": "210 20% 98%",
        secondary: "220 14.3% 95.9%",
        "secondary-foreground": "220.9 39.3% 11%",
        muted: "220 14.3% 95.9%",
        "muted-foreground": "220 8.9% 46.1%",
        accent: "220 14.3% 95.9%",
        "accent-foreground": "220.9 39.3% 11%",
        destructive: "0 84.2% 60.2%",
        "destructive-foreground": "210 20% 98%",
        border: "220 13% 91%",
        input: "220 13% 91%",
        ring: "224 71.4% 4.1%",
        radius: "0.35rem"
      },
      dark: {
        background: "224 71.4% 4.1%",
        foreground: "210 20% 98%",
        card: "224 71.4% 4.1%",
        "card-foreground": "210 20% 98%",
        popover: "224 71.4% 4.1%",
        "popover-foreground": "210 20% 98%",
        primary: "210 20% 98%",
        "primary-foreground": "220.9 39.3% 11%",
        secondary: "215 27.9% 16.9%",
        "secondary-foreground": "210 20% 98%",
        muted: "215 27.9% 16.9%",
        "muted-foreground": "217.9 10.6% 64.9%",
        accent: "215 27.9% 16.9%",
        "accent-foreground": "210 20% 98%",
        destructive: "0 62.8% 30.6%",
        "destructive-foreground": "210 20% 98%",
        border: "215 27.9% 16.9%",
        input: "215 27.9% 16.9%",
        ring: "216 12.2% 83.9%"
      }
    }
  },
  {
    name: "neutral",
    label: "Neutral",
    activeColor: {
      light: "0 0% 45.1%",
      dark: "0 0% 32.2%"
    },
    cssVars: {
      light: {
        background: "0 0% 100%",
        foreground: "0 0% 3.9%",
        card: "0 0% 100%",
        "card-foreground": "0 0% 3.9%",
        popover: "0 0% 100%",
        "popover-foreground": "0 0% 3.9%",
        primary: "0 0% 9%",
        "primary-foreground": "0 0% 98%",
        secondary: "0 0% 96.1%",
        "secondary-foreground": "0 0% 9%",
        muted: "0 0% 96.1%",
        "muted-foreground": "0 0% 45.1%",
        accent: "0 0% 96.1%",
        "accent-foreground": "0 0% 9%",
        destructive: "0 84.2% 60.2%",
        "destructive-foreground": "0 0% 98%",
        border: "0 0% 89.8%",
        input: "0 0% 89.8%",
        ring: "0 0% 3.9%"
      },
      dark: {
        background: "0 0% 3.9%",
        foreground: "0 0% 98%",
        card: "0 0% 3.9%",
        "card-foreground": "0 0% 98%",
        popover: "0 0% 3.9%",
        "popover-foreground": "0 0% 98%",
        primary: "0 0% 98%",
        "primary-foreground": "0 0% 9%",
        secondary: "0 0% 14.9%",
        "secondary-foreground": "0 0% 98%",
        muted: "0 0% 14.9%",
        "muted-foreground": "0 0% 63.9%",
        accent: "0 0% 14.9%",
        "accent-foreground": "0 0% 98%",
        destructive: "0 62.8% 30.6%",
        "destructive-foreground": "0 0% 98%",
        border: "0 0% 14.9%",
        input: "0 0% 14.9%",
        ring: "0 0% 83.1%"
      }
    }
  },
  {
    name: "red",
    label: "Red",
    activeColor: {
      light: "0 72.2% 50.6%",
      dark: "0 72.2% 50.6%"
    },
    cssVars: {
      light: {
        background: "0 0% 100%",
        foreground: "0 0% 3.9%",
        card: "0 0% 100%",
        "card-foreground": "0 0% 3.9%",
        popover: "0 0% 100%",
        "popover-foreground": "0 0% 3.9%",
        primary: "0 72.2% 50.6%",
        "primary-foreground": "0 85.7% 97.3%",
        secondary: "0 0% 96.1%",
        "secondary-foreground": "0 0% 9%",
        muted: "0 0% 96.1%",
        "muted-foreground": "0 0% 45.1%",
        accent: "0 0% 96.1%",
        "accent-foreground": "0 0% 9%",
        destructive: "0 84.2% 60.2%",
        "destructive-foreground": "0 0% 98%",
        border: "0 0% 89.8%",
        input: "0 0% 89.8%",
        ring: "0 72.2% 50.6%",
        radius: "0.4rem"
      },
      dark: {
        background: "0 0% 3.9%",
        foreground: "0 0% 98%",
        card: "0 0% 3.9%",
        "card-foreground": "0 0% 98%",
        popover: "0 0% 3.9%",
        "popover-foreground": "0 0% 98%",
        primary: "0 72.2% 50.6%",
        "primary-foreground": "0 85.7% 97.3%",
        secondary: "0 0% 14.9%",
        "secondary-foreground": "0 0% 98%",
        muted: "0 0% 14.9%",
        "muted-foreground": "0 0% 63.9%",
        accent: "0 0% 14.9%",
        "accent-foreground": "0 0% 98%",
        destructive: "0 62.8% 30.6%",
        "destructive-foreground": "0 0% 98%",
        border: "0 0% 14.9%",
        input: "0 0% 14.9%",
        ring: "0 72.2% 50.6%"
      }
    }
  },
  {
    name: "rose",
    label: "Rose",
    activeColor: {
      light: "346.8 77.2% 49.8%",
      dark: "346.8 77.2% 49.8%"
    },
    cssVars: {
      light: {
        background: "0 0% 100%",
        foreground: "240 10% 3.9%",
        card: "0 0% 100%",
        "card-foreground": "240 10% 3.9%",
        popover: "0 0% 100%",
        "popover-foreground": "240 10% 3.9%",
        primary: "346.8 77.2% 49.8%",
        "primary-foreground": "355.7 100% 97.3%",
        secondary: "240 4.8% 95.9%",
        "secondary-foreground": "240 5.9% 10%",
        muted: "240 4.8% 95.9%",
        "muted-foreground": "240 3.8% 46.1%",
        accent: "240 4.8% 95.9%",
        "accent-foreground": "240 5.9% 10%",
        destructive: "0 84.2% 60.2%",
        "destructive-foreground": "0 0% 98%",
        border: "240 5.9% 90%",
        input: "240 5.9% 90%",
        ring: "346.8 77.2% 49.8%",
        radius: "0.5rem"
      },
      dark: {
        background: "20 14.3% 4.1%",
        foreground: "0 0% 95%",
        popover: "0 0% 9%",
        "popover-foreground": "0 0% 95%",
        card: "24 9.8% 10%",
        "card-foreground": "0 0% 95%",
        primary: "346.8 77.2% 49.8%",
        "primary-foreground": "355.7 100% 97.3%",
        secondary: "240 3.7% 15.9%",
        "secondary-foreground": "0 0% 98%",
        muted: "0 0% 15%",
        "muted-foreground": "240 5% 64.9%",
        accent: "12 6.5% 15.1%",
        "accent-foreground": "0 0% 98%",
        destructive: "0 62.8% 30.6%",
        "destructive-foreground": "0 85.7% 97.3%",
        border: "240 3.7% 15.9%",
        input: "240 3.7% 15.9%",
        ring: "346.8 77.2% 49.8%"
      }
    }
  },
  {
    name: "orange",
    label: "Orange",
    activeColor: {
      light: "24.6 95% 53.1%",
      dark: "20.5 90.2% 48.2%"
    },
    cssVars: {
      light: {
        background: "0 0% 100%",
        foreground: "20 14.3% 4.1%",
        card: "0 0% 100%",
        "card-foreground": "20 14.3% 4.1%",
        popover: "0 0% 100%",
        "popover-foreground": "20 14.3% 4.1%",
        primary: "24.6 95% 53.1%",
        "primary-foreground": "60 9.1% 97.8%",
        secondary: "60 4.8% 95.9%",
        "secondary-foreground": "24 9.8% 10%",
        muted: "60 4.8% 95.9%",
        "muted-foreground": "25 5.3% 44.7%",
        accent: "60 4.8% 95.9%",
        "accent-foreground": "24 9.8% 10%",
        destructive: "0 84.2% 60.2%",
        "destructive-foreground": "60 9.1% 97.8%",
        border: "20 5.9% 90%",
        input: "20 5.9% 90%",
        ring: "24.6 95% 53.1%",
        radius: "0.95rem"
      },
      dark: {
        background: "20 14.3% 4.1%",
        foreground: "60 9.1% 97.8%",
        card: "20 14.3% 4.1%",
        "card-foreground": "60 9.1% 97.8%",
        popover: "20 14.3% 4.1%",
        "popover-foreground": "60 9.1% 97.8%",
        primary: "20.5 90.2% 48.2%",
        "primary-foreground": "60 9.1% 97.8%",
        secondary: "12 6.5% 15.1%",
        "secondary-foreground": "60 9.1% 97.8%",
        muted: "12 6.5% 15.1%",
        "muted-foreground": "24 5.4% 63.9%",
        accent: "12 6.5% 15.1%",
        "accent-foreground": "60 9.1% 97.8%",
        destructive: "0 72.2% 50.6%",
        "destructive-foreground": "60 9.1% 97.8%",
        border: "12 6.5% 15.1%",
        input: "12 6.5% 15.1%",
        ring: "20.5 90.2% 48.2%"
      }
    }
  },
  {
    name: "green",
    label: "Green",
    activeColor: {
      light: "142.1 76.2% 36.3%",
      dark: "142.1 70.6% 45.3%"
    },
    cssVars: {
      light: {
        background: "0 0% 100%",
        foreground: "240 10% 3.9%",
        card: "0 0% 100%",
        "card-foreground": "240 10% 3.9%",
        popover: "0 0% 100%",
        "popover-foreground": "240 10% 3.9%",
        primary: "142.1 76.2% 36.3%",
        "primary-foreground": "355.7 100% 97.3%",
        secondary: "240 4.8% 95.9%",
        "secondary-foreground": "240 5.9% 10%",
        muted: "240 4.8% 95.9%",
        "muted-foreground": "240 3.8% 46.1%",
        accent: "240 4.8% 95.9%",
        "accent-foreground": "240 5.9% 10%",
        destructive: "0 84.2% 60.2%",
        "destructive-foreground": "0 0% 98%",
        border: "240 5.9% 90%",
        input: "240 5.9% 90%",
        ring: "142.1 76.2% 36.3%"
      },
      dark: {
        background: "20 14.3% 4.1%",
        foreground: "0 0% 95%",
        popover: "0 0% 9%",
        "popover-foreground": "0 0% 95%",
        card: "24 9.8% 10%",
        "card-foreground": "0 0% 95%",
        primary: "142.1 70.6% 45.3%",
        "primary-foreground": "144.9 80.4% 10%",
        secondary: "240 3.7% 15.9%",
        "secondary-foreground": "0 0% 98%",
        muted: "0 0% 15%",
        "muted-foreground": "240 5% 64.9%",
        accent: "12 6.5% 15.1%",
        "accent-foreground": "0 0% 98%",
        destructive: "0 62.8% 30.6%",
        "destructive-foreground": "0 85.7% 97.3%",
        border: "240 3.7% 15.9%",
        input: "240 3.7% 15.9%",
        ring: "142.4 71.8% 29.2%"
      }
    }
  },
  {
    name: "blue",
    label: "Blue",
    activeColor: {
      light: "221.2 83.2% 53.3%",
      dark: "217.2 91.2% 59.8%"
    },
    cssVars: {
      light: {
        background: "0 0% 100%",
        foreground: "222.2 84% 4.9%",
        card: "0 0% 100%",
        "card-foreground": "222.2 84% 4.9%",
        popover: "0 0% 100%",
        "popover-foreground": "222.2 84% 4.9%",
        primary: "221.2 83.2% 53.3%",
        "primary-foreground": "210 40% 98%",
        secondary: "210 40% 96.1%",
        "secondary-foreground": "222.2 47.4% 11.2%",
        muted: "210 40% 96.1%",
        "muted-foreground": "215.4 16.3% 46.9%",
        accent: "210 40% 96.1%",
        "accent-foreground": "222.2 47.4% 11.2%",
        destructive: "0 84.2% 60.2%",
        "destructive-foreground": "210 40% 98%",
        border: "214.3 31.8% 91.4%",
        input: "214.3 31.8% 91.4%",
        ring: "221.2 83.2% 53.3%"
      },
      dark: {
        background: "222.2 84% 4.9%",
        foreground: "210 40% 98%",
        card: "222.2 84% 4.9%",
        "card-foreground": "210 40% 98%",
        popover: "222.2 84% 4.9%",
        "popover-foreground": "210 40% 98%",
        primary: "217.2 91.2% 59.8%",
        "primary-foreground": "222.2 47.4% 11.2%",
        secondary: "217.2 32.6% 17.5%",
        "secondary-foreground": "210 40% 98%",
        muted: "217.2 32.6% 17.5%",
        "muted-foreground": "215 20.2% 65.1%",
        accent: "217.2 32.6% 17.5%",
        "accent-foreground": "210 40% 98%",
        destructive: "0 62.8% 30.6%",
        "destructive-foreground": "210 40% 98%",
        border: "217.2 32.6% 17.5%",
        input: "217.2 32.6% 17.5%",
        ring: "224.3 76.3% 48%"
      }
    }
  },
  {
    name: "yellow",
    label: "Yellow",
    activeColor: {
      light: "47.9 95.8% 53.1%",
      dark: "47.9 95.8% 53.1%"
    },
    cssVars: {
      light: {
        background: "0 0% 100%",
        foreground: "20 14.3% 4.1%",
        card: "0 0% 100%",
        "card-foreground": "20 14.3% 4.1%",
        popover: "0 0% 100%",
        "popover-foreground": "20 14.3% 4.1%",
        primary: "47.9 95.8% 53.1%",
        "primary-foreground": "26 83.3% 14.1%",
        secondary: "60 4.8% 95.9%",
        "secondary-foreground": "24 9.8% 10%",
        muted: "60 4.8% 95.9%",
        "muted-foreground": "25 5.3% 44.7%",
        accent: "60 4.8% 95.9%",
        "accent-foreground": "24 9.8% 10%",
        destructive: "0 84.2% 60.2%",
        "destructive-foreground": "60 9.1% 97.8%",
        border: "20 5.9% 90%",
        input: "20 5.9% 90%",
        ring: "20 14.3% 4.1%",
        radius: "0.95rem"
      },
      dark: {
        background: "20 14.3% 4.1%",
        foreground: "60 9.1% 97.8%",
        card: "20 14.3% 4.1%",
        "card-foreground": "60 9.1% 97.8%",
        popover: "20 14.3% 4.1%",
        "popover-foreground": "60 9.1% 97.8%",
        primary: "47.9 95.8% 53.1%",
        "primary-foreground": "26 83.3% 14.1%",
        secondary: "12 6.5% 15.1%",
        "secondary-foreground": "60 9.1% 97.8%",
        muted: "12 6.5% 15.1%",
        "muted-foreground": "24 5.4% 63.9%",
        accent: "12 6.5% 15.1%",
        "accent-foreground": "60 9.1% 97.8%",
        destructive: "0 62.8% 30.6%",
        "destructive-foreground": "60 9.1% 97.8%",
        border: "12 6.5% 15.1%",
        input: "12 6.5% 15.1%",
        ring: "35.5 91.7% 32.9%"
      }
    }
  },
  {
    name: "violet",
    label: "Violet",
    activeColor: {
      light: "262.1 83.3% 57.8%",
      dark: "263.4 70% 50.4%"
    },
    cssVars: {
      light: {
        background: "0 0% 100%",
        foreground: "224 71.4% 4.1%",
        card: "0 0% 100%",
        "card-foreground": "224 71.4% 4.1%",
        popover: "0 0% 100%",
        "popover-foreground": "224 71.4% 4.1%",
        primary: "262.1 83.3% 57.8%",
        "primary-foreground": "210 20% 98%",
        secondary: "220 14.3% 95.9%",
        "secondary-foreground": "220.9 39.3% 11%",
        muted: "220 14.3% 95.9%",
        "muted-foreground": "220 8.9% 46.1%",
        accent: "220 14.3% 95.9%",
        "accent-foreground": "220.9 39.3% 11%",
        destructive: "0 84.2% 60.2%",
        "destructive-foreground": "210 20% 98%",
        border: "220 13% 91%",
        input: "220 13% 91%",
        ring: "262.1 83.3% 57.8%"
      },
      dark: {
        background: "224 71.4% 4.1%",
        foreground: "210 20% 98%",
        card: "224 71.4% 4.1%",
        "card-foreground": "210 20% 98%",
        popover: "224 71.4% 4.1%",
        "popover-foreground": "210 20% 98%",
        primary: "263.4 70% 50.4%",
        "primary-foreground": "210 20% 98%",
        secondary: "215 27.9% 16.9%",
        "secondary-foreground": "210 20% 98%",
        muted: "215 27.9% 16.9%",
        "muted-foreground": "217.9 10.6% 64.9%",
        accent: "215 27.9% 16.9%",
        "accent-foreground": "210 20% 98%",
        destructive: "0 62.8% 30.6%",
        "destructive-foreground": "210 20% 98%",
        border: "215 27.9% 16.9%",
        input: "215 27.9% 16.9%",
        ring: "263.4 70% 50.4%"
      }
    }
  }
];

// src/kach.ts
var KACH_INSURER_SESSION_COOKIE_NAME = "kach_insurer_session";

// src/permission.ts
var permissionLabelType = [
  "manage_case_load_balance_rule",
  "manage_team",
  "manage_sinister",
  "read_sinister",
  "manage_complaint",
  "read_complaint",
  "manage_claim",
  "read_claim",
  "manage_role",
  "manage_user",
  "manage_settings",
  "manage_team",
  "read_notification",
  "read_company_link"
];
var permissionsWithLabel = [
  {
    label: "manage_case_load_balance_rule",
    permissions: [
      "can_create_load_balance_rule",
      "can_read_load_balance_rule",
      "can_delete_load_balance_rule",
      "can_update_load_balance_rule"
    ]
  },
  {
    label: "manage_team",
    permissions: [
      "can_create_team",
      "can_read_team",
      "can_update_team",
      "can_delete_team"
    ]
  },
  {
    label: "manage_sinister",
    permissions: [
      "can_create_sinister",
      "can_update_sinister",
      "can_read_sinister",
      "can_delete_sinister"
    ]
  },
  {
    label: "read_sinister",
    permissions: ["can_read_sinister"]
  },
  {
    label: "manage_complaint",
    permissions: [
      "can_create_complaint",
      "can_update_complaint",
      "can_read_complaint",
      "can_delete_complaint"
    ]
  },
  {
    label: "read_complaint",
    permissions: ["can_read_complaint"]
  },
  {
    label: "manage_claim",
    permissions: [
      "can_create_claim",
      "can_update_claim",
      "can_read_claim",
      "can_delete_claim"
    ]
  },
  {
    label: "read_claim",
    permissions: ["can_read_claim"]
  },
  {
    label: "manage_role",
    permissions: [
      "can_read_role",
      "can_update_role",
      "can_delete_role",
      "can_create_role"
    ]
  },
  {
    label: "manage_user",
    permissions: ["can_read_user", "can_update_user"]
  },
  {
    label: "manage_settings",
    permissions: ["can_read_settings", "can_update_settings"]
  },
  {
    label: "manage_team",
    permissions: ["can_read_team", "can_update_team"]
  },
  {
    label: "read_notification",
    permissions: ["can_read_notification"]
  },
  {
    label: "read_company_link",
    permissions: ["can_read_company_link"]
  }
];

// src/cuit.ts
var countryCuit = ["ARG", "PRY"];

// src/country-currency.ts
var countryCurrencyList = [
  {
    country: "Afghanistan",
    currency_code: "AFN"
  },
  {
    country: "Albania",
    currency_code: "ALL"
  },
  {
    country: "Algeria",
    currency_code: "DZD"
  },
  {
    country: "American Samoa",
    currency_code: "USD"
  },
  {
    country: "Andorra",
    currency_code: "EUR"
  },
  {
    country: "Angola",
    currency_code: "AOA"
  },
  {
    country: "Anguilla",
    currency_code: "XCD"
  },
  {
    country: "Antarctica",
    currency_code: "XCD"
  },
  {
    country: "Antigua and Barbuda",
    currency_code: "XCD"
  },
  {
    country: "ARG",
    currency_code: "ARS"
  },
  {
    country: "Armenia",
    currency_code: "AMD"
  },
  {
    country: "Aruba",
    currency_code: "AWG"
  },
  {
    country: "Australia",
    currency_code: "AUD"
  },
  {
    country: "Austria",
    currency_code: "EUR"
  },
  {
    country: "Azerbaijan",
    currency_code: "AZN"
  },
  {
    country: "Bahamas",
    currency_code: "BSD"
  },
  {
    country: "Bahrain",
    currency_code: "BHD"
  },
  {
    country: "Bangladesh",
    currency_code: "BDT"
  },
  {
    country: "Barbados",
    currency_code: "BBD"
  },
  {
    country: "Belarus",
    currency_code: "BYR"
  },
  {
    country: "Belgium",
    currency_code: "EUR"
  },
  {
    country: "Belize",
    currency_code: "BZD"
  },
  {
    country: "Benin",
    currency_code: "XOF"
  },
  {
    country: "Bermuda",
    currency_code: "BMD"
  },
  {
    country: "Bhutan",
    currency_code: "BTN"
  },
  {
    country: "Bolivia",
    currency_code: "BOB"
  },
  {
    country: "Bosnia and Herzegovina",
    currency_code: "BAM"
  },
  {
    country: "Botswana",
    currency_code: "BWP"
  },
  {
    country: "Bouvet Island",
    currency_code: "NOK"
  },
  {
    country: "Brazil",
    currency_code: "BRL"
  },
  {
    country: "British Indian Ocean Territory",
    currency_code: "USD"
  },
  {
    country: "Brunei",
    currency_code: "BND"
  },
  {
    country: "Bulgaria",
    currency_code: "BGN"
  },
  {
    country: "Burkina Faso",
    currency_code: "XOF"
  },
  {
    country: "Burundi",
    currency_code: "BIF"
  },
  {
    country: "Cambodia",
    currency_code: "KHR"
  },
  {
    country: "Cameroon",
    currency_code: "XAF"
  },
  {
    country: "Canada",
    currency_code: "CAD"
  },
  {
    country: "Cape Verde",
    currency_code: "CVE"
  },
  {
    country: "Cayman Islands",
    currency_code: "KYD"
  },
  {
    country: "Central African Republic",
    currency_code: "XAF"
  },
  {
    country: "Chad",
    currency_code: "XAF"
  },
  {
    country: "Chile",
    currency_code: "CLP"
  },
  {
    country: "China",
    currency_code: "CNY"
  },
  {
    country: "Christmas Island",
    currency_code: "AUD"
  },
  {
    country: "Cocos (Keeling) Islands",
    currency_code: "AUD"
  },
  {
    country: "Colombia",
    currency_code: "COP"
  },
  {
    country: "Comoros",
    currency_code: "KMF"
  },
  {
    country: "Congo",
    currency_code: "XAF"
  },
  {
    country: "Cook Islands",
    currency_code: "NZD"
  },
  {
    country: "Costa Rica",
    currency_code: "CRC"
  },
  {
    country: "Croatia",
    currency_code: "EUR"
  },
  {
    country: "Cuba",
    currency_code: "CUP"
  },
  {
    country: "Cyprus",
    currency_code: "EUR"
  },
  {
    country: "Czech Republic",
    currency_code: "CZK"
  },
  {
    country: "Denmark",
    currency_code: "DKK"
  },
  {
    country: "Djibouti",
    currency_code: "DJF"
  },
  {
    country: "Dominica",
    currency_code: "XCD"
  },
  {
    country: "Dominican Republic",
    currency_code: "DOP"
  },
  {
    country: "East Timor",
    currency_code: "USD"
  },
  {
    country: "Ecuador",
    currency_code: "ECS"
  },
  {
    country: "Egypt",
    currency_code: "EGP"
  },
  {
    country: "El Salvador",
    currency_code: "SVC"
  },
  {
    country: "England",
    currency_code: "GBP"
  },
  {
    country: "Equatorial Guinea",
    currency_code: "XAF"
  },
  {
    country: "Eritrea",
    currency_code: "ERN"
  },
  {
    country: "Estonia",
    currency_code: "EUR"
  },
  {
    country: "Ethiopia",
    currency_code: "ETB"
  },
  {
    country: "Falkland Islands",
    currency_code: "FKP"
  },
  {
    country: "Faroe Islands",
    currency_code: "DKK"
  },
  {
    country: "Fiji Islands",
    currency_code: "FJD"
  },
  {
    country: "Finland",
    currency_code: "EUR"
  },
  {
    country: "France",
    currency_code: "EUR"
  },
  {
    country: "French Guiana",
    currency_code: "EUR"
  },
  {
    country: "French Polynesia",
    currency_code: "XPF"
  },
  {
    country: "French Southern territories",
    currency_code: "EUR"
  },
  {
    country: "Gabon",
    currency_code: "XAF"
  },
  {
    country: "Gambia",
    currency_code: "GMD"
  },
  {
    country: "Georgia",
    currency_code: "GEL"
  },
  {
    country: "Germany",
    currency_code: "EUR"
  },
  {
    country: "Ghana",
    currency_code: "GHS"
  },
  {
    country: "Gibraltar",
    currency_code: "GIP"
  },
  {
    country: "Greece",
    currency_code: "EUR"
  },
  {
    country: "Greenland",
    currency_code: "DKK"
  },
  {
    country: "Grenada",
    currency_code: "XCD"
  },
  {
    country: "Guadeloupe",
    currency_code: "EUR"
  },
  {
    country: "Guam",
    currency_code: "USD"
  },
  {
    country: "Guatemala",
    currency_code: "QTQ"
  },
  {
    country: "Guinea",
    currency_code: "GNF"
  },
  {
    country: "Guinea-Bissau",
    currency_code: "CFA"
  },
  {
    country: "Guyana",
    currency_code: "GYD"
  },
  {
    country: "Haiti",
    currency_code: "HTG"
  },
  {
    country: "Heard Island and McDonald Islands",
    currency_code: "AUD"
  },
  {
    country: "Holy See (Vatican City State)",
    currency_code: "EUR"
  },
  {
    country: "Honduras",
    currency_code: "HNL"
  },
  {
    country: "Hong Kong",
    currency_code: "HKD"
  },
  {
    country: "Hungary",
    currency_code: "HUF"
  },
  {
    country: "Iceland",
    currency_code: "ISK"
  },
  {
    country: "India",
    currency_code: "INR"
  },
  {
    country: "Indonesia",
    currency_code: "IDR"
  },
  {
    country: "Iran",
    currency_code: "IRR"
  },
  {
    country: "Iraq",
    currency_code: "IQD"
  },
  {
    country: "Ireland",
    currency_code: "EUR"
  },
  {
    country: "Israel",
    currency_code: "ILS"
  },
  {
    country: "Italy",
    currency_code: "EUR"
  },
  {
    country: "Ivory Coast",
    currency_code: "XOF"
  },
  {
    country: "Jamaica",
    currency_code: "JMD"
  },
  {
    country: "Japan",
    currency_code: "JPY"
  },
  {
    country: "Jordan",
    currency_code: "JOD"
  },
  {
    country: "Kazakhstan",
    currency_code: "KZT"
  },
  {
    country: "Kenya",
    currency_code: "KES"
  },
  {
    country: "Kiribati",
    currency_code: "AUD"
  },
  {
    country: "Kuwait",
    currency_code: "KWD"
  },
  {
    country: "Kyrgyzstan",
    currency_code: "KGS"
  },
  {
    country: "Laos",
    currency_code: "LAK"
  },
  {
    country: "Latvia",
    currency_code: "EUR"
  },
  {
    country: "Lebanon",
    currency_code: "LBP"
  },
  {
    country: "Lesotho",
    currency_code: "LSL"
  },
  {
    country: "Liberia",
    currency_code: "LRD"
  },
  {
    country: "Libyan Arab Jamahiriya",
    currency_code: "LYD"
  },
  {
    country: "Liechtenstein",
    currency_code: "CHF"
  },
  {
    country: "Lithuania",
    currency_code: "EUR"
  },
  {
    country: "Luxembourg",
    currency_code: "EUR"
  },
  {
    country: "Macau",
    currency_code: "MOP"
  },
  {
    country: "North Macedonia",
    currency_code: "MKD"
  },
  {
    country: "Madagascar",
    currency_code: "MGF"
  },
  {
    country: "Malawi",
    currency_code: "MWK"
  },
  {
    country: "Malaysia",
    currency_code: "MYR"
  },
  {
    country: "Maldives",
    currency_code: "MVR"
  },
  {
    country: "Mali",
    currency_code: "XOF"
  },
  {
    country: "Malta",
    currency_code: "EUR"
  },
  {
    country: "Marshall Islands",
    currency_code: "USD"
  },
  {
    country: "Martinique",
    currency_code: "EUR"
  },
  {
    country: "Mauritania",
    currency_code: "MRO"
  },
  {
    country: "Mauritius",
    currency_code: "MUR"
  },
  {
    country: "Mayotte",
    currency_code: "EUR"
  },
  {
    country: "Mexico",
    currency_code: "MXN"
  },
  {
    country: "Micronesia, Federated States of",
    currency_code: "USD"
  },
  {
    country: "Moldova",
    currency_code: "MDL"
  },
  {
    country: "Monaco",
    currency_code: "EUR"
  },
  {
    country: "Mongolia",
    currency_code: "MNT"
  },
  {
    country: "Montserrat",
    currency_code: "XCD"
  },
  {
    country: "Morocco",
    currency_code: "MAD"
  },
  {
    country: "Mozambique",
    currency_code: "MZN"
  },
  {
    country: "Myanmar",
    currency_code: "MMR"
  },
  {
    country: "Namibia",
    currency_code: "NAD"
  },
  {
    country: "Nauru",
    currency_code: "AUD"
  },
  {
    country: "Nepal",
    currency_code: "NPR"
  },
  {
    country: "Netherlands",
    currency_code: "EUR"
  },
  {
    country: "Netherlands Antilles",
    currency_code: "ANG"
  },
  {
    country: "New Caledonia",
    currency_code: "XPF"
  },
  {
    country: "New Zealand",
    currency_code: "NZD"
  },
  {
    country: "Nicaragua",
    currency_code: "NIO"
  },
  {
    country: "Niger",
    currency_code: "XOF"
  },
  {
    country: "Nigeria",
    currency_code: "NGN"
  },
  {
    country: "Niue",
    currency_code: "NZD"
  },
  {
    country: "Norfolk Island",
    currency_code: "AUD"
  },
  {
    country: "North Korea",
    currency_code: "KPW"
  },
  {
    country: "Northern Ireland",
    currency_code: "GBP"
  },
  {
    country: "Northern Mariana Islands",
    currency_code: "USD"
  },
  {
    country: "Norway",
    currency_code: "NOK"
  },
  {
    country: "Oman",
    currency_code: "OMR"
  },
  {
    country: "Pakistan",
    currency_code: "PKR"
  },
  {
    country: "Palau",
    currency_code: "USD"
  },
  {
    country: "Palestine",
    currency_code: null
  },
  {
    country: "Panama",
    currency_code: "PAB"
  },
  {
    country: "Papua New Guinea",
    currency_code: "PGK"
  },
  {
    country: "PRY",
    currency_code: "PYG"
  },
  {
    country: "Peru",
    currency_code: "PEN"
  },
  {
    country: "Philippines",
    currency_code: "PHP"
  },
  {
    country: "Pitcairn Islands",
    currency_code: "NZD"
  },
  {
    country: "Poland",
    currency_code: "PLN"
  },
  {
    country: "Portugal",
    currency_code: "EUR"
  },
  {
    country: "Puerto Rico",
    currency_code: "USD"
  },
  {
    country: "Qatar",
    currency_code: "QAR"
  },
  {
    country: "Reunion",
    currency_code: "EUR"
  },
  {
    country: "Romania",
    currency_code: "RON"
  },
  {
    country: "Russia",
    currency_code: "RUB"
  },
  {
    country: "Rwanda",
    currency_code: "RWF"
  },
  {
    country: "Saint Helena",
    currency_code: "SHP"
  },
  {
    country: "Saint Kitts and Nevis",
    currency_code: "XCD"
  },
  {
    country: "Saint Lucia",
    currency_code: "XCD"
  },
  {
    country: "Saint Pierre and Miquelon",
    currency_code: "EUR"
  },
  {
    country: "Saint Vincent and the Grenadines",
    currency_code: "XCD"
  },
  {
    country: "Samoa",
    currency_code: "WST"
  },
  {
    country: "San Marino",
    currency_code: "EUR"
  },
  {
    country: "Sao Tome and Principe",
    currency_code: "STD"
  },
  {
    country: "Saudi Arabia",
    currency_code: "SAR"
  },
  {
    country: "Scotland",
    currency_code: "GBP"
  },
  {
    country: "Senegal",
    currency_code: "XOF"
  },
  {
    country: "Serbia",
    currency_code: "RSD"
  },
  {
    country: "Seychelles",
    currency_code: "SCR"
  },
  {
    country: "Sierra Leone",
    currency_code: "SLL"
  },
  {
    country: "Singapore",
    currency_code: "SGD"
  },
  {
    country: "Slovakia",
    currency_code: "EUR"
  },
  {
    country: "Slovenia",
    currency_code: "EUR"
  },
  {
    country: "Solomon Islands",
    currency_code: "SBD"
  },
  {
    country: "Somalia",
    currency_code: "SOS"
  },
  {
    country: "South Africa",
    currency_code: "ZAR"
  },
  {
    country: "South Georgia and the South Sandwich Islands",
    currency_code: "GBP"
  },
  {
    country: "South Korea",
    currency_code: "KRW"
  },
  {
    country: "South Sudan",
    currency_code: "SSP"
  },
  {
    country: "Spain",
    currency_code: "EUR"
  },
  {
    country: "Sri Lanka",
    currency_code: "LKR"
  },
  {
    country: "Sudan",
    currency_code: "SDG"
  },
  {
    country: "Suriname",
    currency_code: "SRD"
  },
  {
    country: "Svalbard and Jan Mayen",
    currency_code: "NOK"
  },
  {
    country: "Swaziland",
    currency_code: "SZL"
  },
  {
    country: "Sweden",
    currency_code: "SEK"
  },
  {
    country: "Switzerland",
    currency_code: "CHF"
  },
  {
    country: "Syria",
    currency_code: "SYP"
  },
  {
    country: "Tajikistan",
    currency_code: "TJS"
  },
  {
    country: "Tanzania",
    currency_code: "TZS"
  },
  {
    country: "Thailand",
    currency_code: "THB"
  },
  {
    country: "The Democratic Republic of Congo",
    currency_code: "CDF"
  },
  {
    country: "Togo",
    currency_code: "XOF"
  },
  {
    country: "Tokelau",
    currency_code: "NZD"
  },
  {
    country: "Tonga",
    currency_code: "TOP"
  },
  {
    country: "Trinidad and Tobago",
    currency_code: "TTD"
  },
  {
    country: "Tunisia",
    currency_code: "TND"
  },
  {
    country: "Turkey",
    currency_code: "TRY"
  },
  {
    country: "Turkmenistan",
    currency_code: "TMT"
  },
  {
    country: "Turks and Caicos Islands",
    currency_code: "USD"
  },
  {
    country: "Tuvalu",
    currency_code: "AUD"
  },
  {
    country: "Uganda",
    currency_code: "UGX"
  },
  {
    country: "Ukraine",
    currency_code: "UAH"
  },
  {
    country: "United Arab Emirates",
    currency_code: "AED"
  },
  {
    country: "United Kingdom",
    currency_code: "GBP"
  },
  {
    country: "United States",
    currency_code: "USD"
  },
  {
    country: "United States Minor Outlying Islands",
    currency_code: "USD"
  },
  {
    country: "Uruguay",
    currency_code: "UYU"
  },
  {
    country: "Uzbekistan",
    currency_code: "UZS"
  },
  {
    country: "Vanuatu",
    currency_code: "VUV"
  },
  {
    country: "Venezuela",
    currency_code: "VEF"
  },
  {
    country: "Vietnam",
    currency_code: "VND"
  },
  {
    country: "Virgin Islands, British",
    currency_code: "USD"
  },
  {
    country: "Virgin Islands, U.S.",
    currency_code: "USD"
  },
  {
    country: "Wales",
    currency_code: "GBP"
  },
  {
    country: "Wallis and Futuna",
    currency_code: "XPF"
  },
  {
    country: "Western Sahara",
    currency_code: "MAD"
  },
  {
    country: "Yemen",
    currency_code: "YER"
  },
  {
    country: "Zambia",
    currency_code: "ZMW"
  },
  {
    country: "Zimbabwe",
    currency_code: "ZWD"
  }
];
var currencySymbolLocaleEquivalences = {
  ARS: "es-AR",
  PYG: "es-PY",
  USD: "en-US",
  EUR: "de-DE"
};
var FALLBACK_LOCALE = "es-AR";

// src/webhook.ts
var webhookEvents = [
  "claim_collaborator_assigned",
  "claim_created",
  "claim_responsible_unassigned",
  "claim_responsible_assigned",
  "claim_status_updated",
  "file_requested",
  "upload_files",
  "claim_documentation_requested",
  "claim_offer_flow",
  "claim_documentation_completed",
  "contract_created",
  "contract_report_created",
  "contract_report_accepted",
  "contract_report_rejected",
  "field_adjuster_assigned",
  "offer_expired",
  "offer_rejected",
  "offer_accepted",
  "fraud_closed"
];

// src/occurrence.ts
var occurrenceCirculationDirection = ["both", "unique"];

// src/reactive-form-flow.ts
var complaintReactiveFormFlowSteps = [
  "welcome",
  "current-email",
  "policyholder-vehicle-info",
  "is-third-party-involved",
  "driver-detail",
  "insured-type",
  "third-party-vehicle-type",
  "occurrence",
  "insured-info",
  "third-party-exists",
  "complaint-confirmation",
  "third-party-detail",
  "vehicle-driver-info",
  "third-party-info",
  "occurrence-sinister",
  "complaint-created",
  "complaint-type",
  "complaint-other-details",
  "ask-police-intervention",
  "policyholder-info",
  "policyholder-type",
  "all-documents"
];
var claimReactiveFormFlowSteps = [
  "claim-created",
  "has-insurance",
  "has-income-verification",
  "occurrence-optional-info",
  "welcome",
  "claim-confirmation",
  "vehicle-category",
  "is-local-insured-involved",
  "special-branch-info",
  "insured-info",
  "damage-material-type",
  "damage-declaration",
  "victim-type-choose",
  "own-claim-choose",
  "recovery-institution-info",
  "victim-legal-person-info",
  "victim-natural-person-info",
  "vehicle-driver-ownership",
  "legal-person-reimbursements-info",
  "vehicle-info",
  "material-damage-insurer-info",
  "vehicle-driver-info",
  "victim-minor-father-info",
  "victim-minor-mother-info",
  "has-health-insurance",
  "was-treated-by-worker-compensation-insurer",
  "surgery-description",
  "had-surgery",
  "was-treated-by-medical-institution",
  "health-insurance-info",
  "worker-compensation-insurer-info",
  "medical-institution-info",
  "claimant-list",
  "sinister-info",
  "claimed-amount",
  "location-info",
  "match-real-property-victim-address",
  "real-property-location",
  "real-property-info",
  "vehicle-ownership-document-choose",
  "victim-injuries-rol",
  "victim-injuries-details",
  "victim-relationship",
  "all-documents"
];

// src/integration-template.ts
var integrationTemplateParentObjectReferences = [
  "claim.victim.natural_person.first_name",
  "claim.victim.natural_person.last_name",
  "claim.victim.natural_person.nationality",
  "claim.victim.natural_person.address",
  "claim.victim.natural_person.state",
  "claim.victim.natural_person.city",
  "claim.victim.natural_person.zip_code",
  "claim.victim.natural_person.sex",
  "claim.victim.natural_person.identification_type",
  "claim.victim.natural_person.identification_value",
  "claim.owner.first_name",
  "claim.owner.last_name",
  "claim.owner.cellphone",
  "claim.owner.email",
  "claim.owner.identification",
  "claim.owner.sex",
  "claim.created_by.first_name",
  "claim.created_by.last_name",
  "claim.created_by.cellphone",
  "claim.created_by.email",
  "claim.created_by.identification",
  "claim.created_by.sex",
  "claim.sinister.date",
  "claim.sinister.license_plate",
  "claim.sinister.external_complaint.external_id",
  "claim.sinister.external_complaint.external_status",
  "claim.sinister.external_complaint.agency",
  "claim.sinister.external_complaint.occurrence_date",
  "claim.sinister.external_complaint.registered_by",
  "claim.sinister.external_complaint.policyholder.policy_number",
  "claim.sinister.external_complaint.policyholder.make",
  "claim.sinister.external_complaint.policyholder.model",
  "claim.sinister.external_complaint.policyholder.version",
  "claim.sinister.external_complaint.policyholder.agency",
  "claim.sinister.external_complaint.policyholder.order",
  "claim.sinister.external_complaint.policyholder.cuil",
  "claim.sinister.external_complaint.policyholder.address",
  "claim.sinister.external_complaint.policyholder.city",
  "claim.sinister.external_complaint.policyholder.country",
  "claim.sinister.external_complaint.policyholder.identification",
  "claim.sinister.external_complaint.policyholder.first_name",
  "claim.sinister.external_complaint.policyholder.last_name",
  "claim.sinister.external_complaint.policyholder.sex",
  "claim.sinister.external_complaint.policyholder.landline_phone",
  "claim.sinister.external_complaint.policyholder.marital_status",
  "claim.sinister.external_complaint.policyholder.license_plate",
  "claim.sinister.external_complaint.policyholder.plan",
  "claim.sinister.external_complaint.policyholder.use",
  "claim.sinister.external_complaint.policyholder.year",
  "claim.sinister.external_complaint.policyholder.zip_code",
  "claim.occurrence.address",
  "claim.occurrence.time",
  "claim.occurrence.city",
  "claim.occurrence.state",
  "claim.occurrence.country",
  "claim.occurrence.zip_code",
  "claim.occurrence.description",
  "claim.occurrence.damage_description",
  "claim.occurrence.sinister_date"
];

// src/currencies.ts
var currencySymbolLabels = {
  PYG: "Guaran\xED",
  USD: "D\xF3lar",
  EUR: "Euro",
  ARS: "Peso Argentino"
};

// index.ts
var USER_TYPE_COOKIE_NAME = "user_type";
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  CHILDREN_WINDOW_DONE_WORD_TOKEN,
  CLAIM_STATUS_WILDCARD_ORDER,
  DEFAULT_COUNTRY_CODE,
  DEFAULT_CURRENCY,
  DEFAULT_ENDORSEMENT,
  DEFAULT_LOCALE,
  DEFAULT_TIMEZONE,
  FALLBACK_LOCALE,
  KACH_INSURER_SESSION_COOKIE_NAME,
  LEGAL_PERSON_AGE_THRESHOLD,
  MAX_CLAIMED_AMOUNT,
  MAX_CLAIM_FILES_PER_UPLOAD,
  MAX_NEWS_REQUEST_FILES,
  MAX_RFP_FILES,
  SYSTEM_EMAIL,
  USER_TYPE_COOKIE_NAME,
  actions,
  alertTables,
  argentinaCities,
  argentinaStateParties,
  argentinaStates,
  argentinaStatesRequiredParty,
  bodyParts,
  booleansString,
  claimCheckAttributes,
  claimCombinations,
  claimReactiveFormFlowSteps,
  claimReportColumns,
  claimTypes,
  claimVehicleUses,
  claimantVictimRelationships,
  claimants,
  companyServiceBackends,
  companyServices,
  complaintCheckAttributes,
  complaintReactiveFormFlowSteps,
  complaintWithLicensePlateVehicleTypes,
  countryAndStates,
  countryCodesAndName,
  countryCuit,
  countryCurrencyList,
  currencySymbolLabels,
  currencySymbolLocaleEquivalences,
  damageTypes,
  datasetEntities,
  datasetEntityKeys,
  defaultElapsedDurationCriteria,
  delegateClaimStrategies,
  delegateComplaintStrategies,
  deriveClaimStrategies,
  deriveComplaintStrategies,
  distinctList,
  documentTypes,
  documentValidations,
  driverVehicleOwnership,
  elapsedDurationCriteriaOptsByType,
  fieldAdjusterClaimStatus,
  flattenDamageTypeToParentType,
  flattenDamageTypes,
  getAllColumnPaths,
  identityProviders,
  inhouseLawyerVictimRelationships,
  insurerClaimColumns,
  insurerClaimFilters,
  insurerComplaintColumns,
  insurerComplaintFilters,
  insurerSinisterColumns,
  integrationTemplateParentObjectReferences,
  invalidChassisMessage,
  invalidEngineMessage,
  legalPersonClaimantVictimRelationships,
  loadBalanceRuleFields,
  messageTemplateContextPaths,
  messageTemplateContextPathsByScope,
  metricsCountGroupBy,
  metricsFrequency,
  metricsHistogramBuckets,
  notificationEventsSupported,
  notificationScopes,
  occurrenceCirculationDirection,
  orderTypeSubTypes,
  paraguayCities,
  permissionLabelType,
  permissionsWithLabel,
  personDamageTypes,
  personParents,
  providerUserTypes,
  realPropertyOwnershipTypes,
  realPropertyTypes,
  requiredFieldMessage,
  resources,
  retrieveCaseInspectionTokenResource,
  searchableEntities,
  sessionTypes,
  sexTypes,
  slaReportColumns,
  slaTypesWithTotalDuration,
  sortTypes,
  ssnStatementCsvOutputKeys,
  teamType,
  themes,
  timezones,
  transformationApplyTo,
  transformers,
  userTypes,
  validationMessages,
  vehicleDocumentTypes,
  vehicleIdentificationKeys,
  vehicleUses,
  victimInjuriesRolesWithMaterialDamage,
  virtualMetrics,
  webhookEvents,
  withLicensePlateVehicleTypes,
  withoutLicensePlateVehicleTypes
});
