'use client';

import * as React from 'react';
import * as TabsPrimitive from '@radix-ui/react-tabs';
import { cn } from '../lib/utils';
import { VariantProps, cva } from 'class-variance-authority';
import { useSafeContext } from '../hooks/useSafeContext';

const tabsListVariants = cva('', {
  variants: {
    variant: {
      filled:
        'inline-flex h-10 items-center justify-center rounded-md bg-muted p-1 text-muted-foreground',
      underline:
        'inline-flex h-10 items-center text-muted-foreground w-full justify-start rounded-none border-b bg-transparent p-0',
    },
  },
  defaultVariants: {
    variant: 'filled',
  },
});

interface TabsListContext extends VariantProps<typeof tabsListVariants> {}

const TabsListContext = React.createContext<TabsListContext>({
  variant: 'filled',
});

const TabsListProvider = (props: React.PropsWithChildren<TabsListContext>) => {
  return (
    <TabsListContext.Provider value={props}>
      {props.children}
    </TabsListContext.Provider>
  );
};

const useTabsListContext = () => useSafeContext(TabsListContext);

/**
 * A set of layered sections of content—known as tab panels—that are displayed one at a time.
 */
const Tabs = TabsPrimitive.Root;

const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List> &
    VariantProps<typeof tabsListVariants>
>(({ className, ...props }, ref) => {
  const ctx = useTabsListContext();
  return (
    <TabsPrimitive.List
      ref={ref}
      className={cn(
        tabsListVariants({
          className,
          variant: ctx.variant,
        }),
      )}
      {...props}
    />
  );
});
TabsList.displayName = TabsPrimitive.List.displayName;

export const tabsTriggerVariants = cva('', {
  variants: {
    variant: {
      filled:
        'inline-flex items-center justify-center whitespace-nowrap rounded-sm px-3 py-1.5 text-sm font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow-sm',
      underline:
        'inline-flex items-center justify-center whitespace-nowrap py-1.5 text-sm ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-background relative rounded-none border-b-2 border-b-transparent bg-transparent px-4 pb-3 pt-2 font-semibold text-muted-foreground shadow-none transition-none data-[state=active]:border-b-primary data-[state=active]:text-foreground data-[state=active]:shadow-none',
    },
  },
  defaultVariants: {
    variant: 'filled',
  },
});

const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => {
  const ctx = useTabsListContext();
  return (
    <TabsPrimitive.Trigger
      ref={ref}
      className={tabsTriggerVariants({
        className,
        variant: ctx.variant,
      })}
      {...props}
    />
  );
});
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn(
      'mt-2 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
      className,
    )}
    {...props}
  />
));
TabsContent.displayName = TabsPrimitive.Content.displayName;

export { Tabs, TabsList, TabsTrigger, TabsContent, TabsListProvider };
