import {
  IconArticle,
  IconFileText,
  IconId,
  IconSquareCheck,
} from '@tabler/icons-react';
import { FileTypes } from 'database';
import { flatten } from 'lodash';
import { Plus } from 'lucide-react';
import { useCallback, useMemo, useRef, useState } from 'react';
import { cn } from 'ui';
import { getHumanFileSize } from 'utils';

const TWENTY_MB_IN_BYTES = 20 * 1024 * 1024;

export const mimetypes: {
  [K in FileTypes]: string[];
} = {
  other: ['*'],
  pdf: ['application/pdf'],
  audio: ['audio/mpeg', 'audio/ogg', 'audio/wav', 'audio/webm'],
  video: ['video/mpeg', 'video/ogg', 'video/webm', 'video/mp4'],
  image: ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/svg+xml'],
  document: [
    'text/plain',
    'text/csv',
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  ],
};

export interface FileInputProps {
  invalid?: boolean;
  maxFiles?: number;
  disabled?: boolean;
  fileTypes?: FileTypes[];
  maxFileSize?: number;
  onDropFiles: (files: File[]) => void;
}

const fileTypesLabels: {
  [K in FileTypes]: {
    plural: string;
    singular: string;
  };
} = {
  pdf: {
    plural: 'PDFs',
    singular: 'un PDF',
  },
  document: {
    plural: 'documentos',
    singular: 'un documento',
  },
  image: {
    plural: 'imágenes',
    singular: 'una imagen',
  },
  audio: {
    plural: 'audios',
    singular: 'un audio',
  },
  video: {
    plural: 'videos',
    singular: 'un video',
  },
  other: {
    plural: 'archivos',
    singular: 'cualquier archivo',
  },
};

const iconDocKeys = ['card_back', 'card_front'] as const;

type IconType = (typeof iconDocKeys)[number];

const icons: Record<IconType, JSX.Element> = {
  card_back: <IconArticle className='w-9 h-9' />,
  card_front: <IconId className='w-9 h-9' />,
};

export const FileInput = ({
  fileTypes = ['image', 'document'],
  maxFileSize = TWENTY_MB_IN_BYTES,
  disabled,
  onDropFiles,
  maxFiles = 1,
  invalid,
  label,
  docKey,
  children,
  isFileLoaded,
}: FileInputProps & {
  label?: string;
  docKey?: string;
  children?: React.ReactNode;
  isFileLoaded?: boolean;
}) => {
  const [isDragging, setIsDragging] = useState(false);

  const labelId = label?.replaceAll(' ', '-').toLowerCase() || '';

  const inputRef = useRef<HTMLInputElement>(null);

  const dataTransferItemsToFiles = useCallback(
    (dataTransferList: DataTransferItemList) => {
      const files: File[] = [];

      for (let i = 0; i < dataTransferList.length; i++) {
        const item = dataTransferList[i];

        const file = item.getAsFile();

        if (file) {
          files.push(file);
        }
      }

      return files;
    },
    [],
  );

  const supportedFileTypes = useMemo(
    () => flatten(fileTypes.map((type) => mimetypes[type])),
    [],
  );

  const handleFile = useCallback(
    (files: File[]) => {
      console.log('files from handleFile args', files);

      if (!files.length) {
        return;
      }

      const filteredFiles = files
        .filter((f) => {
          const sizeCheck = f.size <= maxFileSize;

          const filetypeCheck = fileTypes.includes('other' satisfies FileTypes)
            ? true
            : supportedFileTypes.includes(f.type);

          return sizeCheck && filetypeCheck;
        })
        .slice(0, maxFiles);

      console.log(
        'filteredFiles',
        filteredFiles,
        'maxFiles',
        maxFiles,
        'onDropFiles',
        onDropFiles,
      );

      onDropFiles(filteredFiles);
    },
    [onDropFiles],
  );

  const iconKey = iconDocKeys.find((iconDocKey) =>
    docKey?.includes(iconDocKey),
  );

  return (
    <div
      onDrop={(ev) => {
        ev.preventDefault();

        if (disabled) return;

        if (ev.dataTransfer.items) {
          const files = dataTransferItemsToFiles(ev.dataTransfer.items);

          handleFile(files);
        }
        setIsDragging(false);
      }}
      onDragLeave={() => setIsDragging(false)}
      onDragEnter={() => {
        if (!disabled) {
          setIsDragging(true);
        }
      }}
      onDragOver={(ev) => ev.preventDefault()}
      className={cn(
        'hover:border-slate-700 hover:[&>*]:text-slate-700 relative transition-all rounded-sm border border-dashed border-piramid-black p-6',
        {
          'border-slate-700 [&>*]:text-slate-700': isDragging,
          'border-red-500': invalid,
          'border-solid border-primary': isFileLoaded,
        },
      )}
    >
      {!isDragging && (
        <input
          ref={inputRef}
          multiple={maxFiles > 1}
          onChange={(ev) => {
            if (ev.target.files?.length) {
              console.log('files to upload', ev.target.files);

              handleFile(Array.from(ev.target.files));
            }

            if (inputRef.current) {
              inputRef.current.value = '';
            }
          }}
          className={cn('opacity-0 absolute inset-0 w-full h-full', {
            'pointer-events-none touch-none': isDragging,
          })}
          disabled={disabled}
          type='file'
          id={`upload-file-${labelId}`}
        />
      )}

      <div
        className={cn('flex text-neutral-300 items-center flex-col space-y-2', {
          'pointer-events-none cursor-not-allowed': !disabled,
        })}
      >
        <span className='text-primary w-9 h-9'>
          {isFileLoaded ? (
            <IconSquareCheck className='w-9 h-9' />
          ) : (
            <IconFileText className='w-9 h-9' />
          )}
        </span>
        <p
          className={cn(
            'font-medium text-[1rem] truncate text-center text-piramid-black',
          )}
        >
          {label}
        </p>
        {isFileLoaded ? (
          children
        ) : (
          <p className='text-[.625rem] text-center h-10 w-36'>
            Sube archivos o arrójalos aquí. Límite{' '}
            {getHumanFileSize(maxFileSize)}
          </p>
        )}
      </div>
    </div>
  );
};
