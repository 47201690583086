'use client';

import * as React from 'react';
import { DialogProps } from '@radix-ui/react-dialog';
import { Command as CommandPrimitive } from 'cmdk';
import { Search } from 'lucide-react';

import { cn } from '../lib/utils';
import { Dialog, DialogContent } from './Dialog';
import { Spinner } from './Spinner';
import { ScrollArea } from './ScrollArea';
import { Input, inputVariants } from './Input';
import { Separator } from '..';

const Command = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive>
>(({ className, ...props }, ref) => (
  <CommandPrimitive
    ref={ref}
    className={cn(
      'flex h-full w-full flex-col overflow-hidden rounded-md bg-popover text-popover-foreground',
      className,
    )}
    {...props}
  />
));
Command.displayName = CommandPrimitive.displayName;

interface CommandDialogProps extends DialogProps {}

const CommandDialog = ({ children, ...props }: CommandDialogProps) => {
  return (
    <Dialog {...props}>
      <DialogContent className='overflow-hidden p-0 shadow-lg'>
        <Command className='[&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:font-medium [&_[cmdk-group-heading]]:text-muted-foreground [&_[cmdk-group]:not([hidden])_~[cmdk-group]]:pt-0 [&_[cmdk-group]]:px-2 [&_[cmdk-input-wrapper]_svg]:h-5 [&_[cmdk-input-wrapper]_svg]:w-5 [&_[cmdk-input]]:h-12 [&_[cmdk-item]]:px-2 [&_[cmdk-item]]:py-3 [&_[cmdk-item]_svg]:h-5 [&_[cmdk-item]_svg]:w-5'>
          {children}
        </Command>
      </DialogContent>
    </Dialog>
  );
};

const CommandInputForm = React.forwardRef<
  React.ElementRef<typeof Input>,
  React.ComponentPropsWithoutRef<typeof Input> & {
    loading?: boolean;
    onValueChange?: (search: string) => void;
    classNames?: {
      container?: string;
      icon?: string;
      iconSeparator?: string;
      iconSeparatorContainer?: string;
      spinner?: string;
    };
    withIconSeparator?: boolean;
    withIconSeparatorContainer?: boolean;
  }
>(
  (
    {
      className,
      loading,
      onValueChange,
      classNames,
      withIconSeparator = false,
      withIconSeparatorContainer = false,
      ...props
    },
    ref,
  ) => {
    return (
      <div
        className={cn(
          inputVariants({
            variant: 'outline',
          }),
          'w-full relative flex items-center',
          classNames?.container,
        )}
      >
        <div className='absolute left-2 h-full flex items-center justify-center'>
          {loading ? (
            <Spinner className={cn(classNames?.spinner)} size={'xs'} />
          ) : (
            <Search
              className={cn(
                'mr-2 h-4 w-4 shrink-0 opacity-50',
                classNames?.icon,
              )}
            />
          )}
        </div>
        {withIconSeparatorContainer && (
          <div className={cn('w-5 h-5', classNames?.iconSeparatorContainer)}>
            {withIconSeparator && (
              <Separator
                className={cn(classNames?.iconSeparator)}
                orientation='vertical'
              />
            )}
          </div>
        )}

        <Input
          {...props}
          className={cn('w-full !bg-transparent border-none', className)}
          variant={'unstyled'}
          ref={ref}
          onChange={(e) => {
            if (onValueChange) {
              onValueChange(e.target.value);
            }
          }}
        />
      </div>
    );
  },
);

const CommandInput = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Input>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Input> & {
    loading?: boolean;
    classNames?: {
      icon?: string;
      container?: string;
    };
  }
>(({ className, loading, classNames, ...props }, ref) => (
  <div
    className={cn('flex items-center border-b px-3', classNames?.container)}
    cmdk-input-wrapper=''
  >
    {loading ? (
      <Spinner size={'xs'} />
    ) : (
      <Search
        className={cn('mr-2 h-4 w-4 shrink-0 opacity-50', classNames?.icon)}
      />
    )}
    <CommandPrimitive.Input
      ref={ref}
      className={cn(
        'flex h-11 w-full rounded-md bg-transparent py-3 text-sm outline-none placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50',
        className,
      )}
      {...props}
    />
  </div>
));

CommandInput.displayName = CommandPrimitive.Input.displayName;

const CommandList = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.List> & {
    withScroll?: boolean;
    classNames?: {
      scrollarea?: string;
    };
  }
>(({ className, classNames, children, withScroll = true, ...props }, ref) => (
  <CommandPrimitive.List
    ref={ref}
    className={cn('max-h-[300px] overflow-y-auto overflow-x-hidden', className)}
    {...props}
  >
    {withScroll ? (
      <ScrollArea
        orientation='vertical'
        className={cn('max-h-[300px] flex flex-col', classNames?.scrollarea)}
      >
        {children}
      </ScrollArea>
    ) : (
      children
    )}
  </CommandPrimitive.List>
));

CommandList.displayName = CommandPrimitive.List.displayName;

const CommandEmpty = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Empty>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Empty>
>((props, ref) => (
  <CommandPrimitive.Empty
    ref={ref}
    className='py-6 text-center text-sm'
    {...props}
  />
));

CommandEmpty.displayName = CommandPrimitive.Empty.displayName;

const CommandGroup = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Group>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Group>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.Group
    ref={ref}
    className={cn(
      'overflow-hidden p-1 text-foreground [&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:py-1.5 [&_[cmdk-group-heading]]:text-xs [&_[cmdk-group-heading]]:font-medium [&_[cmdk-group-heading]]:text-muted-foreground',
      className,
    )}
    {...props}
  />
));

CommandGroup.displayName = CommandPrimitive.Group.displayName;

const CommandSeparator = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.Separator
    ref={ref}
    className={cn('-mx-1 h-px bg-border', className)}
    {...props}
  />
));
CommandSeparator.displayName = CommandPrimitive.Separator.displayName;

const CommandItem = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Item>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.Item
    ref={ref}
    className={cn(
      'relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none aria-selected:bg-accent aria-selected:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
      className,
    )}
    {...props}
  />
));

CommandItem.displayName = CommandPrimitive.Item.displayName;

const CommandShortcut = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLSpanElement>) => {
  return (
    <span
      className={cn(
        'ml-auto text-xs tracking-widest text-muted-foreground',
        className,
      )}
      {...props}
    />
  );
};
CommandShortcut.displayName = 'CommandShortcut';

const CommandLoading = CommandPrimitive.Loading;

export {
  Command,
  CommandDialog,
  CommandInput,
  CommandList,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandShortcut,
  CommandSeparator,
  CommandLoading,
  CommandInputForm,
};
